import types from '../types';

const defaultState = {
  changePasswordResponse: null,
  loading: false,
  error: null,
};

const changePassword = (state = defaultState, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordResponse: action.changePasswordResponse,
        error: null,
        loading: false,
      };
    case types.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        error: "",
        loading: false,
        changePasswordResponse: "",
      };
    default:
      return state;
  }
};
export default changePassword;
