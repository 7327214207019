import React, {useEffect, useState} from 'react'
import {Card, Col, Container, Form, Row, Spinner, Table} from "react-bootstrap";
import './style.css'
import {connect} from "react-redux";
import {
  cancelSubscription, clearCancelSubsError, getCardInfo, getInvoiceInfo
} from "../../../redux/actions/billingInfoAction";
import {getCurrentPricingPlan, postBillingInfo} from "../../../redux/actions/billingAction";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {CARD_OPTIONS, stateOptions, stripePromise} from "../../../utils/utils";
import {useSnackbar} from "notistack";
import ButtonComponent from "../../../components/ButtonComponent";
import Select from "react-select";
import axiosInstance from "../../../services/api/axios";
import {onLogout} from "../../../redux/actions/login";
import {useHistory} from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const BillingInfoPage = (props) => {
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [cardBtn, setCardbtn] = useState(false)
  const [subsPause, setSubsPause] = useState(false)
  const [displayCcFields, setDisplayCcFields] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [ccInfo, setCcInfo] = useState(null)
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentCard, setCurrentCard] = useState(null);
  const {
    getAllInvoices,
    invoiceState,
    currentPricingPlanState,
    fetchCurrentPricingPlan,
    cardState,
    fetchCreditCardInfo,
    billingAction,
    cardDataState,
    cancelSubscriptionState,
    cancelSubscriptionDispatch,
    resetCancelSubsDispatch
  } = props;

  useEffect(() => {
    getAllInvoices();
    fetchCreditCardInfo();
    if (!currentPricingPlanState.data) {
      fetchCurrentPricingPlan();
    }
  }, [])

  useEffect(() => {
    if (!invoiceState.loading) {
      if (invoiceState.error) {
        enqueueSnackbar(invoiceState.error, {variant: "error"});
      } else if (invoiceState.data) {
        setInvoiceData(invoiceState.data.results)
      }
    }
  }, [invoiceState])

  useEffect(() => {
    if (!cancelSubscriptionState.loading) {
      if (cancelSubscriptionState.data) {
        enqueueSnackbar('Plan has been paused.', {variant: "success"});
        resetCancelSubsDispatch()
        setSubsPause(false)
        fetchCurrentPricingPlan()
      }
      if (cancelSubscriptionState.error) {
        enqueueSnackbar(cancelSubscriptionState.error, {variant: "error"});
      }
    }
  }, [cancelSubscriptionState])

  useEffect(() => {
    if (!cardState.loading) {
      if (cardState.error) {
        enqueueSnackbar(cardState.error, {variant: "error"});
      } else if (cardState.data) {
        setCurrentCard(cardState.data.card_last_digits);
      }
    }
  }, [cardState])

  useEffect(() => {
    if (currentPricingPlanState.data?.id) {
      setCurrentPlan(currentPricingPlanState.data)
    } else {
      setCurrentPlan(null)
    }
  }, [currentPricingPlanState])

  useEffect(() => {
    if (!cardDataState.loading) {
      if (cardDataState?.error) {
        setBtnLoading(false)
        try {
          Object.values(cardDataState?.error?.response?.data).map((err) => {
            enqueueSnackbar(err, {variant: "error"})
          });
        } catch (err) {
          enqueueSnackbar(err.message, {variant: "error"})
        }
      }
      if (!cardDataState?.error && cardDataState?.data && !cardDataState?.loading) {
        setBtnLoading(false)
        fetchCreditCardInfo();
        setCardbtn(false)
        enqueueSnackbar(cardDataState?.data?.message, {variant: "success"});
      }
    } else {
      setBtnLoading(true)
    }
  }, [cardDataState]);

  const convertDate = (dateRange) => dateRange.split(" - ")
    .map(date => new Intl.DateTimeFormat('en-US').format(new Date(date)))
    .join(" - ")

  const invoiceClicked = (id) => {
    const linkSource = process.env.REACT_APP_BASE_URL + `business/invoices/${id}/download/`;
    axiosInstance({
      url: linkSource, method: 'GET', responseType: 'blob'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
    })
  }

  const displayCreditCardFields = () => {
    setDisplayCcFields(!displayCcFields);
  }

  const creditCardInfo = data => {
    console.log(data)
    setCcInfo({...data})
    if (data.card_token && data.street) {
      setCardbtn(true)
    } else {
      setCardbtn(false)
    }
  }

  const handleCardSave = () => {
    setBtnLoading(true)
    billingAction(ccInfo)
  }

  const LoaderComponent = () => {
    return (<div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <Spinner animation="border" className="spinner"/>
    </div>)
  }

  const pauseSubscriptionClick = () => {
    setSubsPause(true)
  }

  const pauseSubscriptionConfirmation = () => {
    cancelSubscriptionDispatch()
  }

  return (<div className='containerMain h-auto' style={{minHeight: "100%"}}>
    <Container fluid className="billing-info-container">
      <Row>
        <Col className='billing-info-col'>
          BILLING INFO
        </Col>
      </Row>

      {currentPlan ? <Row>
        <Card className="business-info-card">
          <Card.Body className="d-flex flex-row">
            <>
              <div style={{width: "80%"}}>

                <Card.Title className="billing-info-card-title">
                  Current plan
                </Card.Title>

                <div className="current-plan-details">
                  {
                    <span>{currentPlan?.name} - ${currentPlan?.amount} - {currentPlan?.no_of_items} job listing credits / {currentPlan?.recurring_interval}</span>}
                  {currentPlan?.is_on_trial ?
                    <span>ON TRIAL - {currentPlan?.trial_days} days remaining - {currentPlan?.remaining_credits} credits remaining</span> : <></>}
                </div>
              </div>

              <div style={{width: "auto"}}>
                {currentPlan && currentPlan.is_bundle_type ? <div className="billing-tracker-wrapper"
                                                                  style={{backgroundColor: currentPlan.isCustom && currentPlan.isOnTrial ? "#2C2B7C" : "#EE6C4D"}}>
                  <div style={{fontSize: '15px'}}
                       className="mt-1">
                    {currentPlan.remaining_credits !== null && currentPlan.remaining_credits >= 0 ?
                      <span>{currentPlan.remaining_credits}/{currentPlan?.no_of_items}</span> : <span>25/25</span>}
                  </div>
                  <div style={{fontSize: '16px'}}>Credits Remaining</div>
                </div> : <></>}
              </div>
            </>
          </Card.Body>
        </Card>
      </Row> : <></>}

      <Row>
        <Card className="business-info-card">
          <Card.Body>
            {currentCard ? <>
              <Card.Title className="billing-info-card-title">
                Credit card info
              </Card.Title>

              <div className="current-card-details">
                Your current card number ends with <span style={{color: "#EE6C4D"}}>{currentCard}</span>.{" "}
                {displayCcFields ? <span className="add-new-card"
                                         onClick={displayCreditCardFields}> I will stick with the current card on file.</span> :
                  <span> To change your card on file, <span className="add-new-card"
                                                            onClick={displayCreditCardFields}>add a new card.</span></span>}

              </div>

              {displayCcFields ? <div className="mt-4">
                <Elements stripe={stripePromise}>
                  <StripeInputComponent cardInfo={creditCardInfo}/>
                </Elements>
              </div> : <></>}

              {cardBtn ? <div className="text-right w-100">
                <ButtonComponent name={"Save Card"}
                                 idName="save-card-btn"
                                 type={'submit'}
                                 loading={btnLoading}
                                 disabled={btnLoading}
                                 onClickFunction={handleCardSave}/>

              </div> : <></>}
            </> : <LoaderComponent/>}
          </Card.Body>
        </Card>
      </Row>

      {currentPlan ? <Row>
        <Card className="business-info-card">
          <Card.Body className="d-flex flex-row">
            <div style={{width: "80%"}}>
              <Card.Title className="billing-info-card-title">
                Pause Subscription
              </Card.Title>

              <div className="current-plan-details">
                <div className="current-card-details">
                  Do you want to pause the current subscription? <span className="add-new-card"
                                                                       onClick={pauseSubscriptionClick}>Yes, pause my subscription.</span>

                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Row> : <></>}

      <Row>
        <Card className="business-info-card" style={{maxHeight: "450px"}}>
          <Card.Body>
            {invoiceData ? <>
              <Card.Title className="billing-info-card-title">
                Receipts
              </Card.Title>

              <div style={{overflowY: "auto", height: "90%"}}>
                <Table responsive borderless hover>
                  <thead>
                  <tr className="billing-receipt-tr">
                    <th>Invoice #</th>
                    <th>Date</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody style={{overflowY: "auto"}} className="billing-receipt-td">
                  {invoiceData && invoiceData.map((data, key) => <tr key={key}>
                    <td className="billing-receipt-inv"
                        onClick={() => invoiceClicked(data.id)}>{data.invoice_id}</td>
                    <td>{convertDate(data.invoice_date)}</td>
                    <td>${data.net_total}</td>
                  </tr>)}
                  </tbody>
                </Table>
              </div>
            </> : <LoaderComponent/>}
          </Card.Body>
        </Card>
      </Row>

      {subsPause && <Modal show={subsPause} dialogClassName="billing-confirm-modal" backdrop="static"
                           onHide={() => setSubsPause(false)}
                           aria-labelledby="contained-modal-title-vcenter" keyboard={false}
                           centered>
        <Container className="modal-body-container" style={{textAlign: 'center'}}>
          <div className="active-info">You will lose access to
            the {currentPlan.remaining_credits} of your remaining credits, and you will lose access to your favorites
            and applicants. Do you want to
            proceed?
          </div>

          <div className="d-flex flex-row align-items-center justify-content-around" style={{marginTop: '25px'}}>
            <ButtonComponent idName='confirmation-cancel-btn' name='Cancel'
                             onClickFunction={() => setSubsPause(false)}/>
            <ButtonComponent idName='confirm-final-btn' name='Confirm'
                             onClickFunction={() => pauseSubscriptionConfirmation()}
                             loading={cancelSubscriptionState.loading} disabled={cancelSubscriptionState.loading}/>
          </div>
        </Container>
      </Modal>}
    </Container>
  </div>)
}

const mapStateToProps = (state) => ({
  meState: state.me,
  invoiceState: state.setBillingInvoice.billingInvoices,
  cardState: state.setBillingCardInfo,
  currentPricingPlanState: state.setBillingInfo.currentPricingPlan,
  cardDataState: state.setBillingInfo.cardData,
  cancelSubscriptionState: state.cancelSubscriptionReducer.cancelSubscription
});

const mapDispatchToProps = (dispatch) => ({
  getAllInvoices: () => dispatch(getInvoiceInfo()),
  fetchCurrentPricingPlan: () => dispatch(getCurrentPricingPlan()),
  fetchCreditCardInfo: () => dispatch(getCardInfo()),
  billingAction: (data) => dispatch(postBillingInfo(data)),
  onLogout: () => dispatch(onLogout()),
  cancelSubscriptionDispatch: () => dispatch(cancelSubscription()),
  resetCancelSubsDispatch: () => dispatch(clearCancelSubsError())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfoPage);


const StripeInputComponent = props => {
  const {enqueueSnackbar} = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [selectedOption, setSelectedOption] = useState(null);
  const [creditCardData, setCreditCardData] = useState({
    card_token: "", street: "", street_alt: "", city: "", state: "", zip: "", phone: "",
  });

  useEffect(() => {
    props.cardInfo(creditCardData)
  }, [creditCardData])

  const onDataChange = (event) => {
    const {id, value} = event.target;
    if (event) {
      setCreditCardData({...creditCardData, [id]: value});
    }
  }

  const cardChange = async (event) => {
    if (event?.error) {
      enqueueSnackbar(event?.error?.message || "Invalid card number", {variant: "error"})
    } else {
      if (event.complete) {
        const cardElement = elements.getElement(CardElement);
        const {error, token} = await stripe.createToken(cardElement);
        if (token) {
          setCreditCardData({...creditCardData, card_token: token.id});
        } else {
          enqueueSnackbar(error, {variant: "error"})
        }
      }
    }
  }

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setCreditCardData({
      ...creditCardData, state: selectedOption.value.name
    })
  };

  return (<>
    <div className="d-flex flex-row" style={{height: '60px'}}>
      <div className="w-50">
        <div className="custom-stripe-input-wrapper">
          <CardElement id="stripeElement" onChange={cardChange}
                       options={CARD_OPTIONS}
          />
        </div>
      </div>

      <div className="w-25 ml-3">
        <Form.Group controlId="city" style={{width: '100%'}}>
          <Form.Control placeholder="City" required className="card-input-green"
                        onChange={onDataChange}/>
        </Form.Group>
      </div>
      <div className="w-25 ml-3">
        <Form.Group controlId="street" style={{width: '100%'}}>
          <Form.Control placeholder="Street" required className="card-input-green"
                        onChange={onDataChange}/>
        </Form.Group>
      </div>
    </div>

    <div className="d-flex flex-row" style={{height: '60px'}}>
      <Form.Group controlId="card_name" style={{width: "calc(100% / 5"}}>
        <Form.Control placeholder="Name On Card" className="card-input-green" onChange={onDataChange}/>
      </Form.Group>

      <Form.Group controlId="street_alt" className="ml-1" style={{width: "calc(100% / 5"}}>
        <Form.Control placeholder="Street alt" className="card-input-green" onChange={onDataChange}/>
      </Form.Group>

      <Form.Group controlId="zip" className="ml-1" style={{width: "calc(100% / 5"}}>
        <Form.Control placeholder="Zip" className="card-input-green" onChange={onDataChange} type={"number"}
                      max="5"/>
      </Form.Group>

      <Form.Group controlId="phone" className="ml-1" style={{width: "calc(100% / 5"}}>
        <Form.Control placeholder="Phone" type={"number"} className="card-input-green" onChange={onDataChange}/>
      </Form.Group>

      <div className="ml-1" style={{width: "calc(100% / 5"}}>
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={stateOptions}
          placeholder={'State'}
          isMulti={false}
          id="card-location-select"
        />
      </div>
    </div>
  </>)
}
