import types from "../types";

const defaultState = {
  addLocationResponse: null,
  loading: false,
  error: null,
  locationData: {
    data: {},
    error: "",
    loading: false,
  },
  hiringManagers: {
    data: [],
    error: "",
    loading: false,
  },
  deleteLocation: {
    loading: false,
    success: "",
    error: "",
  },
  editLocation: {
    loading: false,
    success: "",
    error: "",
  },
  selectedLocation: {
    loading: false,
    error: "",
    data: {
      business_owners: [],
      hiring_managers: [],
      jobs: {},
      location: {}
    },
  },
  locationsWithJobs : {
    loading: false,
    data: "",
    error: ""
  }
};

const addLocation = (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_LOCATION_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_LOCATION:
      return {
        ...state,
        addLocationResponse: action.response,
        loading: false,
        error: null,
      };
    case types.ADD_LOCATION_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.GET_LOCATION_DATA:
      return {
        ...state,
        locationData: {
          data: action.data,
          error: "",
          loading: false,
        },
      };
    case types.GET_LOCATION_LOADING:
      return {
        ...state,
        locationData: {
          data: {},
          error: "",
          loading: true,
        },
      };
    case types.GET_LOCATION_FAILED:
      return {
        ...state,
        locationData: {
          data: {},
          error: action.error,
          loading: false,
        },
      };
    case types.GET_HIRING_MANAGERS_LOADING:
      return {
        ...state,
        hiringManagers: {
          data: [],
          error: "",
          loading: true,
        },
      };
    case types.GET_HIRING_MANAGERS_FAILED:
      return {
        ...state,
        hiringManagers: {
          data: [],
          error: action.error,
          loading: false,
        },
      };
    case types.GET_HIRING_MANAGERS_SUCCESS:
      return {
        ...state,
        hiringManagers: {
          data: action.data,
          error: "",
          loading: false,
        },
      };
    case types.EDIT_LOCATION_LOADING:
      return {
        ...state,
        editLocation: {
          success: "",
          error: "",
          loading: true,
        },
      };
    case types.EDIT_LOCATION_ERROR:
      return {
        ...state,
        editLocation: {
          success: "",
          error: action.error,
          loading: false,
        },
      };
    case types.EDIT_LOCATION_SUCCESS:
      return {
        ...state,
        editLocation: {
          success: action.data,
          error: "",
          loading: false,
        },
      };
    case types.DELETE_LOCATION_LOADING:
      return {
        ...state,
        deleteLocation: {
          success: "",
          error: "",
          loading: true,
        },
      };
    case types.DELETE_LOCATION_ERROR:
      return {
        ...state,
        deleteLocation: {
          success: "",
          error: action.error,
          loading: false,
        },
      };
    case types.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        deleteLocation: {
          success: action.data,
          error: "",
          loading: false,
        },
      };
    case types.REFRESH_MANAGE_LOCATION_EDIT:
      return {
        ...state,
        editLocation: {
          success: false,
          error: "",
          loading: false,
        },
      };
    case types.REFRESH_MANAGE_LOCATION_DELETE:
      return {
        ...state,
        deleteLocation: {
          success: false,
          error: "",
          loading: false,
        },
      };
    case types.SET_SELECTED_LOCATION_LOADING:
      return {
        ...state,
        selectedLocation: {
          data: "",
          error: "",
          loading: true,
        },
      };
    case types.SET_SELECTED_LOCATION_FAILED:
      return {
        ...state,
        selectedLocation: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: {
          data: action.data,
          error: "",
          loading: false,
        },
      };
    case types.CHANGE_UPDATED_JOB_DATA:
      return {
        ...state,
        selectedLocation: {
          ...state.selectedLocation,
          data: {
            ...state.selectedLocation.data,
            jobs: state.selectedLocation.data.jobs.length > 0 && state.selectedLocation.data.jobs.map((job) => job.id === action.updateJobData.job_id ? { ...job, title: action.updateJobData.title } : job )
          }
        },
      };
      case types.CLEAR_SELECTED_LOCATION_ERROR:
      return {
        ...state,
        selectedLocation: {
          data: "",
          error: "",
          loading: false,
        },
      };
      case types.CLEAR_FETCHED_LOCATIONS_ERROR: 
      return {
        ...state,
        locationData: {
          data: "",
          error: "",
          loading: false
        }
      }
      case types.CLEAR_ADD_LOCATION_ERROR: 
      return {
        ...state,
        addLocationResponse: "",
        error: "",
        loading: false
      }
      case types.GET_LOCATIONS_WITH_JOB_LOADING: 
      return {
        ...state,
        locationsWithJobs : {
          loading: true,
          data: "",
          error: ""
        }
      }
      case types.GET_LOCATIONS_WITH_JOB_ERROR: 
      return {
        ...state,
        locationsWithJobs : {
          loading: false,
          data: "",
          error: action.error
        }
      }
      case types.GET_LOCATIONS_WITH_JOB_SUCCESS: 
      return {
        ...state,
        locationsWithJobs : {
          loading: false,
          data: action.data,
          error: ""
        }
      }
      case types.CLEAR_LOCATION_WITH_JOB_ERROR: 
      return {
        ...state,
        locationsWithJobs : {
          loading: false,
          data: "",
          error: ""
        }
      }
    default:
      return state;
  }
};
export default addLocation;
