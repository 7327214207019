import types from "../types";

const defaultState = {
  error: "",
  loading: false,
  data: "",
};

const favourites = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_FAVOURITES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_FAVOURITES_FAILED:
      return {
        data: "",
        error: action.error,
        loading: false,
      };
    case types.GET_FAVOURITES_SUCCESS:
      return {
        error: "",
        data: action.data?.results,
        loading: false,
      };
    default:
      return state;
  }
};
export default favourites;
