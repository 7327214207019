import types from "../types";
import axiosInstance from "../../services/api/axios";

const getApplicationQuestionsLoading = () => ({
  type: types.GET_APPLICATION_QUESTIONS_LOADING,
});

const getQuestions = (data) => ({
  type: types.GET_APPLICATION_QUESTIONS,
  data,
});

const getApplicationQuestionsFailed = (error) => ({
  type: types.GET_APPLICATION_QUESTIONS_FAILED,
  error,
});

const setApplicationQuestionsLoading = () => ({
  type: types.SET_APPLICATION_QUESTIONS_LOADING,
});

const setQuestions = (data) => ({
  type: types.SET_APPLICATION_QUESTIONS,
  data,
});

const setApplicationQuestionsFailed = (error) => ({
  type: types.SET_APPLICATION_QUESTIONS_FAILED,
  error,
});

export const getApplicationQuestions = () => {
  return async (dispatch) => {
    try {
      dispatch(getApplicationQuestionsLoading());
      const response = await axiosInstance.get("/profile/questions/");
      if (response && response.status === 200) {
        dispatch(getQuestions(response.data.data));
      }
    } catch (err) {
      dispatch(getApplicationQuestionsFailed(err.message));
    }
  };
};

export const setApplicationQuestions = (data) => {
  let dataArray = [];
  for (const [key, value] of Object.entries(data)) {
    dataArray.push({ name: value });
  }
  return async (dispatch) => {
    try {
      dispatch(setApplicationQuestionsLoading());
      const response = await axiosInstance.post("/profile/questions/", {
        questions: dataArray,
      });
      if (response && response.status === 200) {
        dispatch(setQuestions(response.data.message));
      }
    } catch (err) {
      dispatch(setApplicationQuestionsFailed(err.message));
    }
  };
};
