import types from "../types";
import * as locationService from "../../services/api/applicantsService";

const getFavouritesLoading = () => ({
    type: types.GET_FAVOURITES_LOADING,
});

const getFavouritesFailed = (error) => ({
    type: types.GET_FAVOURITES_FAILED,
    error
})

const getFavouritesSuccess = (data) => ({
    type: types.GET_FAVOURITES_SUCCESS,
    data
})

export const getFavouritesList = (jobId) => {
    return async (dispatch) => {
        try {
            dispatch(getFavouritesLoading());
            const response = await locationService.getFavourites(jobId);
            if (response && response.status === 200 && response.data?.data) {
                dispatch(getFavouritesSuccess(response.data.data));
            }
        } catch (err) {
            dispatch(getFavouritesFailed("Could not fetch required favourites"));
        }
    };
};
