import types from "../types";

const defaultState = {
  applicationType: {
    loading: false,
    error: "",
    data: "",
  }
};

const applicationTypeData = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_APPLICATION_TYPE_LOADING:
      return {
        ...state,
        applicationType: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.SET_APPLICATION_TYPE_FAILED:
      return {
        ...state,
        applicationType: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.SET_APPLICATION_TYPE:
      return {
        ...state,
        applicationType: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    default:
      return state;
  }
};
export default applicationTypeData;
