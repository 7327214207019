import React, {useEffect, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as addLocationActions from "../../../redux/actions/addLocation";
import LocationCardComponent from "../../../components/LocationCardComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import
  MapComponent from '../../../components/MapComponent'
import "./style.css";
import {checkPhone, checkZip, stateOptions} from "../../../utils/utils";
import {
  clearFetchedLocationsError,
  getHiringManagers,
  refreshManageLocationDelete,
  refreshManageLocationEdit,
} from "../../../redux/actions";
import StatusConfirmationModal from "../../../components/StatusConfirmationModal";
import {updateLocationStatus} from "../../../services/api/locationService";
import {clearCurrentBusinessPlanState, getCurrentPricingPlan} from "../../../redux/actions/billingAction";
import AddPositionModal from "../LocationPage/AddPositionModal";
import PackageSelectionModal from "../../../components/PackageSelectionModal/PackageSelectionModal";

const initialLocationData = {
  name: "",
  street: "",
  street_alt: "",
  state: "",
  zip: "",
  phone: "",
  lat: "",
  lng: "",
  hiringManagers: [],
};

const LocationManagerPage = (props) => {
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const {
    addLocation,
    add_location,
    getAllLocations,
    allLocationsData = [],
    getHiringManagers,
    hiringManagers = [],
    fetchedLocations,
    editLocationData,
    editLocationState,
    refreshState,
    // deleteLocationState,
    refreshDelete,
    getSelectedLocation,
    clearSelectedLocationError,
    clearFetchError,
    clearAddError,
    fetchCurrentPricingPlan,
    currentPricingPlanState,
    clearCurrentPricingPlanState,
  } = props;
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showAddJobModal, setShowAddJobModal] = useState({status: false, modal: null});
  const [showMap, setShowMap] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState({modalState: false, id: null, status: null, type: null});
  const [selectedId, setSelectedId] = useState(null);
  // const [search, setSearch] = useState("");
  const [isInvite, setIsInvite] = useState(false)
  const [hasCoordinates, setHasCoordinates] = useState(false)
  const [options, setOptions] = useState([]);
  const [newLocationData, setNewLocationData] = useState(initialLocationData);

  const redirectPage = (item) => {
    history.push(item);
  };

  // useEffect(() => {
  //   if (
  //     deleteLocationState.success &&
  //     !deleteLocationState.loading &&
  //     !deleteLocationState.error
  //   ) {
  //     enqueueSnackbar("Location Deleted Successfully", {
  //       variant: "success",
  //     });
  //     refreshDelete();
  //   } else if (
  //     deleteLocationState.error &&
  //     !deleteLocationState.loading &&
  //     !deleteLocationState.success
  //   ) {
  //     enqueueSnackbar("Location Delete Failed !", {
  //       variant: "error",
  //     });
  //     refreshDelete();
  //   }
  // }, [deleteLocationState.error, deleteLocationState.success]);

  useEffect(() => {
    if (!addLocation.loading) {
      if (addLocation.error) {
        setBtnLoading(false);
        enqueueSnackbar("Error adding location. Please try again later", {
          variant: "error",
        });
        clearAddError()
      } else {
        if (
          addLocation.addLocationResponse &&
          !addLocation.loading &&
          newLocationData.zip.length > 0
        ) {
          enqueueSnackbar(
            addLocation.addLocationResponse.message
              ? addLocation.addLocationResponse.message
              : "Success",
            {variant: "success"}
          );
          setNewLocationData(initialLocationData);
          handleClose();
          setValidated(false);
          setBtnLoading(false);
        }
      }
    }
  }, [addLocation.addLocationResponse, addLocation.error]);

  useEffect(() => {
    if (
      editLocationState.success &&
      !editLocationState.loading &&
      !editLocationState.error
    ) {
      setBtnLoading(false);
      if (isInvite) {
        enqueueSnackbar("Success", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Location has been edited successfully", {
          variant: "success",
        });
      }
      handleClose();
      refreshState();
    } else if (
      !editLocationState.success &&
      !editLocationState.loading &&
      editLocationState.error
    ) {
      setBtnLoading(false);
      if (isInvite) {
        enqueueSnackbar("Failed", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Location Edit Failed", {variant: "error"});
      }
      refreshState();
    }
  }, [editLocationState.error, editLocationState.success]);

  useEffect(() => {
    if (fetchedLocations.error && !fetchedLocations.loading) {
      enqueueSnackbar("Error fetching location data. Please try again later", {
        variant: "error",
      });
      clearFetchError()
    }
  }, [fetchedLocations.error]);

  // useEffect(() => {
  //   if (!currentPricingPlanState.data && currentPricingPlanState.error && !currentPricingPlanState.loading && showAddJobModal.modal === "addJob") {
  //     enqueueSnackbar("Could not complete required action", {variant: "error"});
  //     clearCurrentPricingPlanState()
  //   } else if (currentPricingPlanState.data && !currentPricingPlanState.error && !currentPricingPlanState.loading && showAddJobModal.modal === "addJob") {
  //     if (currentPricingPlanState.data?.is_bundle_type == true && currentPricingPlanState.data?.remaining_credits == 0) {
  //       //first show confirm modal
  //       setShowAddJobModal({status: true, modal: "showConfirmModal"});
  //
  //     } else if (currentPricingPlanState.data?.is_bundle_type == false || (currentPricingPlanState.data?.is_bundle_type === true && currentPricingPlanState.data?.remaining_credits > 0)) {
  //       //direct show modal
  //       setShowAddJobModal({status: true, modal: "showAddJob"});
  //     }
  //   }
  // }, [currentPricingPlanState.data, currentPricingPlanState.error])

  useEffect(() => {
    if (
      !currentPricingPlanState.data &&
      currentPricingPlanState.error &&
      !currentPricingPlanState.loading &&
      (showAddJobModal.modal === "addJob" || showStatusModal.type === "check")
    ) {
      enqueueSnackbar("Could not complete required action", {
        variant: "error",
      });
      setShowAddJobModal({status: false, modal: null})
      clearCurrentPricingPlanState();
    } else if (currentPricingPlanState.data && !currentPricingPlanState.error && !currentPricingPlanState.loading) {
      if (showAddJobModal.modal === "addJob" || showStatusModal.type === "check") {
        if (currentPricingPlanState.data?.is_bundle_type && (currentPricingPlanState.data?.remaining_credits > 0 || currentPricingPlanState.data?.remaining_credits === null)) {
          if (showAddJobModal.status && showAddJobModal.modal === "addJob") {
            setShowAddJobModal({status: true, modal: "showAddJob"});
          } else if (showStatusModal.type === "check") {
            setShowStatusModal({
              ...showStatusModal,
              modalState: true,
              type: "job"
            });
          }
        } else if (currentPricingPlanState.data?.is_bundle_type && currentPricingPlanState.data?.remaining_credits === 0) {
          setShowAddJobModal({status: true, modal: "package"});
        } else if (!currentPricingPlanState.data?.is_bundle_type) {
          if (showAddJobModal.modal && showAddJobModal.modal === "addJob") {
            setShowAddJobModal({status: true, modal: "package"});
          } else if (showStatusModal.modalState && showStatusModal.type === "check") {
            if ((showAddJobModal.status && showAddJobModal.modal === "package") || (showStatusModal.modalState && showStatusModal.type === "check")) {
              setShowAddJobModal({status: true, modal: "package"});
            } else if (showAddJobModal.status && showAddJobModal.modal === "showAddJob") {
              setShowStatusModal({
                ...showStatusModal,
                modalState: true,
                type: "job"
              });
            }
          }
        }
      }
    }
  }, [currentPricingPlanState.data, currentPricingPlanState.error]);

  useEffect(() => {
    if (hiringManagers.data?.length > 0) {
      let selectionData = [];
      hiringManagers.data.map((data) => {
        selectionData.push({
          label: data.first_name + " " + data.last_name,
          value: data.email,
        });
      });
    }
  }, [hiringManagers.data]);

  useEffect(() => {
    getAllLocations();
    if (hiringManagers.data?.length === 0 && (props.accountType && props.accountType === "business_owner")) {
      getHiringManagers();
    } else if (hiringManagers.data?.length > 0) {
      let selectionData = [];
      hiringManagers.data.map((data) => {
        selectionData.push({
          label: data.first_name + " " + data.last_name,
          value: data.email,
        });
      });
      setOptions(selectionData);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const allErrors = {};
    if (!newLocationData.name) {
      allErrors.name = "Please enter a name for location";
    }
    if (!newLocationData.street) {
      allErrors.street = "Please enter the street";
    }
    if (!newLocationData.state) {
      allErrors.state = "Please enter the state";
    }
    if (!newLocationData.zip) {
      allErrors.zip = "Please enter your zip code";
    } else {
      if (!checkZip(newLocationData.zip)) {
        allErrors.zip = "Please enter a valid zip code";
      }
    }
    if (!newLocationData.phone) {
      allErrors.phone = "Please enter a valid phone number";
    }
    if (newLocationData.phone) {
      if (!checkPhone(newLocationData.phone)) {
        allErrors.phone = "Please enter a valid phone number";
      }
    }
    if (Object.keys(allErrors).length > 0) {
      setErrors(allErrors);
      setValidated(false);
    } else {
      setBtnLoading(true);
      let postHiringManagers = [];
      if (newLocationData.hiringManagers?.length > 0) {
        newLocationData.hiringManagers.map((data) => {
          postHiringManagers.push({email: data.value});
        });
      }
      let submitData = {
        name: newLocationData.name,
        street: newLocationData.street,
        street_alt: newLocationData.street_alt,
        city: newLocationData.city,
        state: newLocationData.state,
        zip: newLocationData.zip,
        phone: newLocationData.phone,
        lat: newLocationData.lat,
        lng: newLocationData.lng,
        hiring_manager:
          postHiringManagers.length > 0 ? postHiringManagers : null,
      };
      setValidated(true);
      if (newLocationData.lat && newLocationData.lng) {
        if (selectedId !== null) {
          editLocationData(selectedId, submitData);
        } else {
          add_location(submitData);
        }
      } else {
        setBtnLoading(false);
        enqueueSnackbar("Please verify your coordinates.", {
          variant: "error",
        });
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedId(null);
    setErrors({});
    setValidated(false);
    setIsInvite(false);
    setNewLocationData(initialLocationData);
  };

  const handleShow = () => setShow(true);

  const handleSelectCordinatesBtnClick = () => {
    // close the location add modal
    setShow(false)

    // open the map modal
    setShowMap(true)
  }

  const handleChange = (e) => {
    if (Object.keys(errors).length > 0) {
      setErrors({});
      setValidated(false);
    }
    setNewLocationData({...newLocationData, [e.target.name]: e.target.value});
  };

  const handleStateChange = (selectedOption) => {
    setNewLocationData({
      ...newLocationData,
      state: selectedOption.value.name
    })
  };

  const handleSelectChange = (selectedOption) => {
    setNewLocationData({...newLocationData, hiringManagers: selectedOption});
  };

  const locationBtnClick = (e) => {
    // set hasCoordinates to false as we are adding a new location
    setHasCoordinates(false)
    handleShow();
  };

  const onLocationEdit = (data, flag) => {
    if (flag === "invite") {
      setIsInvite(true)
    }
    setSelectedId(data?.location?.id);
    setHasCoordinates(!!data?.location.lat)
    const hiringMgrs = [];
    if (data.hiring_managers?.length > 0) {
      data.hiring_managers.map((manager) => {
        hiringMgrs.push({
          label: manager.first_name + " " + manager.last_name,
          value: manager.email,
        });
      });
    }
    setNewLocationData({
      name: data.location?.name,
      street: data.location?.street,
      street_alt: data.location?.street_alt,
      city: data.location?.city,
      state: data.location?.state,
      zip: data.location?.zip,
      lat: data.location?.lat,
      lng: data.location?.lng,
      phone: data.location?.phone,
      hiringManagers: hiringMgrs,
    });
    handleShow();
  };

  const handleJobModalClose = () => {
    setShowAddJobModal({status: false, modal: null});
  }

  const handleModalShow = (value, id) => {
    setSelectedId(id);
    fetchCurrentPricingPlan()
    setShowAddJobModal({status: !show.status, modal: value});
  };

  const handleConfirmation = (continueData) => {
    if (continueData) {
      setShowAddJobModal({status: true, modal: "showAddJob"})
    } else {
      setShowAddJobModal({status: false, modal: null})
    }
  }

  const getValue = () => {
    if (newLocationData.hiringManagers?.length > 0) {
      let value = [];
      newLocationData.hiringManagers.map((data) => {
        value.push({value: data.value, label: data.label});
      });
      return value;
    }
  };

  const changeLocationStatus = async (id, status) => {
    setConfirmationLoading(true);
    try {
      const response = await updateLocationStatus({location_id: id, is_active: !status});
      if (response && response.status === 200) {
        enqueueSnackbar("Location Status update successful", {
          variant: "success",
        });
      }
      setConfirmationLoading(false)
      setShowStatusModal(false);
      getAllLocations();
      getSelectedLocation(id)
    } catch (error) {
      setConfirmationLoading(false);
      enqueueSnackbar("Could not update location status currently !", {
        variant: "error",
      });
    }
  }

  const deactivationAction = (data) => {
    if (data.modalState) {
      changeLocationStatus(data.id, data.status).then();
    } else {
      setShowStatusModal(false);
    }
  }

  const packageConfirmed = (data) => {
    if (data === "showAddJob") {
      setShowAddJobModal({status: true, modal: data})
      clearStatusModal();
    } else if (data === "showConfirmation") {
      setShowStatusModal({
        ...showStatusModal,
        modalState: true,
        type: "job"
      });
    } else {
      setShowAddJobModal({status: false, modal: ""});
      clearStatusModal();
    }
  }

  const clearStatusModal = () => {
    setShowStatusModal({
      modalState: false,
      id: null,
      status: null,
      type: null,
    })
  }

  return (
    <div className="containerMain">
      <Container fluid className="main-body" style={{height: '100%'}}>
        <Row className="locationRow d-flex flex-row justify-content-between align-items-center">
          <Col lg="auto" className="p-0">
            <label className="location-header">My Locations</label>
          </Col>

          {(props.accountType && props.accountType === "business_owner") &&
            <Col lg="auto">
              <ButtonComponent
                idName="addNewLocationBtn"
                name="ADD NEW"
                onClickFunction={locationBtnClick}
              />
            </Col>
          }
          {/*Search the location input*/}
          {/* <Col className="d-flex flex-row justify-content-end align-items-center">
            <div className="search-name-input">
              <div className="icon-div">
                <FontAwesomeIcon icon={faSearch} style={{ color: "#2C2B7C" }} />
              </div>
              <div className="input-div">
                <input
                  type="text"
                  placeholder="Search By Name"
                  className="location-search-input"
                  onChange={(e) => delayedHandleChange(e.target.value)}
                />
              </div>
            </div>
          </Col> */}
        </Row>

        <Row className="location-table-row d-flex flex-column flex-nowrap">
          {fetchedLocations.loading ? (
            <div className="spinner-wrapper">
              <Spinner animation="border" className="spinner"/>
            </div>
          ) : allLocationsData.length > 0 ? (
            allLocationsData.map((data, index) => (
              <div key={index} onClick={() => redirectPage(`location/${data?.location?.id}`)}>
                <LocationCardComponent
                  data={data}
                  activateDeactivate={(id, status) => setShowStatusModal({
                    modalState: true,
                    id,
                    status,
                    type: 'location'
                  })}
                  onEdit={(id, flag) => onLocationEdit(id, flag)}
                  handleModal={(flag, id) => handleModalShow(flag, id)}
                  {...props}
                />
              </div>
            ))
          ) : (
            <>
              <div className="no-data"> No data to display</div>
              {" "}
            </>
          )}
        </Row>

        {/*<PaginationComponent currentPage={1} totalItems={12}/>*/}

        {showStatusModal.modalState &&
          <StatusConfirmationModal loading={confirmationLoading} show={showStatusModal}
                                   hide={(data) => deactivationAction(data)}/>
        }

      </Container>

      <AddPositionModal
        show={showAddJobModal.status && showAddJobModal.modal === "showAddJob"}
        id={selectedId}
        onHide={() => handleJobModalClose()}
        openForEdit={show.modal === "editJob"}
        idOfEditableJob={""}
      />

      <Modal
        show={show} onHide={handleClose} backdrop={"static"} keyboard={false} dialogClassName="add-location-modal"
        aria-labelledby="contained-modal-title-vcenter" centered>
        <Container className="modal-body-container">
          <div className="location-modal-title">
            {selectedId ? isInvite ? "Invite User" : "Edit Location" : "Add A Location"}
            <FontAwesomeIcon
              icon={faTimes}
              id="location-modal-close"
              onClick={handleClose}
            />
          </div>

          <Form
            noValidate
            validated={validated}
            onSubmit={(event) => handleSubmit(event)}
            id="addLocationModalForm"
          >

            <Form.Group controlId="formBasicLast" className="mt-3 mb-0" style={{height: '60px'}}>
              <Form.Control
                name="name"
                placeholder="Location Name"
                value={newLocationData.name}
                isInvalid={errors?.name}
                onChange={(e) => handleChange(e)}
                disabled={isInvite}
                required
                className="modal-input-green"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name}
              </Form.Control.Feedback>
            </Form.Group>

            {/* To select the co-ordinates  */}
            <Row className="">
              <Col>
                <ButtonComponent
                  idName="setCoordinatesOnMap"
                  btnType="button"
                  name={hasCoordinates ? "Update Coordinates" : "Select Coordinates"}
                  style={{width: "100%"}}
                  onClickFunction={() => handleSelectCordinatesBtnClick()}
                />
              </Col>
            </Row>

            <Form.Group controlId="formBasicLast" className="mt-3 mb-0" style={{height: '60px'}}>
              <Form.Control
                name="street"
                placeholder="Street"
                value={newLocationData.street}
                isInvalid={errors?.street}
                onChange={(e) => handleChange(e)}
                disabled={isInvite}
                required
                className="modal-input-green"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.street}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicLast" className="mb-0" style={{height: '60px'}}>
              <Form.Control
                name="street_alt"
                placeholder="Street alt"
                disabled={isInvite}
                value={
                  Object.keys(newLocationData).includes("street_alt")
                    ? newLocationData.street_alt
                    : ""
                }
                onChange={(e) => handleChange(e)}
                className="modal-input-green"
              />
            </Form.Group>

            <Form.Group controlId="formBasicLast" className="mb-0" style={{height: '60px'}}>
              <Form.Control
                name="city"
                placeholder="City"
                disabled={isInvite}
                value={
                  Object.keys(newLocationData).includes("city")
                    ? newLocationData.city
                    : ""
                }
                onChange={(e) => handleChange(e)}
                className="modal-input-green"
              />
            </Form.Group>

            <Form.Group controlId="formBasicLast" style={{height: '45px'}} className="mb-0">
              <Form.Row>
                <Col>
                  <Select
                    value={stateOptions.find((opt) => opt.label === newLocationData.state)}
                    onChange={handleStateChange}
                    options={stateOptions}
                    placeholder={'State'}
                    isMulti={false}
                    className="modal-input-green"
                  />
                </Col>
                <Col>
                  <Form.Control
                    name="zip"
                    placeholder="Zip"
                    value={newLocationData.zip}
                    disabled={isInvite}
                    isInvalid={errors?.zip}
                    type={"number"}
                    onChange={(e) => handleChange(e)}
                    required
                    className="modal-input-green"
                  />
                </Col>
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors?.zip}
                </Form.Control.Feedback>
              </Form.Row>
            </Form.Group>

            <Form.Group controlId="formBasicLast" style={{height: '60px'}} className="mt-3 mb-0">
              <Form.Control
                name="phone"
                placeholder="Phone"
                type="number"
                disabled={isInvite}
                value={
                  Object.keys(newLocationData).includes("phone")
                    ? newLocationData.phone
                    : ""
                }
                isInvalid={errors?.phone}
                onChange={(e) => handleChange(e)}
                className="modal-input-green"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Select
              value={getValue()}
              onChange={handleSelectChange}
              options={options}
              placeholder={options.length > 0 ? "Select a user" : "Assigned user - admin (default)"}
              isMulti={true}
              isClearable={!isInvite}
              id="location-select"/>

            <Row className="mt-4">
              <Col>
                <ButtonComponent
                  idName="addLocationBtn"
                  loading={btnLoading}
                  name={selectedId ? isInvite ? "Invite User" : "Update Location" : "Add Location"}
                  style={{width: "100%"}}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal>

      {showAddJobModal.status && showAddJobModal.modal === "package" && (
        <PackageSelectionModal show={showAddJobModal} hide={(data) => packageConfirmed(data)}/>
      )}

      <MapComponent show={showMap} hide={() => setShowMap(false)} openLocationModal={handleShow}
                    setNewLocationData={setNewLocationData} newLocationData={newLocationData}/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addLocation: state.addLocation,
  allLocationsData: state.addLocation.locationData?.data?.results || [],
  fetchedLocations: state.addLocation.locationData,
  hiringManagers: state.addLocation.hiringManagers,
  editLocationState: state.addLocation.editLocation,
  deleteLocationState: state.addLocation.deleteLocation,
  currentPricingPlanState: state.setBillingInfo.currentPricingPlan
});

const mapDispatchToProps = (dispatch) => ({
  add_location: (addLocationData) =>
    dispatch(addLocationActions.addLocation(addLocationData)),
  getAllLocations: () => dispatch(addLocationActions.getLocationData()),
  getHiringManagers: () => dispatch(getHiringManagers()),
  editLocationData: (id, data) => dispatch(addLocationActions.editLocation(id, data)),
  refreshState: () => dispatch(refreshManageLocationEdit()),
  refreshDelete: () => dispatch(refreshManageLocationDelete()),
  getSelectedLocation: (id) => dispatch(addLocationActions.getLocationFromLocationId(id)),
  clearSelectedLocationError: () => dispatch(addLocationActions.clearSelectedLocationError()),
  clearFetchError: () => dispatch(clearFetchedLocationsError()),
  clearAddError: () => dispatch(addLocationActions.clearAddLocationError()),
  fetchCurrentPricingPlan: () => dispatch(getCurrentPricingPlan()),
  clearCurrentPricingPlanState: () => dispatch(clearCurrentBusinessPlanState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationManagerPage);
