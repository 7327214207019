import Modal from "react-bootstrap/Modal";
import React, {useEffect} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './style.css';
import ButtonComponent from "../ButtonComponent";

const BillingConfirmationModal = (props) => {
  const {show, displayInfo, continueFlow} = props;

  useEffect(() => {
    console.log('Im being triggered!');
  }, []);

  return (
    <>
      <Modal show={show.status} dialogClassName="billing-confirmaion-confirmation-modal" keyboard={false}
             backdrop={"static"}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <div className="modal-close-fab" onClick={() => continueFlow(false)}>
          <FontAwesomeIcon icon={faTimes}/>
        </div>

        <div className="d-flex flex-column align-items-center w-100 h-100">
          <div className="billing-confirmaion-info-display">
            {displayInfo}
          </div>

          <ButtonComponent
            idName="billing-confirmaion-location-btn"
            name={"Continue"}
            onClickFunction={() => continueFlow(true)}
          />
        </div>
      </Modal>
    </>
  )
}

export default BillingConfirmationModal;
