import React, {useEffect} from "react";
import "./style.css";
import {OverlayTrigger, Popover, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import {connect} from "react-redux";
import {deleteUserByID, getUsers, resetDeleteUsers} from "../../../../redux/actions";
import {useSnackbar} from "notistack";

const ManagerTableComponent = (props) => {
    const {
        deleteUser,
        getAllUsers,
        deleteLoading,
        deleteError,
        deleteSuccess,
        refreshState
    } = props;
    const {enqueueSnackbar} = useSnackbar();

    const handleDelete = (data) => {
        const userId = data.id;
        deleteUser(userId);
    };

    useEffect(() => {
        if (deleteSuccess && !deleteLoading && !deleteError) {
            enqueueSnackbar("User Deleted Successfully", {
                variant: "success",
            });
            refreshState();
        } else if (deleteError && !deleteSuccess && !deleteLoading) {
            enqueueSnackbar("User Delete Failed !", {
                variant: "error",
            });
            refreshState();
        }
    }, [deleteError, deleteSuccess]);

    return (
        <div id="manager-table">
            <div className="manager-table-head">
                <Row className="mx-0 w-100">
                    <Col lg={{span: 2}}>Name</Col>
                    <Col lg={{span: 2}}>Type</Col>
                    <Col lg={"auto"}>Location(s)</Col>
                </Row>
            </div>

            <div className="manager-table-body">
                {props.userData &&
                    props.userData.length > 0 &&
                    props.userData.map((data, index) => (
                        <Row className="pt-td mx-0 w-100 manager-table-tr" key={index}>
                            <Col
                                lg={{span: 2}}
                                className=" d-flex flex-row align-items-center"
                            >
                                <OverlayTrigger
                                    placement={'right'}
                                    overlay={
                                        <Popover>
                                            <Popover.Content className="custom-jp-popover">
                                                {data.first_name + " " + data.last_name}
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                <span className="table-lbl">
                  {data.first_name + " " + data.last_name}
                </span>
                                </OverlayTrigger>
                            </Col>
                            <Col
                                lg={{span: 2}}
                                className=" d-flex flex-row align-items-center"
                            >
                <span className="table-lbl">
                  {data.account_type === "business_owner" ? "Admin" : "User"}
                </span>
                            </Col>
                            <Col
                                lg={{span: 7}}
                                className=" d-flex flex-column justify-content-center table-lbl"
                            >
                                {data.account_type === "business_owner"
                                    ? "All"
                                    : data.locations &&
                                    data.locations.length > 0 &&
                                    data.locations.map((location, i) => {
                                        return data.locations.length - 1 === i
                                            ? `${" " + location.name}`
                                            : `${location.name + " /"}`;
                                    })}
                            </Col>

                            {(props.accountType && props.accountType === "business_owner") &&
                                <Col
                                    lg={{span: 1}}
                                    className="px-4 d-flex flex-row justify-content-end align-items-center"
                                >
                                    <Dropdown onClick={(e) => e.stopPropagation()}>
                                        <Dropdown.Toggle className="manager-table-ellipsis">
                                            <FontAwesomeIcon color={"#2C2B7C"} icon={faEllipsisV}/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="manager-dropdown-menu">
                                            <Dropdown.Item
                                                className="manager-dropdown-item"
                                                onClick={() => props.onUserEdit(index, data)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    style={{color: "#2C2B7C"}}
                                                    className="mx-3"
                                                />
                                                <span>Edit User</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="manager-dropdown-item"
                                                style={{color: "#EE6C4D"}}
                                                onClick={() => handleDelete(data)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{color: "#EE6C4D"}}
                                                    className="mx-3"
                                                />
                                                <span>Delete User</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            }
                        </Row>
                    ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    deleteLoading: state.manageUsers.deleteUsers.loading,
    deleteError: state.manageUsers.deleteUsers.error,
    deleteSuccess: state.manageUsers.deleteUsers.success,
});

const mapDispatchToProps = (dispatch) => ({
    deleteUser: (id) => dispatch(deleteUserByID(id)),
    getAllUsers: () => dispatch(getUsers()),
    refreshState: () => dispatch(resetDeleteUsers())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagerTableComponent);
