import types from '../types';
import * as userService from '../../services/api/userService';


const setLoadingAction = () => ({
  type: types.CREATE_ACCOUNT_SET_LOADING,
});

const createAccountSuccess = createAccountData => ({
  type: types.CREATE_ACCOUNT,
  createAccountData,
});

const createAccountFailed = error => ({
  type: types.CREATE_ACCOUNT_FAILED,
  error,
});

export const createAccount = (signUpForm) => dispatch => {
    dispatch(setLoadingAction());
    return userService.createAccount(signUpForm)
    .then(
        response => {
            dispatch(createAccountSuccess(response.data))
        },
        error => {
      dispatch(createAccountFailed(error))
    }
  );
};

