import axiosInstance from "./axios";

export const postCardAndPlanData = async (cardData) =>
  axiosInstance.post("business/save-card-and-update-pricing-plan/", cardData);

export const postCardData = async (cardDate) =>
  axiosInstance.post("business/save-card/", cardDate);

export const getPricingPlans = async() => await axiosInstance.get("business/pricing-plan/")

export const postPricingPlan = async(data) => await axiosInstance.post("business/pricing-plan/", data)

export const getBusinessPricingPlan = async() => await axiosInstance.get("business/business-pricing-plan/")

export const getRemainingCredits = async() => await axiosInstance.get("business/remaining-credits/")
