import types from '../types';

const defaultState = {
  businessInfo: "",
  loading: false,
  error: "",
};

const setBusinessInfo = (state = defaultState, action) => {
  switch (action.type) {
    case types.businessInfoConstants.SET_BUSINESS_INFO_LOADING:
      return {
        ...state,
        businessInfo: "",
        loading: true,
        error: ""
      };
    case types.businessInfoConstants.SET_BUSINESS_INFO_FAILED:
      return {
        ...state,
        businessInfo: "",
        error: action.error,
        loading: false,
      };
    case types.businessInfoConstants.SET_BUSINESS_INFO:
      return {
        ...state,
        error: "",
        businessInfo: action.data,
        loading: false,
      };
      case types.businessInfoConstants.CLEAR_BUSINESS_INFO_STATE:
      return {
        ...state,
        error: "",
        businessInfo: "",
        loading: false,
      };
    default:
      return state;
  }
};
export default setBusinessInfo;
