import types from "../types";
import {
    getConversationDetails,
    getConversationList,
    getConversationMessages,
    getPaginatedMessages,
    jobSwipe,
    postConversationReadStatus,
    postMessage
} from "../../services/api/messagingService";

const getConversationListLoading = () => ({
    type: types.messagingConstants.GET_CONVERSATION_LIST_LOADING,
});

const getConversationListError = (error) => ({
    type: types.messagingConstants.GET_CONVERSATION_LIST_FAILED,
    error,
});

const getConversationListSuccess = (data) => ({
    type: types.messagingConstants.GET_CONVERSATION_LIST_SUCCESS,
    data,
})

const getConversationDetailLoading = () => ({
    type: types.messagingConstants.GET_CONVERSATION_DETAILS_LOADING,
});

const getConversationDetailError = (error) => ({
    type: types.messagingConstants.GET_CONVERSATION_DETAILS_FAILED,
    error,
});

const getConversationDetailSuccess = (data) => ({
    type: types.messagingConstants.GET_CONVERSATION_DETAILS_SUCCESS,
    data,
})

const getConversationMessagesLoading = () => ({
    type: types.messagingConstants.GET_CONVERSATION_MESSAGES_LOADING,
});

const getConversationMessagesError = (error) => ({
    type: types.messagingConstants.GET_CONVERSATION_MESSAGES_FAILED,
    error,
});

export const getConversationMessagesSuccess = (data) => ({
    type: types.messagingConstants.GET_CONVERSATION_MESSAGES_SUCCESS,
    data,
})

const postConversationMessageLoading = () => ({
    type: types.messagingConstants.POST_CONVERSATION_MESSAGE_LOADING,
});

const postConversationMessageSuccess = () => ({
    type: types.messagingConstants.POST_CONVERSATION_MESSAGE_SUCCESS,
});

const postConversationMessageError = (error) => ({
    type: types.messagingConstants.POST_CONVERSATION_MESSAGE_FAILED,
    error,
});

const jobSwipeLoading = () => ({
    type: types.messagingConstants.JOB_SWIPE_LOADING,
});

const jobSwipeError = (error) => ({
    type: types.messagingConstants.JOB_SWIPE_FAILED,
    error,
});

export const jobSwipeSuccess = (data) => ({
    type: types.messagingConstants.JOB_SWIPE_SUCCESS,
    data,
})

export const jobSwipeReset = () => ({
    type: types.messagingConstants.JOB_SWIPE_RESET,
});

export const clearConversationList = () => ({
    type: types.messagingConstants.CLEAR_CONVERSATION_LIST
})

export const getConversationListAction = () => {
    return async (dispatch) => {
        try {
            dispatch(getConversationListLoading());
            const response = await getConversationList();
            if (response && response.data && response.data.data) {
                dispatch(getConversationListSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(getConversationListError(error.message));
        }
    };
};

export const updateConversationListAction = (data) => {
    return (dispatch) => {
        dispatch(getConversationListLoading());
        dispatch(getConversationListSuccess(data));
    };
};

export const getConversationDetailsAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getConversationDetailLoading());
            const response = await getConversationDetails(id);
            if (response && response.data && response.data.data) {
                dispatch(getConversationDetailSuccess(response.data.data))
            }
        } catch (error) {
            dispatch(getConversationDetailError(error.message));
        }
    };
};

export const getConversationMessagesAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getConversationMessagesLoading());
            const response = await getConversationMessages(id);
            if (response && response.data && response.data.data) {
                dispatch(getConversationMessagesSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(getConversationMessagesError(error.message));
        }
    };
};

export const getPaginatedConversationMessagesAction = (oldConversations, url) => {
    return async (dispatch) => {
        try {
            dispatch(getConversationMessagesLoading());
            const response = await getPaginatedMessages(url);
            if (response && response.data && response.data.data) {
                const newMessageList = {
                    ...response.data.data,
                    results: [...oldConversations.data?.results, ...response.data.data.results]
                }
                console.log(newMessageList)
                dispatch(getConversationMessagesSuccess(newMessageList));
            }
        } catch (error) {
            dispatch(getConversationMessagesError(error.message));
        }
    };
};

export const postConversationMessageAction = (oldConversations, data) => {
    return async (dispatch) => {
        try {
            dispatch(postConversationMessageLoading());
            const response = await postMessage(data);
            if (response && response.data && response.data.data) {
                dispatch(postConversationMessageSuccess(response.data.data));
                dispatch(getConversationMessagesLoading());
                const newMessageList = {
                    ...oldConversations.data,
                    results: [...oldConversations.data?.results, response.data.data]
                }
                dispatch(getConversationMessagesSuccess(newMessageList));
            }
        } catch (error) {
            dispatch(postConversationMessageError(error.message));
        }
    };
};
export const postConversationReadStatusAction = (recipients, id) => {
    return async (dispatch) => {
        try {
            const response = await postConversationReadStatus(id);
            if (response && response.data) {
                dispatch(getConversationListLoading());
                const updatedRecipientList = recipients?.results.map((recipient, index) => {
                    if (recipient.conversation?.id === id) {
                        return {
                            ...recipients?.results[index],
                            conversation: {...recipients?.results[index]?.conversation, has_unread_messages: false}
                        }
                    }
                    return recipient
                })
                dispatch(getConversationListSuccess({...recipients, results: [...updatedRecipientList]}));
            }
        } catch (error) {
            console.log(error)
        }
    };
};

export const updateConversationMessageAction = (oldConversations, data) => {
    return (dispatch) => {
        dispatch(getConversationMessagesLoading());
        const newMessageList = {...oldConversations.data, results: [...oldConversations.data?.results, data]}
        dispatch(getConversationMessagesSuccess(newMessageList));
    };
};

export const jobSwipeAction = (data) => {
    return async (dispatch) => {
        try {
            dispatch(jobSwipeLoading());
            const response = await jobSwipe(data);
            if (response && response.data && response.data.data) {
                dispatch(jobSwipeSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(jobSwipeError(error.message));
        }
    };
};
