import React, {useEffect, useState} from "react";
import {Container, Form, Spinner} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ButtonComponent from "../../../../../components/ButtonComponent";
import "./style.css";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";
import validator from "validator";
import {
    getApplicationQuestions,
    setApplicationQuestions,
} from "../../../../../redux/actions/applicationQuestionsAction";

const AddQuestions = (props) => {
    const [newQuestion, setNewQuestion] = useState("");
    const [displayQuestions, setDisplayQuestions] = useState([]);
    const [questionCount, setQuestionCount] = useState(0);
    const [show, setShow] = useState(false);
    const {nextClicked, changeNextClicked, changePage, navigationState} = props;
    const {
        getApplicationQuestionsAction,
        applicationQuestions,
        getLoading,
        setApplicationQuestionsAction,
        applicationQuestionData,
        loading,
        error,
        getError,
    } = props;
    const toggleModal = () => setShow(!show);
    const {enqueueSnackbar} = useSnackbar();

    const handleDescChange = (e) => {
        if (e) {
            setQuestionCount(e.target.value.length);
            setNewQuestion(e.target.value);
        }
    };

    useEffect(() => {
        navigationState(true);
    }, [displayQuestions]);

    useEffect(() => {
        getApplicationQuestionsAction();
    }, []);

    useEffect(() => {
        if (nextClicked) {
            submitData();
        }
    }, [nextClicked]);

    const submitData = () => {
        let selectedData = [];
        displayQuestions.length > 0 &&
        displayQuestions.map((data) => {
            if (data.is_selected) {
                selectedData.push(data.name);
            }
        });
        setApplicationQuestionsAction(selectedData);
    };

    useEffect(() => {
        if (applicationQuestionData && !loading && !error && nextClicked) {
            enqueueSnackbar("Application Question updated successfully", {
                variant: "success",
            });
            changeNextClicked();
            changePage();
        } else if (!applicationQuestionData && !loading && error && nextClicked) {
            enqueueSnackbar("Application Question update Failed", {
                variant: "error",
            });
            changeNextClicked();
        }
    }, [applicationQuestionData, loading, error]);

    useEffect(() => {
        if (applicationQuestions && !loading && !getError) {
            setDisplayQuestions(applicationQuestions);
        } else if (!applicationQuestions && !loading && getError) {
            enqueueSnackbar("Could Not Fetch Application Questions", {
                variant: "error",
            });
        }
    }, [applicationQuestions, getError, getLoading]);

    const selectQuestion = (name) => {
        const questionsArray = displayQuestions;
        if (questionsArray.some((object) => object.name === name)) {
            const idx = questionsArray.findIndex((object) => object.name === name);
            if (questionsArray[idx].is_selected === true) {
                questionsArray[idx].is_selected = false;
                setDisplayQuestions([...questionsArray]);
            } else {
                if (checkMaxSelection() < 5) {
                    questionsArray[idx].is_selected = true;
                    setDisplayQuestions([...questionsArray]);
                } else {
                    enqueueSnackbar("You can only select upto 5 items", {
                        variant: "error",
                    });
                }
            }
        } else {
            alert("Object not found.");
        }
    };

    const validate = () => {
        if (!validator.trim(newQuestion)) {
            return false;
        } else if (newQuestion.length < 5) {
            return false;
        } else {
            return true;
        }
    };

    const addQuestion = (event) => {
        event.preventDefault();
        validate();
        if (!validate()) {
            enqueueSnackbar("Question must be of minimum 5 characters long", {
                variant: "error",
            });
        } else {
            if (checkMaxSelection() < 5) {
                setDisplayQuestions([
                    ...displayQuestions,
                    {name: newQuestion, is_selected: true},
                ]);
                setNewQuestion("")
                setQuestionCount(0)
                toggleModal();
            } else {
                enqueueSnackbar("Maximum question selection reached", {
                    variant: "error",
                });
            }
        }
    };

    const checkMaxSelection = () => {
        if (displayQuestions?.length > 0) {
            const count = displayQuestions.filter((obj) => obj.is_selected === true)
                .length;
            return count;
        } else {
            return 0;
        }
    };

    return (
        <>
            <div style={{height: "30%"}}>
                <div className="stepper-title">Application questions</div>
                <div className="stepper-info">
                    Jipe will provide you with all the necessary applicant info when a
                    candidate submits an application. This includes their profile info
                    PLUS their phone number and email address. You may also ask up to 5
                    optional YES/NO application questions for candidates to answer. You
                    may select from commonly asked questions we've provided below, or you
                    may create your own.
                </div>
            </div>

            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{height: "70%", marginTop: "10px"}}
            >
                {getLoading ? (
                    <div className="spinner-wrapper">
                        <Spinner animation="border" className="spinner"/>
                    </div>
                ) : (
                    <>
                        <div className="overflow-auto h-100 w-100">
                            {displayQuestions?.length > 0 &&
                                displayQuestions.map((item, index) => (
                                    <button
                                        className={
                                            "add-question-custom-btn " +
                                            (item.is_selected ? "active-add-question-btn" : "")
                                        }
                                        onClick={() => selectQuestion(item.name)}
                                        key={index}
                                    >
                                        {item.name}
                                    </button>
                                ))}
                            {
                                displayQuestions.length === 0
                                &&
                                <div className="d-flex flex-row justify-content-center align-items-center h-100">
                                    <span id="no-qn-lbl">No questions added yet, create your first question!</span>
                                </div>
                            }
                        </div>

                        <ButtonComponent
                            name={"Create your own"}
                            disabled={checkMaxSelection() > 5}
                            idName="create-question-btn"
                            onClickFunction={() => toggleModal()}
                        />
                    </>
                )}
            </div>

            <Modal
                show={show}
                onHide={toggleModal}
                dialogClassName="add-question-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Container className="modal-body-container">
                    <Form noValidate onSubmit={(event) => addQuestion(event)}>
                        <Form.Group controlId="descriptionForm">
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Question"
                                required
                                className="qn-input"
                                onChange={(e) => handleDescChange(e)}
                                maxLength="150"
                            />
                        </Form.Group>

                        <div
                            className={
                                "qn-counter mt-3 " +
                                (questionCount === 150 ? "max-counter" : "")
                            }
                        >
                            {questionCount}/150
                        </div>

                        <ButtonComponent
                            disabled={checkMaxSelection() > 4}
                            name={"Add Question"}
                            idName="add-question-btn"
                            type={"submit"}
                        />
                    </Form>
                </Container>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    applicationQuestions: state.applicationQuestions.getData.data,
    getLoading: state.applicationQuestions.getData.loading,
    getError: state.applicationQuestions.getData.error,
    applicationQuestionData: state.applicationQuestions.postData.data,
    loading: state.applicationQuestions.postData.loading,
    error: state.applicationQuestions.postData.error,
});

const mapDispatchToProps = (dispatch) => ({
    getApplicationQuestionsAction: () => dispatch(getApplicationQuestions()),
    setApplicationQuestionsAction: (data) =>
        dispatch(setApplicationQuestions(data)),
});

export const AddQuestionsComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddQuestions);
