import React, {useEffect, useState} from "react";
import "./style.css";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import LocationCardComponent from "../../../components/LocationCardComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Modal from "react-bootstrap/Modal";
import AddPositionModal from "./AddPositionModal";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import JobListingTableComponent from "./JobListingTableComponent";
import {useSnackbar} from "notistack";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {checkPhone, checkZip, stateOptions} from "../../../utils/utils";
import {
    clearSelectedLocationError,
    editLocation,
    getHiringManagers,
    getLocationData,
    getLocationFromLocationId,
    getLocations,
    refreshManageLocationDelete,
    refreshManageLocationEdit,
} from "../../../redux/actions";
import {connect} from "react-redux";
import {activateJobById, deactivateJobById,} from "../../../services/api/jobCreationService";
import MapComponent from "../../../components/MapComponent";
import {updateLocationStatus} from "../../../services/api/locationService";
import StatusConfirmationModal from "../../../components/StatusConfirmationModal";
import {clearGetJobDetailsError, getJobDetails,} from "../../../redux/actions/getJobDetailsAction";
import PreviewProfileModal from "../../../components/PreviewProfileModal/PreviewProfileModal";
import BillingConfirmationModal from "../../../components/BillingModal";
import {clearCurrentBusinessPlanState, getCurrentPricingPlan,} from "../../../redux/actions/billingAction";
import PackageSelectionModal from "../../../components/PackageSelectionModal";
import DeactivateQuestionModal from "./DeactivateQuestionModal";
import {getDeactivationReasons} from "../../../redux/actions/getDeactivationReasons";

const initialLocationData = {
    name: "",
    street: "",
    street_alt: "",
    city: "",
    state: "",
    lat: "",
    lng: "",
    zip: "",
    phone: "",
    hiringManagers: [],
};

const LocationPage = (props) => {
    const {
        hiringManagers,
        getHiringManagers,
        editLocationData,
        editLocationState,
        refreshState,
        refreshDelete,
        deleteLocationState,
        getSelectedLocation,
        selectedLocationState,
        getAllLocations,
        clearSelectedLocationError,
        getSelectedJob,
        selectedJobState,
        clearJobDetails,
        meData,
        fetchCurrentPricingPlan,
        currentPricingPlanState,
        clearCurrentPricingPlanState,
        getDeactivationReasons
    } = props;

    const [validated, setValidated] = useState(false);
    const [target, setTarget] = useState({
        isBundle: "",
        credits: "",
        total_credits: ""
    });
    const [showStatusModal, setShowStatusModal] = useState({
        modalState: false,
        id: null,
        status: null,
        type: null,
    });
    const [status, setStatus] = useState(false);
    const [errors, setErrors] = useState({});
    const [showLocationInfo, setShowLocationInfo] = useState(false);
    const [locationEditData, setLocationEditData] = useState(initialLocationData);
    const [isInvite, setIsInvite] = useState(false);
    const [show, setShow] = useState({status: false, modal: null});
    const [showMap, setShowMap] = useState(false);
    const [confirmationLoading, setConfirmationLoading] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [options, setOptions] = useState([]);
    const [idOfEditableJob, setIdOfEditableJob] = useState({id: "", action: ''});
    const [modalShow, setModalShow] = useState(false);
    const [deactivationModalState, setDeactivationModalState] = useState({state: false, id: null, status: null});
    const [deactivationData, setDeactivationData] = useState(null);

    const history = useHistory();
    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        setShow({status: false, modal: null});
        setErrors({});
        setValidated(false);
        setIsInvite(false);
    };

    const handleCardClick = async (locationId, jobId, applicants) => {
        history.push({pathname: `/position/${locationId + "/" + jobId}`, state: {applicants}});
    };

    useEffect(() => {
        if (deactivationData?.reasons.length > 0) {
            setDeactivationModalState(false)
            setShowStatusModal({
                modalState: true,
                id: deactivationData.id,
                status: deactivationData.status,
                type: "job",
                reasons: deactivationData.reasons
            });
        }
    }, [deactivationData])

    useEffect(() => {
        getDeactivationReasons();
        if (!currentPricingPlanState.data) {
            fetchCurrentPricingPlan();
        }
    }, [])

    useEffect(() => {
        if (id) {
            getSelectedLocation(id);
        }
        if (hiringManagers.data?.length === 0) {
            getHiringManagers();
        } else if (hiringManagers.data?.length > 0) {
            let selectionData = [];
            hiringManagers.data.map((data) => {
                selectionData.push({
                    label: data.first_name + " " + data.last_name,
                    value: data.email,
                });
            });
            setOptions(selectionData);
        }
    }, []);

    useEffect(() => {
        if (!selectedLocationState.loading && selectedLocationState.error) {
            enqueueSnackbar("Error fetching location data. Please try again later", {
                variant: "error",
            });
            clearSelectedLocationError();
        }
    }, [selectedLocationState.error, selectedLocationState.data]);

    useEffect(() => {
        if (hiringManagers.data?.length > 0) {
            let selectionData = [];
            hiringManagers.data.map((data) => {
                selectionData.push({
                    label: data.first_name + " " + data.last_name,
                    value: data.email,
                });
            });
            setOptions(selectionData);
        }
    }, [hiringManagers.data]);

    useEffect(() => {
        if (deleteLocationState.success &&
            !deleteLocationState.loading &&
            !deleteLocationState.error) {
            enqueueSnackbar("Location Deleted Successfully", {
                variant: "success",
            });
            refreshDelete();
            history.push("/location-manager");
        } else if (
            deleteLocationState.error &&
            !deleteLocationState.loading &&
            !deleteLocationState.success
        ) {
            enqueueSnackbar("Location Delete Failed !", {
                variant: "error",
            });
            refreshDelete();
        }
    }, [deleteLocationState.error, deleteLocationState.success]);

    useEffect(() => {
        if (!selectedJobState.loading && selectedJobState.error) {
            enqueueSnackbar("Could not fetch job details", {
                variant: "error",
            });
            clearJobDetails();
        }
    }, [selectedJobState.error]);

    useEffect(() => {
        if (
            editLocationState.success &&
            !editLocationState.loading &&
            !editLocationState.error
        ) {
            if (isInvite) {
                enqueueSnackbar("Success", {
                    variant: "success",
                });
            } else {
                enqueueSnackbar("Location has been edited successfully", {
                    variant: "success",
                });
            }
            getSelectedLocation(id);
            handleClose();
            refreshState();
        } else if (
            !editLocationState.success &&
            !editLocationState.loading &&
            editLocationState.error
        ) {
            if (isInvite) {
                enqueueSnackbar("Failed", {
                    variant: "error",
                });
            } else {
                enqueueSnackbar("Location Edit Failed", {variant: "error"});
            }
            refreshState();
        }
    }, [editLocationState.error, editLocationState.success]);

    useEffect(() => {
        if (
            !currentPricingPlanState.data &&
            currentPricingPlanState.error &&
            !currentPricingPlanState.loading &&
            (show.modal === "addJob" || showStatusModal.type === "check")
        ) {
            enqueueSnackbar("Could not complete required action", {
                variant: "error",
            });
            setShow({status: false, modal: null})
            clearCurrentPricingPlanState();
        } else if (currentPricingPlanState.data?.id && !currentPricingPlanState.error && !currentPricingPlanState.loading) {
            console.log(currentPricingPlanState.data)
            console.log(show)
            setTarget({
                isCustom: currentPricingPlanState.data?.is_custom,
                isOnTrial: currentPricingPlanState.data?.is_on_trial,
                isBundle: currentPricingPlanState.data?.is_bundle_type,
                credits: currentPricingPlanState.data?.remaining_credits,
                total_credits: currentPricingPlanState.data?.no_of_items
            });
            if (show.modal === "addJob" || showStatusModal.type === "check") {
                if (currentPricingPlanState.data?.is_bundle_type && (currentPricingPlanState.data?.remaining_credits > 0 || currentPricingPlanState.data?.remaining_credits === null)) {
                    if (show.status && show.modal === "addJob") {
                        setShow({status: true, modal: "showAddJob"});
                    } else if (showStatusModal.type === "check") {
                        setShowStatusModal({
                            ...showStatusModal,
                            modalState: true,
                            type: "job"
                        });
                    }
                } else if (currentPricingPlanState.data?.is_bundle_type && currentPricingPlanState.data?.remaining_credits === 0) {
                    enqueueSnackbar("Out of credits, please select a package to continue", {
                        variant: "error",
                    });
                    setShow({status: true, modal: "package"});
                } else if (!currentPricingPlanState.data?.is_bundle_type) {
                    if (show.modal && show.modal === "addJob") {
                        setShow({status: true, modal: "package"});
                    } else if (showStatusModal.modalState && showStatusModal.type === "check") {
                        if (show.status && show.modal === "package") {
                            setShow({status: true, modal: "package"});
                        } else if (showStatusModal.modalState && showStatusModal.type === "check") {
                            setShow({status: true, modal: "package"});
                            setStatus(true);
                        } else if (show.status && show.modal === "showAddJob") {
                            setShowStatusModal({
                                ...showStatusModal,
                                modalState: true,
                                type: "job"
                            });
                        }
                    }
                }
            }
        }
    }, [currentPricingPlanState.data, currentPricingPlanState.error]);

    const handleSubmit = (event) => {
        event.preventDefault()
        const allErrors = {};
        if (!locationEditData.name) {
            allErrors.name = "Please enter a name for location";
        }
        if (!locationEditData.street) {
            allErrors.street = "Please enter the street";
        }
        if (!locationEditData.state) {
            allErrors.state = "Please enter the state";
        }
        if (!locationEditData.zip) {
            allErrors.zip = "Please enter your zip code";
        } else {
            if (!checkZip(locationEditData.zip)) {
                allErrors.zip = "Please enter a valid zip code";
            }
        }
        if (locationEditData.phone) {
            if (!checkPhone(locationEditData.phone)) {
                allErrors.phone = "Please enter a valid phone number";
            }
        }
        if (Object.keys(allErrors).length > 0) {
            setErrors(allErrors);
            setValidated(false);
        } else {
            let postHiringManagers = [];
            if (locationEditData.hiringManagers?.length > 0) {
                locationEditData.hiringManagers.map((data) => {
                    postHiringManagers.push({email: data.value});
                });
            }
            let submitData = {
                name: locationEditData.name,
                street: locationEditData.street,
                street_alt: locationEditData.street_alt,
                city: locationEditData.city,
                state: locationEditData.state,
                zip: locationEditData.zip,
                phone: locationEditData.phone,
                lat: locationEditData.lat,
                lng: locationEditData.lng,
                hiring_manager:
                    postHiringManagers.length > 0 ? postHiringManagers : null,
            };
            setValidated(true);
            editLocationData(id, submitData);
        }
    };

    const handleModalShow = (value, editableJobId = "") => {
        console.log(value, editableJobId)
        if (value === 'editJob' || value === 'cloneJob') {
            setIdOfEditableJob({id: editableJobId, action: value});
        }
        setShow({status: !show.status, modal: value});
        fetchCurrentPricingPlan();
    };

    const handleSelectCordinatesBtnClick = () => {
        // close the location add modal
        setShow({status: false, modal: null});

        // open the map modal
        setShowMap(true);
    };

    const handleChange = (e) => {
        if (Object.keys(errors).length > 0) {
            setErrors({});
            setValidated(false);
        }

        setLocationEditData({
            ...locationEditData,
            [e.target.name]: e.target.value,
        });
    };

    const handleStateChange = (selectedOption) => {
        setLocationEditData({
            ...locationEditData,
            state: selectedOption.value.name
        })
    };

    const handleSelectChange = (selectedOption) => {
        setLocationEditData({
            ...locationEditData,
            hiringManagers: selectedOption,
        });
    };

    const onLocationEdit = (data, flag) => {
        if (flag === "invite") {
            setIsInvite(true);
        }

        handleModalShow("invite");
        const hiringMgrs = [];
        if (selectedLocationState.data.hiring_managers?.length > 0) {
            selectedLocationState.data.hiring_managers.map((manager) => {
                hiringMgrs.push({
                    label: manager.first_name + " " + manager.last_name,
                    value: manager.email,
                });
            });
        }

        setLocationEditData({
            name: selectedLocationState.data?.location?.name,
            street: selectedLocationState.data?.location?.street,
            street_alt: selectedLocationState.data?.location?.street_alt,
            city: selectedLocationState.data?.location?.city,
            state: selectedLocationState.data?.location?.state,
            lat: selectedLocationState.data?.location?.lat,
            lng: selectedLocationState.data?.location?.lng,
            zip: selectedLocationState.data?.location?.zip,
            phone: selectedLocationState.data?.location?.phone,
            hiringManagers: hiringMgrs,
        });
    };

    const getValue = () => {
        if (locationEditData.hiringManagers?.length > 0) {
            let value = [];
            locationEditData.hiringManagers.map((data) => {
                value.push({value: data.value, label: data.label});
            });
            return value;
        }
    };

    const handleJobActivation = async (isActive, jobId, reasons = null) => {
        if (isActive) {
            try {
                const response = await deactivateJobById(jobId, reasons);
                if (response && response.status === 200) {
                    setConfirmationLoading(false);
                    setStatus(false);
                    getSelectedLocation(id);
                    clearStatusModal()
                    setShow({modal: "", status: false})
                    enqueueSnackbar("Job paused successfully", {
                        variant: "success",
                    });
                }
            } catch (err) {
                setConfirmationLoading(false);
                setStatus(false);
                clearStatusModal()
                setShow({modal: "", status: false})
                enqueueSnackbar(err?.message || "Job deactivation failed!", {
                    variant: "error",
                });
            }
        } else {
            try {
                const response = await activateJobById(jobId);
                if (response && response.status === 200) {
                    setStatus(false);
                    setConfirmationLoading(false);
                    getSelectedLocation(id);
                    fetchCurrentPricingPlan();
                    clearStatusModal()
                    setShow({modal: "", status: false})
                    enqueueSnackbar("Job activated successfully", {
                        variant: "success",
                    });
                }
            } catch (err) {
                clearStatusModal()
                setShow({modal: "", status: false})
                setStatus(false);
                setConfirmationLoading(false);
                enqueueSnackbar(err?.message || "Job deactivation failed!", {
                    variant: "error",
                });
            }
        }
    };

    const changeLocationStatus = async (id, status) => {
        setConfirmationLoading(true);
        try {
            const response = await updateLocationStatus({
                location_id: id,
                is_active: !status,
            });
            if (response && response.status === 200) {
                setConfirmationLoading(false);
                enqueueSnackbar("Location Status update successful", {
                    variant: "success",
                });
                clearStatusModal();
                getSelectedLocation(id);
                getAllLocations();
            }
        } catch (error) {
            console.log(error?.data)
            setConfirmationLoading(false);
            enqueueSnackbar("Could not update location status currently !", {
                variant: "error",
            });
        }
    };

    const deactivationAction = (data) => {
        console.log(data)
        if (data.type === "job") {
            setConfirmationLoading(true);
            handleJobActivation(data.status, data.id, data.reasons).then();
        } else if (data.type === "location") {
            if (data.modalState) {
                changeLocationStatus(data.id, data.status).then();
            } else {
                clearStatusModal();
            }
        } else {
            clearStatusModal();
            setConfirmationLoading(false);
        }
    };

    const handleConfirmation = (continueData) => {
        console.log(continueData);
        if (continueData && show.modal === "addJob") {
            setShow({status: true, modal: "showAddJob"});
        } else if (continueData && showStatusModal.type === "check") {
            handleJobActivation(false, showStatusModal.id).then();
        } else {
            setShow({status: false, modal: null});
        }
    };

    const handleJobPreview = (jobId) => {
        getSelectedJob(jobId);
        setModalShow(true);
    };

    const handleJobStatus = (status, id) => {
        console.log(status)
        console.log(showStatusModal)
        if (status) {
            setDeactivationModalState({state: true, status, id})
        } else {
            fetchCurrentPricingPlan();
            setShowStatusModal({
                modalState: true,
                id,
                status,
                type: "check",
            });
        }
    };

    const packageConfirmed = (data) => {
        console.log(data)
        console.log(idOfEditableJob)
        if (data === "showAddJob") {
            if (idOfEditableJob?.id && idOfEditableJob?.action) {
                return setShow({status: true, modal: idOfEditableJob?.action})
            }
            setShow({status: true, modal: data})
            clearStatusModal();
        } else if (data === "showConfirmation") {
            console.log(showStatusModal)
            setShow({status: false, modal: ""});
            setShowStatusModal({
                ...showStatusModal,
                modalState: true,
                type: "job"
            });
        } else {
            setShow({status: false, modal: ""});
            clearStatusModal();
            setStatus(false);
        }
    }

    const clearStatusModal = () => {
        setShowStatusModal({
            modalState: false,
            id: null,
            status: null,
            type: null,
        })
    }

    return (
        <div className="containerMain">
            <div className="breadcrumb-container d-flex flex-row align-items-center justify-content-between">
                <div className="jipe-breadcrumb">
          <span className="jipe-breadcrumb-item" onClick={() => history.push("/location-manager")}>
            My Locations
          </span>
                    <span className="jipe-breadcrumb-arrow">{`>`}</span>
                    <span className="jipe-breadcrumb-item">{selectedLocationState.data?.location?.name}</span>
                    {selectedLocationState.data?.location?.name
                        ?
                        <span className="location-see-info-span"
                              onClick={() => setShowLocationInfo(!showLocationInfo)}>{showLocationInfo ? "(Hide info)" : "(See more info)"}</span>
                        :
                        <></>
                    }
                </div>

                {
                    target.isBundle ?
                        <div className="plan-tracker-container">
                            <div className="tracker-wrapper"
                                 style={{backgroundColor: target.isCustom && target.isOnTrial ? "#2C2B7C" : "#EE6C4D"}}>
                                <div style={{fontSize: '15px', height: '40%'}}
                                     className="mt-1">
                                    {target.credits !== null && target.credits >= 0 ?
                                        <span>{target.credits}/{target?.total_credits}</span>
                                        : <span>25/25</span>
                                    }
                                </div>
                                <div style={{fontSize: '14px', height: '60%'}}>Credits Remaining</div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>

            {selectedLocationState.loading ? (
                <div className="spinner-wrapper">
                    <Spinner animation="border" className="spinner"/>
                </div>
            ) : (
                <>
                    <Container
                        fluid
                        className="main-body"
                        style={{height: "calc(100% - 50px)"}}
                    >
                        {
                            showLocationInfo ?
                                <Row className="d-flex flex-row align-items-center justify-content-center">
                                    <LocationCardComponent data={selectedLocationState.data}
                                                           activateDeactivate={(id, status) => {
                                                               setShowStatusModal({
                                                                   modalState: true,
                                                                   id,
                                                                   status,
                                                                   type: "location",
                                                               })
                                                           }}
                                                           onEdit={(data, flag) => onLocationEdit(data, flag)}
                                                           handleModal={(flag) => handleModalShow(flag)}
                                                           {...props}
                                    />
                                </Row>
                                :
                                <></>
                        }

                        <Row id="location-header">
                            <span id="position-lbl" className="py-2">Job Listings</span>
                            <ButtonComponent
                                idName="loc-create-opening-btn"
                                name="Add Job Listing"
                                loading={currentPricingPlanState.loading}
                                disabled={currentPricingPlanState.loading}
                                onClickFunction={() => handleModalShow("addJob")}
                            />
                        </Row>

                        <Row id="position-table-row"
                             style={{height: showLocationInfo ? "calc(100% - 263px)" : "calc(100% - 105px)"}}>
                            {selectedLocationState.data?.jobs?.length > 0 ? (
                                <JobListingTableComponent
                                    locationId={id}
                                    handleCardClick={(locationId, jobId, applicants) =>
                                        handleCardClick(locationId, jobId, applicants)
                                    }
                                    jobsData={selectedLocationState.data?.jobs}
                                    location={selectedLocationState.data?.location?.name}
                                    handleModalShow={(val, editableJobId) => handleModalShow(val, editableJobId)}
                                    locationStatus={selectedLocationState.data?.location?.is_active}
                                    handleActivation={(status, id) => handleJobStatus(status, id)}
                                    handlePreview={(jobId) => handleJobPreview(jobId)}
                                    meData={meData}
                                />
                            ) : (
                                <div className="no-data">No data to display</div>
                            )}
                        </Row>
                    </Container>

                    {
                        show.status && (show.modal === "showAddJob" || show.modal === "editJob" || show.modal === "cloneJob")
                        && (<AddPositionModal
                            show={
                                show.status &&
                                (show.modal === "showAddJob" || show.modal === "editJob" || show.modal === "cloneJob")
                            }
                            setShow={setShow}
                            id={id}
                            onHide={() => handleClose()}
                            openForEdit={show.modal === "editJob" || show.modal === "cloneJob"}
                            openForClone={show.modal === "cloneJob"}
                            idOfEditableJob={idOfEditableJob}
                        />)
                    }

                    {selectedJobState.data && (
                        <PreviewProfileModal
                            show={modalShow}
                            hide={() => setModalShow(false)}
                            meData={meData?.data}
                            jobCreationData={selectedJobState?.data}
                        />
                    )}

                    <Modal
                        show={show.status && show.modal === "invite"} onHide={() => handleClose()}
                        dialogClassName="add-location-modal"
                        aria-labelledby="contained-modal-title-vcenter" centered>
                        <Container className="modal-body-container">
                            <div className="location-modal-title">
                                {isInvite ? "Invite User" : "Edit Location"}
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    id="location-modal-close"
                                    onClick={handleClose}
                                />
                            </div>

                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={(event) => handleSubmit(event)}
                                id="addLocationModalForm"
                            >
                                <Form.Group controlId="formBasicLast" className="my-3">
                                    <Form.Control
                                        name="name"
                                        placeholder="Location Name"
                                        value={locationEditData.name}
                                        isInvalid={errors.name}
                                        onChange={(e) => handleChange(e)}
                                        disabled={isInvite}
                                        required
                                        className="modal-input-green my-3"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row className="mt-4">
                                    <Col>
                                        <ButtonComponent
                                            idName="addLocationBtn"
                                            btnType="button"
                                            name={"Update Coordinates"}
                                            style={{width: "100%"}}
                                            onClickFunction={() => handleSelectCordinatesBtnClick()}
                                        />
                                    </Col>
                                </Row>

                                <Form.Group controlId="formBasicLast" className="my-3">
                                    <Form.Control
                                        name="street"
                                        placeholder="Street"
                                        value={locationEditData.street}
                                        isInvalid={errors.street}
                                        onChange={(e) => handleChange(e)}
                                        disabled={isInvite}
                                        required
                                        className="modal-input-green my-3"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.street}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formBasicLast" className="my-3">
                                    <Form.Control
                                        name="street_alt"
                                        placeholder="Street Alt"
                                        disabled={isInvite}
                                        value={
                                            Object.keys(locationEditData).includes("street_alt")
                                                ? locationEditData.street_alt
                                                : ""
                                        }
                                        onChange={(e) => handleChange(e)}
                                        className="modal-input-green my-3"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicLast" className="my-3">
                                    <Form.Control
                                        name="city"
                                        placeholder="City"
                                        disabled={isInvite}
                                        value={
                                            Object.keys(locationEditData).includes("city")
                                                ? locationEditData.city
                                                : ""
                                        }
                                        onChange={(e) => handleChange(e)}
                                        className="modal-input-green my-3"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicLast">
                                    <Form.Row>
                                        <Col>
                                            <Select
                                                value={stateOptions.find((opt) => opt.label === locationEditData.state)}
                                                onChange={handleStateChange}
                                                options={stateOptions}
                                                placeholder={'State'}
                                                isMulti={false}
                                                className="modal-input-green"
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                name="zip"
                                                placeholder="Zip"
                                                value={locationEditData.zip}
                                                disabled={isInvite}
                                                isInvalid={errors.zip}
                                                onChange={(e) => handleChange(e)}
                                                required
                                                className="modal-input-green"
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.zip}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formBasicLast">
                                    <Form.Control
                                        name="phone"
                                        placeholder="Phone Number"
                                        disabled={isInvite}
                                        value={
                                            Object.keys(locationEditData).includes("phone")
                                                ? locationEditData.phone
                                                : ""
                                        }
                                        isInvalid={errors.phone}
                                        onChange={(e) => handleChange(e)}
                                        className="modal-input-green my-3"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Select
                                    value={getValue()}
                                    onChange={handleSelectChange}
                                    options={options}
                                    placeholder={options.length > 0 ? "Select A User" : "No users found"}
                                    isMulti
                                    isClearable={!isInvite}
                                    id="location-select"
                                />

                                {!isInvite && (
                                    <Row className="mt-4">
                                        <Col>
                                            <ButtonComponent
                                                idName="addLocationBtn"
                                                name={isInvite ? "Invite User" : "Update Location"}
                                                style={{width: "100%"}}
                                            />
                                        </Col>
                                    </Row>
                                )}

                            </Form>
                        </Container>
                    </Modal>

                    <MapComponent
                        show={showMap}
                        hide={() => setShowMap(false)}
                        openLocationModal={() => handleModalShow("invite")}
                        setNewLocationData={setLocationEditData}
                        newLocationData={locationEditData}
                    />

                    {show.status && show.modal === "package" && (
                        <PackageSelectionModal show={show} hide={(data) => packageConfirmed(data)} actionType={status}/>
                    )}

                    {show.status && show.modal === "showConfirmModal" && (
                        <BillingConfirmationModal
                            show={show} displayInfo={confirmationMessage}
                            continueFlow={(continueData) => handleConfirmation(continueData)}
                        />
                    )}

                    {showStatusModal.modalState && ((showStatusModal.type === "job") || (showStatusModal.type === "location")) && (
                        <StatusConfirmationModal
                            show={showStatusModal}
                            loading={confirmationLoading}
                            hide={(data) => deactivationAction(data)}
                        />
                    )}

                    {deactivationModalState.state && (
                        <DeactivateQuestionModal
                            show={deactivationModalState}
                            actions={(data) => setDeactivationData(data)}
                            hide={() => setDeactivationModalState(false)}
                        />
                    )}
                </>
            )}
        </div>
    );
}


const mapStateToProps = (state) => ({
    hiringManagers: state.addLocation.hiringManagers,
    editLocationState: state.addLocation.editLocation,
    deleteLocationState: state.addLocation.deleteLocation,
    selectedLocationState: state.addLocation.selectedLocation,
    selectedJobState: state.jobDetails?.getData,
    meData: state.me.meResponse,
    currentPricingPlanState: state.setBillingInfo.currentPricingPlan,
});

const mapDispatchToProps = (dispatch) => ({
    getHiringManagers: () => dispatch(getHiringManagers()),
    editLocationData: (id, data) => dispatch(editLocation(id, data)),
    refreshState: () => dispatch(refreshManageLocationEdit()),
    refreshDelete: () => dispatch(refreshManageLocationDelete()),
    getSelectedLocation: (id) => dispatch(getLocationFromLocationId(id)),
    getAllLocations: () => dispatch(getLocationData()),
    clearSelectedLocationError: () => dispatch(clearSelectedLocationError()),
    getLocationList: () => dispatch(getLocations()),
    getSelectedJob: (jobId) => dispatch(getJobDetails({job_id: jobId})),
    clearJobDetails: () => dispatch(clearGetJobDetailsError()),
    fetchCurrentPricingPlan: () => dispatch(getCurrentPricingPlan()),
    clearCurrentPricingPlanState: () => dispatch(clearCurrentBusinessPlanState()),
    getDeactivationReasons: () => dispatch(getDeactivationReasons()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
