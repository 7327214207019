import { useState, useEffect } from "react";
import io from "socket.io-client";

const useSocket = (serverUrl) => {
    const [socket, setSocket] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        const newSocket = io(serverUrl);

        newSocket.on("connect", () => {
            console.log("Connected to socket");
            console.log(newSocket.id)
            setSocket(newSocket);
        });

        newSocket.on("reconnect", () => {
            console.log("Reconnected to socket");
            setSocket(newSocket);
        });

        newSocket.on("disconnect", () => {
            console.log("Disconnected from socket");
            setSocket(newSocket);
        });

        newSocket.on("newChatMessage", (newData) => {
            setData(newData);
        });


        return () => {
            newSocket.disconnect();
        };
    }, [serverUrl]);

    const emitEvent = (eventName, eventData) => {
        if (socket) {
            socket.emit(eventName, eventData);
        }
    };

    return { data, socket, emitEvent };
};

export default useSocket;
