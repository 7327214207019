import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './styles.css';
import {getAllPricingPlans, getCurrentPricingPlan, postPricingPlanId} from "../../redux/actions/billingAction";
import {postPromoCode, resetPromoCode} from "../../redux/actions/subscriptionAction";
import {connect} from "react-redux";
import {Spinner} from "react-bootstrap";
import ButtonComponent from "../ButtonComponent";
import {useSnackbar} from "notistack";
import * as meActions from "../../redux/actions/me";
import SubscriptionModal from "../SubscriptionModal";
import {useHistory} from "react-router-dom";

const PackageSelectionModal = (props) => {
    const history = useHistory();
    const [selectedPlan, setSelectedPlan] = useState({planId: ""});
    const [promoCode, setPromoCode] = useState(null);
    const [showSubscription, setShowSubscription] = useState({state: false, data: null});
    const [btnLoading, setBtnLoading] = useState(false);
    const [validateBtnLoading, setValidateBtnLoading] = useState(false);
    const {
        getPricingPlans,
        pricingPlans,
        postPricingPlan,
        selectedPlanState,
        fetchCurrentPricingPlan,
        postPromoCode,
        subscriptionState,
        resetSubscriptionState
    } = props;
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!pricingPlans?.data) {
            getPricingPlans();
        }
    }, []);

    useEffect(() => {
        console.log(pricingPlans);
    }, [pricingPlans])

    useEffect(() => {
        console.log(subscriptionState)
        if (!subscriptionState.loading) {
            if (subscriptionState.error) {
                setValidateBtnLoading(false)
                enqueueSnackbar(subscriptionState.error, {variant: "error"});
            }
            if (subscriptionState.data) {
                setValidateBtnLoading(false)
                setShowSubscription({state: true, data: subscriptionState.data})
            }
        } else {
            setValidateBtnLoading(true)
        }
    }, [subscriptionState])

    useEffect(() => {
        if (selectedPlan.planId) {
            if (!selectedPlanState.loading) {
                setBtnLoading(false);
                if (selectedPlanState.data) {
                    console.log(selectedPlanState.data)
                    fetchCurrentPricingPlan();
                    resetSubscriptionState()
                    setShowSubscription({state: false, data: ""})
                    console.log(props.actionType)
                    if (props.actionType) {
                        props.hide("showConfirmation");
                    }
                    else {
                        history.go(0)
                        // props.hide("showAddJob");
                    }
                } else {
                    enqueueSnackbar(selectedPlanState.error.code, {variant: "error"});
                }
            } else {
                setBtnLoading(true);
            }
        }
    }, [selectedPlanState])

    const handleChange = (plan) => {
        if (selectedPlan.planId === plan) {
            setSelectedPlan({planId: ""})
        } else {
            setSelectedPlan({planId: plan});
        }
    }

    const onPlanSelected = () => {
        if (selectedPlan.planId) {
            postPricingPlan(selectedPlan.planId, toast);
        }
    }

    const handlePromoChange = (e) => {
        const value = e.target.value;
        setPromoCode(value);
    };

    const onPromoValidation = () => {
        setValidateBtnLoading(true);
        if (promoCode) {
            postPromoCode(promoCode);
        } else {
            setValidateBtnLoading(false);
            enqueueSnackbar("Please enter the code before validating!", {variant: "error"});
        }
    }

    const toast = (message, value) => {
        enqueueSnackbar(message, {variant: value});
    };

    const subscriptionModalAction = (data) => {
        if (data) {
            setSelectedPlan({planName: showSubscription.data, planId: showSubscription.data.id});
            postPricingPlan(showSubscription.data.id, toast);
        } else {
            resetSubscriptionState()
            setShowSubscription({state: data, data: ""})
        }
    }

    return (
        <>
            <Modal show={props.show.status} dialogClassName="package-confirmation-modal" keyboard={false}
                   backdrop={"static"} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-close-fab" onClick={() => btnLoading ? null : props.hide(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </div>

                <div className="d-flex flex-column align-items-center w-100 h-100">
                    {pricingPlans.loading ? (
                        <div className="spinner-wrapper">
                            <Spinner animation="border" className="spinner"/>
                        </div>
                    ) : pricingPlans.data ? (
                        <>
                            <div className="pricing-plan-info mt-2">
                                <div className="pricing-plan-title">Choose a pricing plan</div>
                                <div className="pricing-plan-offer-tag mt-2">Free listings! Limited time only!</div>
                                <div>1 active listing = 1 credit</div>
                                <div>Active listings auto-renew after 30 days</div>
                                <div>Easy pause/unpause listings</div>
                                <div>Unused job listing credits on volume plans do not roll over</div>
                                <div>If your business needs more than 25 credits per month,
                                    <a style={{color: "#EE6C4D"}} target="_blank"
                                       href="https://www.jipejobs.com/contact"> REQUEST
                                        ENTERPRISE PRICING</a>
                                </div>
                            </div>

                            <div
                                className="d-flex flex-row align-items-center justify-content-between"
                                style={{height: "60%", width: '100%', marginTop: '20px'}}
                            >
                                {pricingPlans?.data?.map((plan, index) => {
                                    return (
                                        <div className={"plan-card h-100"} style={{borderRight: '1px solid #2C2B7C'}}>
                                            <div className="plan-header">{plan?.no_of_items} credit / MO</div>
                                            <div className="plan-sub-header d-flex flex-column justify-content-center">
                                                <span>{plan?.short_description}</span>
                                            </div>

                                            <div className="mt-3">
                                            <span className="plan-price">
                                            ${plan?.amount}
                                            </span>
                                                <span className="plan-per-list-lbl">/ mo</span>
                                            </div>

                                            <div className="mt-2" style={{height: '25px'}}>
                                                <span
                                                    className="pricing-plan-per-list-lbl">{plan?.save_description}</span>
                                            </div>


                                            <button
                                                className={"buy-plan-btn mt-3" + (selectedPlan?.planId === plan?.id ? " selected-plan" : "")}
                                                onClick={() => handleChange(plan?.id)}>
                                                <span style={{color: '#A3CF62'}}>${plan?.net_total}</span>
                                                <span> - Buy Now</span>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>

                            <div style={{height: 'auto'}}
                                 className="w-100 d-flex flex-row justify-content-center align-items-center">
                                <ButtonComponent idName="saveBtn" name="Save" onClickFunction={onPlanSelected}
                                                 loading={btnLoading}
                                                 disabled={btnLoading}/>
                            </div>
                        </>
                    ) : (
                        <div className="no-pricing-plans">No Pricing plans</div>
                    )}
                </div>
            </Modal>

            {showSubscription.state && (
                <SubscriptionModal show={showSubscription} hide={(data) => subscriptionModalAction(data)}
                                   loading={btnLoading}/>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    pricingPlans: state.setBillingInfo.pricingPlans,
    selectedPlanState: state.setBillingInfo.selectedPlan,
    subscriptionState: state.subscription
});

const mapDispatchToProps = (dispatch) => ({
    getPricingPlans: () => dispatch(getAllPricingPlans()),
    fetchCurrentPricingPlan: () => dispatch(getCurrentPricingPlan()),
    getMe: () => dispatch(meActions.me()),
    postPricingPlan: (id, toast) =>
        dispatch(postPricingPlanId({product_id: id}, toast)),
    postPromoCode: (code) => dispatch(postPromoCode(code)),
    resetSubscriptionState: () => dispatch(resetPromoCode())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PackageSelectionModal);
