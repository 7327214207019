import React, {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {faImages} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonComponent from "../../../../components/ButtonComponent";
import ImageCropperComponent from '../../../../components/ImageCropperComponent'
import "./style.css";
import {postImage} from "../../../../redux/actions";
import {useSnackbar} from "notistack";

const ProfilePicture = (props) => {
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [src, setSrc] = useState(null)
  const [btnLoading, setBtnLoading] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [showImageCropper, setShowImageCropper] = useState(false)

  const imgInput1 = useRef(null);

  const inputClicked = (input) => {
    input.current.click();
  };
  const {enqueueSnackbar} = useSnackbar();

  const {data, uploadProfilePicture, meData, profilePictureState} = props;
  useEffect(() => {
    if (data) {
      setSelectedLogo(data);
    }
  }, [data]);

  useEffect(() => {
    if (!profilePictureState.loading) {
      setBtnLoading(false);
      if (profilePictureState.profilePicture && buttonEnabled) {
        setButtonEnabled(false)
      } else if (!profilePictureState.profilePicture && buttonEnabled && profilePictureState.error) {
        setButtonEnabled(true)
      }
    } else {
      setBtnLoading(true);
    }
  }, [profilePictureState])

  const onLogoChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSrc(reader.result)
      );
      reader.readAsDataURL(event.target.files[0]);
    }
    setShowImageCropper(true)
  };

  const handleUpload = () => {
    setBtnLoading(true);
    if (buttonEnabled) {
      const formData = new FormData();
      formData.append("file", selectedLogo);
      uploadProfilePicture(formData, showNotification, meData);
    }
  };

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }
  return (
    <>
      <div id="profileCard">
        <Row>
          <Col>
            <div className="profile-card-header">Logo</div>

            <div className="d-flex flex-row align-items-center">
              <div className="bg-white">
                <div
                  id="profile-logo-container"
                  onClick={() => inputClicked(imgInput1)}
                  style={{
                    backgroundImage: `url(${
                      selectedLogo
                        ? selectedLogo.name
                          ? URL.createObjectURL(selectedLogo)
                          : selectedLogo
                        : ""
                    })`,
                  }}
                >
                  {!selectedLogo ? <FontAwesomeIcon icon={faImages}/> : <></>}
                </div>
              </div>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => onLogoChange(e)}
                onClick={(event) => {
                  event.target.value = null
                }}
                hidden={true}
                ref={imgInput1}
              />
              <ButtonComponent
                idName="edit-logo-btn"
                name="UPLOAD"
                loading={btnLoading}
                disabled={!buttonEnabled || btnLoading}
                onClickFunction={() => handleUpload()}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ImageCropperComponent show={showImageCropper} hide={() => setShowImageCropper(false)} type={"profile"} src={src}
                             setButtonEnabled={setButtonEnabled} setSelectedImage={setSelectedLogo}/>
    </>
  );
};
const mapStateToProps = (state) => ({
  meData: state.me.meResponse,
  profilePictureState: state.setProfilePicture
});

const mapDispatchToProps = (dispatch) => ({
  uploadProfilePicture: (selectedLogo, snackBar, meData) =>
    dispatch(postImage(selectedLogo, "profilePicture", snackBar, meData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
