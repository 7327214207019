export const billingConstants = {
  POST_PRICING_PLAN_SUCCESS: "POST_PRICING_PLAN_SUCCESS",
  POST_PRICING_PLAN_FAILED: "POST_PRICING_PLAN_FAILED",
  POST_PRICING_PLAN_LOADING: "POST_PRICING_PLAN_LOADING",
  GET_PRICING_PLANS_LOADING : "GET_PRICING_PLANS_LOADING",
  GET_PRICING_PLANS_ERROR : "GET_PRICING_PLANS_ERROR",
  GET_PRICING_PLANS_SUCCESS : "GET_PRICING_PLANS_SUCCESS",
  POST_CARD_DATA_LOADING : "POST_CARD_DATA_LOADING",
  POST_CARD_DATA_SUCCESS : "POST_CARD_DATA_SUCCESS",
  POST_CARD_DATA_FAILED : "POST_CARD_DATA_FAILED",
  POST_CARD_DATA_AND_PLAN_LOADING : "POST_CARD_DATA_AND_PLAN_LOADING",
  POST_CARD_DATA_AND_PLAN_SUCCESS : "POST_CARD_DATA_AND_PLAN_SUCCESS",
  POST_CARD_DATA_AND_PLAN_FAILED : "POST_CARD_DATA_AND_PLAN_FAILED",
  GET_BUSINESS_PRICING_PLAN_LOADING :  "GET_BUSINESS_PRICING_PLAN_LOADING",
  GET_BUSINESS_PRICING_PLAN_SUCCESS :  "GET_BUSINESS_PRICING_PLAN_SUCCESS",
  GET_BUSINESS_PRICING_PLAN_FAILED :  "GET_BUSINESS_PRICING_PLAN_FAILED",
  CLEAR_CURRENT_BUSINESS_PRICING_PLAN: "CLEAR_CURRENT_BUSINESS_PRICING_PLAN"
}
