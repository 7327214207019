import types from '../types';
import * as verifyService from '../../services/api/userVerificationService';


const setLoadingAction = () => ({
  type: types.RESET_PASSWORD_SET_LOADING,
});

const resetPasswordSuccess = verifiedData => ({
  type: types.RESET_PASSWORD,
  verifiedData,
});

const resetPasswordFailed = error => ({
  type: types.RESET_PASSWORD_FAILED,
  error,
});

export const resetPassword = (resetPasswordData) => dispatch => {
  dispatch(setLoadingAction())
  return verifyService.resetPassword(resetPasswordData)
    .then(
      response => {
        dispatch(resetPasswordSuccess(response.data))
      },
      error => {
        dispatch(resetPasswordFailed(error))
      }
    );
};

