import './style.css';
import React, {useEffect} from "react";
import {Image} from "react-bootstrap";
import oops from "../../../assets/oops-monkey.svg";
import {ReactComponent as AppleIcon} from "../../../assets/apple-logo.svg";
import playstore from "../../../assets/play-store-logo.svg";

const DownloadPage = () => {
  return (
    <div className="oops-main-wrapper d-flex flex-column">
      <div>
        <Image src={oops} alt="user" className='oops-logo'/>
      </div>

      <div className="download-text-container">
        <div className="woops-txt d-flex flex-column">
          <span>Woops!</span>
          <span>Looks like you do not have the jipe app downloaded.</span>
        </div>

        <div className="woops-info">
          To get yours, click on the buttons below according to your device!
        </div>
      </div>

      <div className="download-btn-container">
        <div className="download-btn">
          <AppleIcon className='apple-logo'/>
          <div className="download-text">
            <span style={{fontSize: '12px'}}>Download on the</span>
            <br/>
            <span style={{fontSize: '18px', fontWeight: 'bold'}}>AppStore</span>
          </div>
        </div>

        <div className="download-btn">
          <Image src={playstore} alt="user" className='play-logo'/>
          <div className="download-text">
            <span style={{fontSize: '12px', textTransform: 'uppercase'}}>Get it on</span>
            <br/>
            <span style={{fontSize: '18px', fontWeight: 'bold'}}>Google Play</span>
          </div>
        </div>
      </div>

      <div className="bottom-background-wrapper">

      </div>
    </div>
  )
}

export default DownloadPage;
