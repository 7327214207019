import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "../src/redux/stores/index";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
  for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={5}
      ref={notistackRef}
      autoHideDuration={3000}
      action={(key) => (
        <span onClick={onClickDismiss(key)} style={{ cursor: "pointer" }}>
          <FontAwesomeIcon
            icon={faTrash}
            style={{ color: "#EE6C4D" }}
            className="mx-3"
          />
        </span>
      )}
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </SnackbarProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.registerServiceWorker();
// serviceWorker.unregister();
