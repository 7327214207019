export const messagingConstants = {
  GET_CONVERSATION_LIST_LOADING :  "GET_CONVERSATION_LIST_LOADING",
  GET_CONVERSATION_LIST_SUCCESS :  "GET_CONVERSATION_LIST_SUCCESS",
  GET_CONVERSATION_LIST_FAILED :  "GET_CONVERSATION_LIST_FAILED",
  GET_CONVERSATION_DETAILS_LOADING :  "GET_CONVERSATION_DETAILS_LOADING",
  GET_CONVERSATION_DETAILS_SUCCESS :  "GET_CONVERSATION_DETAILS_SUCCESS",
  GET_CONVERSATION_DETAILS_FAILED :  "GET_CONVERSATION_DETAILS_FAILED",
  GET_CONVERSATION_MESSAGES_LOADING :  "GET_CONVERSATION_MESSAGES_LOADING",
  GET_CONVERSATION_MESSAGES_SUCCESS :  "GET_CONVERSATION_MESSAGES_SUCCESS",
  GET_CONVERSATION_MESSAGES_FAILED :  "GET_CONVERSATION_MESSAGES_FAILED",
  JOB_SWIPE_LOADING :  "JOB_SWIPE_LOADING",
  JOB_SWIPE_SUCCESS :  "JOB_SWIPE_SUCCESS",
  JOB_SWIPE_FAILED :  "JOB_SWIPE_FAILED",
  JOB_SWIPE_RESET :  "JOB_SWIPE_RESET",
  POST_CONVERSATION_MESSAGE_LOADING :  "POST_CONVERSATION_MESSAGE_LOADING",
  POST_CONVERSATION_MESSAGE_SUCCESS :  "POST_CONVERSATION_MESSAGE_SUCCESS",
  POST_CONVERSATION_MESSAGE_FAILED :  "POST_CONVERSATION_MESSAGE_FAILED",
  CLEAR_CONVERSATION_LIST: "CLEAR_CONVERSATION_LIST"
}
