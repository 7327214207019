import types from "../types";
import * as locationService from "../../services/api/applicantsService";

const getApplicantsLoading = () => ({
    type: types.GET_APPLICANTS_LOADING,
});

const getApplicantsFailed = (error) => ({
    type: types.GET_APPLICANTS_FAILED,
    error
})

const getApplicantsSuccess = (data) => ({
    type: types.GET_APPLICANTS_SUCCESS,
    data
})

export const applicantsFromJobId = (jobId, status) => {
    return async (dispatch) => {
        try {
            dispatch(getApplicantsLoading());
            const response = await locationService.getApplicantsFromJobId(jobId, status);
            if (response && response.status === 200 && response.data?.data) {
                dispatch(getApplicantsSuccess(response.data.data));
            }
        } catch (err) {
            dispatch(getApplicantsFailed("Could not fetch required applicants"));
        }
    };
};

export const clearApplicantsError = () => ({
    type: types.CLEAR_APPLICANTS_GET_ERROR
})

export const getAllApplicants = (locationId, jobId, status) => {
    return async (dispatch) => {
        try {
            dispatch(getAllApplicantsLoading());
            const response = await locationService.getApplicantsFromJobLocationId(locationId, jobId, status);
            if (response && response.status === 200 && response.data?.data) {
                dispatch(getAllApplicantsSuccess(response.data.data));
            }
        } catch (err) {
            dispatch(getAllApplicantsFailed("Could not fetch applicants"));
        }
    };
}

const getAllApplicantsLoading = () => ({
    type: types.GET_ALL_APPLICANTS_LOADING
})

const getAllApplicantsFailed = (error) => ({
    type: types.GET_ALL_APPLICANTS_ERROR,
    error
})

const getAllApplicantsSuccess = (data) => ({
    type: types.GET_ALL_APPLICANTS_SUCCESS,
    data
})

export const clearGetAllApplicantsError = () => ({
    type: types.CLEAR_GET_ALL_APPLICANTS_ERROR
})

const updateApplicantStatusLoading = () => ({
    type: types.UPDATE_APPLICANT_STATUS_LOADING
})

const updateApplicantStatusFailed = (error) => ({
    type: types.UPDATE_APPLICANT_STATUS_ERROR,
    error
})

const updateApplicantStatusSuccess = (data) => ({
    type: types.UPDATE_APPLICANT_STATUS_SUCCESS,
    data
})

export const updateApplicantStatus = (data) => {
    return async (dispatch) => {
        try {
            dispatch(updateApplicantStatusLoading());
            const response = await locationService.updateApplicantStatus(data);
            if (response && response.status === 200 && response.data) {
                dispatch(updateApplicantStatusSuccess(response.data.message));
            }
        } catch (err) {
            dispatch(updateApplicantStatusFailed("Could not update applicant's status"));
        }
    };
};


const hideApplicantLoading = () => ({
    type: types.HIDE_APPLICANT_LOADING
})

const hideApplicantFailed = (error) => ({
    type: types.HIDE_APPLICANT_ERROR,
    error
})

const hideApplicantSuccess = (data) => ({
    type: types.HIDE_APPLICANT_SUCCESS,
    data
})

export const hideApplicant = (data) => {
    return async (dispatch) => {
        try {
            dispatch(hideApplicantLoading());
            const response = await locationService.hideApplicant(data);
            console.log(response)
            if (response && response.status === 200 && response.data) {
                dispatch(hideApplicantSuccess(response.data.message));
            }
        } catch (err) {
            dispatch(hideApplicantFailed("Could not update applicant's status"));
        }
    };
};
