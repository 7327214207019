import {Route} from "react-router-dom";
import React from "react";

import auth from '../services/auth';

export const PublicRoute = ({component: Component, layout: Layout, ...rest}) => (
  <Route {...rest} render={props => {
    if (auth.isAuthenticated() && props.location.pathname === "/login") {
      props.history.push("/location-manager")
    }
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    )
  }
  }/>
)
