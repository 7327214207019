import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import ButtonComponent from "../../../../components/ButtonComponent";
import "./style.css";
import {useSnackbar} from "notistack";
import {connect} from "react-redux";
import {clearBusinessInfo, setBusinessInfo,} from "../../../../redux/actions/setBusinessInfoAction";

const BasicInfo = (props) => {
  const [basicInfo, setBasicInfo] = useState({
    businessName: "",
  });

  const [errors, setErrors] = useState({});
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const {
    data,
    setBasicInfoData,
    clearBusinessInfoState,
    businessInfoState,
    meData,
  } = props;

  useEffect(() => {
    if (data) {
      setPropsToState(data);
    }
  }, [data]);

  useEffect(() => {
    clearBusinessInfoState();
  }, []);

  useEffect(() => {
    if (!businessInfoState.loading) {
      setBtnLoading(false);
      if (businessInfoState.error) {
        enqueueSnackbar("Could not update Business Info", {
          variant: "error",
        });
        clearBusinessInfoState();
      } else if (businessInfoState.businessInfo) {
        enqueueSnackbar("Business Info updated Successfully", {
          variant: "success",
        });
        clearBusinessInfoState();
        setButtonEnabled(false);
      }
    } else {
      setBtnLoading(true);
    }
  }, [businessInfoState]);

  const setPropsToState = (data) => {
    setBasicInfo({
      businessName: data?.basic_info?.business_name,
    });
    if (buttonEnabled) {
      setButtonEnabled(false)
    }
  };

  const handleBasicInfoChange = (e) => {
    e.preventDefault();
    if (
      meData.data.basic_info.business_name !== e.target.value &&
      !buttonEnabled
    ) {
      setButtonEnabled(true);
    } else if (
      meData.data.basic_info.business_name === e.target.value &&
      buttonEnabled
    ) {
      setButtonEnabled(false);
    }
    if (e.target?.name) {
      setBasicInfo({...basicInfo, [e.target.name]: e.target.value});
    }
  };

  const handleSubmit = () => {
    if (!validation()) {
      return;
    } else {
      //post data
      setBasicInfoData({full_name: basicInfo.businessName}, meData);
    }
  };

  const validation = () => {
    const validationErr = {};

    if (!basicInfo.businessName) {
      validationErr.businessName = "Business name is required";
    }

    if (Object.keys(validationErr).length > 0) {
      setErrors(validationErr);
    }
    return Object.keys(validationErr).length === 0;
  };


  return (
    <div id="profileCard">
      <Row>
        <Col>
          <div className="profile-card-header">Basic business info</div>

          <Row className="mt-3 fix-margin">
            <Col>
              <Form.Group>
                <Form.Control
                  placeholder="Business name"
                  name="businessName"
                  value={basicInfo.businessName}
                  required
                  isInvalid={errors.businessName}
                  onChange={(e) => handleBasicInfoChange(e)}
                  style={{
                    borderRadius: "19px",
                    border: "2px solid #A3CF62",
                    color: "#2C2B7C"
                  }}
                />
                {errors.businessName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.businessName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="d-flex flex-row justify-content-end mt-3 fix-margin">
        <ButtonComponent
          idName="saveBtn"
          name="Save"
          loading={btnLoading}
          disabled={businessInfoState.loading || !buttonEnabled}
          onClickFunction={handleSubmit}
        />
        {buttonEnabled && (
          <ButtonComponent
            className={"profile-cancel-btn"}
            name="Cancel"
            type={"outline"}
            disabled={!buttonEnabled || btnLoading}
            onClickFunction={() => setPropsToState(data)}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  businessInfoState: state.setBusinessInfo,
  meData: state.me?.meResponse,
});
const mapDispatchToProps = (dispatch) => ({
  setBasicInfoData: (data, meData) =>
    dispatch(setBusinessInfo(data, meData, "businessName")),
  clearBusinessInfoState: () => dispatch(clearBusinessInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
