import types from "../types";

const defaultState = {
  cardData: {
    data: "",
    loading: false,
    error: "",
  },
  cardAndPlanData: {
    data: "",
    loading: false,
    error: "",
  },
  pricingPlans: {
    loading: false,
    error: "",
    data: "",
  },
  selectedPlan: {
    loading: false,
    error: "",
    data: ""
  },
  currentPricingPlan: {
    loading: false,
    error: "",
    data: ""
  }
};

const setBillingInfo = (state = defaultState, action) => {
  switch (action.type) {
    case types.billingConstants.POST_CARD_DATA_LOADING:
      return {
        ...state,
        cardData: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.billingConstants.POST_CARD_DATA_FAILED:
      return {
        ...state,
        cardData: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.billingConstants.POST_CARD_DATA_SUCCESS:
      return {
        ...state,
        cardData: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.billingConstants.POST_CARD_DATA_AND_PLAN_LOADING:
      return {
        ...state,
        cardAndPlanData: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.billingConstants.POST_CARD_DATA_AND_PLAN_FAILED:
      return {
        ...state,
        cardAndPlanData: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.billingConstants.POST_CARD_DATA_AND_PLAN_SUCCESS:
      return {
        ...state,
        cardAndPlanData: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.billingConstants.GET_PRICING_PLANS_LOADING:
      return {
        ...state,
        pricingPlans: {
          error: "",
          data: "",
          loading: true,
        },
      };
    case types.billingConstants.GET_PRICING_PLANS_ERROR:
      return {
        ...state,
        pricingPlans: {
          error: action.error,
          data: "",
          loading: false,
        },
      };
    case types.billingConstants.GET_PRICING_PLANS_SUCCESS:
      return {
        ...state,
        pricingPlans: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.billingConstants.POST_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        selectedPlan: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.billingConstants.POST_PRICING_PLAN_FAILED:
      return {
        ...state,
        selectedPlan: {
          error: action.error,
          data: "",
          loading: false,
        },
      };
    case types.billingConstants.POST_PRICING_PLAN_LOADING:
      return {
        ...state,
        selectedPlan: {
          error: "",
          data: "",
          loading: true,
        },
      };
    case types.billingConstants.GET_BUSINESS_PRICING_PLAN_LOADING:
      return {
        ...state,
        currentPricingPlan: {
          error: "",
          data: "",
          loading: true,
        },
      };
    case types.billingConstants.GET_BUSINESS_PRICING_PLAN_FAILED:
      return {
        ...state,
        currentPricingPlan: {
          error: action.error,
          data: "",
          loading: false,
        },
      };
    case types.billingConstants.GET_BUSINESS_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        currentPricingPlan: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.billingConstants.CLEAR_CURRENT_BUSINESS_PRICING_PLAN:
      return {
        ...state,
        currentPricingPlan: {
          error: "",
          data: "",
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default setBillingInfo;
