import types from "../types";

const defaultState = {
    recipient: {
        data: "",
        loading: false,
        error: "",
    },
    activeChat: {
        data: "",
        loading: false,
        error: "",
    },
    newMessage: {
        data: "",
        loading: false,
        error: "",
    },
    jobSwipe: {
        data: "",
        loading: false,
        error: "",
    }
};

const messagingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.messagingConstants.GET_CONVERSATION_LIST_LOADING:
            return {
                ...state,
                recipient: {
                    data: "",
                    loading: true,
                    error: "",
                },
            };
        case types.messagingConstants.GET_CONVERSATION_LIST_FAILED:
            return {
                ...state,
                recipient: {
                    data: "",
                    error: action.error,
                    loading: false,
                },
            };
        case types.messagingConstants.GET_CONVERSATION_LIST_SUCCESS:
            return {
                ...state,
                recipient: {
                    error: "",
                    data: action.data,
                    loading: false,
                },
            };
        case types.messagingConstants.GET_CONVERSATION_MESSAGES_LOADING:
            return {
                ...state,
                activeChat: {
                    data: "",
                    loading: true,
                    error: "",
                },
            };
        case types.messagingConstants.GET_CONVERSATION_MESSAGES_FAILED:
            return {
                ...state,
                activeChat: {
                    data: "",
                    error: action.error,
                    loading: false,
                },
            };
        case types.messagingConstants.GET_CONVERSATION_MESSAGES_SUCCESS:
            return {
                ...state,
                activeChat: {
                    error: "",
                    data: action.data,
                    loading: false,
                },
            };
        case types.messagingConstants.JOB_SWIPE_LOADING:
            return {
                ...state,
                jobSwipe: {
                    data: "",
                    loading: true,
                    error: "",
                },
            };
        case types.messagingConstants.JOB_SWIPE_FAILED:
            return {
                ...state,
                jobSwipe: {
                    data: "",
                    error: action.error,
                    loading: false,
                },
            };
        case types.messagingConstants.JOB_SWIPE_SUCCESS:
            return {
                ...state,
                jobSwipe: {
                    error: "",
                    data: action.data,
                    loading: false,
                },
            };
        case types.messagingConstants.JOB_SWIPE_RESET:
            console.log(state)
            return {
                ...state,
                jobSwipe: {
                    error: "",
                    data: "",
                    loading: false,
                },
            };
        case types.messagingConstants.POST_CONVERSATION_MESSAGE_LOADING:
            return {
                ...state,
                newMessage: {
                    data: "",
                    error: "",
                    loading: true,
                },
            };
        case types.messagingConstants.POST_CONVERSATION_MESSAGE_FAILED:
            return {
                ...state,
                newMessage: {
                    data: "",
                    error: action.error,
                    loading: false,
                },
            };
        case types.messagingConstants.POST_CONVERSATION_MESSAGE_SUCCESS:
            return {
                ...state,
                newMessage: {
                    error: "",
                    data: action.data,
                    loading: false,
                },
            };
        default:
            return state;
    }
};

export default messagingReducer;
