import axiosInstance from "../../services/api/axios";
import types from "../types";
import { meSuccess } from "./me";

const setImageSuccess = (imageData) => ({
  type: types.UPLOAD_BANNER_IMAGE,
  imageData,
});

const setImageFailed = (error) => ({
  type: types.UPLOAD_BANNER_IMAGE_FAILED,
  error,
});

export const setBannerImageLoading = () => ({ 
    type: types.UPLOAD_BANNER_IMAGE_LOADING,
  });

export const setBannerImage = (data, showNotification, meData, profile_completed_up_to = null) => {
  return async (dispatch) => {
    try {
      dispatch(setBannerImageLoading())
      const response = await axiosInstance.post("profile/banner-picture/", {
        banner_picture: data,
      });
      if (response && response.data) {
        if(showNotification) {
          showNotification("Banner Image Uploaded Successfully", "success")
        }
        dispatch(setImageSuccess(response.data?.data?.banner_picture_url || "default"));
        if(meData) {
          dispatch(meSuccess({...meData,data: {...meData.data, banner_picture_url: response.data?.data?.banner_picture_url ? response.data?.data?.banner_picture_url: data? data : ""}}))
          if(profile_completed_up_to) {
            dispatch(meSuccess({
              ...meData,
              data: {
                ...meData.data, 
                profile_completed_up_to : profile_completed_up_to,
                banner_picture_url: response?.data?.data?.banner_picture_url
              }
            }))
          }
        }
      }
    } catch (err) {
      if(showNotification) {
        showNotification("Banner Image Upload Failed", "error")
      }
      dispatch(setImageFailed(err.message));
    }
  };
};

export const setPreviousBannerPicture = (banner_picture_url) => dispatch =>{
  dispatch(setImageSuccess(banner_picture_url))
}
