import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {useSnackbar} from "notistack";
import * as createJobActions from '../../../../redux/actions/createJob';
import * as getJobDetailsActions from '../../../../redux/actions/getJobDetailsAction';
import "./style.css";
import {ReactComponent as MonkeySuccess} from "../../../../assets/success-monkey2.svg";
import ButtonComponent from "../../../../components/ButtonComponent";
import CreateJobComponent from "../CreateJobComponent";
import ResponsibilityListComponent from "../ResponsibilityListComponent";
import ShareAvailabilityComponent from "../ShareAvailabilityComponent";
import PreviewProfileModal from "../../../../components/PreviewProfileModal";
import BillingConfirmationModal from "../../../../components/BillingConfirmationModal";
import validator from "validator";
import {getCurrentPricingPlan} from "../../../../redux/actions/billingAction";
import {checkTimeAvailability, checkTimeMayVary} from "../../../../utils/utils";

const StepperComponent = (props) => {
    const {createJob, create_job, meData, currentPricingPlanState, fetchCurrentPricingPlan} = props;
    const {enqueueSnackbar} = useSnackbar();

    const [stepperActivePage, setStepperActivePage] = useState(1);
    const [stepperNavigation, setStepperNavigation] = useState(false);
    const [confirmationLoading, setConfirmationLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [errors, setErrors] = useState({
        type: "Please select atleast one job type"
    });
    const [billingModalShow, setBillingModalShow] = useState({state: false, displayInfo: ""});
    const [jobCreationResponsibility, setJobCreationResponsibility] = useState({});
    const [jobType, setJobType] = useState({});
    const [jobCreationData, setJobCreationData] = useState({
        title: null,
        number: null,
        min_age: null,
        hourly_rate: null,
        online_application_url: null,
        responsibilities: [],
        locations: [],
        availabilities: {
            types: [],
            time_sheets: [
                {
                    day: 1,
                    may_vary: false,
                    time_slots: []
                },
                {
                    day: 2,
                    may_vary: false,
                    time_slots: []
                },
                {
                    day: 3,
                    may_vary: false,
                    time_slots: []
                },
                {
                    day: 4,
                    may_vary: false,
                    time_slots: []
                },
                {
                    day: 5,
                    may_vary: false,
                    time_slots: []
                },
                {
                    day: 6,
                    may_vary: false,
                    time_slots: []
                },
                {
                    day: 7,
                    may_vary: false,
                    time_slots: []
                }
            ]
        }
    });

    useEffect((props) => {
        try {
            const {activeStepperIndex} = props.props.location.state
            setStepperActivePage(activeStepperIndex)
        } catch (err) {
            // pass
        }
    }, []);

    useEffect(() => {
        if (!currentPricingPlanState.loading) {
            if (!currentPricingPlanState.data) {
                fetchCurrentPricingPlan();
            } else {
                console.log(currentPricingPlanState.data);
                if (!currentPricingPlanState.data?.is_bundle_type) {
                    setBillingModalShow({
                        state: false,
                        displayInfo: `Confirming will charge you with $${currentPricingPlanState?.data?.amount} for this listing and immediately post your job. Confirm to continue.`
                    });
                } else if (currentPricingPlanState.data?.is_bundle_type && currentPricingPlanState.data?.remaining_credits > 0) {
                    setBillingModalShow({
                        state: false,
                        displayInfo: "Creating this job will use 1 credit from your remaining " + currentPricingPlanState.data?.remaining_credits + ". Do you wish to continue ?"
                    });
                } else if (currentPricingPlanState.data?.is_bundle_type && currentPricingPlanState.data?.remaining_credits === 0) {
                    handleBackCLick()
                }
            }
        }
    }, [currentPricingPlanState])

    useEffect(() => {
        if (meData?.data) {
            if (meData?.data?.business_locations?.length > 0) {
                setJobCreationData({
                    ...jobCreationData,
                    locations: [
                        {
                            "location_id": meData?.data?.business_locations[0]?.id
                        }
                    ]
                })
            } else {
                // there's no location in me response so deleting the locations data from jobCreationData
                setJobCreationData((prevData) => {
                    const newData = {...prevData}
                    delete newData["locations"]
                    return newData
                })
            }
        }
    }, [meData])

    useEffect(() => {
        try {
            if (!createJob.loading) {
                setConfirmationLoading(false);
                setBillingModalShow({...billingModalShow, state: false});
                if (createJob.error) {
                    setConfirmationLoading(false);
                    try {
                        Object.values(createJob.error.response.data).map((err) => {
                            enqueueSnackbar(err, {
                                variant: 'error',
                            });
                        })
                    } catch (err) {
                        enqueueSnackbar("Internal Error !!! Error creating job", {
                            variant: 'error',
                        });
                    }
                } else {
                    setConfirmationLoading(false);
                    if (createJob.createJobData && createJob.createJobData.data.id) {
                        enqueueSnackbar(createJob.createJobData.message ? createJob.createJobData.message : "Success", {variant: "success"});
                    }
                }
            } else {
                setConfirmationLoading(true);
            }
        } catch (err) {
            // handle while the response is null
            // console.log("error in getting createJob states = ", err)
        }
    }, [createJob])

    const enableNavigation = (val) => {
        setStepperNavigation(val)
    }

    const setResponsibility = (data) => {
        setJobCreationResponsibility(data)
    }

    const handleNextClick = () => {
        if (stepperNavigation) {
            if (stepperActivePage === 3) {
                if (checkTimeAvailability(jobCreationData.availabilities.time_sheets) || checkTimeMayVary(jobCreationData.availabilities.time_sheets)) {
                    setJobCreationData({
                        ...jobCreationData,
                        availabilities: {
                            ...jobCreationData.availabilities,
                            types: Object.values(jobType)
                        }
                    })
                    if (Object.keys(errors).length > 0) {
                        Object.keys(errors).map((errMessage) => {
                            enqueueSnackbar(errors[errMessage], {
                                variant: 'error',
                            });
                        })
                    } else {
                        setStepperActivePage(stepperActivePage + 1);
                    }
                } else {
                    enqueueSnackbar("Please enter at least one time availability!", {
                        variant: 'error',
                    });
                }
            } else if (stepperActivePage === 1) {
                if (jobCreationData.online_application_url && !validator.isURL(jobCreationData.online_application_url, {
                    protocols: ['http', 'https'],
                    require_protocol: true
                })) {
                    enqueueSnackbar("Please enter a valid url starting from http(s)", {
                        variant: 'error',
                    });
                } else {
                    setStepperActivePage(stepperActivePage + 1)
                    setStepperNavigation(false)
                }
            } else {
                setJobCreationData({
                    ...jobCreationData,
                    responsibilities: Object.values(jobCreationResponsibility)
                })
                setStepperActivePage(stepperActivePage + 1)
                setStepperNavigation(false)
            }
        }
    }

    const handleBackCLick = () => {
        if (stepperActivePage === 1) {
            props.history.push("/billing")
        } else {
            setStepperActivePage(stepperActivePage - 1)
            setStepperNavigation(false)
        }
    }

    const billingConfirmed = (data) => {
        if (data) {
            setConfirmationLoading(true)
            create_job(jobCreationData, null, meData, "job_listing_create")
        } else {
            setConfirmationLoading(false)
            setBillingModalShow({...billingModalShow, state: false});
        }
    }

    const showConfirmationModal = () => {
        setBillingModalShow({...billingModalShow, state: true})
    }


    if (stepperActivePage !== 4) {
        return (
            <div className="stepper-card">
                <div className="stepper-main-body">
                    <div className="h-100 w-100">
                        {
                            stepperActivePage === 1 &&
                            <CreateJobComponent navigationState={enableNavigation} jobCreationData={jobCreationData}
                                                meData={meData}
                                                setJobCreationData={(data) => setJobCreationData(data)}/>
                        }
                        {
                            stepperActivePage === 2 &&
                            <ResponsibilityListComponent navigationState={enableNavigation}
                                                         jobCreationResponsibility={jobCreationResponsibility}
                                                         setResponsibility={(data) => setResponsibility(data)}/>
                        }
                        {
                            stepperActivePage === 3 &&
                            <ShareAvailabilityComponent navigationState={enableNavigation}
                                                        jobCreationData={jobCreationData}
                                                        setJobCreationData={(data) => setJobCreationData(data)}
                                                        jobType={jobType}
                                                        setJobType={setJobType}
                                                        setErrors={setErrors}
                                                        errors={errors}/>
                        }
                    </div>
                </div>

                <div className="stepper-footer">
                    <div className={"stepper-btn-div"}
                         onClick={() => handleBackCLick()}>
                        <FontAwesomeIcon icon={faAngleLeft} style={{color: "#2C2B7C"}}/>
                        <span style={{marginLeft: '5px'}}>back</span>
                    </div>

                    <div className="stepper-indicator-wrapper">
                        {
                            [1, 2, 3].map((item, key) => <div
                                className={"stepper-page-indicator" + (stepperActivePage === item ? " active-stepper-indicator" : "")}
                                key={key}></div>)
                        }
                    </div>

                    <div className={"stepper-btn-div" + (!stepperNavigation ? " disabled-page-btns" : "")}
                         onClick={() => handleNextClick()}>
                        <span style={{marginRight: '5px'}}>next</span>
                        <FontAwesomeIcon icon={faAngleRight} style={{color: "#2C2B7C"}}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <PreviewProfileModal show={modalShow} hide={() => setModalShow(false)} meData={meData?.data}
                                     jobCreationData={jobCreationData}/>

                <BillingConfirmationModal show={billingModalShow.state} displayInfo={billingModalShow.displayInfo}
                                          btnState={confirmationLoading}
                                          confirmation={(data) => billingConfirmed(data)}/>

                <div className="thankyou-title">
                    You're all set!
                </div>
                <div style={{width: "350px"}} className="d-flex flex-column justify-content-center align-items-center">
                    <div className="thankyou-info">
                        You can edit your job listing at any time. Preview your listing and confirm to start receiving
                        applications and search for candidates.
                    </div>

                    <div>
                        <MonkeySuccess className="mt-4" style={{height: '200px', width: '200px'}}/>
                    </div>

                    <ButtonComponent type="link" idName="preview-btn" name='Preview Profile'
                                     onClickFunction={() => setModalShow(true)}/>

                    <ButtonComponent idName='create-listing-btn' name='CONFIRM LISTING'
                                     onClickFunction={() => showConfirmationModal()}/>

                    <ButtonComponent type="link" idName="signup-back-btn" name='< back'
                                     onClickFunction={() => handleBackCLick()}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    createJob: state.createJob,
    meData: state.me.meResponse,
    currentPricingPlanState: state.setBillingInfo.currentPricingPlan,
});

const mapDispatchToProps = dispatch => ({
    create_job: (jobCreationData, id, meData, profile_completed_up_to) => dispatch(createJobActions.createJob(jobCreationData, id, meData, profile_completed_up_to)),
    getJobDetailsAction: (data) => dispatch(getJobDetailsActions.getJobDetails(data)),
    fetchCurrentPricingPlan: () => dispatch(getCurrentPricingPlan()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StepperComponent));
