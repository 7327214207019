export const timeOptions = [
  {label: '12:00 AM', value: '00:00'},
  {label: '12:30 AM', value: '00:30'},
  {label: '01:00 AM', value: '01:00'},
  {label: '01:30 AM', value: '01:30'},
  {label: '02:00 AM', value: '02:00'},
  {label: '02:30 AM', value: '02:30'},
  {label: '03:00 AM', value: '03:00'},
  {label: '03:30 AM', value: '03:30'},
  {label: '04:00 AM', value: '04:00'},
  {label: '04:30 AM', value: '04:30'},
  {label: '05:00 AM', value: '05:00'},
  {label: '05:30 AM', value: '05:30'},
  {label: '06:00 AM', value: '06:00'},
  {label: '06:30 AM', value: '06:30'},
  {label: '07:00 AM', value: '07:00'},
  {label: '07:30 AM', value: '07:30'},
  {label: '08:00 AM', value: '08:00'},
  {label: '08:30 AM', value: '08:30'},
  {label: '09:00 AM', value: '09:00'},
  {label: '09:30 AM', value: '09:30'},
  {label: '10:00 AM', value: '10:00'},
  {label: '10:30 AM', value: '10:30'},
  {label: '11:00 AM', value: '11:00'},
  {label: '11:30 AM', value: '11:30'},
  {label: '12:00 PM', value: '12:00'},
  {label: '12:30 PM', value: '12:30'},
  {label: '01:00 PM', value: '13:00'},
  {label: '01:30 PM', value: '13:30'},
  {label: '02:00 PM', value: '14:00'},
  {label: '02:30 PM', value: '14:30'},
  {label: '03:00 PM', value: '15:00'},
  {label: '03:30 PM', value: '15:30'},
  {label: '04:00 PM', value: '16:00'},
  {label: '04:30 PM', value: '16:30'},
  {label: '05:00 PM', value: '17:00'},
  {label: '05:30 PM', value: '17:30'},
  {label: '06:00 PM', value: '18:00'},
  {label: '06:30 PM', value: '18:30'},
  {label: '07:00 PM', value: '19:00'},
  {label: '07:30 PM', value: '19:30'},
  {label: '08:00 PM', value: '20:00'},
  {label: '08:30 PM', value: '20:30'},
  {label: '09:00 PM', value: '21:00'},
  {label: '09:30 PM', value: '21:30'},
  {label: '10:00 PM', value: '22:00'},
  {label: '10:30 PM', value: '22:30'},
  {label: '11:00 PM', value: '23:00'},
  {label: '11:30 PM', value: '23:30'},
]

export const convertTime = (value) => {
  const filteredTime = timeOptions.find(time => time.value === value);
  if (value === "24:00") {
    return "11:59 PM";
  } else {
    if (filteredTime) {
      return filteredTime.label;
    }
  }
}

export const days = {1: "MONDAY", 2: "TUESDAY", 3: "WEDNESDAY", 4: "THURSDAY", 5: "FRIDAY", 6: "SATURDAY", 7: "SUNDAY"}

export const hasConflict = (startTimeToBeAdded, endTimeToBeAdded, currentTimeSheet, currentDayIndex) => {
  // check if the time range flows over to another day
  // get two chuncks of time range if it flows to another day else check conflicts in the respective day
  // check if the time range has any conflict in that respective day and also the other day if it flows over another day
  // throw error of hasConflict else add time range to the respective day's time slot.
  let rangeOverFlowToOtherDay = isNextDayOverflow(startTimeToBeAdded, endTimeToBeAdded)

  if (rangeOverFlowToOtherDay) {
    let chunksOfTimeRange = getChunksOfTimeRange(startTimeToBeAdded, endTimeToBeAdded)
    for (let i = 0; i < chunksOfTimeRange.length; i++) {
      if (hasConflictInDay(getTimeSlotsOfDay(currentDayIndex + i > 7 ? 1 : currentDayIndex + i, currentTimeSheet), [chunksOfTimeRange[i]])) {
        return {
          hasConflict: true,
          toAdd: [],
          error: i === 0 ? "The time range is conflicting in the day you are trying to add. Please select any other time range." : "The time range is conflicting in the next day you are trying to add. Please select any other time range."
        }
      }
    }
    return {
      hasConflict: false,
      toAdd: [{
        [currentDayIndex]: {start: chunksOfTimeRange[0].start, end: chunksOfTimeRange[0].end},
        [currentDayIndex + 1 > 7 ? 1 : currentDayIndex + 1]: {
          start: chunksOfTimeRange[1].start,
          end: chunksOfTimeRange[1].end
        }
      }],
      error: null
    }
  } else {
    let existingTimeSlotOfRespectiveDay = getTimeSlotsOfDay(currentDayIndex, currentTimeSheet)
    let hasConflict = hasConflictInDay(existingTimeSlotOfRespectiveDay, [{
      start: startTimeToBeAdded,
      end: endTimeToBeAdded
    }])
    if (hasConflict) {
      return {
        hasConflict: true,
        toAdd: [],
        error: "The time range is conflicting in the day you are trying to add. Please select any other time range."
      }
    } else {
      return {
        hasConflict: false,
        toAdd: [{
          [currentDayIndex]: {
            start: startTimeToBeAdded,
            end: endTimeToBeAdded === "00:00" ? "24:00" : endTimeToBeAdded
          }
        }],
        error: null
      }
    }
  }
}

export const isNextDayOverflow = (startTimeToBeAdded, endTimeToBeAdded) => {
  if (startTimeToBeAdded < endTimeToBeAdded) {
    return false
  } else {
    if (endTimeToBeAdded === '00:00') {
      return false
    } else {
      return true
    }
  }
}

const getChunksOfTimeRange = (startTimeToBeAdded, endTimeToBeAdded) => {
  return [{start: startTimeToBeAdded, end: "24:00"}, {
    start: "00:00",
    end: endTimeToBeAdded === '00:00' ? '24:00' : endTimeToBeAdded
  }]
}

const hasConflictInDay = (existingTimeSlotOfRespectiveDay, timeRangeTobeChecked) => {
  let hasConflict = true
  if (existingTimeSlotOfRespectiveDay.length === 0) {
    hasConflict = false
  } else {
    for (let i = 0; i < existingTimeSlotOfRespectiveDay.length; i++) {
      if (checkForMiddleRange(existingTimeSlotOfRespectiveDay[i], timeRangeTobeChecked)) {
        hasConflict = false
      } else {
        return true
      }
    }
  }
  return hasConflict
}

const checkForMiddleRange = (existingTimeSlotOfRespectiveDay, timeRangeTobeChecked) => {
  return (((timeRangeTobeChecked[0].start < existingTimeSlotOfRespectiveDay.start) && (timeRangeTobeChecked[0].end === "00:00" ? "24:00" : timeRangeTobeChecked[0].end <= existingTimeSlotOfRespectiveDay.start))
    || ((timeRangeTobeChecked[0].start >= existingTimeSlotOfRespectiveDay.end) && (timeRangeTobeChecked[0].end === "00:00" ? "24:00" : timeRangeTobeChecked[0].end > existingTimeSlotOfRespectiveDay.end)))
}

export const getTimeSlotsOfDay = (dayIndex, currentTimeSheet) => {
  // returns array of object of the respective day time slot
  return currentTimeSheet.find((timeSheetObjects) => timeSheetObjects.day === dayIndex)["time_slots"]
}
