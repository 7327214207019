import axiosInstance from "../../services/api/axios";
import types from "../types";
import { meSuccess } from "./me";

const setSocialLinksSuccess = (links) => ({
  type: types.SET_SOCIAL_LINKS,
  links,
});

const setSocialLinksFailed = (error) => ({
  type: types.SET_SOCIAL_LINKS_FAILED,
  error,
});

export const setSocialLinksLoading = () => ({
  type: types.SET_SOCIAL_LINKS_LOADING,
});

export const clearSocialLinksState = () => ({
  type: types.CLEAR_SOCIAL_LINKS_STATE
})

export const setSocialLinks = (data, meData, showNotification, profile_completed_up_to = null) => {
  return async (dispatch) => {
    try {
      dispatch(setSocialLinksLoading())
      const response = await axiosInstance.post("profile/social-urls/", data
      );
      if (response && response.status === 200) {
        if(showNotification) {
          showNotification("Social Links Updated successfully", "success")
        }
        dispatch(setSocialLinksSuccess(data));
        if(meData) {
          dispatch(meSuccess({...meData,data: {...meData.data, social_urls: data}}))
          if(profile_completed_up_to) {
            dispatch(meSuccess({
              ...meData,
              data: {
                ...meData.data,
                social_urls: data,
                profile_completed_up_to : profile_completed_up_to
              }
            }))
          }
        }
      }
    } catch (err) {
      if(showNotification) {
        showNotification("Social Links Update Failed", "error")
      }
      dispatch(setSocialLinksFailed(err.message));
    }
  };
};
