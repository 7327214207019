import React, {useEffect} from "react";
import "./style.css";
import {Form, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import {getJobCategories} from "../../../../../redux/actions/jobCategoryAction";
import {useSnackbar} from "notistack";

const JobCategory = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {getJobCategoriesAction, getLoading, jobCategories, error, selectedOptions, checkBoxToggled} = props;


  useEffect(() => {
    if (selectedOptions) {
      //console.log(selectedOptions.length);
      if (selectedOptions.length > 0) {
        props.navigationState(true);
      } else {
        props.navigationState(false);
      }
    }
  }, [selectedOptions]);

  useEffect(() => {
    getJobCategoriesAction();
  }, []);

  return (
    <>
      {error &&
      enqueueSnackbar("Could not fetch Job categories", {
        variant: "error",
      })}
      <div className="h-25">
        <div className="stepper-title">Job category</div>
        <div className="stepper-info">
          What job category best describes your business and job listings?
        </div>
      </div>
      {getLoading ? (
        <div className="spinner-wrapper">
          <Spinner animation="border" className="spinner"/>
        </div>
      ) : (
        <div className="h-75">
          <div className="job-overflow-wrapper">
            {jobCategories &&
            jobCategories.map((data, i) => {
              return (
                <div className="job-category-wrapper" key={i}>
                  <div className="job-category-header">
                    <div className="text-uppercase pb-2">{data.name}</div>
                    <div>
                      <Form.Check
                        className="job-category-checkbox"
                        id={data.name}
                        onChange={(event) => checkBoxToggled(event)}
                        checked={selectedOptions.includes(data.name)}
                      />
                    </div>
                  </div>
                  <div className="job-category-desc">{data.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getLoading: state.jobCategory.getData.loading,
  jobCategories: state.jobCategory.getData.data,
  error: state.jobCategory.getData.error,
});

const mapDispatchToProps = (dispatch) => ({
  getJobCategoriesAction: () => dispatch(getJobCategories()),
});

export const JobCategoryComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobCategory);
