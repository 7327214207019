import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import * as meActions from './redux/actions/me';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BlankLayoutComponent from "./components/BlankLayoutComponent";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {ProtectedRoute} from "./utils/protected.route";
import {PublicRoute} from "./utils/public.route";
import LocationManagerPage from "./containers/private/LocationManagerPage";
import LocationPage from "./containers/private/LocationPage";
import ApplicationPage from "./containers/private/ApplicationPage";
import MessagePage from "./containers/private/MessagePage";
import PositionPage from "./containers/private/PositionPage";
import EditProfilePage from "./containers/private/ProfilePage";
import ManagerPage from "./containers/private/ManagerPage/ManagerPage";
import FullLayoutComponent from "./components/FullLayoutComponent";
import HeaderLayoutComponent from "./components/HeaderLayoutComponent";
import HiringManagerSignUpPage from "./containers/private/HiringManagerSignUpPage";
import DeleteConfirmationPage from "./containers/public/DeleteConfirmationPage";
import LoginPage from "./containers/public/LoginPage/LoginPage";
import CreateAccountPage from "./containers/public/CreateAccountPage";
import ForgotPasswordPage from "./containers/public/ForgotPasswordPage";
import ErrorPage from "./containers/public/ErrorPage";
import ResetPasswordPage from "./containers/public/ResetPasswordPage";
import SignUpPage from "./containers/public/SignUpPage/SignUpPage";
import BillingPage from "./containers/public/Billingpage";
import JobListingPage from "./containers/public/JobListingPage";
import ConfirmationPage from "./containers/public/ConfirmationPage";
import DownloadPage from "./containers/public/DownloadPage";
import EmailConfirmedPage from "./containers/public/EmailConfirmedPage";
import BillingInfoPage from "./containers/private/BillingInfoPage";
import SettingsPage from "./containers/private/SettingsPage";
import AccountDeletionPage from "./containers/public/AccountDeletionPage";


function App(props) {

  const {meData, meDataLoading} = props

  useEffect(() => {
    if (!props.meData) {
      props.getMeAction()
    }
  }, [])

  return (
    <div className="app">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/location-manager"/>
          </Route>
          <PublicRoute path="/login" exact layout={BlankLayoutComponent} component={LoginPage}/>
          <PublicRoute path="/create-account" exact layout={BlankLayoutComponent} component={CreateAccountPage}/>
          <PublicRoute path="/forgot-password" exact layout={BlankLayoutComponent} component={ForgotPasswordPage}/>
          <PublicRoute path="/confirmation/:type?" exact layout={BlankLayoutComponent} component={ConfirmationPage}/>
          <PublicRoute path="/email-confirmation/:id?/:token?" exact layout={BlankLayoutComponent}
                       component={EmailConfirmedPage}/>
          <PublicRoute path="/job/:id?" exact layout={BlankLayoutComponent} component={DownloadPage}/>
          <PublicRoute path="/delete-confirmation" exact layout={HeaderLayoutComponent}
                       component={DeleteConfirmationPage}/>
          <PublicRoute path="/forget-password-confirmation/:id?/:token?" exact layout={HeaderLayoutComponent}
                       component={ResetPasswordPage}/>
          <PublicRoute exact path="/manager-signup/:id?/:token?" layout={HeaderLayoutComponent}
                       component={HiringManagerSignUpPage}/>

          <ProtectedRoute path="/signup" exact meData={meData} meDataLoading={meDataLoading}
                          layout={HeaderLayoutComponent} component={SignUpPage}/>
          <ProtectedRoute path="/billing" exact meData={meData} meDataLoading={meDataLoading}
                          layout={HeaderLayoutComponent} component={BillingPage}/>
          <ProtectedRoute path="/job-creation" exact meData={meData} meDataLoading={meDataLoading}
                          layout={HeaderLayoutComponent} component={JobListingPage}/>
          <ProtectedRoute path="/location-manager" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={LocationManagerPage}/>
          <ProtectedRoute path="/location/:id" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={LocationPage}/>
          <ProtectedRoute path="/application" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={ApplicationPage}/>
          <ProtectedRoute path="/message/:id?" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={MessagePage} messaging={true}/>
          <ProtectedRoute path="/billing-info" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={BillingInfoPage}/>
          <ProtectedRoute path="/settings" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={SettingsPage}/>
          <ProtectedRoute path="/position/:locationId/:jobId" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={PositionPage}/>
          <ProtectedRoute path="/edit-profile" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={EditProfilePage}/>
          <ProtectedRoute path="/manager" exact meData={meData} meDataLoading={meDataLoading}
                          layout={FullLayoutComponent} component={ManagerPage}/>
          <PublicRoute exact={true} path="/account-deletion" layout={HeaderLayoutComponent} component={AccountDeletionPage}/>
          <PublicRoute exact={true} path="/*" layout={HeaderLayoutComponent} component={ErrorPage}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = state => ({
  meData: state.me.meResponse,
  meDataLoading: state.me.loading,
});

const mapDispatchToProps = dispatch => ({
  getMeAction: () => dispatch(meActions.me())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
