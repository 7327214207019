import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import "./style.css";
import * as resetPasswordActions from "../../../redux/actions/resetPassword";
import ButtonComponent from "../../../components/ButtonComponent";
import * as verifyService from "../../../services/api/userService";
import {passwordValidation} from "../../../utils/utils";

const eye = <FontAwesomeIcon icon={faEye}/>;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash}/>;

const ResetPasswordPage = (props) => {

  const {reset_password, resetPassword} = props
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [errors, setErrors] = useState({})
  const [validated, setValidated] = useState(false);
  const [passwords, setPasswords] = useState({password: null, confirmPassword: null});
  const [userEmail, setUserEmail] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [resetPasswordForm, setResetPasswordForm] = useState({
    uid: '',
    token: '',
    type: 'forget_password',
    data: {
      password: ''
    }
  })

  useEffect(() => {
    console.log(resetPasswordForm)
  }, [resetPasswordForm])

  useEffect(() => {
    try {
      const {id, token} = props.match.params;
      //console.log(id, token);
      if (id && token) {
        setResetPasswordForm({...resetPasswordForm, uid: id, token: token})
        verifyService.getUserDetail({uid: id, token: token})
          .then((response) => {
            setUserEmail(response.data?.data?.basic_info?.email);
          }, (error) => {
            enqueueSnackbar(error.response.data.token, {variant: "error"});
          });
      }
    } catch (err) {
      // console.log("error getting values in reset password page = ", err)
    }
  }, [props.match.params])

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const handleChange = (type, e) => {
    //console.log(e.target.value);
    if (Object.keys(errors).length > 0) {
      setErrors({})
    }
    if (type === 'confirm') {
      setPasswords({...passwords, confirmPassword: e.target.value});
    } else {
      setPasswords({...passwords, password: e.target.value});
    }
  };

  useEffect(() => {
    console.log(resetPassword)
    if (!resetPassword.loading) {
      if (resetPassword.error) {
        const errorMessage = resetPassword.error.response.data ? Object.values(resetPassword.error.response.data)[0] : 'Error resetting password. Please try again later.'
        console.log(errorMessage)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });

      } else {
        if (resetPassword.resetPasswordResponse) {
          enqueueSnackbar(resetPassword.resetPasswordResponse.message ? resetPassword.resetPasswordResponse.message : "Success", {variant: "success"});
          history.push("/login");
        }
      }
    }
  }, [resetPassword])

  const handleSubmit = (event) => {
    event.preventDefault();
    const allErrors = {};
    if (!passwords.password) {
      allErrors.password = "Please enter your password"
    }
    if (!passwords.confirmPassword) {
      allErrors.confirmPassword = "Please enter your confirm password"
    }
    if (passwords.password && passwords.confirmPassword) {
      if (passwords.password !== passwords.confirmPassword) {
        allErrors.confirmPassword = "Password Don't match."
      } else if (!passwordValidation(passwords.password)) {
        console.log(passwordValidation(passwords.password))
        allErrors.password = "Please enter a valid password. It must contain at least 8 character, one number, one letter and one special character"
      }
    }
    if (Object.keys(allErrors).length > 0) {
      setErrors(allErrors);
      setValidated(false);
    } else {
      setValidated(true);
      reset_password({...resetPasswordForm, data: {password: passwords.password}})
    }
  };


  return (
    <div className="reset-main-container">
      <Container fluid className="reset-wrapper">
        <Row>
          <Col className='reset-header'>
            Reset Password
          </Col>
        </Row>

        <Row>
          <Col className="reset-email mt-2">
            {userEmail}
          </Col>
        </Row>

        <Row>
          <Col className="reset-info mt-2">
            Forgot your password? No problem. Type a new password in below to reset it. All passwords must be at least 6
            characters long, have at least 1 lower case, 1 capital letter, 1 special character, and 1 number.
          </Col>
        </Row>

        <Form noValidate
              validated={validated}
              onSubmit={(event) => handleSubmit(event)}
              id="forgotPasswordForm"
        >
          <Form.Group controlId="formBasicPassword" className="mt-4" style={{height: '50px'}}>
            <div className="password-input-wrapper">
              <Form.Control placeholder="Password" type={passwordShown ? "text" : "password"}
                            value={passwords.password}
                            isInvalid={errors.password} onChange={(e) => handleChange('password', e)} required
                            className="password-input-blue" style={{backgroundColor: 'transparent'}}/>
              <i onClick={togglePasswordVisiblity} className="pass-toggle-icon">{passwordShown ? eye : eyeSlash}</i>
            </div>
            <Form.Control.Feedback>Password looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" style={{display: errors.password ? 'block' : ''}}>
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword" style={{height: '50px'}}>
            <div className="password-input-wrapper">
              <Form.Control placeholder="Confirm password" type={confirmPasswordShown ? "text" : "password"}
                            value={passwords.confirmPassword}
                            isInvalid={errors.confirmPassword} onChange={(e) => handleChange('confirm', e)}
                            required className="password-input-blue" style={{backgroundColor: 'transparent'}}/>
              <i onClick={toggleConfirmPasswordVisiblity}
                 className="pass-toggle-icon">{confirmPasswordShown ? eye : eyeSlash}</i>
            </div>
            <Form.Control.Feedback>Password Match!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" style={{display: errors.confirmPassword ? 'block' : ''}}>
              {errors.confirmPassword}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col className="mt-2 text-left">
              <ButtonComponent name={"Submit"} idName={"reset-submit-btn"}/>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({
  resetPassword: state.resetPassword,
})

const mapDispatchToProps = dispatch => ({
  reset_password: (resetPasswordData) => dispatch(resetPasswordActions.resetPassword(resetPasswordData))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
