import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Form } from "react-bootstrap";
import ButtonComponent from "../../../../../components/ButtonComponent";
import ImageCropperComponent from '../../../../../components/ImageCropperComponent'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { postImage } from "../../../../../redux/actions";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { setBannerImageLoading } from "../../../../../redux/actions/setBannerImageAction";

const Banner = ({selectedOption, selectedLogo, setSelectedOption, setSelectedLogo, checkBoxToggled, navigationState, meData, picture, error, loading, postImage, setBannerImageLoading, nextClicked }) => {
  const imgInput5 = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [showImageCropper, setShowImageCropper] = useState(false)
  const [src, setSrc] = useState(null)

  const inputClicked = (input) => {
    input.current.click();
  };

  useEffect(() => {
    if (picture && !loading && !error && picture !== "default" ) {
      navigationState(true);
      setButtonEnabled(false)
    } else if (error && !loading && !picture && !nextClicked ) {
      // error message handled from action
    }
  }, [picture, error, loading]);

  useEffect(() => {
    enableNavigation();
  }, [selectedOption]);

  const enableNavigation = () => {
    navigationState(false)
    if (selectedOption === "custom") {
      if ( picture && picture !== "default") {
        navigationState(true);
      }
    } else if (selectedOption === "jipe") {
      navigationState(true);

    }
  };

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }

  const onFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
      setSrc(reader.result)
      );
      reader.readAsDataURL(event.target.files[0]);
    }
    setShowImageCropper(true)
    setSelectedOption("custom");
  };

  const handleSubmit = () => {
    if(selectedOption === "custom" && buttonEnabled) {
      const formData = new FormData();
      formData.append("file", selectedLogo);
      setBannerImageLoading();
      postImage(formData, showNotification, meData, "banner_picture");
    }
  };

  const changeButton = (enableButton) => {
    setButtonEnabled(enableButton)
    navigationState(false)
  }



  return (
    <>
      <div className="h-25">
        <div className="stepper-title">Banner</div>
        <div className="stepper-info">
          Upload a banner image for your business profile. We suggest a branded
          image, or use the Jipe default banner. Please note you can edit this
          later.
        </div>
      </div>

      <div className="h-75">
        <div className="h-50">
          <div className="banner-header">
            <div className="pb-2">Custom Image (500x1200)</div>
            <div>
              <Form.Check
                className="banner-checkbox"
                id="custom"
                onChange={checkBoxToggled}
                checked={selectedOption === "custom" ? true : false}
              />
            </div>
          </div>

          <div className="d-flex flex-row h-auto align-items-center mt-2">
            <div
              className="custom-image-container"
              onClick={() => inputClicked(imgInput5)}
              style={{
                backgroundImage: `url(${
                  selectedLogo ? URL.createObjectURL(selectedLogo) : picture 
                })`,
              }}
            >
              {!selectedLogo && (!picture || picture === "default") ? <FontAwesomeIcon icon={faImages} /> : <></>}
            </div>
            <input
              accept="image/*"
              type="file"
              onChange={(e) => {onFileChange(e)}}
              onClick={(event)=> { event.target.value = null }}
              hidden={true}
              ref={imgInput5}
            />

            <div>
              <ButtonComponent
                idName="customImageUploadBtn"
                name="UPLOAD"
                disabled={!selectedLogo || loading || !buttonEnabled}
                onClickFunction={handleSubmit}
              />
            </div>
          </div>
        </div>

        <div className="h-50">
          <div className="banner-header">
            <div className="pb-2">Jipe Banner</div>
            <div>
              <Form.Check
                className="banner-checkbox"
                id="jipe"
                onChange={(event) => checkBoxToggled(event)}
                checked={selectedOption === "jipe" ? true : false}
              />
            </div>
          </div>

          <div className="d-flex flex-row h-auto align-items-center mt-2">
            <div className="jipe-image-container"></div>
          </div>
        </div>
      </div>
      <ImageCropperComponent show={showImageCropper} hide={() => setShowImageCropper(false)} type={"banner"} src={src} setButtonEnabled={changeButton} setSelectedImage={setSelectedLogo}/>
    </>
  );
};

const mapStateToProps = (state) => ({
   picture : state.setBanner.bannerImage,
   loading : state.setBanner.loading,
   error : state.setBanner.error
});

const mapDispatchToProps = (dispatch) => ({
  postImage: (data, showNotification, meData, profile_completed_up_to) => dispatch(postImage(data, "banner", showNotification, meData, profile_completed_up_to)),
  setBannerImageLoading : () => dispatch(setBannerImageLoading())
})

export const BannerComponent = connect(mapStateToProps, mapDispatchToProps)(Banner);
