import React from "react";
import './style.css';
import brand from "../../assets/jipe-green-logo.svg";
import FooterComponent from "../FooterComponent";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {onLogout} from "../../redux/actions/login";
import {connect} from "react-redux";
import LocalStorageService from "../../utils/LocalStorageService";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useHistory} from "react-router-dom";


const HeaderLayoutComponent = (props) => {
  const {onLogout} = props;
  const history = useHistory();
  const localStorageService = LocalStorageService;

  const logOut = () => {
    onLogout()
    localStorageService.clearToken();
    history.push("/login");
  };

  return (
    <>
      <div className="header-navbar-container">
        <img
          alt=""
          src={brand}
          height={"40px"}
          width={'200px'}
          className="header-logo"
        />

        <div className="header-logout-icon" onClick={logOut}>
          <OverlayTrigger
            placement={'bottom'}
            overlay={
              <Popover id={"logoutPopover"}>
                <Popover.Content>
                  Logout
                </Popover.Content>
              </Popover>
            }
            defaultShow={false}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className="header-body-container">
        {props.children}
      </div>

      <div className="header-footer-image">
        <FooterComponent/>
      </div>
    </>
  )
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(onLogout())
})

export default connect(null, mapDispatchToProps)(HeaderLayoutComponent);
