import types from '../types';
import * as verifyService from '../../services/api/userVerificationService';


export const setLoadingAction = () => ({
  type: types.emailVerificationConstants.SET_VERIFY_EMAIL_LOADING,
});

const emailVerificationSuccess = verifiedData => ({
  type: types.emailVerificationConstants.VERIFY_EMAIL,
  verifiedData,
});

const emailVerificationFailed = error => ({
  type: types.emailVerificationConstants.SET_VERIFY_EMAIL_FAILED,
  error,
});

export const verifyEmail = (emailData) => dispatch => {
  return verifyService.verifyEmail(emailData)
    .then(
      response => {
        dispatch(emailVerificationSuccess(response.data))
      },
      error => {
        dispatch(emailVerificationFailed(error))
      }
    );
};

