import axiosInstance from "../../services/api/axios";
import types from "../types";
import { meSuccess } from "./me";

const setSuccess = (data) => ({
  type: types.SET_PHOTOS_VIDEOS,
  data,
});

const setPhotoVideoError = (error) => ({
  type: types.SET_PHOTOS_VIDEOS_FAILED,
  error,
});

export const setPhotoVideoLoading = () => ({ 
    type: types.SET_PHOTOS_VIDEOS_LOADING,
  });

const deleteMediaFilter = (mediaId) => ({
  type: types.REMOVE_DELETED_PHOTOS_VIDEOS,
  mediaId
})

export const setPhotosAndVideos = (data, showNotification, meData, profile_completed_up_to = null) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("profile/media/", {
        media: data,
      });
      if (response && response.data) {
        showNotification("Media Files Updated Successfully", "success")
        dispatch(setSuccess(response.data.data.media));
        if(profile_completed_up_to && meData) {
          dispatch(meSuccess({
            ...meData,
            data: {
              ...meData.data,
              media: response?.data?.data?.media,
              profile_completed_up_to : profile_completed_up_to
            }
          }))
        }
      }
    } catch (err) {
      showNotification("Media Files Upload Failed", "error")
      dispatch(setPhotoVideoError(err.message));
    }
  };
};

export const setPreviousPhotosAndVideos = (media) => dispatch => {
  dispatch(setSuccess(media));
}

export const removeDeletedMedia = (mediaId) => (dispatch) => {
  dispatch(deleteMediaFilter(mediaId))
}
