import React, {useEffect, useRef} from "react";
import "./style.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import ImageCropperComponent from '../../../../../components/ImageCropperComponent'

const PhotoVideo = ({
                        navigationState,
                        removeImage,
                        onFileChange,
                        showImageCropper,
                        setCroppedImage,
                        setShowImageCropper,
                        src,
                        photosAndVideos,
                        photoVideo,
                        loading,
                    }) => {
    const imgInput1 = useRef(null);
    const imgInput2 = useRef(null);
    const imgInput3 = useRef(null);
    const imgInput4 = useRef(null);
    const imgInput5 = useRef(null);
    const imgInput6 = useRef(null);

    const inputClicked = (input) => {
        input.current.click();
    };

    useEffect(() => {
        if (photosAndVideos?.media1 || photoVideo.length > 0) {
            navigationState(true);
        } else {
            navigationState(false);
        }
    }, [photosAndVideos, photoVideo]);

    return (
        <>
            <div style={{height: "15%"}}>
                <div className="stepper-title">Photos & videos</div>
                <div className="stepper-info">
                    Add up to 6 photos and/or videos that help display the culture at your
                    business.
                </div>
            </div>

            <div
                style={{height: "85%", marginTop: "10px"}}
                className="d-flex flex-row flex-wrap align-items-center"
            >
                <div
                    className="photo-video-container"
                    style={{
                        backgroundImage: `url(${
                            photosAndVideos.media1
                                ? URL.createObjectURL(photosAndVideos.media1)
                                : photoVideo.length > 0
                                    ? photoVideo[0].url
                                    : ""
                        })`,
                    }}
                >
                    {photosAndVideos.media1 && loading && (
                        <Spinner animation="border" className="spinner"/>
                    )}
                    {!photosAndVideos.media1 && photoVideo.length === 0 ? (
                        <div
                            className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                            onClick={() => inputClicked(imgInput1)}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{color: "#FFF"}}/>
                            <input
                                accept="image/*, video/mp4"
                                type="file"
                                onChange={(e) => onFileChange(e, "media1")}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                hidden={true}
                                ref={imgInput1}
                            />
                        </div>
                    ) : (photosAndVideos.media1 &&
                        photosAndVideos.media1.type === "video/mp4") ||
                    photoVideo[0]?.type === "video" ? (
                        <>
                            <video muted loop controls width="100%" height="100%">
                                <source
                                    src={
                                        photoVideo[0]?.url
                                            ? photoVideo[0].url
                                            : URL.createObjectURL(photosAndVideos.media1)
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        </>
                    ) : (
                        <></>
                    )}
                    {photosAndVideos.media1 || photoVideo.length > 0 ? (
                        <div
                            className="photo-remove-fab"
                            onClick={() => removeImage("media1")}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className="photo-video-container"
                    style={{
                        backgroundImage: `url(${
                            photosAndVideos.media2
                                ? URL.createObjectURL(photosAndVideos.media2)
                                : photoVideo.length > 1
                                    ? photoVideo[1].url
                                    : ""
                        })`,
                    }}
                >
                    {photosAndVideos.media2 && loading && (
                        <Spinner animation="border" className="spinner"/>
                    )}
                    {photosAndVideos.media1 || photoVideo.length === 1 ? (
                        <div
                            className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                            onClick={() => inputClicked(imgInput2)}
                            style={{
                                visibility: photosAndVideos?.media2 ? "hidden" : "unset",
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{color: "#FFF"}}/>
                            <input
                                accept="image/*, video/mp4"
                                type="file"
                                onChange={(e) => onFileChange(e, "media2")}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                hidden={true}
                                ref={imgInput2}
                            />
                        </div>
                    ) : (photosAndVideos.media2 &&
                        photosAndVideos.media2.type === "video/mp4") ||
                    photoVideo[1]?.type === "video" ? (
                        <>
                            <video muted loop controls width="100%" height="100%">
                                <source
                                    src={
                                        photoVideo[1]?.url
                                            ? photoVideo[1].url
                                            : URL.createObjectURL(photosAndVideos.media2)
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        </>
                    ) : (
                        <></>
                    )}

                    {photosAndVideos.media2 || photoVideo.length > 1 ? (
                        <div
                            className="photo-remove-fab"
                            onClick={() => removeImage("media2")}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className="photo-video-container"
                    style={{
                        backgroundImage: `url(${
                            photosAndVideos.media3
                                ? URL.createObjectURL(photosAndVideos.media3)
                                : photoVideo.length > 2
                                    ? photoVideo[2].url
                                    : ""
                        })`,
                    }}
                >
                    {photosAndVideos.media3 && loading && (
                        <Spinner animation="border" className="spinner"/>
                    )}
                    {(!photosAndVideos.media3 && photoVideo.length === 2) ||
                    photosAndVideos.media2 ? (
                        <div
                            className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                            onClick={() => inputClicked(imgInput3)}
                            style={{
                                visibility: photosAndVideos?.media3 ? "hidden" : "unset",
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{color: "#FFF"}}/>
                            <input
                                accept="image/*, video/mp4"
                                type="file"
                                onChange={(e) => onFileChange(e, "media3")}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                hidden={true}
                                ref={imgInput3}
                            />
                        </div>
                    ) : (photosAndVideos.media3 &&
                        photosAndVideos.media3.type === "video/mp4") ||
                    photoVideo[2]?.type === "video" ? (
                        <>
                            <video muted loop controls width="100%" height="100%">
                                <source
                                    src={
                                        photoVideo[2]?.url
                                            ? photoVideo[2].url
                                            : URL.createObjectURL(photosAndVideos.media3)
                                    }
                                    type="video/m4v"
                                />
                            </video>
                        </>
                    ) : (
                        <></>
                    )}
                    {photosAndVideos.media3 || photoVideo.length > 2 ? (
                        <div
                            className="photo-remove-fab"
                            onClick={() => removeImage("media3")}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className="photo-video-container"
                    style={{
                        backgroundImage: `url(${
                            photosAndVideos.media4
                                ? URL.createObjectURL(photosAndVideos.media4)
                                : photoVideo.length > 3
                                    ? photoVideo[3].url
                                    : ""
                        })`,
                    }}
                >
                    {photosAndVideos.media4 && loading && (
                        <Spinner animation="border" className="spinner"/>
                    )}
                    {(!photosAndVideos.media4 && photoVideo.length === 3) ||
                    photosAndVideos.media3 ? (
                        <div
                            className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                            onClick={() => inputClicked(imgInput4)}
                            style={{
                                visibility: photosAndVideos?.media4 ? "hidden" : "unset",
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{color: "#FFF"}}/>
                            <input
                                accept="image/*, video/mp4"
                                type="file"
                                onChange={(e) => onFileChange(e, "media4")}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                hidden={true}
                                ref={imgInput4}
                            />
                        </div>
                    ) : (photosAndVideos.media4 &&
                        photosAndVideos.media4.type === "video/mp4") ||
                    photoVideo[3]?.type === "video" ? (
                        <>
                            <video muted loop controls width="100%" height="100%">
                                <source
                                    src={
                                        photoVideo[3]?.url
                                            ? photoVideo[3].url
                                            : URL.createObjectURL(photosAndVideos.media4)
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        </>
                    ) : (
                        <></>
                    )}
                    {photosAndVideos.media4 || photoVideo.length > 3 ? (
                        <div
                            className="photo-remove-fab"
                            onClick={() => removeImage("media4")}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className="photo-video-container"
                    style={{
                        backgroundImage: `url(${
                            photosAndVideos.media5
                                ? URL.createObjectURL(photosAndVideos.media5)
                                : photoVideo.length > 4
                                    ? photoVideo[4].url
                                    : ""
                        })`,
                    }}
                >
                    {photosAndVideos.media5 && loading && (
                        <Spinner animation="border" className="spinner"/>
                    )}
                    {(!photosAndVideos.media5 && photoVideo.length === 4) ||
                    photosAndVideos.media4 ? (
                        <div
                            className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                            onClick={() => inputClicked(imgInput5)}
                            style={{
                                visibility: photosAndVideos?.media5 ? "hidden" : "unset",
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{color: "#FFF"}}/>
                            <input
                                accept="image/*, video/mp4"
                                type="file"
                                onChange={(e) => onFileChange(e, "media5")}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                hidden={true}
                                ref={imgInput5}
                            />
                        </div>
                    ) : (photosAndVideos.media5 &&
                        photosAndVideos.media5.type === "video/mp4") ||
                    photoVideo[4]?.type === "video" ? (
                        <>
                            <video muted loop controls width="100%" height="100%">
                                <source
                                    src={
                                        photoVideo[4]?.url
                                            ? photoVideo[4].url
                                            : URL.createObjectURL(photosAndVideos.media5)
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        </>
                    ) : (
                        <></>
                    )}
                    {photosAndVideos.media5 || photoVideo.length > 4 ? (
                        <div
                            className="photo-remove-fab"
                            onClick={() => removeImage("media5")}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className="photo-video-container"
                    style={{
                        backgroundImage: `url(${
                            photosAndVideos.media6
                                ? URL.createObjectURL(photosAndVideos.media6)
                                : photoVideo.length > 5
                                    ? photoVideo[5].url
                                    : ""
                        })`,
                    }}
                >
                    {photosAndVideos.media6 && loading && (
                        <Spinner animation="border" className="spinner"/>
                    )}
                    {(!photosAndVideos.media6 && photoVideo.length === 5) ||
                    photosAndVideos.media5 ? (
                        <div
                            className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                            onClick={() => inputClicked(imgInput6)}
                            style={{
                                visibility: photosAndVideos?.media6 ? "hidden" : "unset",
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{color: "#FFF"}}/>
                            <input
                                accept="image/*, video/mp4"
                                type="file"
                                onChange={(e) => onFileChange(e, "media6")}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                hidden={true}
                                ref={imgInput6}
                            />
                        </div>
                    ) : (photosAndVideos.media6 &&
                        photosAndVideos.media6.type === "video/mp4") ||
                    photoVideo[5]?.type === "video" ? (
                        <>
                            <video muted loop controls width="100%" height="100%">
                                <source
                                    src={
                                        photoVideo[5]?.url
                                            ? photoVideo[5].url
                                            : URL.createObjectURL(photosAndVideos.media6)
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        </>
                    ) : (
                        <></>
                    )}
                    {photosAndVideos.media6 || photoVideo.length > 5 ? (
                        <div
                            className="photo-remove-fab"
                            onClick={() => removeImage("media6")}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <ImageCropperComponent show={showImageCropper} hide={() => setShowImageCropper(false)} type={"photoVideo"}
                                   src={src} setSelectedImage={setCroppedImage}/>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export const PhotoVideoComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(PhotoVideo);
