import types from "../types";
import * as locationService from "../../services/api/locationService";
import { getUsers, getLocations } from "./manageUsersAction";

const setLoadingAction = () => ({
  type: types.ADD_LOCATION_SET_LOADING,
});

const addLocationSuccess = (response) => ({
  type: types.ADD_LOCATION,
  response,
});

const addLocationFailed = (error) => ({
  type: types.ADD_LOCATION_FAILED,
  error,
});

const getLocationLoading = () => ({
  type: types.GET_LOCATION_LOADING,
});

const getLocationFailed = (error) => ({
  type: types.GET_LOCATION_FAILED,
  error,
});

const getLocationSuccess = (data) => ({
  type: types.GET_LOCATION_DATA,
  data,
});

const getHiringManagersLoading = () => ({
  type: types.GET_HIRING_MANAGERS_LOADING,
});

const getHiringManagersFailed = (error) => ({
  type: types.GET_HIRING_MANAGERS_FAILED,
  error,
});

const getHirigingManagersSuccess = (data) => ({
  type: types.GET_HIRING_MANAGERS_SUCCESS,
  data,
});

const editLocationLoading = () => ({
  type: types.EDIT_LOCATION_LOADING,
});

const editLocationError = (error) => ({
  type: types.EDIT_LOCATION_ERROR,
  error,
});

const editLocationSuccess = (data) => ({
  type: types.EDIT_LOCATION_SUCCESS,
  data,
});

const deleteLocationLoading = () => ({
  type: types.DELETE_LOCATION_LOADING,
});

const deleteLocationError = (error) => ({
  type: types.DELETE_LOCATION_ERROR,
  error,
});

const deleteLocationSuccess = (data) => ({
  type: types.DELETE_LOCATION_SUCCESS,
  data,
});

export const refreshManageLocationEdit = () => ({
  type: types.REFRESH_MANAGE_LOCATION_EDIT
})

export const refreshManageLocationDelete = () => ({
  type: types.REFRESH_MANAGE_LOCATION_DELETE
})

const selectedLocationSuccess = (data) => ({
  type: types.SET_SELECTED_LOCATION,
  data,
});

const selectedLocationError = (error) => ({
  type: types.SET_SELECTED_LOCATION_FAILED,
  error,
});

const selectedLocationLoading = (error) => ({
  type: types.SET_SELECTED_LOCATION_LOADING,
  error,
});

export const clearSelectedLocationError = () => ({
  type: types.CLEAR_SELECTED_LOCATION_ERROR,

});

export const clearFetchedLocationsError = () => ({
  type: types.CLEAR_FETCHED_LOCATIONS_ERROR
})

export const clearAddLocationError = () => ({
  type: types.CLEAR_ADD_LOCATION_ERROR
})

export const addLocation = (addLocationData) => (dispatch) => {
  dispatch(setLoadingAction());
  return locationService.addLocation(addLocationData).then(
    (response) => {
      dispatch(addLocationSuccess(response.data));
      dispatch(getUsers());
      dispatch(getLocationData());
      dispatch(getLocations())
    },
    (error) => {
      dispatch(addLocationFailed(error));
    }
  );
};

export const getLocationData = () => {
  return async (dispatch) => {
    dispatch(getLocationLoading());
    try {
      const response = await locationService.getLocations();
      if (response && response.data && response.data.data) {
        dispatch(getLocationSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(getLocationFailed(err.message || "Could not get location data"));
    }
  };
};

export const getHiringManagers = () => {
  return async (dispatch) => {
    try {
      dispatch(getHiringManagersLoading());
      const response = await locationService.getHiringManagers();
      if (response && response.status === 200 && response.data.data) {

        // const hiringManagers = response.data.data.filter((obj) => obj.account_status === "active")
        dispatch(getHirigingManagersSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(getHiringManagersFailed(err.message || "Could not fetch Users"));
    }
  };
};

export const editLocation = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(editLocationLoading());
      const response = await locationService.editLocationData(id, data);
      if (response && response.status === 200) {
        dispatch(editLocationSuccess(true));
        dispatch(getLocationData());
        dispatch(getLocations());
        dispatch(getUsers());
        dispatch(getLocationFromLocationId(id))
      }
    } catch (err) {
      dispatch(editLocationError(err.message || "Edit Location Failed"));
    }
  };
};

export const deleteLocation = (id) => {
  //console.log(id)
  return async (dispatch) => {
    try {
      dispatch(deleteLocationLoading());
      const response = await locationService.deleteLocationData(id);
      if (response && response.status === 200) {
        dispatch(deleteLocationSuccess(true));
        dispatch(getLocationData());
        dispatch(getLocations())
        dispatch(getUsers());
      }
    } catch (err) {
      dispatch(deleteLocationError(err.message || "Delete Location Failed"));
    }
  };
};

export const getLocationFromLocationId = (id) => {
  return async (dispatch) => {
    try {
      dispatch(selectedLocationLoading());
      const response = await locationService.getLocationFromId(id);
      if (response && response.status === 200 && response.data?.data) {
        dispatch(selectedLocationSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(selectedLocationError("Fetch Location Failed"));
    }
  };
}

export const getLocationsWithJobs = () => {
  return async (dispatch) => {
    try {
      dispatch(getLocationsWithJobsLoading());
      const response = await locationService.getLocationsWithJobs();
      if (response && response.status === 200 && response.data?.data) {
        dispatch(getLocationsWithJobsSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(getLocationsWithJobsError( "Could not get Location list"));
    }
  };
}

const getLocationsWithJobsLoading = () => ({
  type: types.GET_LOCATIONS_WITH_JOB_LOADING,
})

const getLocationsWithJobsSuccess = (data) => ({
  type: types.GET_LOCATIONS_WITH_JOB_SUCCESS,
  data
})

const getLocationsWithJobsError = (error) => ({
  type: types.GET_LOCATIONS_WITH_JOB_ERROR,
  error
})

export const clearGetLocationWithJobError = () => ({
  type: types.CLEAR_LOCATION_WITH_JOB_ERROR
})

