import React, {useEffect} from "react";
import "./style.css";
import {Form} from "react-bootstrap";

const ResponsibilityListComponent = (props) => {
  const {jobCreationResponsibility, setResponsibility} = props

  const onDataChange = (event) => {
    const {id, value} = event.target;
    if (event) {
      if(!value) {
        setResponsibility((prevData) => {
          const newData = {...prevData}
          delete newData[id]
          return newData
        })
      }else {
        setResponsibility({...jobCreationResponsibility, [id]: {name: value}});
      }
    }
  };

  useEffect(() => {
    if (jobCreationResponsibility) { 
      props.navigationState(true);
    } else {
      props.navigationState(false);
    }
  }, [jobCreationResponsibility]);

  return (
    <>
      <div style={{height: '20%'}}>
        <div className="stepper-title">List responsibilities</div>
        <div className="stepper-info">
          Jipe keeps it simple. List up to 5 responsibilities for this role.
        </div>
      </div>

      <div style={{height: '80%'}}>
        <Form.Group controlId="responsibility1" onChange={onDataChange} style={{height: '60px'}}>
          <Form.Control placeholder="Responsibility 1" value={jobCreationResponsibility?.responsibility1?.name || ""} required className="responsibility-input-green"/>
        </Form.Group>

        <Form.Group controlId="responsibility2" onChange={onDataChange} style={{height: '60px'}}>
          <Form.Control placeholder="Responsibility 2" value={jobCreationResponsibility?.responsibility2?.name || ""} required className="responsibility-input-green"/>
        </Form.Group>

        <Form.Group controlId="responsibility3" onChange={onDataChange} style={{height: '60px'}}>
          <Form.Control placeholder="Responsibility 3" value={jobCreationResponsibility?.responsibility3?.name || ""} required className="responsibility-input-green"/>
        </Form.Group>

        <Form.Group controlId="responsibility4" onChange={onDataChange} style={{height: '60px'}}>
          <Form.Control placeholder="Responsibility 4" value={jobCreationResponsibility?.responsibility4?.name || ""} required className="responsibility-input-green"/>
        </Form.Group>

        <Form.Group controlId="responsibility5" onChange={onDataChange} style={{height: '60px'}}>
          <Form.Control placeholder="Responsibility 5" value={jobCreationResponsibility?.responsibility5?.name || ""} required className="responsibility-input-green"/>
        </Form.Group>
      </div>
    </>
  )
}

export default ResponsibilityListComponent;
