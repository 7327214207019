import React from "react";
import {Card, Image, ListGroup, ListGroupItem} from "react-bootstrap";
import banner from "../../assets/banner.png";
import application from "../../assets/application.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentAlt, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../ButtonComponent";
import "./style.css";
import {generateNameInitialImg} from "../../utils/nameInitial";

const ApplicationCardComponent = (props) => {
  const {data} = props;
  //console.log(data)
  return (
    <Card id="applicationCard">
      <Card.Img variant="top" src={banner} id="bannerCard"/>
      <div className="ac-pp-wrapper">
        <Image
          src={data?.user?.seeker_profile_picture || generateNameInitialImg(data?.user?.seeker_full_name)}
          id="bannerImg"
        />
      </div>
      <div id="bannerEllipse">
        <FontAwesomeIcon color={"white"} icon={faCommentAlt}/>
      </div>

      <Card.Body style={{position: "relative", marginTop: "40px", padding: "0"}}>
        <Card.Title id="applicationTitle">
          {data.user?.seeker_full_name}
        </Card.Title>
        <Card.Subtitle id="applicationSub">
          {data?.user?.about_you}
        </Card.Subtitle>

        <div className="applicant-card-body">
          <ListGroup className="list-group-flush" id="experienceInfo">
            {data.user?.work_history?.length > 0 ? data.user?.work_history.map((workHistory, idx) => (
              <ListGroupItem style={{backgroundColor: "transparent"}} key={idx}>
                <span className="jobPlace"> {workHistory?.name}</span> <br/>
                <span className="jobDuration">
                {workHistory?.position} <br/>
                  {(workHistory?.start_date ? workHistory?.start_date : "") +
                  (workHistory?.end_date ? ` - ` : "") +
                  (workHistory?.end_date ? workHistory?.end_date : "")}
              </span>
              </ListGroupItem>
            )) : <> <FontAwesomeIcon color={"#bfbfbf"} size="3x" className="exclamation" icon={faExclamationTriangle}/>
              <div className="no-work-history">No work history</div>
            </>}
          </ListGroup>

          <Card.Body id="showSpecialities">
            {data?.user?.skills.length > 0 ?
              data.user?.skills.map((skill) => {
                return (
                  <ButtonComponent
                    idName="speciality"
                    name={skill.name}
                  />
                );
              }) : "No skills"}
          </Card.Body>

          <div className="mt-2">
            <div className="applicant-items-heading">Application Questions</div>
            <div>
              {
                data?.user?.responsibilities_answers.map(item =>
                  <div className="d-flex flex-row align-items-center mt-2" style={{height: '40px'}}>
                    <div className="applicant-card-responsibility-lbl">{item?.name}</div>
                    <div className="applicant-card-responsibility-radio">
                      <div className="d-flex flex-row align-items-center">
                        <input type="radio" checked={item?.value === 'yes'} disabled={item?.value === 'no'}/>
                        <span className="ml-2">Yes</span>
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <input type="radio" checked={item?.value === 'no'} disabled={item?.value === 'yes'}/>
                        <span className="ml-2">No</span>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ApplicationCardComponent;
