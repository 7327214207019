import React, {useEffect, useState} from 'react';
import {Card, Form, Image} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import validator from 'validator';
import {useSnackbar} from "notistack";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {passwordValidation} from '../../../utils/utils'
import * as createAccountActions from '../../../redux/actions/createAccount';
import ButtonComponent from '../../../components/ButtonComponent';
import logo from '../../../assets/jipe-forbusiness-wg.svg';
import './style.css';

const eye = <FontAwesomeIcon icon={faEye}/>;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash}/>;

const signUpFormDefault = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    account_type: "business_owner"
}

export function CreateAccountPage(props) {
    const {enqueueSnackbar} = useSnackbar();
    const {create_account, createAccount} = props;
    const [errors, setErrors] = useState({})
    const [termsPoliciesAccepted, setTermsPoliciesAccepted] = useState({terms: false, policies: false});
    const [validated, setValidated] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    // form fields
    const [signUpForm, setSignUpForm] = useState(signUpFormDefault)

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    const handleChange = (event) => {
        const {id, value} = event.target;

        if (Object.keys(errors).length > 0) {
            setErrors((previousState) => {
                const newData = {...previousState}
                delete newData[id]
                return newData
            })
        }

        setSignUpForm({...signUpForm, [id]: value});
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const allErrors = {};
        if (!signUpForm.first_name) {
            allErrors.first_name = "Please enter the first name"
        }
        if (!signUpForm.last_name) {
            allErrors.last_name = "Please enter the last name"
        }
        if (!signUpForm.email) {
            allErrors.email = "Please enter the email"
        }
        if (!signUpForm.password) {
            allErrors.password = "Please enter the password"
        } else {
            if (!passwordValidation(signUpForm.password)) {
                enqueueSnackbar("Please enter a valid password. It must contain at least 8 character, one number, one letter and one special character", {variant: 'error'})
                allErrors.password = "Please enter a valid password"
            }
        }
        if (!signUpForm.confirm_password) {
            allErrors.confirm_password = "Please enter the confirm password"
        }
        if (signUpForm.password && signUpForm.confirm_password) {
            if (signUpForm.password !== signUpForm.confirm_password) {
                allErrors.confirm_password = "Password Don't match."
            }
        }
        if (!validator.isEmail(signUpForm.email)) {
            allErrors.email = "Invalid email address"
        }
        if (!termsPoliciesAccepted.policies || !termsPoliciesAccepted.terms) {
            enqueueSnackbar("Please agree to the terms and policies", {variant: 'error'})
        }
        if (Object.keys(allErrors).length > 0 || !termsPoliciesAccepted.policies || !termsPoliciesAccepted.terms) {
            setErrors(allErrors);
            setValidated(false);
        } else {
            setValidated(true);
            create_account(signUpForm)
        }
    };


    useEffect(() => {
        if (!createAccount.loading) {
            if (createAccount.error) {
                Object.values(createAccount.error.response.data).map((err) => {
                    enqueueSnackbar(err, {variant: "error"});
                })
                setSignUpForm({
                    ...signUpForm,
                    password: "",
                    confirm_password: ""
                })
                setValidated(false)
            } else {
                if (createAccount.signUpData) {
                    enqueueSnackbar(createAccount?.signUpData?.message, {variant: "success"});
                    setSignUpForm(signUpFormDefault)
                    redirectSignup()
                } else {
                    // handle null value in response data
                }
            }
        }
    }, [createAccount])

    const redirectSignup = () => {
        props.history.push({
            pathname: 'confirmation/email',
            state: {email: signUpForm?.email}
        });
    }

    return (
        <>
            <div className="main-container">
                <div className="containerRow">
                    <div className="logoCol">
                        <Image src={logo} alt="user" className='mainLogo'/>
                    </div>

                    <div className="loginRow">
                        <Card className="create-account-card">
                            <div>
                                <Card.Title className="create-acc-title">Create An Account</Card.Title>
                                <Card.Body>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group controlId="email">
                                            <Form.Control placeholder="Email" type='email' value={signUpForm.email}
                                                          isInvalid={errors.email}
                                                          onChange={handleChange} required
                                                          className="ca-input-green"/>
                                            <Form.Control.Feedback>Email looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="first_name">
                                            <Form.Control placeholder="First Name" type='text'
                                                          value={signUpForm.first_name}
                                                          isInvalid={errors.first_name}
                                                          onChange={handleChange}
                                                          required
                                                          className="ca-input-green"/>
                                            <Form.Control.Feedback>Firstname looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.first_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="last_name">
                                            <Form.Control placeholder="Last Name" type='text'
                                                          value={signUpForm.last_name}
                                                          isInvalid={errors.last_name}
                                                          onChange={handleChange} required
                                                          className="ca-input-green"/>
                                            <Form.Control.Feedback>Lastname looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.last_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="password">
                                            <div className="password-input-wrapper">
                                                <Form.Control placeholder="Password"
                                                              type={passwordShown ? "text" : "password"}
                                                              value={signUpForm.password}
                                                              isInvalid={errors.password}
                                                              onChange={handleChange} required
                                                              className="password-input-blue"
                                                />
                                                <i onClick={togglePasswordVisiblity}
                                                   className="pass-toggle-icon">{passwordShown ? eye : eyeSlash}</i>
                                            </div>
                                            <Form.Control.Feedback>Password looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="confirm_password">
                                            <div className="password-input-wrapper">
                                                <Form.Control placeholder="Confirm Password"
                                                              type={confirmPasswordShown ? "text" : "password"}
                                                              value={signUpForm.confirm_password}
                                                              isInvalid={errors.confirm_password}
                                                              onChange={handleChange} required
                                                              className="password-input-blue"/>
                                                <i onClick={toggleConfirmPasswordVisiblity}
                                                   className="pass-toggle-icon">{confirmPasswordShown ? eye : eyeSlash}</i>
                                            </div>
                                            <div style={{
                                                width: '100%',
                                                marginTop: '.25rem',
                                                fontSize: '80%',
                                                color: '#dc3545',
                                                textAlign: 'right'
                                            }}>
                                                {errors.confirm_password}
                                            </div>
                                        </Form.Group>


                                        <div className="terms-and-policy">
                                            <div className="signup-terms-link">
                                                <div style={{width: '30px', height: '100%'}}>
                                                    <Form.Check className="terms-checkbox" id="custom"
                                                                onChange={($event) => setTermsPoliciesAccepted({
                                                                    ...termsPoliciesAccepted,
                                                                    terms: $event.currentTarget.checked
                                                                })}/>
                                                </div>
                                                <div style={{width: 'auto', height: '100%', marginTop: '3px'}}>
                                                    <Link to={{pathname: "https://www.jipejobs.com/terms-of-use"}}
                                                          target="_blank"
                                                          style={{color: '#2c2b7c', textDecoration: 'underline'}}>
                                                        Terms of use
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="signup-terms-link">
                                                <div style={{width: '30px', height: '100%'}}>
                                                    <Form.Check className="terms-checkbox" id="custom"
                                                                onChange={($event) => setTermsPoliciesAccepted({
                                                                    ...termsPoliciesAccepted,
                                                                    policies: $event.currentTarget.checked
                                                                })}/>
                                                </div>
                                                <div style={{width: 'auto', height: '100%', marginTop: '3px'}}>
                                                    <Link to={{pathname: "https://www.jipejobs.com/privacy-policy"}}
                                                          style={{textDecoration: 'underline', color: '#2c2b7c'}}
                                                          target="_blank">
                                                        Privacy policy
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <ButtonComponent idName='ca-btn' name='CONTINUE' type={'submit'}/>
                                    </Form>
                                </Card.Body>
                            </div>
                        </Card>

                        <div className='backgroundCol'>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => (
    {
        createAccount: state.createAccount,
    }
);

const mapDispatchToProps = dispatch => (
    {
        create_account: (signUpForm) => dispatch(createAccountActions.createAccount(signUpForm)),
    }
);


export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPage);
