import types from "../types";
import axiosInstance from "../../services/api/axios";
import {meSuccess} from "./me";

const setLoadingAction = () => ({
    type: types.GET_JOB_CATEGORY_LOADING,
});

const getJobCategory = (data) => ({
    type: types.GET_JOB_CATEGORY,
    data,
});

const jobCategoryFailed = (error) => ({
    type: types.GET_JOB_CATEGORY_FAILED,
    error,
});

export const setJobCategoryLoading = () => ({
    type: types.SET_JOB_CATEGORY_LOADING,
});

const setJobCategory = (data) => ({
    type: types.SET_JOB_CATEGORY,
    data,
});

const setJobCategoryFailed = (error) => ({
    type: types.SET_JOB_CATEGORY_FAILED,
    error,
});

export const getJobCategories = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingAction());
            const response = await axiosInstance.get("/profile/industries/");
            if (response && response.status === 200) {
                dispatch(getJobCategory(response.data.data.industries));
            }
        } catch (err) {
            dispatch(jobCategoryFailed(err.message));
        }
    };
};

export const setJobCategories = (data, meData, showNotification, profile_completed_up_to = null) => {
    let dataArray = [];
    for (const [key, value] of Object.entries(data)) {
        dataArray.push({name: value});
    }
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post("/profile/industries/", {
                industries: dataArray,
            });
            if (response && response.status === 200) {
                dispatch(setJobCategory(response.data.message));
                if (meData) {
                    const industriesData = []
                    if (data.length > 0) {
                        data.map((industry) => {
                            industriesData.push({name: industry})
                        })
                        dispatch(meSuccess({
                            ...meData, data: {
                                ...meData.data, industries: industriesData
                            }
                        }))
                        if (profile_completed_up_to) {
                            dispatch(meSuccess({
                                ...meData,
                                data: {
                                    ...meData.data,
                                    industries: industriesData,
                                    profile_completed_up_to: profile_completed_up_to
                                }
                            }))
                        }
                    }
                }
                if (showNotification) {
                    showNotification("Job Category updated successfully", "success")
                }
            }
        } catch (err) {
            dispatch(setJobCategoryFailed(err.message));
            if (showNotification) {
                showNotification("Job Category update Failed", "error")
            }
        }
    };
};
