import React, {useEffect} from "react";
import "./style.css";
import {connect} from "react-redux";
import {getAllPricingPlans} from "../../../../redux/actions/billingAction";
import {Spinner} from "react-bootstrap";
import * as meActions from "../../../../redux/actions/me";
import {useSnackbar} from "notistack";
import {postPromoCode, resetPromoCode} from "../../../../redux/actions/subscriptionAction";

const PricingPlanComponent = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const {getPricingPlans, pricingPlans, handlePlans, selectedPlan, postPromoCode, subscriptionState} = props;

    useEffect(() => {
        if (!pricingPlans?.data) {
            getPricingPlans();
        }
    }, []);

    useEffect(() => {
        console.log(selectedPlan)
        if (selectedPlan && selectedPlan.planId) {
            props.navigationState(true)
        }
    }, [selectedPlan])

    const handleChange = (planId) => {
        handlePlans(planId)
    }

    return (
        <div className={"d-flex flex-column justify-content-between h-100"}>
            <div>
                <div className="stepper-title">Choose a pricing plan</div>
                <div className="pricing-stepper-info mt-2">
                    <div className="pricing-stepper-offer-tag">Free listings! Limited time only!</div>
                    <div>1 active listing = 1 credit</div>
                    <div>Active listings auto-renew after 30 days</div>
                    <div>Easy pause/unpause listings</div>
                    <div>Unused job listing credits on volume plans do not roll over</div>
                    <div>If your business needs more than 25 credits per month,
                        <a style={{color: "#EE6C4D"}}
                           target="_blank"
                           href="https://www.jipejobs.com/contact"> REQUEST
                            ENTERPRISE PRICING</a>
                    </div>
                </div>
            </div>

            {pricingPlans.loading ? (
                <div className="spinner-wrapper">
                    <Spinner animation="border" className="spinner"/>
                </div>
            ) : pricingPlans.data ?
                (
                    <div className="d-flex flex-col mt-auto" style={{width: "100%"}}>
                        {pricingPlans?.data?.map((plan, index) => {
                            return (
                                <div className={"stepper-plan-card"} style={{borderRight: '1px solid #2C2B7C'}}
                                     key={index}>
                                    <div className="stepper-plan-header">{plan?.no_of_items} credit / MO</div>
                                    <div className="stepper-plan-sub-header">
                                        <span>{plan?.short_description}</span>
                                    </div>

                                    <div className="mt-3">
                                            <span className="plan-price">
                                            ${plan?.amount}
                                            </span>
                                        <span className="stepper-plan-per-list-lbl">/ mo</span>
                                    </div>

                                    <div className="mt-2" style={{height: '25px'}}>
                                        <span className="stepper-plan-per-list-lbl">{plan?.save_description}</span>
                                    </div>

                                    <button
                                        className={"buy-plan-btn mt-3" + (selectedPlan?.planId === plan?.id ? " selected-plan" : "")}
                                        onClick={() => handleChange(plan?.id)}>
                                        <span style={{color: '#A3CF62'}}>${plan?.net_total}</span>
                                        <span> - Buy Now</span>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                ) :
                (
                    <div className="no-pricing-plans">No Pricing plans</div>
                )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    pricingPlans: state.setBillingInfo.pricingPlans,
    subscriptionState: state.subscription
});

const mapDispatchToProps = (dispatch) => ({
    getMe: () => dispatch(meActions.me()),
    postPromoCode: (code) => dispatch(postPromoCode(code)),
    getPricingPlans: () => dispatch(getAllPricingPlans()),
    resetSubscriptionState: () => dispatch(resetPromoCode())
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingPlanComponent);
