import React, {useEffect, useState} from 'react';
import {Card, Form, Image} from "react-bootstrap";
import validator from 'validator';
import {useSnackbar} from "notistack";
import {connect} from 'react-redux';
import {useHistory} from "react-router-dom";
import * as forgotPasswordActions from '../../../redux/actions/forgotPassword';
import logo from "../../../assets/jipe-forbusiness-wg.svg";
import ButtonComponent from "../../../components/ButtonComponent";
import "./style.css";


const ForgotPasswordPage = (props) => {
    const {forgotPassword, forgot_password, reset_forgot_password} = props
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory()
    const [forgotPasswordForm, setforgotPasswordForm] = useState({
        type: "forget_password",
        data: {
            email: ""
        },
    });
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({})
    const goBack = () => history.push("/login");

    useEffect(() => {
        //console.log(forgotPassword)
        if (!forgotPassword.loading) {
            if (forgotPassword.error) {
                const errorMessage = forgotPassword.error.response.data ? Object.values(forgotPassword.error.response.data) : 'Something went wrong!'
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
            } else {
                if (forgotPassword.forgotPasswordResponse) {
                    enqueueSnackbar(forgotPassword.forgotPasswordResponse.message ? forgotPassword.forgotPasswordResponse.message : "Success", {variant: "success"});
                    reset_forgot_password();
                    history.push({
                        pathname: "/confirmation/reset", state: {
                            "email": forgotPasswordForm.data.email
                        }
                    });
                }
            }
        }
    }, [forgotPassword]);

    const handleChange = (e) => {
        if (Object.keys(errors).length > 0) {
            setErrors({})
        }
        setforgotPasswordForm({...forgotPasswordForm, data: {email: e.target.value}});
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const allErrors = {};
        if (!validator.isEmail(forgotPasswordForm.data.email)) {
            allErrors.email = "Invalid email address"
        }

        if (Object.keys(allErrors).length > 0) {
            setErrors(allErrors);
            setValidated(false);
        } else {
            setValidated(true);
            forgot_password(forgotPasswordForm)
        }
    };

    return (
        <div className="main-container">
            <div className="containerRow">
                <div className="logoCol">
                    <Image src={logo} alt="user" className='mainLogo'/>
                </div>

                <div className="loginRow">
                    <Card className="forgot-password-card">
                        <div style={{width: "100%"}}>
                            <Card.Title className="fp-title">Forgot Password?</Card.Title>

                            <div className="reset-email-label">Submit your email below and we'll email you a reset
                                link.
                            </div>

                            <Card.Body>
                                <Form noValidate
                                      validated={validated}
                                      onSubmit={(event) => handleSubmit(event)}
                                      id="forgotPasswordForm"
                                >
                                    <Form.Group controlId="formBasicFirst"
                                                className="d-flex flex-column justify-content-center align-items-center w-100">
                                        <Form.Control placeholder="Email" value={forgotPasswordForm.data.email}
                                                      isInvalid={errors.email}
                                                      onChange={(e) => handleChange(e)} required
                                                      className="fp-input-green"/>
                                        <Form.Control.Feedback>Email looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <ButtonComponent idName="resetPassBtn" name='Submit'/>

                                </Form>
                            </Card.Body>
                        </div>

                        <ButtonComponent type="link" idName="back-btn" name='Back' onClickFunction={goBack}/>
                    </Card>

                    <div className='backgroundCol'>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    forgotPassword: state.forgotPassword
});

const mapDispatchToProps = dispatch => ({
    forgot_password: (forgotPasswordData) => dispatch(forgotPasswordActions.forgotPassword(forgotPasswordData)),
    reset_forgot_password: () => dispatch(forgotPasswordActions.resetForgotPassword())
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
