import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: "AIzaSyAJVW4yC7uD0YPfZ5wCsDEYPhjrgSmnllc",
  authDomain: "jipe-stage.firebaseapp.com",
  databaseURL: "https://jipe-stage.firebaseio.com",
  projectId: "jipe-stage",
  storageBucket: "jipe-stage.appspot.com",
  messagingSenderId: "946135270020",
  appId: "1:946135270020:web:6e73f313aaef8872bf38d1",
};

firebase.initializeApp(config);

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then((firebaseToken) => {
          resolve(firebaseToken);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });
