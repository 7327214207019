import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {connect} from "react-redux";
import ButtonComponent from "../../../../components/ButtonComponent";
import {setApplicationType} from "../../../../redux/actions/applicationTypeAction";

const ApplicationType = (props) => {
  const {meData, applicationTypeAction, applicationTypeState} = props;
  const [selectedAppType, setSelectedAppType] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (meData) {
      setSelectedAppType([meData?.data?.application_type])
      setButtonEnabled(false)
    }
  }, [meData])


  useEffect(() => {
    if (!applicationTypeState.loading) {
      setBtnLoading(false);
    } else {
      setBtnLoading(true);
    }
  }, [applicationTypeState])

  const applicationTypeSelected = (event) => {
    if (buttonEnabled && meData?.data?.application_type === event.target.id) {
      setButtonEnabled(false);
    } else {
      setButtonEnabled(true);
    }
    setSelectedAppType([event.target.id]);
  };

  const refresh = () => {
    setSelectedAppType([meData?.data?.application_type]);
    setButtonEnabled(false);
  }

  const handleSubmit = () => {
    if (buttonEnabled) {
      setBtnLoading(true);
      applicationTypeAction(selectedAppType, meData, showNotification);
    }
  }

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }

  return (
    <div id="profileCard">
      <Row>
        <Col>
          <div className="profile-card-header">Application type</div>

          <Form.Check
            className="industry-checkbox"
            id="jipe_application"
            label="Jipe Solution"
            onChange={(event) => applicationTypeSelected(event)}
            checked={selectedAppType[0] === "jipe_application" ? true : false}
          />

          <Form.Check
            className="industry-checkbox"
            id="online_application"
            label="Your existing online solution"
            onChange={(event) => applicationTypeSelected(event)}
            checked={selectedAppType[0] === "online_application" ? true : false}
          />
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-end mt-3">
        <ButtonComponent idName="saveBtn" name="Save" loading={btnLoading} disabled={!buttonEnabled || btnLoading}
                         onClickFunction={() => handleSubmit()}/>
        {buttonEnabled && <ButtonComponent className={"profile-cancel-btn"} name="Cancel"
                                           type={"outline"}
                                           disabled={!buttonEnabled || btnLoading} onClickFunction={() => refresh()}/>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  meData: state.me.meResponse,
  applicationTypeState: state.applicationTypeData.applicationType
});
const mapDispatchToProps = (dispatch) => ({
  applicationTypeAction: (data, meData, showNotification) => dispatch(setApplicationType(data, meData, showNotification))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationType);
