import types from "../types";

const defaultState = {
  cancelSubscription: {
    data: "",
    loading: false,
    error: "",
  },
};

const cancelSubscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.cancelSubscriptionConstants.CANCEL_SUBSCRIPTION_LOADING:
      return {
        ...state,
        cancelSubscription: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.cancelSubscriptionConstants.CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        cancelSubscription: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.cancelSubscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscription: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.cancelSubscriptionConstants.RESET_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscription: {
          error: "",
          data: "",
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default cancelSubscriptionReducer;
