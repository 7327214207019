import axiosInstance from "../../services/api/axios";
import types from "../types";
import { meSuccess } from "./me";

const setImageSuccess = (imageData) => ({
  type: types.uploadImageConstants.SET_PROFILE_IMAGE,
  imageData,
});

const setImageFailed = (error) => ({
  type: types.uploadImageConstants.SET_PROFILE_IMAGE_FAILED,
  error,
});

export const setProfilePictureLoading = () => ({ 
    type: types.uploadImageConstants.SET_PROFILE_IMAGE_LOADING,
  });

export const setProfilePicture = (data, showNotification, meData, profile_completed_up_to = null) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("business/business-logo/", {
        business_logo: data,
      });
      if (response && response.data) {
        if(showNotification) {
          showNotification("Logo uploaded successfully", "success");
        }
        dispatch(setImageSuccess(response.data?.data?.business_logo_url));
        if(meData) {
          dispatch(meSuccess({...meData,data: {...meData.data, profile_picture_url: response.data?.data?.business_logo_url}}))
          if(profile_completed_up_to) {
            dispatch(meSuccess({
              ...meData,
              data: {
                ...meData.data, 
                business_logo_url: response?.data?.data?.business_logo_url,
                profile_completed_up_to : profile_completed_up_to
              }
            }))
          }
        }
      }
    } catch (err) {
      if(showNotification) {
        showNotification("Logo upload failed", "error")
      }
      dispatch(setImageFailed(err.message));
    }
  };
};

export const setPreviousProfilePicture = (profile_picture_url) => dispatch =>{
  dispatch(setImageSuccess(profile_picture_url))
}
