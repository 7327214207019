import LocalStorageService from '../utils/LocalStorageService';

// LocalstorageService
const localStorageService = LocalStorageService.getService();

class Auth {
  isAuthenticated() {
    try {
      const token = localStorageService.getAccessToken();
      const accountType = localStorageService.getAccountType()

      if(token && (accountType === "business_owner" || accountType === "hiring_manager")) {
        return accountType
      } else {
        return null
      }
    } catch(err) {
      return null
    }
  }
}
export default new Auth();
