import types from '../types';

const defaultState = {
  verified: "",
  loading: false,
  error: "",
};

const setVerificationData = (state = defaultState, action) => {
  switch (action.type) {
    case types.emailVerificationConstants.SET_VERIFY_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.emailVerificationConstants.SET_VERIFY_EMAIL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.emailVerificationConstants.VERIFY_EMAIL:
      return {
        ...state,
        verified: action.verifiedData,
        loading: false,
      };
    default:
      return state;
  }
};
export default setVerificationData;
