export const ADD_LOCATION_SET_LOADING = Symbol("ADD_LOCATION_SET_LOADING");
export const ADD_LOCATION = Symbol("ADD_LOCATION");
export const ADD_LOCATION_FAILED = Symbol("ADD_LOCATION_FAILED");
export const ADD_LOCATION_RESET = Symbol("ADD_LOCATION_RESET");
export const CLEAR_ADD_LOCATION_ERROR = "CLEAR_ADD_LOCATION_ERROR";

export const GET_LOCATION_DATA = "GET_LOCATION_DATA";
export const GET_LOCATION_LOADING = "GET_LOCATION_LOADING";
export const GET_LOCATION_FAILED = "GET_LOCATION_FAILED";
export const CLEAR_FETCHED_LOCATIONS_ERROR = "CLEAR_FETCHED_LOCATIONS_ERROR";

export const GET_HIRING_MANAGERS_FAILED = "GET_HIRING_MANAGERS_FAILED";
export const GET_HIRING_MANAGERS_LOADING = "GET_HIRING_MANAGERS_LOADING";
export const GET_HIRING_MANAGERS_SUCCESS = "GET_HIRING_MANAGERS_SUCCESS";

export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_ERROR = "EDIT_LOCATION_ERROR";
export const EDIT_LOCATION_LOADING = "EDIT_LOCATION_LOADING";
export const REFRESH_MANAGE_LOCATION_EDIT = "REFRESH_MANAGE_LOCATION_EDIT";

export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR";
export const DELETE_LOCATION_LOADING = "DELETE_LOCATION_LOADING";
export const REFRESH_MANAGE_LOCATION_DELETE = "REFRESH_MANAGE_LOCATION_DELETE";

export const SET_SELECTED_LOCATION_LOADING = "SET_SELECTED_LOCATION_LOADING";
export const SET_SELECTED_LOCATION_FAILED = "SET_SELECTED_LOCATION_FAILED";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const CLEAR_SELECTED_LOCATION_ERROR ="CLEAR_SELECTED_LOCATION_ERROR";

// to change the updated job data locally
export const CHANGE_UPDATED_JOB_DATA = "CHANGE_UPDATED_JOB_DATA"

export const GET_LOCATIONS_WITH_JOB_LOADING = "GET_LOCATIONS_WITH_JOB_LOADING";
export const GET_LOCATIONS_WITH_JOB_ERROR = "GET_LOCATIONS_WITH_JOB_ERROR";
export const GET_LOCATIONS_WITH_JOB_SUCCESS = "GET_LOCATIONS_WITH_JOB_SUCCESS";
export const CLEAR_LOCATION_WITH_JOB_ERROR = "CLEAR_LOCATION_WITH_JOB_ERROR";
export const CLEAR_GET_ALL_APPLICANTS_ERROR = "CLEAR_GET_ALL_APPLICANTS_ERROR";

export const UPDATE_APPLICANT_STATUS_LOADING = "UPDATE_APPLICANT_STATUS_LOADING";
export const UPDATE_APPLICANT_STATUS_ERROR = "UPDATE_APPLICANT_STATUS_ERROR";
export const UPDATE_APPLICANT_STATUS_SUCCESS = "UPDATE_APPLICANT_STATUS_SUCCESS";


export const HIDE_APPLICANT_LOADING = "HIDE_APPLICANT_LOADING";
export const HIDE_APPLICANT_ERROR = "HIDE_APPLICANT_ERROR";
export const HIDE_APPLICANT_SUCCESS = "HIDE_APPLICANT_SUCCESS";
