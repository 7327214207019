import Modal from "react-bootstrap/Modal";
import {Container} from "react-bootstrap";
import React, {useState} from "react";
import {useSnackbar} from "notistack";
import "./style.css";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CompanyIcon from "../../assets/website.png";
import FbIcon from "../../assets/facebookBtn.png";
import TwitterIcon from "../../assets/twitterBtn.png";
import InstagramIcon from "../../assets/instagramBtn.png";
import pp from "../../assets/profile-pic.png";
import defaultBanner from "../../assets/banner.png";
import validator from "validator";
import {convertTime} from "../../utils/availabilityUtils";

const PreviewProfileModal = (props) => {
    const {meData, jobCreationData} = props;
    const {enqueueSnackbar} = useSnackbar();
    console.log(meData)
    const [showHoursNeeded, setShowHoursNeeded] = useState(false);

    const getUrl = (handle, handleAccountType) => {
        if (handle) {
            let handleUrl = validator.isURL(handle)
                ? handle.includes("://")
                    ? handle.split("://")[0].includes("https")
                        ? handle
                        : handle.split("://")[0].includes("http")
                            ? `https://${handle.split("://")[1]}`
                            : `https://${handle.split("://")[1]}`
                    : `https://${handle}`
                : `https://www.${handleAccountType}.com/${handle}`;
            return handleUrl;
        } else {
            return null;
        }
    };

    const openInMap = () => {
        if (jobCreationData?.address) {
            if (jobCreationData?.address[0]?.lat && jobCreationData?.address[0]?.lng) {
                window.open("https://maps.google.com?q=" + Number(jobCreationData?.address[0]?.lat) + "," + Number(jobCreationData?.address[0]?.lng));
            } else {
                enqueueSnackbar("Coordinates not found !!!", {
                    variant: "error",
                });
            }
        } else {
            if (meData.business_locations.length > 0 && meData.business_locations[0]?.lat && meData.business_locations[0]?.lng) {
                window.open("https://maps.google.com?q=" + Number(meData.business_locations[0]?.lat) + "," + Number(meData.business_locations[0]?.lng));
            } else {
                enqueueSnackbar("Coordinates not found !!!", {
                    variant: "error",
                });
            }
        }
    }

    const handleClick = () => {
        setShowHoursNeeded(true);
    };

    const handleClose = () => {
        // props.hide();
        setShowHoursNeeded(false);
    };

    const days = [
        {day: "Monday", value: 1},
        {day: "Tuesday", value: 2},
        {day: "Wednesday", value: 3},
        {day: "Thursday", value: 4},
        {day: "Friday", value: 5},
        {day: "Saturday", value: 6},
        {day: "Sunday", value: 7},
    ];

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.hide}
                dialogClassName="profile-preview-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div
                    className="preview-banner"
                    style={{
                        backgroundImage: `url(${
                            meData?.banner_picture_url || defaultBanner
                        }`,
                    }}
                >
                    <div className="modal-close-fab" onClick={props.hide}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>

                    <div
                        className="pre-profile-picture"
                        style={{
                            backgroundImage: `url(${meData?.business_logo_url || pp}`,
                        }}
                    />
                </div>

                <Container className="modal-body-container">
                    {jobCreationData ?
                        <div className="pre-job-title py-2">
                            {jobCreationData?.title || ""}
                        </div>
                        :
                        <></>
                    }

                    <div className="pre-business-owner py-2">
                       <span className="pre-business-light">
                                      {meData?.basic_info?.business_name || ""}
                        </span>
                    </div>

                    {jobCreationData ?
                        <div
                            className="pre-business-cards-wrapper d-flex flex-row justify-content-between align-items-center">
                            <div className="pre-business-info-card hoverable" style={{backgroundColor: "#7FB7BE"}}
                                 onClick={() => openInMap()}>

                                {
                                    jobCreationData?.address ?
                                        <span id="pre-business-bold">
                    {jobCreationData?.address[0]?.street}
                                            {jobCreationData?.address[0]?.street_alt
                                                ? ", " + jobCreationData?.address[0]?.street_alt
                                                : ""}
                  </span>
                                        :
                                        <span id="pre-business-bold">
                     {meData?.business_locations[0]?.street}
                                            {meData?.business_locations[0]?.street_alt
                                                ? ", " + meData?.business_locations[0]?.street_alt
                                                : ""}
                  </span>
                                }
                            </div>

                            <div
                                className="pre-business-info-card pre-business-light hoverable"
                                style={{
                                    backgroundColor: "#EE6C4D",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                    fontSize: 16,
                                }}
                                onClick={() => handleClick()}
                            >
                                View Time
                            </div>

                            <div
                                className="pre-business-info-card"
                                id="pre-business-salary"
                                style={{backgroundColor: "#A3CF62"}}
                            >
                                <span>${jobCreationData?.hourly_rate || ""}</span>
                                <span>hr</span>
                            </div>
                        </div>
                        :
                        <></>
                    }

                    {
                        jobCreationData &&
                        <div className="pre-responsibility-card">
                            {jobCreationData?.responsibilities &&
                                jobCreationData?.responsibilities.map((data, index) => (
                                    <div className="pre-responsibility-listing" key={index}>
                                        <div className="responsibility-item">
                                            {data?.name}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    }

                    <div className="pre-about-us">
                        <div id="pre-aboutus-title">About Us</div>
                        <div id="pre-aboutus-info">{meData?.about_you}</div>
                    </div>

                    <div id="pre-social-url-wrapper">
                        {meData?.social_urls?.instagram_url ? (
                            <a rel={"external"} target="_blank">
                                <img
                                    src={InstagramIcon}
                                    alt="Instagram"
                                    height="40px"
                                    width="40px"
                                    onClick={() =>
                                        window.open(
                                            getUrl(meData?.social_urls?.instagram_url, "instagram"),
                                            "_blank"
                                        )
                                    }
                                />
                            </a>
                        ) : (
                            <></>
                        )}
                        {meData?.social_urls?.facebook_url ? (
                            <a rel={"external"} target="_blank">
                                <img
                                    src={FbIcon}
                                    alt="Facebook"
                                    height="40px"
                                    width="40px"
                                    onClick={() =>
                                        window.open(
                                            getUrl(meData?.social_urls?.facebook_url, "facebook"),
                                            "_blank"
                                        )
                                    }
                                />
                            </a>
                        ) : (
                            <></>
                        )}
                        {meData?.social_urls?.twitter_url ? (
                            <a rel={"external"} target="_blank">
                                <img
                                    src={TwitterIcon}
                                    alt="Twitter"
                                    height="40px"
                                    width="40px"
                                    onClick={() =>
                                        window.open(
                                            getUrl(meData?.social_urls?.twitter_url, "twitter"),
                                            "_blank"
                                        )
                                    }
                                />
                            </a>
                        ) : (
                            <></>
                        )}
                        {meData?.social_urls?.company_url ? (
                            <a rel={"external"} target="_blank">
                                <img
                                    src={CompanyIcon}
                                    alt="Company Website"
                                    height="40px"
                                    width="40px"
                                    onClick={() =>
                                        window.open(meData?.social_urls?.company_url, "_blank")
                                    }
                                />
                            </a>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div id="pre-photo-video-wrapper">
                        {meData?.media &&
                            meData?.media.map((data, index) => (
                                data.type === "video" ? <div
                                        className="pre-photo-div"
                                        key={index}
                                    >
                                        <video muted loop controls height="100%" width="100%">
                                            <source
                                                src={data.url}
                                                type="video/mp4"
                                            />
                                        </video>
                                    </div> :
                                    <div
                                        className="pre-photo-div"
                                        key={index}
                                        style={{backgroundImage: `url(${data.url})`}}
                                    >

                                    </div>
                            ))}
                    </div>
                </Container>
            </Modal>

            {showHoursNeeded && (
                <Modal
                    show={showHoursNeeded}
                    backdropClassName={"time-preview-backdrop"}
                    dialogClassName="time-preview-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop={'static'} keyboard={true}
                >
                    <div>
                        <div className="modal-close-fab" onClick={() => handleClose()}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>

                        <div id="time-modal-title">
                            HOURS NEEDED
                        </div>

                        <div style={{display: "flex", justifyContent: "center"}}>
                            {jobCreationData.availabilities?.types?.length > 0
                                ? jobCreationData.availabilities.types.map((types, id) => {
                                    return (
                                        <div id="time-modal-availability-lbl"
                                             style={{
                                                 display: "flex",
                                                 justifyContent: "center",
                                                 marginTop: 20,
                                             }}
                                             key={id}
                                        >
                                            {` ${types.type} `}
                                            {jobCreationData.availabilities.types.length - 1 >
                                            id ? (
                                                <div>/</div>
                                            ) : null}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <Container className="hours-modal-body-container">
                        {jobCreationData?.availabilities?.time_sheets.map((data, index) => {
                            return (
                                <div
                                    style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}
                                    key={index}
                                >
                                    <div
                                        className="time-modal-days">{days.find(item => item.value === data.day).day}</div>
                                    <div className="time-modal-times">
                                        {data.time_slots.length > 0 ?
                                            (
                                                data.time_slots.map((time, idx) =>
                                                    <div className={"d-flex flex-col px-1"} key={idx}>
                                                        <div>{convertTime(time.start)}</div>
                                                        <div>-</div>
                                                        <div>{convertTime(time.end)}</div>
                                                    </div>
                                                ))
                                            : data.may_vary ?
                                                <span>Times may vary </span>
                                                :
                                                <div className="text-center"> - - - </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }
                    </Container>
                </Modal>
            )}
        </>
    );
};

export default PreviewProfileModal;
