import React from "react";
import {Badge, Card, Col, Row} from "react-bootstrap";
import "./style.css";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {deleteLocation} from "../../redux/actions";
import {faEllipsisV, faPen, faPlus, faStop,} from "@fortawesome/free-solid-svg-icons";

const LocationCardComponent = (props) => {
  const {data, onEdit, deleteLocationData, activateDeactivate, accountType} = props;
  // const handleDelete = (data) => {
  //   const locationId = data.location.id;
  //   deleteLocationData(locationId);
  // };

  return (
    <Card className="location-card" style={props.style}>
      <Card.Body>
        <div className="d-flex flex-row justify-content-between">
          <Card.Title style={{fontFamily: "Poppins Medium, sans-serif"}}>
            {data?.location?.name}
          </Card.Title>

          <Dropdown onClick={(e) => e.stopPropagation()}>
            <Dropdown.Toggle className="manage-location-ellipsis">
              <FontAwesomeIcon icon={faEllipsisV} style={{color: "white"}}/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="location-dropdown-menu">

              {(accountType && accountType === "business_owner") &&
              <Dropdown.Item className="loc-dropdown-item" onClick={() => onEdit(data, "invite")}
                             visible={(accountType && (accountType === 'business_owner').toString())}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{color: "#2C2B7C"}}
                  className="mx-3"
                />
                <span>Invite User to Location</span>
              </Dropdown.Item>
              }

              <Dropdown.Item className="loc-dropdown-item"
                             onClick={() => props.handleModal("addJob", data?.location?.id)}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{color: "#2C2B7C"}}
                  className="mx-3"
                />
                <span>Add Job Listing</span>
              </Dropdown.Item>

              {(accountType && accountType === "business_owner") &&
              <Dropdown.Item
                className="loc-dropdown-item"
                onClick={() => onEdit(data)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  style={{color: "#2C2B7C"}}
                  className="mx-3"
                />
                <span>Edit Location</span>
              </Dropdown.Item>
              }

              {/*{(accountType && accountType === "business_owner") &&*/}
              {/*<Dropdown.Item*/}
              {/*  className="loc-dropdown-item"*/}
              {/*  style={{color: data.location?.is_active ? "#EE6C4D" : "#00000"}}*/}
              {/*  onClick={() => activateDeactivate(data?.location?.id, data.location?.is_active)}*/}
              {/*>*/}
              {/*  <FontAwesomeIcon*/}
              {/*    icon={faStop}*/}
              {/*    style={{color: data.location?.is_active ? "#EE6C4D" : "#2C2B7C"}}*/}
              {/*    className="mx-3"*/}
              {/*  />*/}
              {/*  {data.location?.is_active ? <span>Deactivate Location</span> : <span>Activate Location</span>}*/}
              {/*</Dropdown.Item>*/}
              {/*}*/}

              {/* <Dropdown.Item
                  className="loc-dropdown-item"
                  style={{ color: "#EE6C4D" }}
                  onClick={() => handleDelete(data)}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "#EE6C4D" }}
                    className="mx-3"
                  />
                  <span>Delete Location</span>
                </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <hr style={{borderColor: "#A3CF62"}}/>

        <Row>
          <Col lg={3} md={6} className="d-flex flex-column">
            <span className="address-title">Address</span>
            <span className="address-value">
              {data?.location?.street}{" "}
              {data?.location?.street_alt
                ? "," + data?.location?.street_alt
                : ""}
            </span>
          </Col>

          <Col lg={3} md={6} className="d-flex flex-column">
            <span className="address-title">Assigned Users</span>
            <span className="address-value">
              {data?.hiring_managers?.length > 0 ?
                data?.hiring_managers?.map((manager, index) => {
                  return data?.hiring_managers?.length - 1 === index
                    ? ` ${manager?.first_name} ${manager?.last_name}`
                    : `${manager?.first_name} ${manager?.last_name} / `;
                }) : data?.business_owners?.map((owner, index) => {
                  return data?.business_owners?.length - 1 === index
                    ? ` ${owner?.first_name} ${owner?.last_name}`
                    : `${owner?.first_name} ${owner?.last_name} / `;
                })}
            </span>
          </Col>

          <Col className="d-flex flex-column" lg={2} md={6}>
            <span className="address-title">Active Job Listings</span>
            <span className="address-value">
              {data?.location?.active_listings}
            </span>
          </Col>

          <Col className="d-flex flex-column" lg={2} md={6}>
            <span className="address-title">Applications</span>
            <span className="address-value">
              {data?.location?.total_applications}
            </span>
          </Col>

          <Col className="d-flex flex-column justify-content-center align-items-center" lg={2} md={6}>
            {data.location?.is_active ? (
              <Badge
                variant="success"
                style={{backgroundColor: "#A3CF62", width: "60px"}}
              >
                Active
              </Badge>
            ) : (
              <Badge
                variant="danger"
                style={{backgroundColor: "#EE6C4D", width: "60px"}}
              >
                Inactive
              </Badge>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  deleteLocationData: (id) => dispatch(deleteLocation(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationCardComponent);
