import axiosInstance from './axios';

export const login = async (loginForm) =>
  axiosInstance.post("auth/login/", loginForm, {
      headers: {
          'devicetype': 'web',
          'Content-Type': 'application/json',
      },
      withCredentials: false,
  })

export const createAccount = async (signupForm) =>
  axiosInstance.post("auth/register/", signupForm)

export const forgotPassword = async (forgotPasswordData) =>
  axiosInstance.post("auth/send-verification-code/", forgotPasswordData)

export const changePassword = async (changePasswordData) =>
  axiosInstance.post("auth/password/change/", changePasswordData)

export const getUserDetail = async (data) =>
  axiosInstance.post("users/user-details-from-uuid-and-token/", data);

export const resendVerification = async (data) =>
  axiosInstance.post("/auth/send-verification-code/", data);

export const me = async () =>
  axiosInstance.post("profile/me/");

