import types from "../types";

const defaultState = {
  getData: {
    loading: false,
    error: "",
    data: "",
  },
};

const jobDetails = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_JOB_DETAILS_LOADING:
      return {
        ...state,
        getData: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.GET_JOB_DETAILS_FAILED:
      return {
        ...state,
        getData: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.GET_JOB_DETAILS:
      return {
        ...state,
        getData: {
          error: "",
          data: action.data.data,
          loading: false,
        },
      };
      case types.CLEAR_GET_JOB_DETAILS_ERROR:
        return {
          ...state,
          getData: {
            error: "",
            data: "",
            loading: false,
          },
        };
    default:
      return state;
  }
};
export default jobDetails;
