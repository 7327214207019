import types from '../types';
import * as jobCreationService from '../../services/api/jobCreationService';

const setLoadingAction = () => ({
  type: types.GET_DEACTIVATION_REASONS_LOADING,
});

const getDeactivationReasonsSuccess = data => ({
  type: types.GET_ALL_APPLICANTS_SUCCESS,
  data
});

const getDeactivationReasonsFailed = error => ({
  type: types.GET_DEACTIVATION_REASONS_FAILED,
  error
});

export const getDeactivationReasons = () => dispatch => {
  dispatch(setLoadingAction());
  return jobCreationService.getDeactivationReasons()
    .then(res => {
      dispatch(getDeactivationReasonsSuccess(res.data))
    }, err => {
      dispatch(getDeactivationReasonsFailed(err))
    })
}


