import types from '../types';
import * as jobCreationService from '../../services/api/jobCreationService';
import {getLocationFromLocationId} from "./addLocation";
import { meSuccess } from "./me";

const setLoadingAction = () => ({
  type: types.CREATE_JOB_SET_LOADING,
});

const createJobSuccess = createJobData => ({
  type: types.CREATE_JOB,
  createJobData,
});

const createJobFailed = error => ({
  type: types.CREATE_JOB_FAILED,
  error,
});

const createJobRefresh = () => ({
  type: types.CREATE_JOB_REFRESH,
});

export const createJob = (jobCreationData, id = null, meData, profile_completed_up_to = null) => dispatch => {
  dispatch(setLoadingAction());
  return jobCreationService.createJob(jobCreationData)
    .then(response => {
        jobCreationService.profileCompleted()
          .then(res => {
            // console.log("res for profile completed = ", res.data)
          }, err => {
            // console.log("error posting profile success = ", err)
          })

          if(meData && profile_completed_up_to) {
            dispatch(meSuccess({
              ...meData,
              data: {
                ...meData.data,
                profile_completed_up_to : profile_completed_up_to,
                is_profile_completed: true
              }
            }))
          }
        dispatch(createJobSuccess(response.data));
        if (id) {
          dispatch(getLocationFromLocationId(id));
        }
      },
      error => {
        dispatch(createJobFailed(error))
      }
    );
};

export const refreshJob = () => dispatch => {
  dispatch(createJobRefresh());
}

