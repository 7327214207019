import {Col, Form, Row} from "react-bootstrap";
import ButtonComponent from "../../../../components/ButtonComponent";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getJobCategories, setJobCategories} from "../../../../redux/actions";
import {useSnackbar} from "notistack";


const JobCategory = (props) => {
  const {jobCategories, setJobCategories, meData, setCategory, getJobCategoriesAction} = props;
  const [selectedJobCategories, setSelectedJobCategories] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (meData?.data) {
      if (meData.data?.industries?.length > 0) {
        let industries = meData.data.industries.map((res) => res.name);
        setSelectedJobCategories(industries);
        setButtonEnabled(false)
      }
    }
  }, [meData]);

  useEffect(() => {
    if (buttonEnabled) {
      let industries = meData?.data?.industries.map((res) => res.name).sort();
      let sortedData = selectedJobCategories.sort();
      if (industries.toString() === sortedData.toString()) {
        setButtonEnabled(false);
      }
    }
  }, [selectedJobCategories]);

  useEffect(() => {
    if (!jobCategories) {
      getJobCategoriesAction();
    }
  }, []);

  const jobCategorySelected = (event) => {
    setButtonEnabled(true);
    if (selectedJobCategories.includes(event.target.id)) {
      const optionData = selectedJobCategories;
      const index = optionData.indexOf(event.target.id);
      optionData.splice(index, 1);
      setSelectedJobCategories([...optionData]);
    } else {
      setSelectedJobCategories([...selectedJobCategories, event.target.id]);
    }
  };

  const resetData = () => {
    if (buttonEnabled && meData?.data) {
      if (meData.data?.industries?.length > 0) {
        let industries = meData.data.industries.map((res) => res.name);
        setSelectedJobCategories(industries);
      }
    }
  };

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }

  const handleSubmit = () => {
    setBtnLoading(true);
    setCategory(selectedJobCategories, meData, showNotification)
  }

  useEffect(() => {
    if (!setJobCategories.loading) {
      setBtnLoading(false);
    } else {
      setBtnLoading(true);
    }
  }, [setJobCategories.data, setJobCategories.error, setJobCategories.loading]);

  return (
    <div id="profileCard">
      <Row>
        <Col>
          <div className="profile-card-header">Job category</div>

          {jobCategories &&
          jobCategories.map((data, i) => (
            <Form.Check
              className="industry-checkbox"
              id={data.name}
              label={data.name}
              key={data.id}
              onChange={(event) => jobCategorySelected(event)}
              checked={
                selectedJobCategories.includes(data.name)
              }
            />
          ))}
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-end mt-3">
        <ButtonComponent
          idName="saveBtn"
          name="Save"
          loading={btnLoading}
          disabled={!buttonEnabled || btnLoading}
          onClickFunction={() => handleSubmit()}
        />
        {buttonEnabled && (
          <ButtonComponent
            className={"profile-cancel-btn"}
            name="Cancel"
            type={"outline"}
            disabled={!buttonEnabled || btnLoading}
            onClickFunction={() => resetData()}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  meData: state.me?.meResponse,
  jobCategories: state.jobCategory.getData.data,
  setJobCategories: state.jobCategory.postData,
});
const mapDispatchToProps = (dispatch) => ({
  setCategory: (data, meData, showNotification) => dispatch(setJobCategories(data, meData, showNotification)),
  getJobCategoriesAction: () => dispatch(getJobCategories()),
});
export default connect(mapStateToProps, mapDispatchToProps)(JobCategory);
