import React from "react";
import {Button} from "react-bootstrap";
import "./style.css";

const ButtonComponent = ({
                           onClickFunction,
                           type,
                           idName,
                           link,
                           icon,
                           name,
                           className = "",
                           disabled,
                           loading,
                           btnType,
                         }) => {
  return (
    <Button
      variant={type}
      href={link}
      type={btnType || "submit"}
      id={idName}
      className={"jipe-btn " + className}
      style={{border: type !== "link" ? "border: 1px solid #7FB7BE" : "none"}}
      onClick={onClickFunction}
      disabled={loading || disabled}
    >
      {
        loading ?
          <div className="d-flex flex-row justify-content-center">
            <div className="btn-loader"></div>
          </div>
          :
          <>{name}</>
      }
    </Button>
  );
};

export default ButtonComponent;
