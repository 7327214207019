import React from "react";
import './style.css';

const BlankLayoutComponent = (props) => {
  return (
    <>
      {props.children}
    </>
  )
};

export default BlankLayoutComponent;
