import types from "../types";

const defaultState = {
  getData: {
    loading: false,
    error: "",
    data: [],
  },
};

const deactivationReasons = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_DEACTIVATION_REASONS_LOADING:
      return {
        ...state,
        getData: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.GET_DEACTIVATION_REASONS_FAILED:
      return {
        ...state,
        getData: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.GET_ALL_APPLICANTS_SUCCESS:
      return {
        ...state,
        getData: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    default:
      return state;
  }
};
export default deactivationReasons;
