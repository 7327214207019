import types from "../types";

const defaultState = {
  billingInvoices: {
    data: "",
    loading: false,
    error: "",
  },
};

const setBillingInvoice = (state = defaultState, action) => {
  switch (action.type) {
    case types.billingInfoConstants.SET_BILLING_INVOICE_LOADING:
      return {
        ...state,
        billingInvoices: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.billingInfoConstants.SET_BILLING_INVOICE_FAILED:
      return {
        ...state,
        billingInvoices: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.billingInfoConstants.SET_BILLING_INVOICE_SUCCESS:
      return {
        ...state,
        billingInvoices: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default setBillingInvoice;
