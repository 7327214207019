import axios from "axios";
import LocalStorageService from '../../utils/LocalStorageService';

// LocalstorageService
const localStorageService = LocalStorageService.getService();

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

axiosInstance.interceptors.request.use(
    config => {
        if (config.url !== "auth/login/" && config.url !== "auth/register/" && config.url !== "auth/send-verification-code/") {
            const token = localStorageService.getAccessToken();
            if (token) {
                config.headers['Authorization'] = 'Token ' + token;
                config.headers['Content-Type'] = 'application/json';
            }
        }
        return config;
    },
    error => {
        // console.log('REQUEST CONFIG ERROR =>>>>>>>>>>>>>>>>>', error);
        Promise.reject(error).then();
    });

axiosInstance.interceptors.response.use((response) => {
    // console.log('API RESPONSE =>>>>>>>>>>>>>>>>>>>>>>>.', response);
    return response
}, (error) => {
    // console.log('ORIGINAL REQUEST =>>>>>', error.response);
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === 'auth/token') {
        return Promise.reject(error);
    }

    if (error.response.status === 403) {
        LocalStorageService.clearToken()
    }

    if (error.response.status === 401 && !originalRequest._retry) {
        // console.log("error 401")
        originalRequest._retry = true;
        const refreshToken = localStorageService.getRefreshToken();
        return axiosInstance.post('/auth/token', {"refresh_token": refreshToken})
            .then(res => {
                if (res.status === 201) {
                    localStorageService.setToken(res.data.data.token, res.data.data.refresh_token);
                    axios.defaults.headers.common['Authorization'] = 'Token ' + localStorageService.getAccessToken();
                    // console.log("original Request in axios = ", originalRequest, " also resssss === ", res)
                    return axiosInstance(originalRequest);
                }
            })
    }
    return Promise.reject(error);
});

export default axiosInstance;
