import types from '../types';

const defaultState = {
  media: "",
  loading: false,
  error: "",
};

const setPhotoVideos = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_PHOTOS_VIDEOS_LOADING:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case types.SET_PHOTOS_VIDEOS:
      return {
        ...state,
        media: action.data,
        error: "",
        loading: false,
      };
    case types.SET_PHOTOS_VIDEOS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.REMOVE_DELETED_PHOTOS_VIDEOS:
      return {
        ...state,
        media: state.media.length > 0 ? state.media.filter((res) => res.id !== action.mediaId) : null
      }
    default:
      return state;
  }
};
export default setPhotoVideos;
