export const billingInfoConstants = {
  SET_BILLING_INVOICE_SUCCESS:"SET_BILLING_INVOICE_SUCCESS",
  SET_BILLING_INVOICE_FAILED: "SET_BILLING_INVOICE_FAILED",
  SET_BILLING_INVOICE_LOADING : "SET_BILLING_INVOICE_LOADING",
}

export const billingCardInfoConstants = {
  SET_BILLING_CARD_INFO_SUCCESS:"SET_BILLING_CARD_INFO_SUCCESS",
  SET_BILLING_CARD_INFO_FAILED: "SET_BILLING_CARD_INFO_FAILED",
  SET_BILLING_CARD_INFO_LOADING : "SET_BILLING_CARD_INFO_LOADING",
}

export const cancelSubscriptionConstants = {
  CANCEL_SUBSCRIPTION_SUCCESS:"CANCEL_SUBSCRIPTION_SUCCESS",
  CANCEL_SUBSCRIPTION_FAILED: "CANCEL_SUBSCRIPTION_FAILED",
  CANCEL_SUBSCRIPTION_LOADING : "CANCEL_SUBSCRIPTION_LOADING",
  RESET_CANCEL_SUBSCRIPTION : "RESET_CANCEL_SUBSCRIPTION",
}

