import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";

import "./style.css";
import ButtonComponent from "../../../components/ButtonComponent";
import {getApplicationQuestions} from "../../../redux/actions/applicationQuestionsAction";
import Banner from "./components/Banner";
import * as meActions from "../../../redux/actions/me";
import ApplicationType from "./components/ApplicationType";
import JobCategory from "./components/JobCategory";
import SocialLinks from "./components/SocialLinks";
import ProfilePicture from "./components/ProfilePicture";
import PhotoVideo from "./components/PhotoVideo";
import Questions from "./components/Questions";
import BusinessName from "./components/BusinessName";
import Description from "./components/Description";
import PreviewProfileModal from "../../../components/PreviewProfileModal";

const EditProfilePage = (props) => {
  const {meDataState} = props;
  const [modalShow, setModalShow] = useState(false);


  return (
    <div className="containerMain" style={{height: "auto"}}>
      <Container fluid className="profileContainer">
        <Row>
          <Col className="profileCol">EDIT BUSINESS ACCOUNT INFO</Col>
        </Row>

        <Row>
          <Col xs sm md lg xl={{span: 8}} className="p-0">
            {/* <BasicInfo data={meDataState?.data} /> */}
            <BusinessName data={meDataState?.data}/>
            <SocialLinks data={meDataState?.data?.social_urls}/>
            <ProfilePicture data={meDataState?.data?.profile_picture_url}/>
            <Banner/>
            <Description/>
            <PhotoVideo/>
            <JobCategory/>
            <ApplicationType/>
            {meDataState?.data?.application_type === "jipe_application" ? (
              <Questions/>
            ) : (
              <></>
            )}
          </Col>

          <Col
            xl={{span: 4}}
            className="d-flex flex-column align-items-center"
          >
            <div id="stickyBtn">
              <Col className="pb-3">
                <ButtonComponent
                  idName="profileBtn"
                  name="PREVIEW"
                  onClickFunction={() => setModalShow(true)}
                />
              </Col>
            </div>
          </Col>
        </Row>
      </Container>

      {modalShow && (
        <PreviewProfileModal
          show={modalShow}
          hide={() => setModalShow(false)}
          meData={meDataState?.data}
          jobCreationData={null}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getApplicationQuestionsState: state.applicationQuestions.getData,
  meDataState: state.me.meResponse,
});

const mapDispatchToProps = (dispatch) => ({
  getApplicationQuestionsAction: () => dispatch(getApplicationQuestions()),
  getMeDataAction: () => dispatch(meActions.me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePage);
