import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import ReactCrop from "react-image-crop";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-image-crop/dist/ReactCrop.css";
import ButtonComponent from "../ButtonComponent";
import './style.css';
import {useSnackbar} from "notistack";

const aspectRatio = {
  profile: 1,
  photoVideo: 1,
  banner: 16 / 9
}

const ImageCropperComponent = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {show, hide, type, src, setButtonEnabled, setSelectedImage} = props
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: aspectRatio[type]
  })
  const [imageRef, setImageRef] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const setImageSelection = () => {
    console.log(croppedImage)
    if (croppedImage) {
      setSelectedImage(croppedImage)
      if (type === "profile" || type === 'banner') {
        setButtonEnabled(true)
      }
      hide()
    } else {
      enqueueSnackbar("Please move the cropper to set the image!", {variant: "warning"});
    }
  }

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image)
  };

  const onCropComplete = (crop) => {
    console.log(crop);
    makeClientCrop(crop).then();
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    console.log(crop)
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageFile = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setCroppedImage(croppedImageFile)
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        console.log(blob)
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        var file = new File([blob], "test.jpg", {type: blob.type})
        resolve(file);
      }, "image/png");
    });
  }

  return (
    <>
      <Modal show={show} onHide={hide} dialogClassName="cropper-modal"
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <div className="h-100">
          <div className="modal-close-fab" onClick={hide}>
            <FontAwesomeIcon icon={faTimes}/>
          </div>
          <div className="mt-4" style={{height: '85%', maxHeight: '85%'}}>
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                circularCrop={type === 'profile'}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )}
          </div>
          <div style={{height: 'auto'}} className="d-flex flex-column justify-content-center">
            <ButtonComponent
              idName="selectImageButton"
              name="Select Image"
              onClickFunction={setImageSelection}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ImageCropperComponent;
