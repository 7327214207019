import {Container, Form} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import './style.css';
import {connect} from "react-redux";
import ButtonComponent from "../../../../components/ButtonComponent";

const DeactivateQuestionModal = (props) => {
  const {deactivationReasons} = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    console.log(deactivationReasons)
  }, [deactivationReasons])

  useEffect(() => {
    console.log(selectedOptions)
  }, [selectedOptions])

  const checkBoxToggled = (event) => {
      if (selectedOptions.includes(event.target.id)) {
        const optionData = selectedOptions;
        const index = optionData.indexOf(event.target.id);
        optionData.splice(index, 1);
        setSelectedOptions([...optionData]);
      } else {
        setSelectedOptions([...selectedOptions, event.target.id]);
      }
  };

  return (
    <>
      <Modal show={props.show.state} dialogClassName="deactivation-modal" keyboard={false} onHide={props.hide}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Container className="modal-body-container">
          <div className="deactivation-question-header">
            Why are you pausing this listing?
          </div>

          <div className="p-2 d-flex flex-column justify-content-center" style={{height: "80%"}}>
            {deactivationReasons?.data.map((item, idx) => (
              <div className="d-flex flex-row deactivation-reasons" key={idx}>
                <div style={{width: "90%"}}>{item.reason}</div>
                <div style={{width: "10%"}}>
                  <Form.Check
                    className="application-category-checkbox"
                    id={item.id}
                    onChange={(event) => checkBoxToggled(event)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex flex-row justify-content-center">
            <ButtonComponent
              idName="continue-deactivate-btn"
              name={"Continue"}
              disabled={selectedOptions.length === 0}
              onClickFunction={() => props.actions({...props.show, reasons: selectedOptions})}
            />
          </div>
        </Container>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  deactivationReasons: state.deactivationReasons.getData.data,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateQuestionModal);
