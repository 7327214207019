import React, {useEffect} from "react";
import Select from "react-select";
import "./style.css";
import {Col, Form, Row} from "react-bootstrap";
import {stateOptions} from '../../../../../utils/utils'
import ButtonComponent from '../../../../../components/ButtonComponent'

const BusinessInfo = (props) => {
  const formRef = React.createRef();
  const {isValid, onChange, formErrors, showMap, businessInfoData, setBusinessInfoData} = props;

  useEffect(() => {
    isValid(formRef.current.checkValidity())
  }, [businessInfoData])

  const formDataChange = (e) => {
    onChange(e);
    isValid(formRef.current.checkValidity());
  }

  const handleChange = (selectedOption) => {
    setBusinessInfoData({
      ...businessInfoData,
      business_state: selectedOption.value.name
    })
  };

  return (
    <>
      <Form ref={formRef} noValidate>
        <div className="stepper-title">Business Info</div>
        <div className="stepper-info">
          We need some basic information about your business to set up your Jipe account.
          Start by adding your first location here.(AKA the location where you want to post your first job listing.) You
          will be able to add more locations later.
        </div>

        <Form.Group controlId="business_name" style={{marginTop: '15px', height: '60px'}}>
          <Form.Control placeholder="Business Name" required className="stepper-input-green"
                        isInvalid={formErrors.business_name} value={businessInfoData.business_name}
                        onChange={(e) => formDataChange(e)}/>
          <Form.Control.Feedback type="invalid">
            {formErrors.business_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="select_coordinates" style={{height: '60px'}}>
          <ButtonComponent
            idName="setCoordinatesOnMap"
            btnType="button"
            name={businessInfoData.lat ? "Update Coordinates" : "Select Coordinates"}
            style={{width: "100%"}}
            onClickFunction={() => showMap()}
          />
        </Form.Group>

        <Form.Group controlId="business_location_name" style={{height: '60px'}}>
          <Form.Control placeholder="Business location name (for internal use only)" className="stepper-input-green"
                        required
                        value={businessInfoData.business_location_name}
                        isInvalid={formErrors.business_location_name}
                        onChange={(e) => formDataChange(e)}/>
          <Form.Control.Feedback type="invalid">
            {formErrors.business_location_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Col>
            <Form.Group controlId="business_street" style={{height: '60px'}}>
              <Form.Control placeholder="Street" required className="stepper-input-green"
                            isInvalid={formErrors.street}
                            value={businessInfoData.business_street}
                            onChange={(e) => formDataChange(e)}/>
              <Form.Control.Feedback type="invalid">
                {formErrors.street}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="business_street_alt" style={{height: '60px'}}>
              <Form.Control placeholder="Street Alt" className="stepper-input-green"
                            onChange={(e) => formDataChange(e)}
                            value={businessInfoData.business_street_alt}/>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="business_city" style={{height: '60px'}}>
              <Form.Control placeholder="City" className="stepper-input-green"
                            onChange={(e) => formDataChange(e)}
                            value={businessInfoData.business_city}/>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="business_state" style={{height: '60px'}}>
              <Select
                value={stateOptions.find((opt) => opt.label === businessInfoData.business_state)}
                onChange={handleChange}
                options={stateOptions}
                placeholder={'State'}
                isMulti={false}
                id="business-info-location-select"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="business_zip" style={{height: '50px'}}>
              <Form.Control placeholder="Zip" required className="stepper-input-green" isInvalid={formErrors.zip}
                            type={"number"} onChange={(e) => formDataChange(e)}
                            value={businessInfoData.business_zip}/>
              <Form.Control.Feedback type="invalid">
                {formErrors.zip}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="business_phone" style={{height: '60px'}}>
          <Form.Control placeholder="Phone Number" required className="stepper-input-green"
                        isInvalid={formErrors.business_phone}
                        value={businessInfoData.business_phone}
                        onChange={(e) => formDataChange(e)}/>
          <Form.Control.Feedback type="invalid">
            {formErrors.business_phone}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  )
};

export const BusinessInfoComponent = BusinessInfo;
