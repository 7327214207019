import React, {useEffect, useState} from 'react'
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import './style.css'
import {connect} from "react-redux";
import {getCurrentPricingPlan} from "../../../redux/actions/billingAction";
import {useSnackbar} from "notistack";
import ButtonComponent from "../../../components/ButtonComponent";
import Modal from "react-bootstrap/Modal";
import {deleteAccountService} from "../../../services/api/billingInfoService";
import {onLogout} from "../../../redux/actions/login";
import localStorageService from "../../../utils/LocalStorageService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {passwordValidation} from "../../../utils/utils";
import * as changePasswordActions from "../../../redux/actions/changePassword";

const deleteReasons = ["I am not hiring at this time", "I like Jipe, but not enough applicants", "Jipe did not meet my expectations"]

const eye = <FontAwesomeIcon icon={faEye}/>;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash}/>;

const SettingsPage = (props) => {
    const {
        meState, currentPricingPlanState, fetchCurrentPricingPlan, changePasswordDispatcher, changePasswordState
    } = props;

    const {enqueueSnackbar} = useSnackbar();
    const [deleteAccModalState, setDeleteAccModalState] = useState(-1)
    const [deleteAccReasons, setDeleteAccReasons] = useState([])
    const [deleteAccLoading, setDeleteAccLoading] = useState(false)
    const [currentPlan, setCurrentPlan] = useState(null);
    const [validated, setValidated] = useState(false);
    const [passwordFieldState, setPasswordFieldState] = useState({
        old_password: false, password: false, confirm_password: false
    });
    const [passwords, setPasswords] = useState({old_password: null, password: null, confirm_password: null});
    const [errors, setErrors] = useState({})
    const [changePasswordData, setChangePasswordData] = useState({
        type: "forget_password", data: {
            email: ""
        },
    });

    useEffect(() => {
        console.log(meState)
        if (!currentPricingPlanState.data) {
            fetchCurrentPricingPlan();
        }
        if (meState?.meResponse?.data?.basic_info?.email) {
            setChangePasswordData({...changePasswordData, data: {email: meState?.meResponse?.data?.basic_info?.email}})
        }
    }, [])

    useEffect(() => {
        if (currentPricingPlanState.data) {
            console.log(currentPricingPlanState.data)
            setCurrentPlan(currentPricingPlanState.data)
        }
    }, [currentPricingPlanState])

    useEffect(() => {
        console.log(changePasswordState)
        if (!changePasswordState.loading) {
            if (changePasswordState.error) {
                const errorMessage = changePasswordState.error.response.data ? Object.values(changePasswordState.error.response.data)[0] : 'Error resetting password. Please try again later.'
                console.log(errorMessage)
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });

            } else {
                if (changePasswordState.changePasswordResponse) {
                    enqueueSnackbar(changePasswordState.changePasswordResponse.message ? changePasswordState.changePasswordResponse.message : "Password change successful. Please login to verify!", {variant: "success"});
                    logOut();
                }
            }
        }
    }, [changePasswordState])

    const logOut = () => {
        onLogout()
        localStorageService.clearToken();
        window.location.reload()
    };

    const deleteAccount = async () => {
        setDeleteAccLoading(true)
        const response = await deleteAccountService({reasons: [...deleteAccReasons]})
        console.log(response)
        if (response && response.status === 200) {
            enqueueSnackbar(response.data.message, {variant: "success"})
            logOut()
        } else {
            setDeleteAccLoading(false)
            enqueueSnackbar("Error deleting account", {variant: "error"})
        }
    }

    const checkBoxToggled = (data) => {
        console.log(data)
        if (deleteAccReasons.includes(data)) {
            const optionData = deleteAccReasons;
            const index = optionData.indexOf(data);
            optionData.splice(index, 1);
            setDeleteAccReasons([...optionData]);
        } else {
            setDeleteAccReasons(prevState => [...prevState, data])
        }
    }


    const changePasswordRequest = (event: SubmitEvent) => {
        event.preventDefault();
        if (!changePasswordState.loading) {
            const allErrors = {};
            if (!passwords.password) {
                allErrors.password = "Please enter your password"
            }
            if (!passwords.old_password) {
                allErrors.old_password = "Please enter your password"
            }
            if (!passwords.confirm_password) {
                allErrors.confirm_password = "Please enter your password"
            }
            if (passwords.password && passwords.confirm_password) {
                if (passwords.password !== passwords.confirm_password) {
                    allErrors.confirm_password = "Password Don't match."
                } else if (!passwordValidation(passwords.password)) {
                    allErrors.password = "Please enter a valid password. It must contain at least 8 character, one number, one letter and one special character"
                    allErrors.confirm_password = "Please enter a valid password. It must contain at least 8 character, one number, one letter and one special character"
                }
            }
            if (Object.keys(allErrors).length > 0) {
                setErrors(allErrors);
                setValidated(false);
            } else {
                setValidated(true);
                changePasswordDispatcher({...passwords})
            }
        }
    }

    const togglePasswordVisibility = (id, state) => {
        setPasswordFieldState({
            ...passwordFieldState, [id]: !state
        });
    };

    const handleChange = (type, e) => {
        if (Object.keys(errors).length > 0) {
            setErrors({})
        }
        setPasswords({...passwords, [type]: e.target.value});
    };

    return (<div className='containerMain h-auto' style={{minHeight: "100%"}}>
        <Container fluid className="settings-container">
            <Row>
                <Col className='settings-col'>
                    Settings
                </Col>
            </Row>

            <Row>
                <Card className="settings-card" style={{maxHeight: "450px"}}>
                    <Card.Body>
                        <Card.Title className="settings-card-title">
                            Change Password
                        </Card.Title>

                        <div style={{width: "auto"}}>
                            <div className="current-card-details">
                                You can change your Jipe account password at anytime.
                                {meState?.meResponse?.data?.basic_info?.email ?
                                    <span> Change account password for <span
                                        style={{color: "#EE6C4D"}}>{meState?.meResponse?.data?.basic_info?.email}</span> below.</span> : <></>}
                            </div>

                            <div className="current-card-details text-right">
                                <Form noValidate
                                      validated={validated} onSubmit={(event) => changePasswordRequest(event)}>
                                    <Form.Group controlId="formBasicPassword" className="mt-4"
                                                style={{height: '50px', width: "50%"}}>
                                        <div className="password-input-wrapper">
                                            <Form.Control placeholder="Old Password"
                                                          type={passwordFieldState.old_password ? "text" : "password"}
                                                          value={passwords.old_password}
                                                          isInvalid={errors.old_password}
                                                          onChange={(e) => handleChange('old_password', e)}
                                                          required
                                                          className="password-input-blue"
                                                          style={{backgroundColor: 'transparent'}}/>
                                            <i onClick={() => togglePasswordVisibility('old_password', passwordFieldState.old_password)}
                                               className="pass-toggle-icon">{passwordFieldState.old_password ? eye : eyeSlash}</i>
                                        </div>
                                        <Form.Control.Feedback type="invalid"
                                                               style={{display: errors.old_password ? 'block' : ''}}>
                                            {errors.old_password}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword" className="mt-4"
                                                style={{height: '50px', width: "50%"}}>
                                        <div className="password-input-wrapper">
                                            <Form.Control placeholder="New Password"
                                                          type={passwordFieldState.password ? "text" : "password"}
                                                          value={passwords.password}
                                                          isInvalid={errors.password}
                                                          onChange={(e) => handleChange('password', e)}
                                                          required
                                                          className="password-input-blue"
                                                          style={{backgroundColor: 'transparent'}}/>
                                            <i onClick={() => togglePasswordVisibility('password', passwordFieldState.password)}
                                               className="pass-toggle-icon">{passwordFieldState.password ? eye : eyeSlash}</i>
                                        </div>
                                        <Form.Control.Feedback type="invalid"
                                                               style={{display: errors.password ? 'block' : ''}}>
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword" className="mt-4"
                                                style={{height: '50px', width: "50%"}}>
                                        <div className="password-input-wrapper">
                                            <Form.Control placeholder="Confirm Password"
                                                          type={passwordFieldState.confirm_password ? "text" : "password"}
                                                          value={passwords.confirm_password}
                                                          isInvalid={errors.confirm_password}
                                                          onChange={(e) => handleChange('confirm_password', e)}
                                                          required
                                                          className="password-input-blue"
                                                          style={{backgroundColor: 'transparent'}}/>
                                            <i
                                                onClick={() => togglePasswordVisibility('confirm_password', passwordFieldState.confirm_password)}
                                                className="pass-toggle-icon">{passwordFieldState.confirm_password ? eye : eyeSlash}</i>
                                        </div>
                                        <Form.Control.Feedback type="invalid"
                                                               style={{display: errors.confirm_password ? 'block' : ''}}>
                                            {errors.confirm_password}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Row>
                                        <Col className="mt-2 text-right">
                                            <ButtonComponent name={"Change Password"} idName={"change-submit-btn"}
                                                             loading={changePasswordState.loading}
                                                             disabled={changePasswordState.loading}/>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Row>

            <Row>
                <Card className="settings-card" style={{maxHeight: "450px"}}>
                    <Card.Body>
                        <Card.Title className="settings-card-title">
                            Delete Account
                        </Card.Title>

                        <div style={{width: "auto"}}>
                            <div className="current-card-details">
                                {currentPlan?.code === "bulk_pricing" ? <span>
                      Did you know you can "pause" your account by pausing your listings? This will save your account
                      details and not charge your card as long as the listings are inactive.
                    </span> :
                                    <span>If you are considering leaving, please contact your Account Manager first. Thank you.</span>}
                            </div>
                            <div className="current-card-details text-right">
                                <span className="settings-link-btn" onClick={() => setDeleteAccModalState(1)}>Delete Account</span>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container>

        <Modal show={deleteAccModalState === 1} dialogClassName="delete-acc-modal" keyboard={false}
               onHide={() => setDeleteAccModalState(-1)}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Container className="modal-body-container">
                <div className="delete-acc-header">
                    Are you sure you want to delete your account and all data associated with it? This action is not
                    reversible.
                </div>

                <div className="d-flex flex-row justify-content-between mt-4">
                    <ButtonComponent
                        className="delete-nn-btn"
                        name={"Not Now"}
                        onClickFunction={() => setDeleteAccModalState(-1)}
                    />

                    <ButtonComponent
                        className="delete-confirmation-btn"
                        name={"Yes, Delete My Account"}
                        onClickFunction={() => setDeleteAccModalState(2)}
                    />
                </div>
            </Container>
        </Modal>

        <Modal show={deleteAccModalState === 2} dialogClassName="delete-reasons-modal" keyboard={false}
               onHide={() => setDeleteAccModalState(-1)}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Container className="modal-body-container">
                <div className="delete-acc-header">
                    We're sad to see you go. Please tell us why you are leaving.
                </div>

                <div className="mt-2">
                    {deleteReasons.map((item, idx) => (<div className="d-flex flex-row deactivation-reasons" key={idx}>
                        <div style={{width: "90%"}}>{item}</div>
                        <div style={{width: "10%"}}>
                            <Form.Check
                                className="delete-acc-checkbox"
                                onChange={() => checkBoxToggled(item)}
                            />
                        </div>
                    </div>))}
                </div>

                <div className="d-flex flex-row justify-content-center mt-2">
                    <ButtonComponent
                        className="delete-acc-btn"
                        name={"Delete Account"}
                        loading={deleteAccLoading}
                        disabled={deleteAccReasons.length < 1}
                        onClickFunction={deleteAccount}
                    />
                </div>
            </Container>
        </Modal>
    </div>)
}

const mapStateToProps = (state) => ({
    meState: state.me,
    currentPricingPlanState: state.setBillingInfo.currentPricingPlan,
    changePasswordState: state.changePassword,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCurrentPricingPlan: () => dispatch(getCurrentPricingPlan()),
    changePasswordDispatcher: (changePasswordData) => dispatch(changePasswordActions.changePassword(changePasswordData)),
    onLogout: () => dispatch(onLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
