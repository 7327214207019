export const manageUsersConstants = {
    MANAGE_USERS_POST_LOADING : "MANAGE_USERS_POST_LOADING",
    MANAGE_USERS_POST_SUCCESS : "MANAGE_USERS_POST_SUCCESS",
    MANAGE_USERS_POST_FAILED : "MANAGE_USERS_POST_FAILED",
    REFRESH_STATE: "REFRESH_STATE",
    GET_LOCATIONS_LOADING: "GET_LOCATIONS_LOADING",
    GET_LOCATIONS_FAILED: "GET_LOCATIONS_FAILED",
    LOCATIONS: "LOCATIONS",
    GET_USERS_LOADING: "GET_USERS_LOADING",
    GET_USERS_FAILED: "GET_USERS_FAILED",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    DELETE_USERS_LOADING : "DELETE_USERS_LOADING",
    DELETE_USERS_FAILED : "DELETE_USERS_FAILED",
    DELETE_USERS_SUCCESS : "DELETE_USERS_SUCCESS",
    RESET_DELETE_USERS : "RESET_DELETE_USERS",
    CLEAR_GET_LOCATION_LIST_ERROR : "CLEAR_GET_LOCATION_LIST_ERROR",
    CLEAR_GET_USER_ERROR : "CLEAR_GET_USER_ERROR",
}