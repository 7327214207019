import React, {useEffect, useState} from 'react'
import './style.css'
import application from '../../../../../assets/profile-pic.png'
import {NavDropdown} from "react-bootstrap";
import {getTimeObject} from "../../../../../utils/utils";
import {generateNameInitialImg} from "../../../../../utils/nameInitial";


const MessageSidebar = (props) => {
  const [finalRecipients, setFinalRecipients] = useState(null)
  const {recipients, setActive, setLocation, locations, activeChat} = props

  useEffect(() => {
    if (recipients && activeChat) {
      setFinalRecipients(sortRecipients(activeChat))
    } else {
      setFinalRecipients(recipients)
    }
  }, [activeChat, recipients])

  const sortRecipients = (active) => {
    const filteredList = [...recipients]
    const indexOfActive = filteredList?.map(item => item.conversation.id).indexOf(active?.id)
    if (indexOfActive !== -1) {
      filteredList.unshift(filteredList.splice(indexOfActive, 1)[0])
      return [...filteredList]
    }
  }

  return (
    <div className="sidebar">
      <div className="sidebar__header">
        <NavDropdown
          id="sidebar-message-dropdown"
          title="ALL MY MESSAGES"
          drop={'right'}
        >
          <NavDropdown.Item onClick={() => setLocation('')} className={'sidebar-message-item'}>All
            my
            messages</NavDropdown.Item>
          {locations?.map((location, idx) => (
            <NavDropdown.Item onClick={() => setLocation(location?.location?.id)} key={idx}
                              className={'sidebar-message-item'}>{location?.location?.name}</NavDropdown.Item>
          ))}
        </NavDropdown>
      </div>

      <div className="sidebar__chats">
        <div className="sidebar__chatinfo">
          {
            finalRecipients?.length ?
              finalRecipients?.map((recipient, index) => (
                <div className="sidebar__chatMessage" onClick={() => setActive(recipient)} key={index}
                     style={{border: activeChat?.id === recipient?.conversation?.id ? '1px solid #EE6C4D' : ''}}>
                  {recipient?.conversation?.has_unread_messages && (
                    <div className={"sidebar-unread-badge"}/>
                  )}
                  <img src={recipient?.seeker_profile_pictures ? recipient?.seeker_profile_picture : generateNameInitialImg(recipient?.seeker_full_name, '#A3CF62')}
                    className="sidebar__avatar" alt={"Profile Picture"}/>
                  <div className="sidebarChatMessage__info">
                    <div className="recipient-info">
                      <span className="recipient-name">{recipient?.seeker_full_name}</span>
                      <span className="recipient-location">{recipient?.title}</span>
                    </div>
                    <div className="recipient-message">
                      <div
                        className="recipient-message-time">{getTimeObject(recipient?.conversation?.last_message_date)}:
                      </div>
                      {/*<div className="recipient-message-text">{recipient?.displayMessage}</div>*/}
                    </div>
                  </div>
                </div>
              )) :
              (<div className={"sidebar-no-messages-info-txt"}>No Recipients Found</div>)
          }
        </div>
      </div>
    </div>

  )
}

export default MessageSidebar;
