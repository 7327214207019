import React from 'react'
import sender from "../../../../../assets/dropDown.png";
import {Card, Container, Row} from "react-bootstrap";
import {generateNameInitialImg} from "../../../../../utils/nameInitial";


const ChatMessageComponent = (props) => {
    const {conversationDetails, currentUser, showAvatar} = props

    const recipientType = () => {
        if (conversationDetails?.user_info?.id && currentUser)
            return conversationDetails?.user_info?.id === currentUser ? 'sender' : 'receiver'
    }

    return (
        <Container id={recipientType()}>
            <Row style={{
                justifyContent: conversationDetails?.user_info?.id === currentUser ? 'end' : 'start',
                flexFlow: conversationDetails?.user_info?.id === currentUser ? '' : 'row-reverse'
            }} className={"align-items-end"}>
                <Card body
                      className={`message-content ${recipientType()}`}>{conversationDetails?.message}</Card>
                <img
                    src={conversationDetails?.user_info?.profile_picture_url ? conversationDetails?.user_info?.profile_picture_url : generateNameInitialImg(`${conversationDetails?.user_info?.first_name} ${conversationDetails?.user_info?.last_name}`, '#A3CF62')}
                    className={'recipient-avatar'}
                    alt={"Profile Picture"}
                    style={{visibility: showAvatar ? 'hidden' : ''}}
                />
            </Row>
        </Container>
    )
}

export default ChatMessageComponent;
