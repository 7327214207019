import {usStates} from './usStates'
import {loadStripe} from "@stripe/stripe-js/pure";

export const formatAMPM = (date) => {
  console.log(date)
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const getTimeObject = (date) => {
  const dateObj = new Date(date)
  if (dateObj)
    return dateObj.toLocaleString()
}

export function checkZip(value) {
  return (/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(value);
};

export function isPositiveInteger(value) {
  return ((/^[1-9]\d*$/).test(value))
}

export function checkPhone(value) {
  return (/^\d{10}$/).test(value);
}

export function isGreaterThanZero(value) {
  return (/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/).test(value)
}

export function checkTimeMayVary(time_sheets) {
  let hasTimeMayVary = time_sheets.map(item => item.may_vary === true);
  return hasTimeMayVary.includes(true)
}

// Checks for atleast one time availability added
export function checkTimeAvailability(time_sheets) {
  let hasTime = time_sheets.map(item => item.time_slots.length > 0);
  return hasTime.includes(true)
}

export const passwordValidation = (pass) => {
  return (/^(?=.* ?[A-Z])(?=.* ?[a-z])(?=.*?[0-9])(?=.*?[ #?!@$%^&*-]).{8,}$/).test(pass)
}
export const profileCompletedUpToDS = [
  "create-account&&register",
  "signup_1&&basic_info",
  "signup_2&&business_logo",
  "signup_3&&banner_picture",
  "signup_4&&about_you",
  "signup_5&&social_urls",
  "signup_6&&media",
  "signup_7&&industries",
  "signup_8&&application_type",
  "signup_9&&questions",
  "billing_1&&pricing_plans",
  "job-creation_1&&job_listing_create"
]

export const stateOptions = usStates.map((states) => {
  return {value: states, label: states.name}
})

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_TOKEN
);

export const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: 'grey',
      color: 'grey',
      fontWeight: 500,
      fontSize: '16px',
      border: '1px solid red',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {color: '#fce883'},
      '::placeholder': {color: 'grey'},
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};
