import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory, withRouter} from "react-router-dom";
import {useSnackbar} from "notistack";
import {connect} from "react-redux";
import {Elements} from "@stripe/react-stripe-js";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import PricingPlanComponent from "../PricingPlanComponent";
import CreditCardComponent from "../CreditCardComponent";
import {ReactComponent as MonkeySuccess} from "../../../../assets/success-monkey2.svg";
import * as meActions from "../../../../redux/actions/me";
import ButtonComponent from "../../../../components/ButtonComponent";
import "./style.css";
import {postPricingPlanId} from "../../../../redux/actions/billingAction";
import {updateStepperCompleteStatus} from "../../../../redux/actions/updateStepperCompleteStatus";
import {stripePromise} from "../../../../utils/utils";

const Stepper = (props) => {
    let history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const {
        meData,
        getMe,
    } = props;
    const [stepperActivePage, setStepperActivePage] = useState(1);
    const [stepperNavigation, setStepperNavigation] = useState(false);
    const [nextClicked, setNextClicked] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({planId: ""});
// credit card
    const [creditCardData, setCreditCardData] = useState({
        street: "",
        street_alt: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
    });
    const enableNavigation = (val) => {
        setStepperNavigation(val);
    };

    useEffect(() => {
        if (!meData.meResponse) {
            getMe();
        }
        try {
            const {activeStepperIndex} = props.props.location.state;
            console.log(activeStepperIndex);
            setStepperActivePage(activeStepperIndex);
        } catch (err) {
            // pass
        }
    }, []);

    useEffect(() => {
        if (!meData.loading) {
            if (meData.error) {
                if (meData.error.response.status === 403) {
                    history.push("/login");
                } else {
                    try {
                        Object.values(meData?.error?.response?.data).map((err) => {
                            enqueueSnackbar(err, {
                                variant: "error",
                            });
                        });
                    } catch (err) {
                        enqueueSnackbar(
                            "Error loading previous information. Please try again later",
                            {
                                variant: "error",
                            }
                        );
                    }
                }
            } else {
                if (meData.meResponse) {
                    console.log(meData?.meResponse?.data)
                    if (meData?.meResponse?.data?.is_profile_completed || meData?.meResponse?.data["profile_completed_up_to"] === "job_listing_create") {
                        history.push("/location-manager");
                    } else {
                        if (meData?.meResponse?.data["profile_completed_up_to"] === "pricing_plans" && !meData?.meResponse?.data["is_card_save"]) {
                            setStepperActivePage(1)
                        } else if (meData?.meResponse?.data["profile_completed_up_to"] === "pricing_plans" && meData?.meResponse?.data["is_card_save"]) {
                            setStepperActivePage(3)
                            console.log(meData?.meResponse?.data)
                        } else {
                            console.log(meData?.meResponse?.data)
                        }
                    }
                }
            }
        }
    }, [meData]);

    const changePage = () => {
        console.log(stepperNavigation)
        if (stepperNavigation) {
            setStepperActivePage(stepperActivePage + 1);
            setStepperNavigation(false);
            handleNextClick(false);
            setNextClicked(false);
        }
    };

    const createListingHandle = () => {
        props.history.push("/job-creation")
    }

    const handleNextClick = (val) => {
        if (stepperNavigation) {
            setNextClicked(val);
        }
    };

    const handleBackCLick = () => {
        if (stepperActivePage === 1) {
            history.push({pathname: "/signup", state: {activeStepperIndex: 9}});
        } else {
            setStepperActivePage(stepperActivePage - 1);
            setStepperNavigation(false);
        }
    };

    const handleSelectedPlan = (planId) => {
        console.log(selectedPlan)
        if (planId === selectedPlan.planId) {
            setSelectedPlan({planId: ""});
        } else {
            setSelectedPlan({planId: planId});
        }
    };

    useEffect(() => {
        if (
            stepperActivePage === 1 &&
            nextClicked === true &&
            selectedPlan.planId
        ) {
            changePage();
        }
    }, [nextClicked]);

    const activePage = () => {
        if (stepperActivePage === 1) {
            return (
                <PricingPlanComponent
                    navigationState={enableNavigation}
                    selectedPlan={selectedPlan}
                    handlePlans={(planId) => handleSelectedPlan(planId)}
                />
            );
        } else if (stepperActivePage === 2) {
            return (
                <Elements stripe={stripePromise}>
                    <CreditCardComponent
                        navigationState={enableNavigation}
                        nextClicked={nextClicked}
                        setNextClicked={setNextClicked}
                        changePage={changePage}
                        creditCardData={creditCardData}
                        setCreditCardData={setCreditCardData}
                        selectedPlan={selectedPlan}
                        meData={meData.meResponse}
                    />
                </Elements>
            );
        }
    };

    return (
        <>
            {stepperActivePage !== 3 ? (
                <div className="stepper-card">
                    <div className="stepper-main-body">
                        <div className="h-100 w-100">{activePage()}</div>
                    </div>

                    <div className="stepper-footer">
                        <div className={"stepper-btn-div"} onClick={handleBackCLick}>
                            <FontAwesomeIcon
                                icon={faAngleLeft}
                                style={{color: "#2C2B7C"}}
                            />
                            <span style={{marginLeft: "5px"}}>back</span>
                        </div>

                        <div className="stepper-indicator-wrapper">
                            {[1, 2].map((item, key) => (
                                <div
                                    className={
                                        "stepper-page-indicator" +
                                        (stepperActivePage === item
                                            ? " active-stepper-indicator"
                                            : "")
                                    }
                                    key={key}
                                ></div>
                            ))}
                        </div>

                        <div
                            className={
                                "stepper-btn-div" +
                                (!stepperNavigation ? " disabled-page-btns" : "")
                            }
                            onClick={() => handleNextClick(true)}
                        >
                            <span style={{marginRight: "5px"}}>next</span>
                            <FontAwesomeIcon
                                icon={faAngleRight}
                                style={{color: "#2C2B7C"}}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="thankyou-title">Thank You!</div>
                    <div
                        style={{width: "350px"}}
                        className="d-flex flex-column justify-content-center align-items-center"
                    >
                        <div className="thankyou-info">
                            We now have your payment information on file. Let's add your first
                            job listing! After adding your first listing, you will be able to
                            edit the listing, add new listings, and add additional locations.
                        </div>

                        <MonkeySuccess
                            className="mt-4"
                            style={{height: "200px", width: "200px"}}
                        />

                        <ButtonComponent
                            idName="create-listing-btn"
                            name="CREATE LISTING"
                            onClickFunction={() => createListingHandle()}
                        />

                        <ButtonComponent
                            type="link"
                            idName="signup-back-btn"
                            name="< back"
                            onClickFunction={() => handleBackCLick()}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    meData: state.me,
});

const mapDispatchToProps = (dispatch) => ({
    getMe: () => dispatch(meActions.me()),
    postPricingPlan: (id, toast, meData, profile_completed_up_to) =>
        dispatch(postPricingPlanId({product_id: id}, toast, meData, profile_completed_up_to)),
    updateStepperCompleteStatus: (meData, profile_completed_up_to) => dispatch(updateStepperCompleteStatus(meData, profile_completed_up_to)),
});

export const StepperComponent = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Stepper)
);
