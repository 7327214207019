import types from '../types';
import * as userService from '../../services/api/userService';

const setLoadingAction = () => ({
  type: types.CHANGE_PASSWORD_SET_LOADING,
});

const changePasswordSuccess = changePasswordResponse => ({
  type: types.CHANGE_PASSWORD,
  changePasswordResponse,
});

const changePasswordFailed = error => ({
  type: types.CHANGE_PASSWORD_FAILED,
  error,
});

export const changePassword = (changePasswordData) => dispatch => {
  dispatch(setLoadingAction());
  return userService.changePassword(changePasswordData)
    .then(
      response => {
        dispatch(changePasswordSuccess(response.data))
      },
      error => {
        dispatch(changePasswordFailed(error))
      }
    );
};

export const resetChangePassword = () => dispatch => {
  dispatch(changePasswordSuccess());
}

