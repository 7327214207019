import React, {useEffect, useState} from "react";
import "./style.css";
import {Form, Row, Spinner} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {hideApplicant, updateApplicantStatus} from "../../../../redux/actions";
import {connect} from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import {useHistory} from "react-router-dom";
import {generateNameInitialImg} from "../../../../utils/nameInitial";

const ApplicantTableComponent = (props) => {
    const history = useHistory();
    const {applicantsInfo, loading, swipeRight} = props;
    const {saveApplicantStatusDispatcher, hideApplicantDispatcher} = props;
    const [applicantStatus, setApplicantStatus] = useState({});

    useEffect(() => {
        if (applicantsInfo) {
            applicantsInfo.map((applicant) => {
                setApplicantStatus(prevState => ({
                    ...prevState,
                    [applicant?.job?.application_id]: applicant?.job?.application_status
                }))
            })
        }
    }, [applicantsInfo])

    const changeApplicantStatus = (e, job) => {
        const {checked, id} = e.target;
        const currentStatus = [...applicantStatus[job.application_id]];
        if (checked) {
            if (!currentStatus.includes(id)) {
                currentStatus.push(id);
            }
        } else {
            if (currentStatus.includes(id)) {
                const index = currentStatus.indexOf(id);
                if (index > -1) {
                    currentStatus.splice(index, 1);
                }
            }
        }
        saveApplicantStatus(job.application_id, currentStatus)
    }

    const containsAll = (arr1, arr2) =>
        arr2.every(arr2Item => arr1.includes(arr2Item))

    const sameMembers = (arr1, arr2) => {
        if (arr1 && arr2) {
            return containsAll(arr1, arr2) && containsAll(arr2, arr1);
        }
    }

    const saveApplicantStatus = (id, status) => {
        saveApplicantStatusDispatcher({application_id: id, actions: status})
    }

    const handleShowHide = (id, hidden) => {
        hideApplicantDispatcher({application_id: id, is_hidden: !hidden});
    }

    const goToMessagePage = (ev, convoId) => {
        ev.stopPropagation()
        ev.preventDefault()
        history.push({pathname: `/message/${convoId}`});
    }

    return (
        <div id="applicant-table">
            <div className="application-table-head">
                <Row className="mx-0 w-100">
                    <Col lg={{span: 2}}>APPLICANT</Col>
                    <Col lg={{span: 2}}>DATE APPLIED</Col>
                    <Col lg={{span: 3}}>CONTACT INFO</Col>
                    <Col lg={{span: 2}}>Status</Col>
                    <Col lg={{span: 2}}>Messaging</Col>
                    <Col lg={{span: 1}} className="text-center">Actions</Col>
                </Row>
            </div>

            <div className="application-table-body">
                {loading ? <div className="spinner-wrapper">
                    <Spinner animation="border" className="spinner"/>
                </div> : applicantsInfo?.length > 0 ? (
                    applicantsInfo.map((data, index) => (
                        <Row
                            className="pt-td mx-0 w-100 applicant-table-tr"
                            onClick={(e) => {
                                const {id} = e.target
                                if (id !== 'interviewed' && id !== 'hired') {
                                    props.itemClicked(true, index)
                                }
                            }}
                            key={index}
                        >
                            <Col
                                lg={{span: 2}}
                                className="d-flex flex-row align-items-center"
                            >
                                <img
                                    src={data?.user?.seeker_profile_picture || generateNameInitialImg(data?.user?.seeker_full_name)}
                                    alt="Applicant Image"
                                    className="applicant-img"
                                />
                                <span className="applicant-name">
                  {data?.user?.seeker_full_name}
                </span>
                            </Col>
                            <Col
                                lg={{span: 2}}
                                className="d-flex flex-row align-items-center job-listing"
                            >
                <span style={{fontSize: "14px"}}>
                  {data?.user?.applied_date.split("T")[0]}
                </span>
                            </Col>
                            <Col
                                lg={{span: 3}}
                                className="d-flex flex-column justify-content-center job-listing"
                            >
                <span style={{
                    fontSize: "12px", width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }}>
                  <b>Email: </b>{data?.user?.basic_info?.email}
                </span>
                                <span style={{fontSize: "12px"}}>
                  <b>Phone Number: </b> {data?.user?.basic_info?.phone}
                </span>
                            </Col>
                            <Col
                                lg={{span: 2}}
                                className="d-flex flex-column justify-content-center applicant-status"
                            >
                                <div className="h-50 w-100 d-flex flex-row" style={{zIndex: '999'}}>
                                    <span style={{width: "60%"}}>Interviewed:</span>
                                    <Form.Check className="terms-checkbox" id="interviewed"
                                                checked={applicantStatus[data?.job?.application_id]?.includes("interviewed")}
                                                onChange={(event) => {
                                                    changeApplicantStatus(event, data?.job)
                                                }}/>
                                </div>
                                <div className="h-50 w-100 d-flex flex-row ">
                                    <span style={{width: "60%"}}>Hired:</span>
                                    <Form.Check className="terms-checkbox" id="hired"
                                                checked={applicantStatus[data?.job?.application_id]?.includes("hired")}
                                                onChange={($event) => {
                                                    changeApplicantStatus($event, data?.job)
                                                }}/>
                                </div>
                            </Col>

                            <Col
                                lg={{span: 2}}
                                className="d-flex flex-column justify-content-center job-listing"
                            >
                                {
                                    data?.user?.conversation_id ?
                                        (
                                            <span onClick={(ev) => goToMessagePage(ev, data?.user?.conversation_id)}
                                                  className={"go-to-message-link"}>Go to messages</span>)
                                        :
                                        (
                                            <span className={"go-to-message-link"} style={{color: '#A3CF62'}}
                                                  onClick={(e) => swipeRight(e, data?.user?.user_id, data?.job?.job_id)}>
                                                  Start conversation
                                              </span>
                                        )
                                }
                            </Col>

                            <Col lg={{span: 1}} className="d-flex flex-row job-listing align-items-center">
                                {/*<div className="w-100">*/}
                                {/*    {*/}
                                {/*        !sameMembers(data.job?.application_status, applicantStatus[data.job?.application_id]) ?*/}
                                {/*            <div*/}
                                {/*                className={"d-flex flex-row justify-content-center align-items-center"}>*/}
                                {/*                <FontAwesomeIcon color={"#A3CF62"} icon={faCheck}*/}
                                {/*                                 style={{fontSize: "20px"}}*/}
                                {/*                                 onClick={(event) => saveApplicantStatus(event, data?.job?.application_id)}/>*/}

                                {/*                <FontAwesomeIcon color={"#EE6C4D"} icon={faTrash}*/}
                                {/*                                 style={{fontSize: "20px", marginLeft: "20px"}}*/}
                                {/*                                 onClick={(event) => resetApplicantStatus(event, data?.job)}/>*/}
                                {/*            </div>*/}
                                {/*            :*/}
                                {/*            <></>*/}
                                {/*    }*/}
                                {/*</div>*/}

                                <Dropdown onClick={(e) => e.stopPropagation()}>
                                    <Dropdown.Toggle className="applicant-table-ellipsis">
                                        <FontAwesomeIcon icon={faEllipsisV} style={{color: "#2c2b7c"}}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className="applicant-dropdown-item"
                                                       onClick={() => handleShowHide(data?.job?.application_id, data?.job?.is_hidden)}
                                                       style={{height: "100%"}}>
                                            <FontAwesomeIcon icon={data?.job?.is_hidden ? faEye : faEyeSlash}
                                                             style={{color: "#2C2B7C"}}
                                                             className="mr-3fa"/>
                                            <span
                                                className="ml-3">{data?.job?.is_hidden ? "Unhide Applicant" : "Hide Applicant"}</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>

                            {/*<Col*/}
                            {/*  lg={{span: 1}}*/}
                            {/*  className="d-flex flex-row justify-content-center align-items-center"*/}
                            {/*>*/}
                            {/*  <FontAwesomeIcon*/}
                            {/*    className="message-icon"*/}
                            {/*    icon={faCommentAlt}*/}
                            {/*    style={{fontSize: "22px"}}*/}
                            {/*  />*/}
                            {/*</Col>*/}
                        </Row>
                    ))
                ) : (
                    <>
                        <div className="no-data" style={{height: '100%'}}> No data to display</div>
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    saveApplicantStatusDispatcher: (data) => dispatch(updateApplicantStatus(data)),
    hideApplicantDispatcher: (data) => dispatch(hideApplicant(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantTableComponent);
