const generateHex = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


const getInitials = (name) => {
    let initials;

    const nameSplit = name ? name.split(" ") : "";
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;
    return initials.toUpperCase();
};
export const generateNameInitialImg = (text, backgroundColor = null) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = 200;
    canvas.height = 200;
    context.fillStyle = backgroundColor ? backgroundColor : generateHex();
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "bold 80px Poppins Medium, sans-serif";
    context.fillStyle = 'white';
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(getInitials(text), canvas.width / 2, canvas.height / 2);
    return canvas.toDataURL("image/png");
}
