import types from "../types";
import * as billingService from "../../services/api/billingService";
import {meSuccess} from "./me";

export const postCardDataLoading = () => ({
  type: types.billingConstants.POST_CARD_DATA_LOADING,
});

const postCardDataSuccess = (data) => ({
  type: types.billingConstants.POST_CARD_DATA_SUCCESS,
  data,
});

const postCardDataFailed = (error) => ({
  type: types.billingConstants.POST_CARD_DATA_FAILED,
  error,
});

const getAllPricingPlansLoading = () => ({
  type: types.billingConstants.GET_PRICING_PLANS_LOADING,
});

const getAllPricingPlansError = (error) => ({
  type: types.billingConstants.GET_PRICING_PLANS_ERROR,
  error,
});

const getAllPricingPlansSuccess = (data) => ({
  type: types.billingConstants.GET_PRICING_PLANS_SUCCESS,
  data,
});

const postPricingPlanLoading = () => ({
  type: types.billingConstants.POST_PRICING_PLAN_LOADING
})

const postPricingPlanSuccess = (data) => ({
  type: types.billingConstants.POST_PRICING_PLAN_SUCCESS,
  data
})

const postPricingPlanFailed = (error) => ({
  type: types.billingConstants.POST_PRICING_PLAN_FAILED,
  error
})

const postBillingAndPlanLoading = () => ({
  type: types.billingConstants.POST_CARD_DATA_AND_PLAN_LOADING
})

const postBillingAndPlanSuccess = (data) => ({
  type: types.billingConstants.POST_CARD_DATA_AND_PLAN_SUCCESS,
  data
})

const postBillingAndPlanFailed = (error) => ({
  type: types.billingConstants.POST_CARD_DATA_AND_PLAN_FAILED,
  error
})
export const postPricingPlanId = (pricingPlanData, toast, meData, profile_completed_up_to = null) => {
  return async (dispatch) => {
    try {
      dispatch(postPricingPlanLoading());
      const response = await billingService.postPricingPlan(pricingPlanData)
      if (response && response.status === 200) {
        dispatch(postPricingPlanSuccess(response.data));
        toast(
          "Success",
          "success"
        );
        if (meData && profile_completed_up_to) {
          dispatch(meSuccess({
            ...meData,
            data: {
              ...meData.data,
              profile_completed_up_to: profile_completed_up_to
            }
          }))
        }
      }
    } catch (error) {
      dispatch(postPricingPlanFailed(error.message));
      toast(
        error.message,
        "error"
      );
    }
  };
}

export const postBillingAndPlanInfo = (data, meData) => {
  return async (dispatch) => {
    try {
      dispatch(postBillingAndPlanLoading());
      const response = await billingService.postCardAndPlanData(data);
      if (response && response.data) {
        dispatch(postBillingAndPlanSuccess(response.data));
        if (meData) {
          dispatch(meSuccess({
            ...meData,
            data: {
              ...meData.data,
              profile_completed_up_to: "pricing_plans",
              is_card_save: true
            }
          }))
        }
      }
    } catch (error) {
      dispatch(postBillingAndPlanFailed(error));
    }
  };
};

export const postBillingInfo = (data, meData) => {
  return async (dispatch) => {
    try {
      dispatch(postCardDataLoading());
      const response = await billingService.postCardData(data);
      if (response && response.data) {
        dispatch(postCardDataSuccess(response.data));
        if (meData) {
          dispatch(meSuccess({
            ...meData,
            data: {
              ...meData.data,
              is_card_save: true
            }
          }))
        }
      }
    } catch (error) {
      dispatch(postCardDataFailed(error));
    }
  };
};

export const getAllPricingPlans = () => {
  return async (dispatch) => {
    try {
      dispatch(getAllPricingPlansLoading());
      const response = await billingService.getPricingPlans();
      if (response && response.data && response.data.data) {
        dispatch(getAllPricingPlansSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getAllPricingPlansError(error.message));
    }
  };
};

export const getCurrentPricingPlan = () => {
  return async (dispatch) => {
    try {
      dispatch(getCurrentPricingPlanLoading());
      const response = await billingService.getBusinessPricingPlan();
      if (response && response.data) {
        dispatch(getCurrentPricingPlanSuccess(response.data.data ? response.data.data : response.data));
      }
    } catch (error) {
      dispatch(getCurrentPricingPlanFailed(error.message));
    }
  };
}

const getCurrentPricingPlanLoading = () => ({
  type: types.billingConstants.GET_BUSINESS_PRICING_PLAN_LOADING
})

const getCurrentPricingPlanSuccess = (data) => ({
  type: types.billingConstants.GET_BUSINESS_PRICING_PLAN_SUCCESS,
  data
})

const getCurrentPricingPlanFailed = (error) => ({
  type: types.billingConstants.GET_BUSINESS_PRICING_PLAN_FAILED,
  error
})

export const clearCurrentBusinessPlanState = () => ({
  type: types.billingConstants.CLEAR_CURRENT_BUSINESS_PRICING_PLAN
})
