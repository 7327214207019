import types from "../types";
import axiosInstance from "../../services/api/axios";
import {getHiringManagers} from "./addLocation";

export const addUsersLoading = () => ({
    type: types.manageUsersConstants.MANAGE_USERS_POST_LOADING,
});

const addUserSuccess = (data) => ({
    type: types.manageUsersConstants.MANAGE_USERS_POST_SUCCESS,
    data,
});

const addUserFailed = (error) => ({
    type: types.manageUsersConstants.MANAGE_USERS_POST_FAILED,
    error,
});


const getLocationLoading = () => ({
    type: types.manageUsersConstants.GET_LOCATIONS_LOADING,
});

const getLocationFailed = (error) => ({
    type: types.manageUsersConstants.GET_LOCATIONS_FAILED,
    error
});

const getLocationSuccess = (data) => ({
    type: types.manageUsersConstants.LOCATIONS,
    data
})

const getUsersLoading = () => ({
    type: types.manageUsersConstants.GET_USERS_LOADING,
})

const getUsersFailed = (error) => ({
    type: types.manageUsersConstants.GET_USERS_FAILED,
    error
})

const getUsersSuccess = (data) => ({
    type: types.manageUsersConstants.GET_USERS_SUCCESS,
    data
})

const deleteUsersLoading = () => ({
    type: types.manageUsersConstants.DELETE_USERS_LOADING
})

const deleteUsersFailed = (error) => ({
    type: types.manageUsersConstants.DELETE_USERS_FAILED,
    error
})

const deleteUsersSuccess = () => ({
    type: types.manageUsersConstants.DELETE_USERS_SUCCESS
})

export const clearGetLocationListError = () => ({
    type: types.manageUsersConstants.CLEAR_GET_LOCATION_LIST_ERROR

});

export const clearGetUserError = () => ({
    type: types.manageUsersConstants.CLEAR_GET_USER_ERROR
});

export const refreshState = () => ({
    type: types.manageUsersConstants.REFRESH_STATE,
});

export const resetDeleteUsers = () => ({
    type: types.manageUsersConstants.RESET_DELETE_USERS
})
export const getLocations = () => {
    return async (dispatch) => {
        try {
            dispatch(getLocationLoading());
            const response = await axiosInstance.get("business/get-locations-only-list/");
            if (response && response.status === 200 && response.data.data) {
                dispatch(getLocationSuccess(response.data.data));
            }
        } catch (err) {
            dispatch(getLocationFailed(err.message || "Get location failed"));
        }
    };
}

export const addUsers = (data) => {
    return async (dispatch) => {
        try {
            dispatch(addUsersLoading());
            const response = await axiosInstance.post(`business/organization-user/`, data);
            if (response && response.status === 200) {
                dispatch(addUserSuccess("Added User Successfully"));
            }
        } catch (err) {
            dispatch(addUserFailed(err.response.data.email || "Add user Failed"));
        }
    };
};

export const updateUserData = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch(addUsersLoading());
            const response = await axiosInstance.put(`business/organization-user/${id}/`, data);
            if (response && response.status === 200) {
                dispatch(addUserSuccess("User Edited Successfully"));
            }
        } catch (err) {
            dispatch(addUserFailed(err.response.data.email || "Edit user Failed"));
        }
    };
};

export const getUsers = () => {
    return async (dispatch) => {
        try {
            dispatch(getUsersLoading());
            const response = await axiosInstance.get("business/users/");
            if (response && response.status === 200 && response.data.data) {
                const managers = response.data.data.filter(
                    (obj) => obj.account_status !== "deleted"
                );
                dispatch(getUsersSuccess(managers));
            } else if (response && response.status === 200 && !response.data.data) {
                dispatch(getUsersSuccess([]))
            }
        } catch (err) {
            dispatch(getUsersFailed(err.message || "Could not fetch Users"));
        }
    };
};

export const deleteUserByID = (id) => {
    return async (dispatch) => {
        try {
            dispatch(deleteUsersLoading());
            const response = await axiosInstance.delete(`business/user/${id}/`);
            if (response && response.status === 200) {
                dispatch(deleteUsersSuccess(true));
                dispatch(getUsers())
                dispatch(getHiringManagers())
            }
        } catch (err) {
            dispatch(deleteUsersFailed(err.message || "Could not fetch Users"));
        }
    };
}
