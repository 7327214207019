import * as login from './login';
import * as forgotPassword from './forgotPassword';
import * as resetPassword from './resetPassword';
import * as createAccount from './createAccount';
import * as createJob from './createJob';
import * as updateJob from './updateJob';
import * as uploadImage from "./uploadImage";
import * as emailVerification from "./emailVerification";
import * as uploadBanner from "./uploadBanner";
import * as description from "./description";
import * as socialLinks from "./socialLinks";
import * as businessInfoConstants from "./businessInfo";
import * as photosAndVideos from "./photosAndVideos";
import * as addLocation from './addLocation';
import * as jobCategory from "./jobCategory";
import * as jobDetails from "./jobDetails";
import * as applicationType from "./applicationType";
import * as applicationQuestions from "./applicationQuestions";
import * as manageUsersConstants from "./manageUsers";
import * as me from "./me";
import * as creditCardConstants from "./billing";
import * as deleteMedia from "./deleteMedia";
import * as applicants from "./applicants";
import * as subscription from "./subscription";
import * as billingInfoConstants from "./billingInfo";
import {billingCardInfoConstants} from "./billingInfo";
import * as deactivationReasons from "./deactivationReasons";
import * as changePassword from "./changePassword";
import * as favourites from "./favourites";
import * as messagingConstants from "./messaging";

const combined = {
    ...login,
    ...forgotPassword,
    ...changePassword,
    ...resetPassword,
    ...createAccount,
    ...createJob,
    ...updateJob,
    ...uploadImage,
    ...emailVerification,
    ...uploadBanner,
    ...description,
    ...socialLinks,
    ...businessInfoConstants,
    ...photosAndVideos,
    ...addLocation,
    ...jobCategory,
    ...applicationType,
    ...applicationQuestions,
    ...manageUsersConstants,
    ...me,
    ...creditCardConstants,
    ...deleteMedia,
    ...jobDetails,
    ...applicants,
    ...subscription,
    ...billingInfoConstants,
    ...billingCardInfoConstants,
    ...deactivationReasons,
    ...favourites,
    ...messagingConstants
};

export default combined;
