import React, {useEffect, useState} from 'react'
import {Col, Container, Form, Row} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import './style.css'
import ButtonComponent from "../../../components/ButtonComponent";
import {Link, useHistory} from 'react-router-dom';
import {getUserDetail} from "../../../services/api/userService";
import {useSnackbar} from "notistack";
import * as verifyService from '../../../services/api/userVerificationService';
import {passwordValidation} from "../../../utils/utils";

const eye = <FontAwesomeIcon icon={faEye}/>;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash}/>;

const HiringManagerSignUpPage = (props) => {
  const uuid = props.match.params.id;
  const token = props.match.params.token;
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const [passwords, setPassword] = useState({password: "", confirmPassword: ""});
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [termsPoliciesAccepted, setTermsPoliciesAccepted] = useState({terms: false, policies: false});

  const onInputChange = (e) => {
    setPassword({...passwords, [e.target.id]: e.target.value});
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const hiringMangerForm = (e) => {
    e.preventDefault();
    if (termsPoliciesAccepted.terms && termsPoliciesAccepted.policies) {
      if (passwords.password && passwords.confirmPassword) {
        if (passwords.password !== passwords.confirmPassword) {
          return enqueueSnackbar('Password and Confirm Password doesnt match!', {variant: "error"});
        } else if (!passwordValidation(passwords.password)) {
          return enqueueSnackbar('Please enter a valid password. It must contain at least 8 character, one number, one letter and one special character', {variant: "error"});
        }
        verifyService.verifyEmail({
          type: 'register',
          uid: uuid,
          token: token,
          data: {password: passwords.password}
        }).then(resp => {
          enqueueSnackbar(resp?.data?.message, {variant: "success"});
          redirect();
        }, err => {
          // console.log(err);
          enqueueSnackbar("Server error!", {variant: "error"});
        });
      }
    } else {
      enqueueSnackbar("Please accept the terms and policies!", {variant: "error"});
    }
  }

  const redirect = () => {
    const timer = setTimeout(() => {
      history.push("/login")
    }, 3000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    if (uuid && token) {
      getUserDetail({uid: uuid, token: token}).then(response => {
        if (response) {
          setUserData(response?.data?.data);
        }
      }, err => {
        // console.log(err);
      })
    }
  }, []);

  return (
    <div className='containerMain'>
      <Container fluid className="HiringMangerSignUpContainer">
        <Row>
          <Col xs sm md lg xl="12" className='welcome_text'>
            <span>WELCOME, {userData?.basic_info?.first_name} {userData?.basic_info?.last_name}!</span>
          </Col>
        </Row>

        {/*<Row>*/}
        {/*  <Col xs sm md lg xl="12" className="ms-user-info">*/}
        {/*    {*/}
        {/*      userData?.assigned_locations?.length > 0*/}
        {/*        ? <span className="mr-2">{userData?.assigned_locations[0]} |</span>*/}
        {/*        : <></>*/}
        {/*    }*/}
        {/*    <span*/}
        {/*      className="mr-2">{userData?.account_type === 'hiring_manager' ? 'Hiring Manager' : 'Business Admin'} |</span>*/}
        {/*    <span>{userData?.assigned_locations?.length} locations</span>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Row>
          <Col xs sm md lg xl={{span: 5}} className="information">
            <span><b>Jipe</b> is here to help simplify your experience hiring new employees. We just need a little information to set up your account.</span>
          </Col>
        </Row>

        <div className="mt-4" style={{width: '300px'}}>
          <Form.Group controlId="password">
            <div className="password-input-wrapper">
              <Form.Control placeholder="password" type={passwordShown ? "text" : "password"} required
                            className="password-input-blue" style={{backgroundColor: 'transparent'}}
                            onChange={onInputChange}/>
              <i onClick={togglePasswordVisiblity} className="pass-toggle-icon">{passwordShown ? eyeSlash : eye}</i>
            </div>
            <Form.Control.Feedback>Password looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid password.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="confirmPassword">
            <div className="password-input-wrapper">
              <Form.Control placeholder="confirm password" type={confirmPasswordShown ? "text" : "password"} required
                            className="password-input-blue" style={{backgroundColor: 'transparent'}}
                            onChange={onInputChange}/>
              <i onClick={toggleConfirmPasswordVisiblity}
                 className="pass-toggle-icon">{confirmPasswordShown ? eyeSlash : eye}</i>
            </div>
            <Form.Control.Feedback type="invalid">
              Please provide the confirm password.
            </Form.Control.Feedback>
          </Form.Group>

          <div className="terms-and-policy-ms">
            <div className="signup-terms-link">
              <div style={{width: '30px', height: '100%'}}>
                <Form.Check className="terms-checkbox" id="custom"
                            onChange={($event) => setTermsPoliciesAccepted({
                              ...termsPoliciesAccepted,
                              terms: $event.currentTarget.checked
                            })}/>
              </div>
              <div style={{width: 'auto', height: '100%', marginTop: '3px'}}>
                <Link to={{pathname: "https://www.jipejobs.com/terms-of-use"}}
                      target="_blank"
                      style={{color: '#2c2b7c', textDecoration: 'underline'}}>
                  Terms of use
                </Link>
              </div>
            </div>

            <div className="signup-terms-link">
              <div style={{width: '30px', height: '100%'}}>
                <Form.Check className="terms-checkbox" id="custom"
                            onChange={($event) => setTermsPoliciesAccepted({
                              ...termsPoliciesAccepted,
                              policies: $event.currentTarget.checked
                            })}/>
              </div>
              <div style={{width: 'auto', height: '100%', marginTop: '3px'}}>
                <Link to={{pathname: "https://www.jipejobs.com/privacy-policy"}}
                      style={{textDecoration: 'underline', color: '#2c2b7c'}}
                      target="_blank">
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>

          <ButtonComponent idName="saveBtn" name='SUBMIT' onClickFunction={hiringMangerForm}/>
        </div>
      </Container>
    </div>
  )
}

export default HiringManagerSignUpPage;
