import React, {useEffect, useState} from "react";
import "./style.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import validator from "validator";
import {
  AddQuestionsComponent,
  ApplicationTypeComponent,
  BannerComponent,
  BusinessInfoComponent,
  DescriptionComponent,
  JobCategoryComponent,
  LogoComponent,
  PhotoVideoComponent,
  SocialComponent,
} from "../../signUpComponents";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import ButtonComponent from "../../../../../components/ButtonComponent";
import {ReactComponent as MonkeySuccess} from "../../../../../assets/success-monkey.svg";
import {
  postImage,
  setDescription,
  setDescriptionLoading,
  setJobCategories,
  setJobCategoryLoading,
} from "../../../../../redux/actions";

import * as setBannerImageActions from "../../../../../redux/actions/setBannerImageAction";
import {setBannerImage, setBannerImageLoading} from "../../../../../redux/actions/setBannerImageAction";
import * as meActions from "../../../../../redux/actions/me";
import * as deleteMediaActions from "../../../../../redux/actions/deleteMedia";
import * as setProfilePictureActions from "../../../../../redux/actions/setProfilePictureAction";
import * as setPhotosAndVideosActions from "../../../../../redux/actions/setPhotosAndVideosAction";
import {setPhotoVideoLoading} from "../../../../../redux/actions/setPhotosAndVideosAction";
import {setSocialLinks, setSocialLinksLoading,} from "../../../../../redux/actions/setSocialLinksAction";
import {setBusinessInfo} from "../../../../../redux/actions/setBusinessInfoAction";
import {updateStepperCompleteStatus} from "../../../../../redux/actions/updateStepperCompleteStatus";
import {checkPhone, checkZip, profileCompletedUpToDS} from "../../../../../utils/utils";
import MapComponent from '../../../../../components/MapComponent';

const initialPhotoVideosState = {
  media1: null,
  media2: null,
  media3: null,
  media4: null,
  media5: null,
  media6: null,
}

const Stepper = (props) => {
  let history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const {
    setBanner,
    setBannerImageLoading,
    setSocialLinks,
    setSocialLinksLoading,
    setDescriptionLoading,
    setDescription,
    setPhotosVidoes,
    setPhotoVideoLoading,
    setJobCategoryLoadingAction,
    setJobCategory,
    updateStepperCompleteStatus,
    setPreviousProfilePicture,
    setPreviousBannerPicture,
    setPreviousPhotosAndVideos,
    deleteMedia,
    applicationType
  } = props;

  const {
    description,
    descriptionError,
    descriptionLoading,
    social,
    socialError,
    socialLoading,
    photoVideo,
    photoVideoError,
    photoVideoLoading,
    jobCategory,
    jobCategoryLoading,
    jobCategoryError,
    bannerError,
    bannerPicture,
    bannerLoading,
    meData,
    deleteMediaResponse,
    removeDeletedMedia,
  } = props;
  const {businessInfoState, businessInfoDispatcher} = props;
  const [nextClicked, setNextClicked] = useState(false);

  const [state, setState] = useState({
    stepperActivePage: 1,
    stepperNavigation: false,
  });

  // for map view
  const [showMap, setShowMap] = useState(false);

  //Business Info
  const [businessInfoErrors, setBusinessInfoErrors] = useState({});
  const [businessInfoData, setBusinessInfoData] = useState({
    business_name: "",
    business_location_name: "",
    business_phone: "",
    business_street: "",
    business_street_alt: "",
    business_city: "",
    business_state: "",
    business_zip: "",
    lat: "",
    lng: "",
  });

  //description
  const [descriptionData, setDescriptionData] = useState("");

  // socialURL
  const [socialData, setSocialData] = useState({
    company_url: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    share_social_url: true
  });
  const [socialErrors, setSocialErrors] = useState({})
  //Photo video
  const [photosAndVideos, setPhotosAndVideos] = useState(initialPhotoVideosState);
  const [showImageCropper, setShowImageCropper] = useState(false)
  const [src, setSrc] = useState(null)
  const [currentId, setCurrentId] = useState(null)

  //Job Category
  const [jobCategorySelected, setJobCategorySelected] = useState([]);

  // Application Type
  const [selectedApplicationTypeOption, setSelectedApplicationTypeOption] = useState([]);

  //banner
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);

  useEffect(() => {
    try {
      const {activeStepperIndex} = props.props.location.state
      if (activeStepperIndex) {
        setState({
          ...state,
          stepperActivePage: activeStepperIndex
        })
      } else {
        setState({
          ...state,
          stepperActivePage: 1
        })
      }
    } catch (err) {
      // pass
      setState({
        ...state,
        stepperActivePage: 1
      })
    }
  }, [])

  useEffect(() => {
    if (!meData.loading) {
      if (meData.error) {
        if (meData.error?.response?.status === 403) {
          history.push("/login")
        } else {
          if (meData.error?.response?.status === 500) {
            enqueueSnackbar(meData?.error?.response?.statusText || "Internal Server Error. Couldn't fetch your data.", {
              variant: 'error',
            });
          } else {
            try {
              Object.values(meData.error?.response?.data).map((err) => {
                enqueueSnackbar(err, {
                  variant: 'error',
                });
              })
            } catch (err) {
              enqueueSnackbar('Error loading previous information. Please try again later', {
                variant: 'error',
              });
            }
          }
        }
      } else {
        if (meData.meResponse) {
          /*
            Uncomment the if else condition below before release as it has commented out for testing purpose currently
          */
          if (meData?.meResponse?.data?.is_profile_completed) {
            // profile is completed
            history.push("/location-manager");
          } else {
            // setting stepper to updated value (if updated)
            const profileCompletedUpToIndex = profileCompletedUpToDS.indexOf(profileCompletedUpToDS.find(status => status.includes(meData.meResponse.data.profile_completed_up_to)))
            const pushUserTo = profileCompletedUpToDS[profileCompletedUpToIndex + 1].split("&&")[0].split("_")
            if (profileCompletedUpToIndex !== 9) {
              if (parseInt(pushUserTo[1]) > state.stepperActivePage) {
                setState({
                  ...state,
                  stepperActivePage: parseInt(pushUserTo[1])
                })
              }
            } else {
              setState({
                ...state,
                stepperActivePage: 10
              })
            }


            // setting previous business info data to enable edit and view
            setBusinessInfoData({
              business_name: meData?.meResponse?.data?.basic_info?.business_name || "",
              business_location_name: meData?.meResponse?.data?.business_locations[0]?.name || "",
              business_phone: meData?.meResponse?.data?.business_locations[0]?.phone || "",
              business_street: meData?.meResponse?.data?.business_locations[0]?.street || "",
              business_street_alt: meData?.meResponse?.data?.business_locations[0]?.street_alt || "",
              business_city: meData?.meResponse?.data?.business_locations[0]?.city || "",
              business_state: meData?.meResponse?.data?.business_locations[0]?.state || "",
              business_zip: meData?.meResponse?.data?.business_locations[0]?.zip || "",
              lat: meData?.meResponse?.data?.business_locations[0]?.lat || "",
              lng: meData?.meResponse?.data?.business_locations[0]?.lng || "",
            })

            // setting previous business logo data to enable edit and view
            setPreviousProfilePicture(meData?.meResponse?.data?.business_logo_url || "")

            // setting previous banner image data to enable edit and view
            try {
              // const bannerPictureIndex = profileCompletedUpToDS.indexOf(profileCompletedUpToDS.find(status => status.includes("banner_picture")))
              // if (meData?.meResponse?.data?.is_profile_completed || profileCompletedUpToIndex >= bannerPictureIndex) {
              if (meData?.meResponse?.data?.banner_picture_url) {
                setPreviousBannerPicture(meData?.meResponse?.data?.banner_picture_url)
                setSelectedOption("custom")
              } else {
                setSelectedOption("jipe")
              }
              // }
            } catch (err) {
              // pass
            }

            // setting previous description to enable edit and view
            setDescriptionData(meData?.meResponse?.data?.about_you || "")

            // setting previous social data to enable edit and view
            setSocialData({
              company_url: meData?.meResponse?.data?.social_urls?.company_url || "",
              facebook_url: meData?.meResponse?.data?.social_urls?.facebook_url || "",
              twitter_url: meData?.meResponse?.data?.social_urls?.twitter_url || "",
              instagram_url: meData?.meResponse?.data?.social_urls?.instagram_url || "",
              youtube_url: meData?.meResponse?.data?.social_urls?.youtube_url || "",
              share_social_url: true
            })

            // setting previous photos and videos to enable edit and view
            setPreviousPhotosAndVideos(meData?.meResponse?.data?.media || "")

            // setting previous photos and videos to enable edit and view
            setPreviousPhotosAndVideos(meData?.meResponse?.data?.media || "")

            // setting previous job category to enable edit and view
            setJobCategorySelected(meData?.meResponse?.data?.industries.length > 0 ? meData?.meResponse?.data?.industries.map(category => category.name) : [])

            // setting previous application type to enable edit and view
            setSelectedApplicationTypeOption(meData?.meResponse?.data?.application_type.length > 0 ? [meData?.meResponse?.data?.application_type] : [])

          }
        }
      }
    }
  }, [meData])

  const checkBoxToggled = (event) => {
    setSelectedOption(event.target.id);
  };

  const handleBannerSubmit = () => {
    if (selectedOption !== "custom") {
      setBannerImageLoading()
      setBanner(null, showNotification)
    }
  }

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }

  useEffect(() => {
    if (nextClicked && bannerPicture && !bannerLoading && !bannerError) {
      handleNextClick();
    } else if (nextClicked && !bannerPicture && !bannerLoading && bannerError) {
    }
  }, [bannerError, bannerPicture, bannerLoading])

  //Business Info
  const businessFormValues = (e) => {
    setBusinessInfoErrors({})
    setBusinessInfoData({
      ...businessInfoData,
      [e?.target?.id]: e?.target?.value,
    });
  };

  const formValidity = (data) => {
    enableNavigation(data);
  };

  const handleBusinessInfoSubmit = () => {
    const validationErr = {};
    if (businessInfoData.business_zip) {
      if (!checkZip(businessInfoData.business_zip)) {
        validationErr.zip = 'Invalid zip code.'
      }
    } else {
      validationErr.zip = 'Please enter zip code.'
    }

    if (!businessInfoData.business_state) {
      validationErr.state = 'Please select a state'
    }

    if (!businessInfoData.business_name) {
      validationErr.business_name = 'Please enter a business name.'
    }

    if (!businessInfoData.business_street) {
      validationErr.street = 'Please enter a street.'
    }

    if (businessInfoData.business_phone) {
      if (!checkPhone(businessInfoData.business_phone)) {
        validationErr.business_phone = 'Please enter a valid phone number.'
      }
    } else {
      validationErr.business_phone = 'Please enter a phone number.'
    }

    if (Object.keys(validationErr).length > 0) {
      setBusinessInfoErrors(validationErr);
      if (validationErr.state) {
        enqueueSnackbar(validationErr.state, {
          variant: "error",
        })
      }
    } else {
      if (state.stepperNavigation) {
        const businessInfoObject = {
          business_name: businessInfoData.business_name,
          address: {
            street: businessInfoData.business_street,
            street_alt: businessInfoData.business_street_alt,
            city: businessInfoData.business_city,
            state: businessInfoData.business_state,
            zip: businessInfoData.business_zip,
            phone: businessInfoData.business_phone,
            name: businessInfoData.business_location_name,
            lat: businessInfoData.lat,
            lng: businessInfoData.lng
          },
        };
        if (businessInfoData.lat && businessInfoData.lng) {
          if (!businessInfoState.loading) {
            businessInfoDispatcher(businessInfoObject, meData.meResponse);
          }
        } else {
          enqueueSnackbar("Please verify your coordinates.", {
            variant: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (
      businessInfoState.error &&
      !businessInfoState.loading &&
      !businessInfoState.businessInfo
    ) {
      if (businessInfoState?.error?.response?.status === 500) {
        enqueueSnackbar("Internal Server Error", {
          variant: "error",
        })
      } else {
        Object.values(businessInfoState.error.response.data).map((err) => {
            enqueueSnackbar(err, {
              variant: "error",
            })
          }
        );
      }
    } else if (
      !businessInfoState.error &&
      businessInfoState.businessInfo &&
      !businessInfoState.loading
    ) {
      enqueueSnackbar(businessInfoState.businessInfo.message, {
        variant: "success",
      });
      changePage();
    }
  }, [businessInfoState]);

  //description
  const handleDescriptionChange = (e) => {
    if (e) {
      setDescriptionData(e.target.value);
    }
  };

  const handleDescriptionSubmit = () => {
    setDescriptionLoading();
    setDescription(descriptionData, meData.meResponse, showNotification);
  };

  useEffect(() => {
    if (nextClicked && descriptionError && !descriptionLoading) {
      // message is handled from action. Add other error handling for description here
    }
    if (nextClicked && description && !descriptionLoading && !descriptionError) {
      handleNextClick();
    }
  }, [descriptionError, descriptionLoading, description]);

  // socialURL
  const handleSocialDataChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSocialData({...socialData, [name]: value});
  };

  const handleSocialSubmit = () => {
    if (!validateSocialData()) {
      return;
    } else {
      setSocialLinksLoading();
      setSocialLinks(socialData, meData.meResponse, showNotification, "social_urls");
    }
  };

  useEffect(() => {
    if (nextClicked && social && !socialError && !socialLoading) {
      handleNextClick();
    } else if (socialError && !socialLoading && nextClicked && !social) {
    }
  }, [social, socialError, socialLoading]);

  const validateSocialData = () => {
    const errors = {};
    const fbRegex = new RegExp(
      "(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?"
    );
    // const twitterRegex = new RegExp("http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)");
    // const twitterUserName = new RegExp("(?<=^|(?<=[^a-zA-Z0-9-_\.]))@([A-Za-z]+[A-Za-z0-9-_]+)");
    const youtubeRegex = new RegExp(
      "~(?:https?://)?(?:www.)?youtu(?:be.com/watch?(?:.*?&(?:amp;)?)?v=|.be/)([w-]+)(?:&(?:amp;)?[w?=]*)?~"
    );
    if (socialData.company_url && !validator.isURL(socialData.company_url)) {
      errors.company_url = "Invalid Company URL";
      enqueueSnackbar(errors.company_url, {
        variant: "error",
      });
    }
    // if (
    //   socialData.facebook_url &&
    //   (!validator.isURL(socialData.facebook_url) ||
    //     !fbRegex.test(socialData.facebook_url))
    // ) {
    //   errors.facebook_url = "Invalid Facebook URL";
    //   enqueueSnackbar(errors.facebook_url, {
    //     variant: "error",
    //   });
    // }
    if (socialData.twitter_url && socialData.twitter_url[0] === "@") {
      errors.twitter_url = "Twitter handle does not require @. Please remove it";
      enqueueSnackbar(errors.twitter_url, {
        variant: "error",
      });
    }

    if (socialData.instagram_url && socialData.instagram_url[0] === "@") {
      errors.instagram_url = "Instagram handle does not require @. Please remove it";
      enqueueSnackbar(errors.instagram_url, {
        variant: "error",
      });
    }
    // if (socialData.twitterURL && (!twitterUserName.test(socialData.twitterURL) && !twitterRegex.test(socialData.twitterURL)) ) {
    //   errors.twitterURL = "Invalid Twitter URL";
    // }
    // if (socialData.instagramURL && !validator.isURL(socialData.instagramURL)) {
    //   errors.instagramURL = "Invalid Instagram URL";
    // }
    if (
      socialData.youtube_url &&
      !validator.isURL(socialData.youtube_url) &&
      !youtubeRegex.test(socialData.youtube_url)
    ) {
      errors.youtube_url = "Invalid Youtube URL";
      enqueueSnackbar(errors.youtube_url, {
        variant: "error",
      });
    }
    setSocialErrors(errors)
    return Object.keys(errors).length === 0;
  };

  /////Photo video
  const removeImage = (id) => {
    if (photoVideo.length >= parseInt(id.slice(-1))) {
      // call delete api and remove the image at parseInt(id.slice(-1))-1 index of the redux state if delete api is successfull
      try {
        if (photoVideo[parseInt(id.slice(-1)) - 1]) {
          deleteMedia(photoVideo[parseInt(id.slice(-1)) - 1]?.id)
        }
      } catch (err) {
        // console.log("error removing media from redux state = ", err)
      }
    } else {
      setPhotosAndVideos({...photosAndVideos, [id]: null});
    }
  };

  // to remove the specific media from redux state after delete media is successful
  useEffect(() => {
    if (!deleteMediaResponse.loading) {
      if (deleteMediaResponse.error) {
        // handle delete media error here
        Object.values(deleteMediaResponse?.error?.response?.data).map((err) => {
          enqueueSnackbar(err, {
            variant: "error",
          });
        })
      } else {
        if (deleteMediaResponse.response) {
          enqueueSnackbar(deleteMediaResponse?.response?.message || "Media Deleted Successfully", {
            variant: "success"
          });
          // remove the deleted media from redux state
          if (deleteMediaResponse?.response?.data?.media_id) {
            removeDeletedMedia(deleteMediaResponse?.response?.data?.media_id)
          }
        }
      }
    }
  }, [deleteMediaResponse])

  const onPhotoVideoChange = (event, id) => {
    const selectedFile = event?.target?.files[0];
    if (selectedFile?.type?.includes("video")) {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => {
          if (media.duration < 61) {
            setPhotosAndVideos({...photosAndVideos, [id]: selectedFile});
          } else {
            alert("Video length too big, must be under a minute!");
          }
        };
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setCurrentId(id)
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSrc(reader.result)
      );
      reader.readAsDataURL(event.target.files[0]);
      setShowImageCropper(true)
    }
  };

  const setCroppedImage = (croppedImage) => {
    setPhotosAndVideos({...photosAndVideos, [currentId]: croppedImage});
  }

  const handlePhotoVideoSubmit = () => {
    if (Object.values(photosAndVideos).find((a) => a !== null)) {
      setPhotoVideoLoading();
      setPhotosVidoes(photosAndVideos, showNotification, meData.meResponse, "media");
    } else {
      changePage();
    }
  };

  useEffect(() => {
    if (nextClicked && photoVideo && !photoVideoError && !photoVideoLoading) {
      setPhotosAndVideos(initialPhotoVideosState)
      handleNextClick();
    } else if (
      nextClicked &&
      photoVideoError !== "" &&
      !photoVideoLoading
    ) {
      // notification triggered from action. Add any other error handling activities here if required
    }
  }, [photoVideo, photoVideoError, photoVideoLoading]);

  //Job Category
  const jobCategoryCheckBoxToggled = (event) => {
    if (jobCategorySelected.includes(event.target.id)) {
      const optionData = jobCategorySelected;
      const index = optionData.indexOf(event.target.id);
      optionData.splice(index, 1);
      setJobCategorySelected([...optionData]);
    } else {
      setJobCategorySelected([...jobCategorySelected, event.target.id]);
    }
  };

  const handleJobDescriptionSubmit = () => {
    setJobCategoryLoadingAction();
    setJobCategory(jobCategorySelected, meData.meResponse, showNotification, "industries");
  };

  useEffect(() => {
    if (!jobCategoryError && !jobCategoryLoading && jobCategory) {
      handleNextClick();
    } else if (jobCategoryError && !jobCategoryLoading && !jobCategory) {
      // error message is handled from actions so add any other error handling cases here
    }
  }, [jobCategory, jobCategoryLoading, jobCategoryError]);

  //Stepper functions
  const enableNavigation = (val) => {
    setState({...state, stepperNavigation: val});
  };

  const changeNextClicked = () => {
    setNextClicked(!nextClicked);
  };

  const handleNextClick = () => {
    if (state.stepperActivePage === 7) {
      setNextClicked(true);
      if (
        nextClicked &&
        jobCategory &&
        !jobCategoryError &&
        !jobCategoryLoading
      ) {
        changePage();
        setNextClicked(false);
      }
    } else if (state.stepperActivePage === 6) {
      setNextClicked(true);
      if (nextClicked && photoVideo && !photoVideoError && !photoVideoLoading) {
        setNextClicked(false);
        changePage();
      }
    } else if (state.stepperActivePage === 5) {
      setNextClicked(true);
      if (nextClicked && social && !socialError && !socialLoading && Object.keys(socialErrors).length < 1) {
        setNextClicked(false);
        changePage();
      }
    } else if (state.stepperActivePage === 4) {
      setNextClicked(true);
      if (
        nextClicked &&
        description &&
        !descriptionError &&
        !descriptionLoading
      ) {
        setNextClicked(false);
        changePage();
      }
    } else if (state.stepperActivePage === 3 && selectedOption !== "custom") {
      // logic for jipe banner here !!!
      setNextClicked(true);
      if (nextClicked && bannerPicture && !bannerLoading && !bannerError) {
        setNextClicked(false);
        changePage();
      }
    } else if (state.stepperActivePage === 2) {
      changePage();
      setNextClicked(false)
    } else if (state.stepperActivePage === 8 || state.stepperActivePage === 9 || state.stepperActivePage === 1) {
      setNextClicked(true);
    } else {
      changePage();
    }
  };

  const addPaymentRedirect = () => {
    if (selectedApplicationTypeOption[0] === 'jipe_application') {
      // in this case, the profile_completed_up_to has already been updated to application_type which now needs to be updated to questions
      updateStepperCompleteStatus(meData.meResponse, "questions")
    } else {
      // this is when application_type is selected online_application so we skip the questions and now update the application_type to online_application and profile_completed_up_to to application_type
      updateStepperCompleteStatus(meData.meResponse, "application_type")
    }
    // history.push("/billing")
  }

  const changePage = () => {
    if (state.stepperActivePage === 8 && applicationType === 'online_application') {
      setState({
        stepperActivePage: state.stepperActivePage + 2,
        stepperNavigation: false,
      });
    } else if (state.stepperActivePage <= 9 && state.stepperNavigation) {
      setState({
        stepperActivePage: state.stepperActivePage + 1,
        stepperNavigation: false,
      });
    }
  };

  const handleBackClick = () => {
    if (state.stepperActivePage === 10 && applicationType === 'online_application') {
      setState({...state, stepperActivePage: state.stepperActivePage - 2});
    } else if (state.stepperActivePage > 1) {
      setState({...state, stepperActivePage: state.stepperActivePage - 1});
    }
  };

  const doSubmit = () => {
    switch (state.stepperActivePage) {
      case 1:
        return handleBusinessInfoSubmit();
      case 3:
        return handleBannerSubmit();
      case 4:
        return handleDescriptionSubmit();
      case 5:
        return handleSocialSubmit();
      case 6:
        return handlePhotoVideoSubmit();
      case 7:
        return handleJobDescriptionSubmit();
      default:
        return null;
    }
  };

  const activePage = () => {
    if (state.stepperActivePage === 1) {
      return (
        <BusinessInfoComponent
          navigationState={(val) => enableNavigation(val)}
          onChange={businessFormValues}
          isValid={formValidity}
          businessInfoData={businessInfoData}
          setBusinessInfoData={setBusinessInfoData}
          formErrors={businessInfoErrors}
          showMap={() => setShowMap(true)}
        />
      );
    } else if (state.stepperActivePage === 2) {
      return <LogoComponent navigationState={(val) => enableNavigation(val)} meData={meData.meResponse}/>;
    } else if (state.stepperActivePage === 3) {
      return (
        <BannerComponent
          navigationState={(val) => enableNavigation(val)}
          nextClicked={nextClicked}
          selectedOption={selectedOption}
          selectedLogo={selectedLogo}
          setSelectedLogo={setSelectedLogo}
          setSelectedOption={setSelectedOption}
          checkBoxToggled={checkBoxToggled}
          meData={meData.meResponse}
        />
      );
    } else if (state.stepperActivePage === 4) {
      return (
        <DescriptionComponent
          value={descriptionData}
          navigationState={(val) => enableNavigation(val)}
          onChange={(e) => handleDescriptionChange(e)}
        />
      );
    } else if (state.stepperActivePage === 5) {
      return (
        <SocialComponent
          navigationState={(val) => enableNavigation(val)}
          handleChange={(e) => handleSocialDataChange(e)}
          data={socialData}
        />
      );
    } else if (state.stepperActivePage === 6) {
      return (
        <PhotoVideoComponent
          navigationState={(val) => enableNavigation(val)}
          onFileChange={onPhotoVideoChange}
          removeImage={removeImage}
          photosAndVideos={photosAndVideos}
          setPhotosAndVideos={setPhotosAndVideos}
          loading={photoVideoLoading}
          photoVideo={photoVideo}
          setCroppedImage={setCroppedImage}
          showImageCropper={showImageCropper}
          setShowImageCropper={setShowImageCropper}
          src={src}
        />
      );
    } else if (state.stepperActivePage === 7) {
      return (
        <JobCategoryComponent
          selectedOptions={jobCategorySelected}
          checkBoxToggled={jobCategoryCheckBoxToggled}
          navigationState={(val) => enableNavigation(val)}
        />
      );
    } else if (state.stepperActivePage === 8) {
      return (
        <ApplicationTypeComponent
          nextClicked={nextClicked}
          navigationState={(val) => enableNavigation(val)}
          selectedOption={selectedApplicationTypeOption}
          setSelectedOption={setSelectedApplicationTypeOption}
          changeNextClicked={() => changeNextClicked()}
          changePage={() => changePage()}
          meData={meData.meResponse}
          showNotification={showNotification}
        />
      );
    } else if (state.stepperActivePage === 9) {
      if (applicationType === 'online_application') {
        changePage();
      } else {
        return (
          <AddQuestionsComponent
            nextClicked={nextClicked}
            navigationState={(val) => enableNavigation(val)}
            changeNextClicked={() => changeNextClicked()}
            changePage={() => changePage()}
            meData={meData.meResponse}
          />
        );
      }
    }
  };

  return (
    <>
      {state.stepperActivePage !== 10 ? (
        <div className="stepper-card">
          <div className="stepper-main-body">
            <div className="h-100 w-100">{activePage()}</div>
          </div>

          <div className="stepper-footer">
            <div
              className={
                "stepper-btn-div" +
                (state.stepperActivePage === 1 ? " disabled-page-btns" : "")
              }
              onClick={handleBackClick}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                style={{color: "#2C2B7C"}}
              />
              <span style={{marginLeft: "5px"}}>back</span>
            </div>

            <div className="stepper-indicator-wrapper">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, key) => (
                <div
                  className={
                    "stepper-page-indicator" +
                    (state.stepperActivePage === item
                      ? " active-stepper-indicator"
                      : "")
                  }
                  key={key}
                />
              ))}
            </div>

            <div
              className={
                "stepper-btn-div" +
                (!state.stepperNavigation ? " disabled-page-btns" : "")
              }
              onClick={() => {
                if (state.stepperNavigation) {
                  doSubmit();
                  handleNextClick();
                }
              }}
            >
              <span style={{marginRight: "5px"}}>next</span>
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{color: "#2C2B7C"}}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="success-title">Success!</div>
          <div
            style={{width: "350px"}}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="success-info">
              Your business profile is now set up. You can preview and edit it
              shortly. The next step is to set up your payment information. The
              final step will be to post a job!
            </div>

            <MonkeySuccess className="mt-2"/>

            <ButtonComponent
              idName="add-payment-btn"
              name="ADD PAYMENT"
              onClickFunction={() => addPaymentRedirect()}
            />

            <ButtonComponent
              type="link"
              idName="signup-back-btn"
              name="< back"
              onClickFunction={handleBackClick}
            />
          </div>
        </div>
      )}

      <MapComponent show={showMap} hide={() => setShowMap(false)} openLocationModal={() => 0}
                    setNewLocationData={setBusinessInfoData} newLocationData={businessInfoData}/>
    </>
  );
};

const mapStateToProps = (state) => ({
  bannerPicture: state.setBanner.bannerImage,
  bannerLoading: state.setBanner.loading,
  bannerError: state.setBanner.error,
  descriptionError: state.setDescription.error,
  descriptionLoading: state.setDescription.loading,
  description: state.setDescription.description,
  social: state.setSocialLinks.links,
  socialLoading: state.setSocialLinks.loading,
  socialError: state.setSocialLinks.error,
  photoVideo: state.setPhotoVideo.media,
  photoVideoError: state.setPhotoVideo.error,
  photoVideoLoading: state.setPhotoVideo.loading,
  businessInfoState: state.setBusinessInfo,
  jobCategory: state.jobCategory.postData.data,
  jobCategoryLoading: state.jobCategory.postData.loading,
  jobCategoryError: state.jobCategory.postData.error,
  meData: state.me,
  deleteMediaResponse: state.deleteMedia,
  applicationType: state.applicationTypeData.applicationType.data,
});

const mapDispatchToProps = (dispatch) => ({
  setBanner: (data, showNotification) => dispatch(setBannerImage(data, showNotification)),
  setBannerImageLoading: () => dispatch(setBannerImageLoading()),
  setDescriptionLoading: () => dispatch(setDescriptionLoading()),
  setDescription: (descriptionData, meData, showNotification) => dispatch(setDescription(descriptionData, meData, showNotification, "about_you")),
  setSocialLinksLoading: () => dispatch(setSocialLinksLoading()),
  setSocialLinks: (socialData, meData, showNotification, profile_completed_up_to) => dispatch(setSocialLinks(socialData, meData, showNotification, profile_completed_up_to)),
  businessInfoDispatcher: (data, meData) => dispatch(setBusinessInfo(data, meData, "", "basic_info")),
  setPhotosVidoes: (data, showNotification, meData, profile_completed_up_to) => dispatch(postImage(data, "photosAndVideos", showNotification, meData, profile_completed_up_to)),
  setPhotoVideoLoading: () => dispatch(setPhotoVideoLoading()),
  setJobCategoryLoadingAction: () => dispatch(setJobCategoryLoading()),
  setJobCategory: (data, meData, showNotification, profile_completed_up_to) => dispatch(setJobCategories(data, meData, showNotification, profile_completed_up_to)),
  getMe: () => dispatch(meActions.me()),
  setPreviousProfilePicture: (profile_picture_url) => dispatch(setProfilePictureActions.setPreviousProfilePicture(profile_picture_url)),
  setPreviousBannerPicture: (banner_picture_url) => dispatch(setBannerImageActions.setPreviousBannerPicture(banner_picture_url)),
  setPreviousPhotosAndVideos: (media) => dispatch(setPhotosAndVideosActions.setPreviousPhotosAndVideos(media)),
  deleteMedia: (mediaId) => dispatch(deleteMediaActions.deleteMedia(mediaId)),
  removeDeletedMedia: (mediaId) => dispatch(setPhotosAndVideosActions.removeDeletedMedia(mediaId)),
  updateStepperCompleteStatus: (meData, profile_completed_up_to) => dispatch(updateStepperCompleteStatus(meData, profile_completed_up_to)),
});

export const StepperComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Stepper);
