import React, {useEffect, useState} from 'react'
import {Button, Card, Form, Image} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import validator from 'validator';
import {useSnackbar} from "notistack";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import ButtonComponent from '../../../components/ButtonComponent';
import * as loginActions from '../../../redux/actions/login';
import {onLogout} from '../../../redux/actions/login';
import logo from '../../../assets/jipe-forbusiness-wg.svg'
import './login.css';
import localStorageService from "../../../utils/LocalStorageService";

const eye = <FontAwesomeIcon icon={faEye}/>;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash}/>;

export const LoginPage = (props) => {
    const {do_login, login, onLogout} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({})
    const [loginForm, setLoginForm] = useState({
        email: "",
        password: "",
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const history = useHistory();

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const allErrors = {};
        if (!loginForm.email) {
            allErrors.email = "Please enter your email"
        } else {
            if (!validator.isEmail(loginForm.email)) {
                allErrors.email = "Invalid email address"
            }
        }

        if (Object.keys(allErrors).length > 0) {
            setErrors(allErrors);
            setValidated(false);
        } else {
            setValidated(true);
            do_login(loginForm);
        }
    };

    useEffect(() => {
        if (!login.loading) {
            if (login.error) {
                try {
                    Object.values(login?.error?.response?.data).map((err) => {
                        enqueueSnackbar(err, {
                            variant: 'error',
                        });
                    })
                } catch (err) {
                    enqueueSnackbar("Network Error. Please check your connection", {
                        variant: 'error',
                    });
                }
                setValidated(false)
            } else {
                if (login.user) {
                    if (login.user?.account_type === 'user') {
                        enqueueSnackbar('Invalid credentials!', {
                            variant: 'error',
                        });
                        localStorageService.clearToken()
                        onLogout()
                    }
                }
            }
        }
    }, [login]);

    const handleChange = (e) => {
        if (Object.keys(errors).length > 0) {
            setErrors({})
            setValidated(false)
        }
        setLoginForm({...loginForm, [e.target.name]: e.target.value});
    };

    const redirectForgotPass = () => {
        history.push("/forgot-password");
    };

    return (
        <div className="main-container">
            <div className="containerRow">
                <div className="logoCol">
                    <Image src={logo} alt="user" className="mainLogo"/>
                </div>

                <div className="loginRow">
                    <Card className="login-card">
                        <div>
                            <Card.Title className="login-title">
                                <b>LOGIN</b>
                            </Card.Title>
                            <Card.Body>
                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={(event) => handleSubmit(event)}
                                    id="loginForm"
                                >
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control
                                            type="email"
                                            required
                                            placeholder="Email"
                                            name="email"
                                            className="jipe-input-blue"
                                            onChange={(e) => handleChange(e)}
                                            isInvalid={errors.email}
                                            value={loginForm.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email!
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <div className="password-input-wrapper">
                                            <Form.Control
                                                type={passwordShown ? "text" : "password"}
                                                placeholder="Password"
                                                name="password"
                                                required
                                                className="password-input-blue"
                                                onChange={(e) => handleChange(e)}
                                                value={loginForm.password}
                                            />
                                            <i onClick={togglePasswordVisiblity}
                                               className="pass-toggle-icon">{passwordShown ? eye : eyeSlash}</i>
                                        </div>
                                        <Button
                                            variant="link"
                                            id="forgotLink"
                                            className="ml-auto"
                                            onClick={redirectForgotPass}
                                        >
                                            Forgot Password?
                                        </Button>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicAccount">
                                        <Form.Text id="formText">Don't have an account?</Form.Text>
                                        <Button
                                            href="/create-account"
                                            variant="link"
                                            id="signUpBtn"
                                        >
                                            <b>SIGN UP</b>
                                        </Button>
                                    </Form.Group>
                                    <ButtonComponent idName="loginBtn" link="" name="LOGIN" loading={login.loading}
                                                     disabled={login.loading}/>
                                </Form>
                            </Card.Body>
                        </div>
                    </Card>

                    <div className="backgroundCol"></div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    login: state.login,
});

const mapDispatchToProps = dispatch => ({
    do_login: (loginForm) => dispatch(loginActions.doLogin(loginForm)),
    onLogout: () => dispatch(onLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
