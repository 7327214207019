import types from '../types';
import * as userService from '../../services/api/userService';

const setLoadingAction = () => ({
  type: types.FORGOT_PASSWORD_SET_LOADING,
});

const forgotPasswordSuccess = forgotPasswordResponse => ({
  type: types.FORGOT_PASSWORD,
  forgotPasswordResponse,
});

const forgotPasswordFailed = error => ({
  type: types.FORGOT_PASSWORD_FAILED,
  error,
});

const clearForgotPassword = () => ({
  type: types.FORGOT_PASSWORD_CLEAR
});

export const forgotPassword = (forgotPasswordData) => dispatch => {
  //console.log(forgotPasswordData);
  dispatch(setLoadingAction());
  return userService.forgotPassword(forgotPasswordData)
    .then(
      response => {
        dispatch(forgotPasswordSuccess(response.data))
      },
      error => {
        dispatch(forgotPasswordFailed(error))
      }
    );
};

export const resetForgotPassword = () => dispatch => {
  dispatch(clearForgotPassword());
}

