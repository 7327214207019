import types from '../types';

const defaultState = {
  updateJobData: null,
  loading: false,
  error: null,
};

const updateJob = (state = defaultState, action) => {
  switch (action.type) {
    case types.UPDATE_JOB_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_JOB:
      return {
        ...state,
        updateJobData: action.updateJobData,
        error: null,
        loading: false,
      };
    case types.UPDATE_JOB_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.UPDATE_JOB_REFRESH:
      return {
        ...state,
        error: null,
        loading: false,
        updateJobData: null,
      };
    default:
      return state;
  }
};
export default updateJob;
