import React, {useEffect, useRef, useState} from 'react';
import MessageSidebar from './components/MessageSidebar'

import './style.css';
import {Form, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import ChatMessageComponent from "./components/ChatMessageComponent/ChatMessageComponent";
import jipeBlue from "../../../assets/jipe-logo-bg.svg";
import {connect} from "react-redux";
import {
    getConversationListAction,
    getConversationMessagesAction,
    getPaginatedConversationMessagesAction,
    postConversationMessageAction,
    postConversationReadStatusAction,
    updateConversationListAction,
    updateConversationMessageAction,
} from "../../../redux/actions/messagingAction";
import * as addLocationActions from "../../../redux/actions/addLocation";
import {useSnackbar} from "notistack";
import {useHistory, useParams} from "react-router-dom";
import useSocket from "../../../utils/useSocket";

const plane = <FontAwesomeIcon icon={faPaperPlane}/>;

const MessagePage = (props) => {
    const {
        recipientListState,
        getConversationListDispatch,
        getAllLocations,
        allLocationsData,
        meData,
        getConversationMessageDispatch,
        getPaginatedConversationMessagesDispatch,
        conversationMessageState,
        postConversationMessageDispatch,
        postConversationMessageState,
        updateConversationMessageDispatch,
        updateConversationListDispatch,
        postConversationReadStatusDispatch,
    } = props
    const [isConnected, setIsConnected] = useState(null)
    const [recipients, setRecipients] = useState(null)
    const [activeChat, setActiveChat] = useState(null)
    const [scrolledToBottom, setScrolledToBottom] = useState(false)
    const [activeConversation, setActiveConversation] = useState(null)
    const [activeConversationPagination, setActiveConversationPagination] = useState({
        count: null,
        next: null,
        previous: null
    })
    const [message, setMessage] = useState('')
    const messagesEndRef = useRef(null)
    const {enqueueSnackbar} = useSnackbar();
    const {id} = useParams();
    const history = useHistory()
    const listInnerRef = useRef();
    const [prevOffset, setPrevOffset] = useState(0)
    const {data, socket, emitEvent} = useSocket(process.env.REACT_APP_SOCKET_BASE_URL)

    useEffect(() => {
        getConversationListDispatch()
        getAllLocations()
    }, [])

    useEffect(() => {
        if (id && recipients) {
            const selectedConversation = recipients.find(recipient => recipient?.conversation?.id === id)
            if (selectedConversation) {
                selectedConversationDetails(selectedConversation)
            }
        }
    }, [id, recipients])

    useEffect(() => {
        if (socket) {
            emitEvent('join', [meData?.user_id]);
        }
    }, [socket]);


    useEffect(() => {
        if (data) {
            console.log(data)
            updateStoreMessages(data)
        }
    }, [data])

    useEffect(() => {
        const {scrollTop} = listInnerRef.current;
        console.log(scrollTop)
        setPrevOffset(scrollTop)
        setTimeout(() => {
            if (!scrolledToBottom) {
                console.log(scrolledToBottom)
                scrollToBottom()
            } else {
                listInnerRef.current?.scrollTo({behavior: "smooth", offset: scrollTop})
            }
        }, 100)
    }, [activeConversation])

    useEffect(() => {
        if (!recipientListState.loading) {
            if (recipientListState.data) {
                setRecipients(recipientListState?.data?.results)
            }
            if (recipientListState.error) {
                enqueueSnackbar(recipientListState.error, {
                    variant: "error",
                })
            }
        }
    }, [recipientListState])

    useEffect(() => {
        if (!conversationMessageState.loading) {
            if (conversationMessageState.data) {
                setScrolledToBottom(false)
                setActiveConversationPagination({
                    count: conversationMessageState.data.count,
                    next: conversationMessageState.data.next,
                    previous: conversationMessageState.data.previous
                })
                const sortedConvos = conversationMessageState?.data?.results?.sort((a, b) => new Date(a.log_date) - new Date(b.log_date));
                setActiveConversation(sortedConvos)
                setMessage('')
            }
            if (conversationMessageState.error) {
                setActiveChat('')
                enqueueSnackbar(conversationMessageState.error, {
                    variant: "error",
                })
            }
        }
    }, [conversationMessageState])

    useEffect(() => {
        if (!postConversationMessageState.loading) {
            if (postConversationMessageState.error) {
                enqueueSnackbar(postConversationMessageState.error, {
                    variant: "error",
                })
            }
        }
    }, [postConversationMessageState])

    const selectedConversationDetails = (data) => {
        if (data && activeChat?.id !== data?.conversation?.id) {
            getMessages(data?.conversation?.id)
            if (data?.conversation?.has_unread_messages) {
                postConversationReadStatusDispatch(recipientListState?.data, data?.conversation?.id)
            }
            setActiveChat({
                name: data?.seeker_full_name,
                title: data?.title,
                location: data?.address[0]?.name,
                id: data?.conversation?.id
            })
        }
    }

    const updateStoreMessages = (message) => {
        if (message?.data?.conversation?.id === activeChat?.id) {
            if (!checkForMessage(message?.data?.id)) {
                updateConversationMessageDispatch(conversationMessageState, message?.data)
            }
        } else {
            if (recipients) {
                const updatedRecipientList = recipients?.map((recipient, index) => {
                    if (recipient.conversation?.id === message?.data?.conversation?.id) {
                        return {
                            ...recipients[index],
                            conversation: {...recipients[index]?.conversation, has_unread_messages: true}
                        }
                    }
                    return recipient
                })
                const recipientListStateUpdated = {...recipientListState?.data, results: [...updatedRecipientList]}
                updateConversationListDispatch(recipientListStateUpdated)
            }
        }
    }

    const checkForMessage = (id) => {
        const existingMessage = conversationMessageState.data?.results.find(message => message.id === id)
        if (existingMessage) {
            return true
        }
        return false
    }

    const selectedLocation = (data) => {
        if (data) {
            const filteredConversations = recipientListState?.data?.results?.filter(conversation => conversation?.address[0]?.id === data)
            return setRecipients(filteredConversations)
        }
        setRecipients(recipientListState?.data?.results)
    }
    const getMessages = (id) => {
        getConversationMessageDispatch(id)
    }

    const sendMessage = (e) => {
        e.preventDefault()
        if (message && !postConversationMessageState.loading) {
            const messageData = {conversation_id: activeChat?.id, message}
            postConversationMessageDispatch(conversationMessageState, messageData)
        }
    }

    const scrollToBottom = (offset = null) => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth", top: offset})
    }

    const scrolling = () => {
        if (listInnerRef.current) {
            const {scrollTop, clientHeight, scrollHeight} = listInnerRef.current;
            if (scrollHeight - scrollTop === clientHeight) {
                console.log(scrollHeight - scrollTop === clientHeight)
                setScrolledToBottom(true)
            }
            if (activeConversationPagination.next && scrolledToBottom && getScrollDirection(scrollTop) === "up") {
                if (scrollTop < 50) {
                    getPaginatedConversationMessagesDispatch(conversationMessageState, activeConversationPagination.next)
                    setActiveConversationPagination({
                        count: null,
                        next: null,
                        previous: null
                    })
                }
            }
        }
    };

    const getScrollDirection = (scrollTop) => {
        setPrevOffset(scrollTop)
        if (scrollTop === 0) {
            return null
        }
        if (scrollTop > prevOffset) {
            return "down"
        } else if (scrollTop < prevOffset) {
            return "up"
        }
    }

    return (<div className="messagePage">
        <div className="sidebar-container">
            <MessageSidebar recipients={recipients}
                            locations={allLocationsData?.data?.results}
                            setLocation={selectedLocation}
                            activeChat={activeChat}
                            setActive={(data) => {
                                history.push("/message")
                                selectedConversationDetails(data)
                            }}/>
        </div>

        <div className='messaging-container'>
            <div className="chat__header">
                <span className="chat-header-name">{activeChat?.name}</span>
                <span className="chat-header-location">{activeChat?.location}</span>
                <span className="chat-header-location">{activeChat?.title}</span>
            </div>

            <div className='chat__body'>
                <div className='chatBody__message' onScroll={scrolling} ref={listInnerRef}>
                    <div className='messageCol'>
                        {(activeChat && activeConversation?.length) ? activeConversation?.map((conversation, index) => {
                                const showAvatar = conversation?.user_info?.id === activeConversation[index + 1]?.user_info?.id
                                return (
                                    <ChatMessageComponent conversationDetails={conversation} currentUser={meData?.user_id}
                                                          key={index} showAvatar={showAvatar}/>)
                            })
                            :
                            <div className={"h-100 w-100 d-flex flex-column justify-content-center align-items-center"}>
                                <div style={{padding: "10px"}}>
                                    <Image src={jipeBlue} alt="user" style={{width: "200px"}}/>
                                </div>
                                <div className="no-messages-found-txt">Go Bananas!</div>
                                <div className="no-messages-info-txt">Start a conversation
                                    {
                                        activeChat && (<> with {activeChat?.name}</>)
                                    }
                                </div>
                            </div>
                        }
                        <div ref={messagesEndRef}/>
                    </div>
                </div>
            </div>

            <div className="chat__footer">
                <div className="message-input-wrapper">
                    <Form onSubmit={(e) => sendMessage(e)} className={'message-input-form'}>
                        <Form.Control placeholder="Write A Message" value={message}
                                      disabled={!(activeChat && activeConversation)}
                                      onChange={(e) => setMessage(e.target.value)}
                                      className="password-input-blue" style={{backgroundColor: 'transparent'}}/>
                        <i className="pass-toggle-icon" onClick={(e) => sendMessage(e)}>{plane}</i>
                    </Form>
                </div>
            </div>
        </div>
    </div>)
}

const mapStateToProps = (state) => ({
    recipientListState: state.messagingReducer?.recipient,
    conversationMessageState: state.messagingReducer?.activeChat,
    postConversationMessageState: state.messagingReducer?.newMessage,
    allLocationsData: state.addLocation.locationData,
    meData: state.me?.meResponse?.data
});

const mapDispatchToProps = (dispatch) => ({
    getConversationListDispatch: () => dispatch(getConversationListAction()),
    getConversationMessageDispatch: (id) => dispatch(getConversationMessagesAction(id)),
    getPaginatedConversationMessagesDispatch: (convo, url) => dispatch(getPaginatedConversationMessagesAction(convo, url)),
    postConversationMessageDispatch: (convo, data) => dispatch(postConversationMessageAction(convo, data)),
    updateConversationMessageDispatch: (convo, data) => dispatch(updateConversationMessageAction(convo, data)),
    updateConversationListDispatch: (data) => dispatch(updateConversationListAction(data)),
    getAllLocations: () => dispatch(addLocationActions.getLocationData()),
    postConversationReadStatusDispatch: (recipients, id) => dispatch(postConversationReadStatusAction(recipients, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagePage)
