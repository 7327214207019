import types from "../types";
import * as stepperService from "../../services/api/signupStepperService";
import {meSuccess} from "./me";

export const setLoadingAction = () => ({
    type: types.businessInfoConstants.SET_BUSINESS_INFO_LOADING,
});

const businessInfoSuccess = data => ({
    type: types.businessInfoConstants.SET_BUSINESS_INFO,
    data,
});

const businessInfoFailed = error => ({
    type: types.businessInfoConstants.SET_BUSINESS_INFO_FAILED,
    error,
});

export const clearBusinessInfo = () => ({
    type: types.businessInfoConstants.CLEAR_BUSINESS_INFO_STATE
})

export const setBusinessInfo = (data, meData, type, profile_completed_up_to = null) => async dispatch => {
    dispatch(setLoadingAction())
    if (type === "businessName") {
        try {
            const response = await stepperService.patchBusinessName(data)
            if (response) {
                dispatch(businessInfoSuccess(response.data))
                dispatch(meSuccess({
                    ...meData,
                    data: {...meData.data, basic_info: {...meData.data.basic_info, business_name: data.full_name}}
                }))
            }
        } catch (err) {
            dispatch(businessInfoFailed(err))
        }

    } else {
        return stepperService.postBusinessInfo(data)
            .then(
                response => {
                    dispatch(businessInfoSuccess(response.data))
                    if (profile_completed_up_to) {
                        dispatch(meSuccess({
                            ...meData,
                            data: {
                                ...meData.data,
                                basic_info: {
                                    ...meData.data.basic_info,
                                    business_name: data.business_name,
                                },
                                business_locations: response?.data?.data?.business_locations,
                                profile_completed_up_to: profile_completed_up_to
                            }
                        }))
                    }
                },
                error => {
                    dispatch(businessInfoFailed(error))
                }
            );
    }

}
