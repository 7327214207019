import types from "../types";
import {cancelSubscriptionPost, getBillingCardDetails, getBillingInvoices} from "../../services/api/billingInfoService";

const getInvoiceInfoLoading = () => ({
  type: types.billingInfoConstants.SET_BILLING_INVOICE_LOADING
});

const getInvoiceInfoError = (error) => ({
  type: types.billingInfoConstants.SET_BILLING_INVOICE_FAILED,
  error,
});

const getInvoiceInfoSuccess = (data) => ({
  type: types.billingInfoConstants.SET_BILLING_INVOICE_SUCCESS,
  data,
});

const getCardInfoLoading = () => ({
  type: types.billingCardInfoConstants.SET_BILLING_CARD_INFO_LOADING
});

const getCardInfoError = (error) => ({
  type: types.billingCardInfoConstants.SET_BILLING_CARD_INFO_FAILED,
  error,
});

const getCardInfoSuccess = (data) => ({
  type: types.billingCardInfoConstants.SET_BILLING_CARD_INFO_SUCCESS,
  data,
});

const cancelSubscriptionLoading = () => ({
  type: types.cancelSubscriptionConstants.CANCEL_SUBSCRIPTION_LOADING
});

const cancelSubscriptionError = (error) => ({
  type: types.cancelSubscriptionConstants.CANCEL_SUBSCRIPTION_FAILED,
  error,
});

const cancelSubscriptionSuccess = (data) => ({
  type: types.cancelSubscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS,
  data,
});

export const clearCancelSubsError = () => ({
  type: types.cancelSubscriptionConstants.RESET_CANCEL_SUBSCRIPTION
})

export const getInvoiceInfo = () => {
  return async (dispatch) => {
    try {
      dispatch(getInvoiceInfoLoading());
      const response = await getBillingInvoices();
      if (response && response.data && response.data.data) {
        dispatch(getInvoiceInfoSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getInvoiceInfoError(error.message));
    }
  };
};

export const getCardInfo = () => {
  return async (dispatch) => {
    try {
      dispatch(getCardInfoLoading());
      const response = await getBillingCardDetails();
      if (response && response.data && response.data.data) {
        dispatch(getCardInfoSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getCardInfoError(error.message));
    }
  };
};


export const cancelSubscription = () => {
  return async (dispatch) => {
    try {
      dispatch(cancelSubscriptionLoading());
      const response = await cancelSubscriptionPost();
      if (response && response.data) {
        dispatch(cancelSubscriptionSuccess(response.data));
      }
    } catch (error) {
      dispatch(cancelSubscriptionError(error.message));
    }
  };
};
