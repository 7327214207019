import axiosInstance from "../../services/api/axios";
import types from "../types";
import { meSuccess } from "./me";

const setDescriptionSuccess = (description) => ({
  type: types.SET_DESCRIPTION,
  description
});

const setDescriptionFailed = (error) => ({
  type: types.SET_DESCRIPTION_FAILED,
  error,
});

export const setDescriptionLoading = () => ({
    type: types.SET_DESCRIPTION_LOADING,
  });

export const setDescription = (data, meData, showNotification, profile_completed_up_to = null) => {
  return async (dispatch) => {
    try {
      dispatch(setDescriptionLoading())
      const response = await axiosInstance.post("profile/share-about-you/", {
        about_you: data,
      });
      if (response && response.status === 200) {
        dispatch(setDescriptionSuccess(data));
        if(meData) {
          dispatch(meSuccess({...meData, data: {...meData.data, about_you: data}}))
          if(profile_completed_up_to) {
            dispatch(meSuccess({
              ...meData,
              data: {
                ...meData.data,
                about_you: data,
                profile_completed_up_to : profile_completed_up_to
              }
            }))
          }
        }
        if(showNotification) {
          showNotification("Description saved", "success")
        }
      }
    } catch (err) {
      dispatch(setDescriptionFailed(err.message));
      if(showNotification) {
        showNotification("Description update Failed", "error")
      }
    }
  };
};
