import React, {useEffect, useState} from 'react';
import "./style.css";
import {Card, Image} from "react-bootstrap";
import ButtonComponent from "../../../components/ButtonComponent";
import logo from "../../../assets/jipe-forbusiness-wg.svg";
import {useHistory} from "react-router-dom";
import {resendVerification} from "../../../services/api/userService";
import {useSnackbar} from "notistack";

const ConfirmationPage = (props) => {
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [displayTxt, setDisplayTxt] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [backUrl, setBack] = useState(null);
  const [confirmationType, setConfirmationType] = useState("");
  const [email, setEmail] = useState("");
  const goBack = () => history.push(backUrl);

  useEffect(() => {
    if (props?.location?.state?.email) {
      //console.log(props?.location?.state?.email);
      setEmail(props?.location?.state?.email);
    }
    if (props.match.params.type === 'email') {
      setBack("/login");
      setConfirmationType("register");
      setDisplayTxt("We just sent you an email confirmation. Please check that and follow the instructions " +
        "provided to continue your sign up process. Be patient. Sometimes it takes a few minutes to receive," +
        " and you may need to check your junk mail. Thanks.")
      setErrMessage("Something went wrong, please try logging in with the added credentials!");
    } else if (props.match.params.type === 'reset') {
      setConfirmationType("forget_password");
      setBack("/forgot-password");
      setDisplayTxt("We just sent you an email confirmation. " +
        "Please check that and follow the instructions provided to reset your password.");
      setErrMessage("Something went wrong, retry forgot password!");
    }
  }, []);

  const resendEmail = () => {
    if (email) {
      //console.log(email);
      const data = {
        type: confirmationType,
        data: {email}
      };
      resendVerification(data).then(resp => {
        if (resp.status === 200) {
          enqueueSnackbar(resp?.data?.message, {variant: "success"});
        }
      }, err => {
        enqueueSnackbar(err.response.data.non_field_errors, {variant: "error"});
      });
    } else {
      enqueueSnackbar(errMessage, {variant: "error"});

    }
  }

  return (
    <div className="main-container">
      <div className="containerRow">
        <div className="logoCol">
          <Image src={logo} alt="user" className='mainLogo'/>
        </div>

        <div className="reset-row">
          <Card className="resend-email-card">
            <div>
              <Card.Title className="resend-title">
                <b>Check Your Email!</b>
              </Card.Title>

              <Card.Body>
                <div className="resend-label">{displayTxt}</div>

                <ButtonComponent type="link" idName="resend-email-btn" name='Resend Email'
                                 onClickFunction={resendEmail}/>
              </Card.Body>
            </div>

            <ButtonComponent type="link" idName="back-btn" name='Back' onClickFunction={goBack}/>
          </Card>

          <div className='backgroundCol'>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationPage;
