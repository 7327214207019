import axiosInstance from './axios';

export const createJob = async (jobCreationData) =>
  axiosInstance.post("business/create-job/", jobCreationData)

export const getJob = async (data) =>
  axiosInstance.post("jobs/details/", data)

export const updateJob = async (jobCreationData) =>
  axiosInstance.put("business/update-job/", jobCreationData)

export const profileCompleted = async () =>
  axiosInstance.post("profile/completed/")

export const deactivateJobById = async (jobId, reasons = null) => axiosInstance.post(`business/close-job-listing/${jobId}/`, {reasons: reasons})

export const activateJobById = async (jobId) => axiosInstance.post(`business/activate-job-listing/${jobId}/`)

export const getDeactivationReasons = async () => await axiosInstance.get(`/jobs/deactive-reasons/`)
