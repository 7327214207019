import React, {useEffect, useRef, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {faImages} from "@fortawesome/free-solid-svg-icons";
import {useSnackbar} from "notistack";

import * as meActions from "../../../../redux/actions/me";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonComponent from "../../../../components/ButtonComponent";
import ImageCropperComponent from '../../../../components/ImageCropperComponent'
import "./style.css";
import {setBannerImage} from "../../../../redux/actions/setBannerImageAction";
import {postImage} from "../../../../redux/actions";

const Banner = (props) => {
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerImg, setBannerImg] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [showImageCropper, setShowImageCropper] = useState(false)
  const [src, setSrc] = useState(null)

  const imgInput2 = useRef(null);

  const {
    meData,
    setBannerAction,
    postBannerImage,
    bannerState,
    getMeDataAction,
  } = props;
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (meData?.data?.banner_picture_url) {
      setBannerImg(meData.data?.banner_picture_url);
      setSelectedBanner("custom");
    } else {
      setSelectedBanner("jipe");
      setBannerImg(null);
    }
  }, [meData]);

  useEffect(() => {
    if (!meData?.data) {
      getMeDataAction();
    } else {
      if (meData?.data?.banner_picture_url) {
        setBannerImg(meData.data?.banner_picture_url);
        setSelectedBanner("custom");
      } else {
        setSelectedBanner("jipe");
        setBannerImg(null);
      }
    }
  }, []);

  useEffect(() => {
    if (!bannerState.loading) {
      setBtnLoading(false);
      if (!bannerState.error) {
        setButtonEnabled(false);
      } else if (bannerState.error) {
        setBtnLoading(false);
      }
    } else {
      setBtnLoading(true);
    }
  }, [bannerState]);

  const onBannerChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
      setSrc(reader.result)
      );
      reader.readAsDataURL(event.target.files[0]);
    }
    setShowImageCropper(true)
    setSelectedBanner("custom");
  };

  const inputClicked = (input) => {
    input.current.click();
  };

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  const handleChange = (type) => {
    //console.log(type);
    setBtnLoading(true);
    if (type === "jipe") {
      setSelectedBanner(type);
      setBannerAction(null, showNotification, meData);
    } else if (type === "custom") {
      setSelectedBanner(type);
      setBtnLoading(false);
    }
  };

  const handleUpload = () => {
    if (buttonEnabled) {
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("file", bannerImg);
      postBannerImage(formData, showNotification, meData);
    }
  };

  return (
    <>
    <div id="profileCard">
      <Row>
        <Col>
          <div className="profile-card-header">Banner</div>

          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center mt-3">
              <div className="mr-3" style={{marginTop: "20px"}}>
                <Form.Check
                  className="banner-checkbox"
                  id="jipe"
                  disabled={btnLoading}
                  onChange={(event) => handleChange(event.target.id)}
                  checked={selectedBanner === "jipe" ? true : false}
                />
              </div>

              <div>
                <span className="profile-banner-title">Jipe Banner</span>
                <div id="jipe-banner-container"></div>
              </div>
            </div>

            <div className="d-flex flex-row align-items-center mt-3">
              <div className="mr-3" style={{marginTop: "20px"}}>
                <Form.Check
                  className="banner-checkbox"
                  id="custom"
                  disabled={btnLoading}
                  onChange={(event) => handleChange(event.target.id)}
                  checked={selectedBanner === "custom" ? true : false}
                />
              </div>

              <div>
                <span className="profile-banner-title">Custom</span>
                <div
                  id="profile-banner-image-container"
                  onClick={() => inputClicked(imgInput2)}
                  style={{
                    backgroundImage: `url(${
                      bannerImg
                        ? bannerImg.name
                        ? URL.createObjectURL(bannerImg)
                        : bannerImg
                        : ""
                    })`,
                  }}
                >
                  {!bannerImg ? <FontAwesomeIcon icon={faImages}/> : <></>}
                </div>
                <input
                  accept="image/*"
                  type="file"
                  onChange={(e) => onBannerChange(e)}
                  onClick={(event)=> { event.target.value = null }}
                  hidden={true}
                  ref={imgInput2}
                />
              </div>

              <div style={{marginTop: "20px"}}>
                <ButtonComponent
                  idName="customImageUploadBtn"
                  name="UPLOAD"
                  onClickFunction={() => handleUpload()}
                  disabled={!buttonEnabled || btnLoading}
                  loading={btnLoading}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <ImageCropperComponent show={showImageCropper} hide={() => setShowImageCropper(false)} type={"banner"} src={src} setButtonEnabled={setButtonEnabled} setSelectedImage={setBannerImg}/>
    </>
  );
};

const mapStateToProps = (state) => ({
  meData: state.me?.meResponse,
  bannerState: state.setBanner,
});

const mapDispatchToProps = (dispatch) => ({
  setBannerAction: (data, notification, meData) =>
    dispatch(setBannerImage(data, notification, meData)),
  postBannerImage: (image, showNotification, meData) =>
    dispatch(postImage(image, "banner", showNotification, meData)),
  getMeDataAction: () => dispatch(meActions.me()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
