import React from 'react';
import './style.css';
import {Link} from "react-router-dom";

const FooterComponent = () => {
  return (
    <>
      <div className="footer">
      </div>

      <div className="legal-info-container">
        <div className="d-flex flex-row align-items-center legal-wrapper">
          <span className="rights-reserved-lbl">© 2022 Jipe Corporation. All rights reserved. </span>

          <div className="position-relative ml-4">
            <div className="legal-info-divider"></div>
            <Link to={{pathname: "https://www.jipejobs.com"}} className="legal-external-links"
                  target="_blank">
              company website
            </Link>
          </div>

          <div className="position-relative ml-1">
            <div className="legal-info-divider"></div>
            <Link to={{pathname: "https://www.jipejobs.com/contact"}} className="legal-external-links"
                  target="_blank">
              contact us
            </Link>
          </div>

          <div className="position-relative ml-1">
            <div className="legal-info-divider"></div>
            <Link to={{pathname: "https://www.jipejobs.com/privacy-policy"}} className="legal-external-links"
                  target="_blank">
              privacy policy
            </Link>
          </div>

          <div className="position-relative ml-1">
            <Link to={{pathname: "https://www.jipejobs.com/terms-of-use"}} className="legal-external-links"
                  target="_blank" style={{border: 0}}>
              terms of use
            </Link>
          </div>

        </div>
      </div>
    </>
  )
}

export default FooterComponent;
