import React from 'react';
import {Spinner} from 'react-bootstrap';
import {Redirect, Route} from 'react-router-dom';

import {profileCompletedUpToDS} from './utils';
import auth from '../services/auth';
import HeaderLayoutComponent from '../components/HeaderLayoutComponent';

export const ProtectedRoute = ({component: Component, layout: Layout, messaging, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => {

        if (auth.isAuthenticated()) {
          if (rest?.meDataLoading || rest.meData === null) {
            return (
              <HeaderLayoutComponent>
                <div className="spinner-wrapper">
                  <Spinner animation="border" className="spinner"/>
                </div>
              </HeaderLayoutComponent>
            )
          }

          if (rest?.meData?.data?.is_profile_completed || rest?.meData?.data?.profile_completed_up_to === "job_listing_create") {
            if (props.location.pathname === "/signup" || props.location.pathname === "/billing" || props.location.pathname === "/job-creation") {
              props.history.push("/location-manager")
            } else {
              return (
                <Layout accountType={auth.isAuthenticated()} messaging={messaging}>
                  <Component {...props} accountType={auth.isAuthenticated()}/>
                </Layout>
              )
            }
          } else {
            if (props.location.pathname === "/signup" || props.location.pathname === "/billing" || props.location.pathname === "/job-creation") {
              const profileCompletedUpToIndex = profileCompletedUpToDS.indexOf(profileCompletedUpToDS.find(status => {
                return status.includes(rest?.meData?.data?.profile_completed_up_to)
              }));
              let pushUserTo = []
              if (rest?.meData?.data?.profile_completed_up_to === "application_type" && rest?.meData?.data?.application_type === "online_application") {
                pushUserTo = profileCompletedUpToDS[profileCompletedUpToIndex + 2].split("&&")[0].split("_")
              } else {
                pushUserTo = profileCompletedUpToDS[profileCompletedUpToIndex + 1].split("&&")[0].split("_")
              }
              if (props.location.pathname.includes(pushUserTo[0])) {
                return (
                  <Layout accountType={auth.isAuthenticated()} messaging={props?.messaging}>
                    <Component {...props} accountType={auth.isAuthenticated()}/>
                  </Layout>
                )
              } else {
                props.history.push(`/${pushUserTo[0]}`)
              }
            } else {
              props.history.push("/signup")
            }
          }
        } else {
          return <Redirect to={
            {
              pathname: "/login",
              state: {
                from: props.location
              }
            }
          }/>
        }
      }
      }/>
  )
}
