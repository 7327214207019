import React, {useEffect} from "react";
import "./style.css";
import {Form} from "react-bootstrap";
import {connect} from "react-redux";
import {setApplicationType} from "../../../../../redux/actions/applicationTypeAction";

const ApplicationType = (props) => {

  const {nextClicked, navigationState, changeNextClicked, changePage, selectedOption, setSelectedOption} = props;
  const {setApplicationTypeAction, applicationType, loading, error, meData, showNotification} = props;

  const checkBoxToggled = (event) => {
    if (selectedOption.includes(event.target.id)) {
      const optionData = selectedOption;
      const index = optionData.indexOf(event.target.id);
      optionData.splice(index, 1);
      setSelectedOption([...optionData]);
    } else {
      setSelectedOption([event.target.id]);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.length > 0) {
        navigationState(true);
      } else {
        navigationState(false);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (nextClicked) {
      submitData();
    }
  }, [nextClicked]);

  const submitData = () => {
    setApplicationTypeAction(selectedOption, meData, showNotification, "application_type");
  };

  useEffect(() => {
    if (applicationType && !loading && !error && nextClicked) {
      changeNextClicked()
      changePage()
    } else if (!applicationType && !loading && error && nextClicked) {
      changeNextClicked()
    }
  }, [applicationType, loading, error])

  return (
    <>
      <div className="h-25">
        <div className="stepper-title">Application type</div>
        <div className="stepper-info">
          You have two options for collecting job application submissions. For the most seamless candidate experience,
          we suggest using Jipe's in-app solution. If you already have an existing solution, no problem! That works
          great too!
        </div>
      </div>

      <div className="h-75">
        <div className="application-overflow-wrapper">
          <div>
            <div className="application-category-header">
              <div className="text-uppercase pb-2">Jipe Solution</div>
              <div>
                <Form.Check
                  className="application-category-checkbox"
                  id="jipe_application"
                  onChange={(event) => checkBoxToggled(event)}
                  checked={selectedOption.includes("jipe_application")}
                />
              </div>
            </div>

            <div className="application-category-desc">
              Jipe's solution is simple. We eliminate paperwork and store all your applicant information securely on
              your account. The applicant's profile becomes the application. You will have the option to add additional
              yes/no questions to every job application in the next step.
            </div>
          </div>

          <div className="mt-3">
            <div className="application-category-header">
              <div className="text-uppercase pb-2">Your existing online solution</div>
              <div>
                <Form.Check
                  className="application-category-checkbox"
                  id="online_application"
                  onChange={(event) => checkBoxToggled(event)}
                  checked={selectedOption.includes("online_application")}
                />
              </div>
            </div>

            <div className="application-category-desc">
              If you already have an existing online application tool or ATS that you require all applicants to use,
              this is the solution for you. You will be able to add a unique external link to each job listing. You
              maintain ability to see profiles of candidates who show interest by clicking on these links.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  applicationType: state.applicationTypeData.applicationType.data,
  loading: state.applicationTypeData.applicationType.loading,
  error: state.applicationTypeData.applicationType.error
});

const mapDispatchToProps = (dispatch) => ({
  setApplicationTypeAction: (data, meData, showNotification, profile_completed_up_to) => dispatch(setApplicationType(data, meData, showNotification, profile_completed_up_to)),
});

export const ApplicationTypeComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationType);
