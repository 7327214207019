import React, {useEffect, useState} from "react";
import {Container, Form, Spinner} from "react-bootstrap";
import "./style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCopy,
    faEllipsisV,
    faEye,
    faGripHorizontal,
    faPen,
    faStop,
    faThList,
} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useParams} from "react-router-dom";
import ApplicationCardComponent from "../../../components/ApplicationCardComponent";
import ApplicantCardModal from "./components/ApplicantCardModal";
import {connect, useDispatch} from "react-redux";
import {
    applicantsFromJobId,
    clearApplicantsError,
    clearSelectedLocationError,
    getFavouritesList,
    getLocationFromLocationId,
} from "../../../redux/actions";
import {useSnackbar} from "notistack";
import {clearGetJobDetailsError, getJobDetails,} from "../../../redux/actions/getJobDetailsAction";
import {deactivateJobById,} from "../../../services/api/jobCreationService";
import AddPositionModal from "../LocationPage/AddPositionModal";
import PreviewProfileModal from "../../../components/PreviewProfileModal/PreviewProfileModal";
import DeactivateQuestionModal from "../LocationPage/DeactivateQuestionModal/DeactivateQuestionModal";
import {getDeactivationReasons} from "../../../redux/actions/getDeactivationReasons";
import StatusConfirmationModal from "../../../components/StatusConfirmationModal";
import FavouritesTableComponent from "./components/FavouritesTableComponent";
import ApplicantTableComponent from "../ApplicationPage/ApplicantTableComponent";
import {jobSwipeAction, jobSwipeReset} from "../../../redux/actions/messagingAction";

const PositionPage = (props) => {
    const dispatch = useDispatch()
    const [displayCard, setDisplayCard] = useState(false);
    const [displayApplicant, setDisplayApplicant] = useState(false);
    const [showModal, setShowModal] = useState({state: false, favorite: false});
    const [show, setShow] = useState({status: false, modal: null});
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [isJipeSolution, setIsJipeSolution] = useState(false);
    const [filterStatus, setFilterStatus] = useState({hired: false, interviewed: false, hidden: false});
    const [deactivationModalState, setDeactivationModalState] = useState({state: false, id: null, status: null});
    const [deactivationData, setDeactivationData] = useState(null);
    const [confirmationLoading, setConfirmationLoading] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState({
        modalState: false,
        id: null,
        status: null,
        type: null,
    });
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const {
        selectedLocationState,
        getApplicants,
        applicantsData,
        applicantsDataLoading,
        applicantsDataFailed,
        selectedJobState,
        getSelectedJob,
        getSelectedLocation,
        selectedLocationLoading,
        selectedLocationFailed,
        clearSelectedLocationError,
        clearSelectedJobError,
        clearApplicantsError,
        applicantStatusState,
        hideApplicantState,
        meData,
        getDeactivationReasons,
        getFavourites,
        favouritesState,
        jobSwipeState,
        jobSwipeActionDispatch
    } = props;
    const {locationId, jobId} = useParams();

    useEffect(() => {
        if (history.location.state?.applicants) {
            setDisplayApplicant(true)
        } else {
            setDisplayApplicant(false)
        }
    }, []);

    useEffect(() => {
        fetchFavourites()
        getDeactivationReasons()
        getSelectedJobOrLocation()
    }, []);

    useEffect(() => {
        setIsJipeSolution(meData?.data?.application_type === "jipe_application")
    }, [meData.data])

    useEffect(() => {
        if (!applicantStatusState.loading) {
            if (applicantStatusState.data) {
                fetchApplicants();
            } else if (applicantStatusState.error) {
                enqueueSnackbar(applicantStatusState.error, {variant: "error"})
            }
        }
    }, [applicantStatusState])

    useEffect(() => {
        if (!jobSwipeState.loading) {
            if (jobSwipeState.data) {
                history.push({pathname: `/message/${jobSwipeState.data?.conversation_id}`});
                dispatch(jobSwipeReset())
            } else if (jobSwipeState.error) {
                enqueueSnackbar(jobSwipeState.error, {variant: "error"})
            }
        }
    }, [jobSwipeState])

    useEffect(() => {
        if (!hideApplicantState.loading) {
            if (hideApplicantState.data) {
                fetchApplicants();
            } else if (hideApplicantState.error) {
                enqueueSnackbar(hideApplicantState.error, {variant: "error"})
            }
        }
    }, [hideApplicantState])

    useEffect(() => {
        if (!applicantsDataLoading) {
            if (applicantsDataFailed) {
                enqueueSnackbar(applicantsDataFailed.error, {variant: "error"})
            }
        }
    }, [applicantsDataLoading, applicantsDataFailed])

    useEffect(() => {
        fetchApplicants();
    }, [filterStatus]);

    const fetchApplicants = () => {
        if (locationId && jobId) {
            getApplicants(jobId, filterStatus);
        } else {
            enqueueSnackbar("Something went wrong. Please refresh the page.", {variant: "error"})
        }
    }

    const fetchFavourites = () => {
        if (locationId && jobId) {
            getFavourites(jobId)
        } else {
            enqueueSnackbar("Something went wrong. Please refresh the page.", {variant: "error"})
        }
    }

    const getSelectedJobOrLocation = () => {
        if (locationId && jobId) {
            getApplicants(jobId, filterStatus);
            getSelectedJob(jobId);
            if (
                !selectedLocationState?.location?.id ||
                locationId !== selectedLocationState?.location?.id
            ) {
                getSelectedLocation(locationId);
            }
        }
    }

    const statusChanged = (event) => {
        const {id, checked} = event.currentTarget;
        setFilterStatus({...filterStatus, [id]: checked})
    }

    useEffect(() => {
        if (deactivationData?.reasons.length > 0) {
            setShowStatusModal({
                modalState: true,
                id: deactivationData.id,
                status: deactivationData.status,
                type: "job",
                reasons: deactivationData.reasons
            });
        }
    }, [deactivationData])

    useEffect(() => {
        if (selectedJobState.error && !selectedJobState.loading) {
            enqueueSnackbar("Could not fetch Job data!", {
                variant: "error",
            });
            clearSelectedJobError();
        } else {
            // console.log("selected job=== ", selectedJobState);
        }
    }, [selectedJobState.error]);

    useEffect(() => {
        if (selectedLocationFailed && !selectedLocationLoading) {
            enqueueSnackbar("Could not fetch Location info !", {
                variant: "error",
            });
            clearSelectedLocationError();
        }
    }, [selectedLocationFailed]);

    useEffect(() => {
        if (!applicantsDataLoading && applicantsDataFailed) {
            enqueueSnackbar("Could not fetch applicants data", {
                variant: "error",
            });
            clearApplicantsError();
        }
    }, [applicantsDataFailed]);

    const deactivationAction = (data) => {
        if (data) {
            console.log(data)
            setConfirmationLoading(true);
            handleJobActivation(data.status, data.id, data.reasons).then();
        } else {
            setShowStatusModal(false)
        }
    };

    const handleJobActivation = async (isActive, jobId, reasons = null) => {
        try {
            const response = await deactivateJobById(jobId, reasons);
            if (response && response.status === 200) {
                enqueueSnackbar("Job paused successfully", {
                    variant: "success",
                });
                getSelectedLocation(selectedLocationState.location.id);
                history.push(`/location/${selectedLocationState.location.id}`)
            }
        } catch (err) {
            enqueueSnackbar("Job deactivation failed!", {
                variant: "error",
            });
        }
        setShowStatusModal(false)
        setDeactivationModalState(false)
        setConfirmationLoading(false);
    };

    const openModal = (status, index) => {
        setShowModal(status);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setShow({status: false, modal: null});
    };

    const closeModal = () => {
        setSelectedIndex(null);
        setShowModal({state: false, favorite: false});
    };

    const handleEditJobListing = () => {
        setShow({status: !show.status, modal: "editJob"});
    }

    const showDeactivationLists = (status, id) => {
        setDeactivationModalState({state: true, status, id})
    }

    const startConversationHandler = (e, seeker) => {
        e.preventDefault()
        e.stopPropagation()
        const data = {
            job_id: jobId,
            seeker_id: seeker,
            swipe_direction: 'right'
        }
        jobSwipeActionDispatch(data)
    }

    // const handleDeactivation = async (jobId) => {
    //   try {
    //     const response = await deactivateJobById(jobId);
    //     if (response && response.status === 200) {
    //       getSelectedLocation(locationId);
    //       enqueueSnackbar("Job paused successfully", {
    //         variant: "success",
    //       });
    //       history.push(`/location/${locationId}`)
    //     }
    //   } catch (err) {
    //     enqueueSnackbar("Job deactivation failed!", {
    //       variant: "error",
    //     });
    //   }
    // };

    const handlePreview = () => {
        setModalShow(true)
    }

    const copyDynamicLink = (data) => {
        if (data.dynamic_link_url) {
            navigator.clipboard.writeText(data.dynamic_link_url).then(() => enqueueSnackbar("Dynamic link copied!", {
                variant: "success",
            }));
        } else {
            enqueueSnackbar("No dynamic url found!", {
                variant: "error",
            })
        }
    }

    return (
        <div className="containerMain">
            <div className="breadcrumb-container d-flex flex-row align-items-center justify-content-between">
                <div className="position-breadcrumb">
          <span
              className="jipe-breadcrumb-item"
              onClick={() => history.push("/location-manager")}
          >
            My Locations
          </span>
                    <span className="jipe-breadcrumb-arrow">{`>`}</span>
                    <span
                        className="jipe-breadcrumb-item"
                        onClick={() =>
                            history.push(`/location/${selectedLocationState.location.id}`)
                        }
                    >
            {/* need to change this after api change */}
                        {selectedLocationState?.location?.name}
          </span>
                    <span className="jipe-breadcrumb-arrow">{`>`}</span>
                    <span className="jipe-breadcrumb-item">
            {/* add current page here */}
                        {selectedJobState?.data?.title}
          </span>
                </div>

                <div className="view-toggle-btns">
                    <div className="toggle-pill d-flex flex-row">
                        <div
                            className={`w-50 d-flex flex-row justify-content-center align-items-center jipe-blue ${
                                displayCard ? "toggle-active" : ""
                            }`}
                            onClick={() => setDisplayCard(true)}
                        >
                            <FontAwesomeIcon
                                icon={faGripHorizontal}
                                className={"toggle-btn-icons"}
                            />
                        </div>
                        <div
                            className={`w-50 d-flex flex-row justify-content-center align-items-center jipe-blue ${
                                !displayCard ? "toggle-active" : ""
                            }`}
                            onClick={() => setDisplayCard(false)}
                        >
                            <FontAwesomeIcon icon={faThList} className="toggle-btn-icons"/>
                        </div>
                    </div>
                </div>
            </div>

            {show.status && show.modal === "editJob" &&
                <AddPositionModal
                    show={show.status && show.modal === "editJob"}
                    id={locationId}
                    onHide={() => handleClose()}
                    openForEdit={show.modal === "editJob"}
                    idOfEditableJob={selectedJobState.data.id}
                />
            }

            {applicantsDataLoading ? (
                <div className="spinner-wrapper">
                    <Spinner animation="border" className="spinner"/>
                </div>
            ) : (
                <Container fluid className="main-body p-0" style={{height: "calc(100% - 80px)"}}>
                    <Row
                        id="position-header-card"
                        className="d-flex flex-row justify-content-between"
                    >
                        <Col className="d-flex flex-row align-items-center">
              <span id="position-header-name">
                {selectedJobState?.data?.title}
              </span>
                            <span id="position-header-id">
                #{selectedJobState?.data?.number}
              </span>
                        </Col>

                        {selectedJobState?.data?.id && (
                            <Col lg={"auto"} className="d-flex flex-row justify-content-end">
                                <Row>
                                    {
                                        !displayCard && (
                                            <Col style={{width: "250px"}}>
                                                <div className="toggle-pill d-flex flex-row">
                                                    <div
                                                        className={`w-50 d-flex flex-row justify-content-center align-items-center applicant-toggle ${
                                                            displayApplicant ? "applicant-toggle-active" : ""
                                                        }`}
                                                        onClick={() => setDisplayApplicant(true)}
                                                    >
                                                        Applicants
                                                    </div>
                                                    <div
                                                        className={`w-50 d-flex flex-row justify-content-center align-items-center applicant-toggle ${
                                                            !displayApplicant ? "applicant-toggle-active" : ""
                                                        }`}
                                                        onClick={() => setDisplayApplicant(false)}
                                                    >
                                                        Favorites
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }

                                    <Col lg={2}>
                                        <Dropdown onClick={(e) => e.stopPropagation()}>
                                            <Dropdown.Toggle className="jl-location-ellipsis">
                                                <FontAwesomeIcon
                                                    className="jl-ellipsis-icon"
                                                    icon={faEllipsisV}
                                                    style={{fontSize: "18px"}}
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="jl-dropdown-menu">
                                                <Dropdown.Item
                                                    className="jl-dropdown-item"
                                                    onClick={() => showDeactivationLists(selectedJobState.data.is_active, selectedJobState.data.id)}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faStop}
                                                        style={{color: "#2C2B7C"}}
                                                        className="mx-3"
                                                    />
                                                    <span>Pause Job Listing</span>

                                                </Dropdown.Item>
                                                <Dropdown.Item className="jl-dropdown-item"
                                                               onClick={() => handleEditJobListing()}>
                                                    <FontAwesomeIcon
                                                        icon={faPen}
                                                        style={{color: "#2C2B7C"}}
                                                        className="mx-3"
                                                    />
                                                    <span>Edit Job Listing</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="jl-dropdown-item"
                                                    onClick={() => handlePreview()}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEye}
                                                        style={{color: "#2C2B7C"}}
                                                        className="mx-3"
                                                    />
                                                    <span>Preview Job</span>
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    className="jl-dropdown-item"
                                                    onClick={() => copyDynamicLink(selectedJobState.data)}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCopy}
                                                        className="mx-3"
                                                    />
                                                    <span>Copy Dynamic Link</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>

                            </Col>
                        )}
                    </Row>

                    {selectedJobState.data && modalShow &&
                        <PreviewProfileModal show={modalShow} hide={() => setModalShow(false)} meData={meData?.data}
                                             jobCreationData={selectedJobState?.data}/>}

                    {displayCard && !applicantsDataLoading ? (
                        <Container fluid id="position-card-wrapper">
                            <Row id="position-card-container">
                                {applicantsData?.length > 0 ? applicantsData.map((applicant, idx) => {
                                    return (
                                        <>
                                            <ApplicationCardComponent data={applicant} key={idx}/>
                                        </>
                                    )
                                }) : (
                                    <>
                                        <div className="no-data" style={{textAlign: 'center', height: '100%'}}> No data
                                            to display
                                        </div>
                                    </>
                                )
                                }
                            </Row>
                        </Container>
                    ) : !applicantsDataLoading ? (
                        <Container fluid id="position-table-wrapper" className="p-0">
                            {displayApplicant && (
                                <Row>
                                    <Col lg={"12"} className="d-flex flex-row justify-content-between">
                                        <div className="d-flex flex-row align-items-center">
                                            <label className="location-header">Filter</label>
                                        </div>

                                        <div className="d-flex flex-row">
                                            <div className="d-flex flex-row align-items-center ml-5 status-filter">
                                                <Form.Check checked={filterStatus.interviewed} className="mt-1"
                                                            id="interviewed"
                                                            onChange={(event) => statusChanged(event)}/>
                                                <span className="ml-2">Interviewed</span>
                                            </div>
                                            <div className="d-flex flex-row align-items-center ml-4 status-filter">
                                                <Form.Check checked={filterStatus.hired} className="mt-1" id="hired"
                                                            onChange={(event) => statusChanged(event)}/>
                                                <span className="ml-2">Hired</span>
                                            </div>
                                            <div className="d-flex flex-row align-items-center ml-4 status-filter">
                                                <Form.Check checked={filterStatus.hidden} className="mt-1" id="hidden"
                                                            onChange={(event) => statusChanged(event)}/>
                                                <span className="ml-2">Hidden</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            <Row id="position-table-container">
                                {displayApplicant ? (
                                    <ApplicantTableComponent
                                        itemClicked={(status, index) => openModal({
                                            state: true,
                                            favorite: false
                                        }, index)}
                                        applicantsInfo={applicantsData}
                                        swipeRight={startConversationHandler}
                                    />
                                ) : (
                                    <FavouritesTableComponent favouritesData={favouritesState}
                                                              swipeRight={startConversationHandler}
                                                              itemClicked={(status, index) => openModal({
                                                                  state: true,
                                                                  favorite: true
                                                              }, index)}/>
                                )
                                }
                            </Row>
                        </Container>
                    ) : null}
                </Container>
            )}

            {
                showModal.state &&
                <ApplicantCardModal
                    show={showModal.state}
                    showContact={showModal.favorite}
                    onHide={() => closeModal()}
                    isJipeSolution={isJipeSolution}
                    data={
                        displayApplicant ? applicantsData?.length && applicantsData[selectedIndex]
                            :
                            favouritesState?.data?.length && favouritesState?.data[selectedIndex]
                    }/>
            }

            {deactivationModalState.state && (
                <DeactivateQuestionModal
                    show={deactivationModalState}
                    actions={(data) => setDeactivationData(data)}
                    hide={() => setDeactivationModalState(false)}
                />
            )}

            {showStatusModal.modalState && (
                <StatusConfirmationModal
                    show={showStatusModal}
                    loading={confirmationLoading}
                    hide={(data) => deactivationAction(data)}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    selectedLocationState: state.addLocation.selectedLocation.data,
    selectedLocationLoading: state.addLocation.selectedLocation.loading,
    selectedLocationFailed: state.addLocation.selectedLocation.error,
    applicantsData: state.applicants?.applicantsFromJobId?.data?.results,
    applicantsDataLoading: state.applicants?.applicantsFromJobId?.loading,
    applicantsDataFailed: state.applicants?.applicantsFromJobId?.error,
    applicantStatusState: state.applicants?.applicantStatus,
    selectedJobState: state.jobDetails.getData,
    hideApplicantState: state.applicants?.hideApplicant,
    meData: state.me.meResponse,
    favouritesState: state.favourites,
    jobSwipeState: state.messagingReducer.jobSwipe
});

const mapDispatchToProps = (dispatch) => ({
    getApplicants: (jobId, status) => dispatch(applicantsFromJobId(jobId, status)),
    getFavourites: (jobId) => dispatch(getFavouritesList(jobId)),
    getSelectedJob: (jobId) => dispatch(getJobDetails({job_id: jobId})),
    getSelectedLocation: (locationId) =>
        dispatch(getLocationFromLocationId(locationId)),
    clearApplicantsError: () => dispatch(clearApplicantsError()),
    clearSelectedJobError: () => dispatch(clearGetJobDetailsError()),
    clearSelectedLocationError: () => dispatch(clearSelectedLocationError()),
    getDeactivationReasons: () => dispatch(getDeactivationReasons()),
    jobSwipeActionDispatch: (data) => dispatch(jobSwipeAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PositionPage);
