import React from "react";
import "./style.css";
import {Row, Spinner} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import banner from '../../../../../assets/profile-pic.png'
import {useHistory} from "react-router-dom";
import {generateNameInitialImg} from "../../../../../utils/nameInitial";

const FavouritesTableComponent = (props) => {
    const history = useHistory();
    const {favouritesData, loading, swipeRight} = props;

    const goToMessagePage = (ev, convoId) => {
        ev.stopPropagation()
        ev.preventDefault()
        history.push({pathname: `/message/${convoId}`});
    }
    return (
        <div id="applicant-table">
            <div className="application-table-head">
                <Row className="mx-0 w-100">
                    <Col lg={{span: 4}}>APPLICANT</Col>
                    <Col lg={{span: 4}}>FAVORITED DATE</Col>
                    <Col lg={{span: 4}}>MESSAGING</Col>
                </Row>
            </div>

            <div className="application-table-body">
                {loading ? <div className="spinner-wrapper">
                    <Spinner animation="border" className="spinner"/>
                </div> : favouritesData?.data?.length > 0 ? (
                    favouritesData?.data?.map((data, index) => (
                        <Row
                            className="pt-td mx-0 w-100 applicant-table-tr"
                            onClick={() => props.itemClicked(true, index)}
                            key={index}
                        >
                            <Col
                                lg={{span: 4}}
                                className="d-flex flex-row align-items-center"
                            >
                                <img
                                    src={data?.user?.seeker_profile_picture || generateNameInitialImg(data?.user?.seeker_full_name)}
                                    alt="Applicant Image"
                                    className="applicant-img"
                                />
                                <span className="applicant-name">
                  {data?.user?.seeker_full_name}
                </span>
                            </Col>
                            <Col
                                lg={{span: 4}}
                                className="d-flex flex-row align-items-center job-listing"
                            >
                <span style={{fontSize: "14px", padding: "5px 8px"}}>
                  {data?.user?.favorited_date?.split("T")[0]}
                </span>
                            </Col>
                            <Col
                                lg={{span: 4}}
                                className="d-flex flex-column justify-content-center job-listing"
                            >
                                {
                                    data?.user?.conversation_id ?
                                        (
                                            <span onClick={(ev) => goToMessagePage(ev, data?.user?.conversation_id)}
                                                  className={"go-to-message-link"}>Go to messages</span>)
                                        :
                                        (
                                            <span className={"go-to-message-link"} style={{color: '#A3CF62'}} onClick={(e) => swipeRight(e, data?.user?.user_id)}>
                                                  Start conversation
                                              </span>
                                        )
                                }
                            </Col>
                        </Row>
                    ))
                ) : (
                    <>
                        <div className="no-data" style={{height: '100%'}}> No data to display</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FavouritesTableComponent;
