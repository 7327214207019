import Modal from "react-bootstrap/Modal";
import {Container} from "react-bootstrap";
import React from "react";
import './style.css';
import ButtonComponent from "../ButtonComponent";


const BillingConfirmationModal = (props) => {
    const confirmed = () => {
        props.confirmation(true);
    }
    const cancel = () => {
        props.confirmation(false);
    }

    return (
        <Modal show={props.show} dialogClassName="billing-confirm-modal" backdrop="static"
               aria-labelledby="contained-modal-title-vcenter" keyboard={false}
               centered>

            <Container className="modal-body-container" style={{textAlign: 'center'}}>
                <div className="active-info">{props?.displayInfo}</div>

                <div className="d-flex flex-row align-items-center justify-content-around" style={{marginTop: '25px'}}>
                    <ButtonComponent idName='confirmation-cancel-btn' name='Cancel' onClickFunction={cancel}
                                     disabled={props.btnState ? true : false}/>
                    <ButtonComponent idName='confirm-final-btn' name='Confirm' onClickFunction={confirmed}
                                     loading={props.btnState} disabled={props.btnState}/>
                </div>
            </Container>
        </Modal>
    )
}

export default BillingConfirmationModal;
