import axiosInstance from "./axios";

export const getApplicantsFromJobId = async (jobId, status) =>
  await axiosInstance.get(`/business/applicants?job_id=${jobId}&show_hired=${status.hired}&show_interviewed=${status.interviewed}&show_hidden=${status.hidden}`);

export const getFavourites = async (jobId) =>
  await axiosInstance.get(`business/job-favourited-job-seeker-details-list/?job_id=${jobId}`);

export const getApplicantsFromJobLocationId = async (locationId, jobId, status) =>
  await axiosInstance.get(
    locationId && jobId
      ? `/business/applicants?location_id=${locationId}&job_id=${jobId}&show_hired=${status.hired}&show_interviewed=${status.interviewed}&show_hidden=${status.hidden}`
      : locationId && !jobId
      ? `/business/applicants?location_id=${locationId}&show_hired=${status.hired}&show_interviewed=${status.interviewed}&show_hidden=${status.hidden}`
      : jobId && !locationId
        ? `/business/applicants?job_id=${jobId}&show_hired=${status.hired}&show_interviewed=${status.interviewed}&show_hidden=${status.hidden}`
        : `/business/applicants?show_hired=${status.hired}&show_interviewed=${status.interviewed}&show_hidden=${status.hidden}`
  );

export const updateApplicantStatus = async (data) =>
  await axiosInstance.post(`/jobs/application-status/`, data);

export const hideApplicant = async (data) =>
  await axiosInstance.post(`/jobs/hide-application/`, data);
