import React, {useEffect, useState} from 'react';
import "./style.css";
import {Image} from "react-bootstrap";
import logo from "../../../assets/jipe-forbusiness-bg.svg";
import {connect} from "react-redux";
import {verifyEmail} from "../../../redux/actions/emailVerificationAction";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";

const EmailConfirmedPage = (props) => {
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const {emailConfirmationState, emailConfirmationDispatcher} = props;
  const uuid = props.match.params.id;
  const token = props.match.params.token;
  const [userData, setUserData] = useState({type: 'register', token: token, uid: uuid});
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    setUserData({...userData, uid: uuid, token: token});
    if (userData.token && userData.uid) {
      emailConfirmationDispatcher(userData);
    }
  }, []);

  useEffect(() => {
    if (!emailConfirmationState?.loading) {
      if (emailConfirmationState.error) {
        enqueueSnackbar(emailConfirmationState?.error?.response?.data?.token, {variant: "error"});
      } else {
        if (emailConfirmationState?.verified) {
          enqueueSnackbar(emailConfirmationState?.verified?.message, {variant: "success"});
          setVerified(true);
          redirect();
        }
      }
    }
  }, [emailConfirmationState]);

  const redirect = () => {
    const timer = setTimeout(() => {
      history.push("/login")
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <div className="email-conf-main-wrapper d-flex flex-column">
      <div>
        <Image src={logo} alt="user" className='email-conf-logo'/>
      </div>

      {
        verified ?
          <div className="email-conf-info">
            Your email has been successfully verified.
          </div> :
          <></>
      }

      <div className="bottom-background-wrapper">
      </div>
    </div>
  )
}

const mapStateToProps = state => (
  {
    emailConfirmationState: state.setVerificationData,
  }
);

const mapDispatchToProps = dispatch => (
  {
    emailConfirmationDispatcher: (data) => dispatch(verifyEmail(data)),
  }
);


export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmedPage);
