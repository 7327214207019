import './style.css';
import React from "react";
import {Image} from "react-bootstrap";
import jipeBlue from "../../../assets/jipe-logo-blue.svg";

const AccountDeletionPage = () => {
    return (
        <div className="deletion-content-wrapper">
            <div style={{width: '90%', margin: '30px'}}>
                <div className={'d-flex flex-row justify-content-center p-2'}>
                    <Image src={jipeBlue} alt="user" className='deletion-jipe-logo'/>
                </div>

                <div className="deletion-text-container">
                    <div className={'w-75'}>
                        <div className="deletion-header-txt mt-5">Your Data Privacy Matters to Us at Jipe.</div>
                        <div className="deletion-info-txt mt-2">
                            At Jipe, we deeply value your privacy and the trust you place in us. In compliance with
                            privacy
                            regulations and our commitment to transparency, we offer you the ability to initiate a data
                            deletion request directly from this page.
                        </div>
                        <div className="deletion-info-header-txt">
                            How to Initiate Your Data Deletion Request:
                        </div>
                        <div className="deletion-info-txt">
                            <li>
                                You can initiate the account delete from the account setting section from the Jipe App.
                            </li>
                            <li>
                                You can enter the reason for account delete to future improvement.
                            </li>
                        </div>

                        <div className="deletion-info-header-txt">
                            What Happens After You Submit a Request:
                        </div>
                        <div className="deletion-info-txt">
                            <li>
                                Verification: The account request will be verified based on the authorization token of
                                the
                                user.
                            </li>
                            <li>
                                Confirmation: After verification of the request, the user status is updated as disabled
                                and
                                their auth token and the some details like location details are deleted.
                            </li>
                        </div>

                        <div className="deletion-info-header-txt">
                            Questions or Concerns?
                        </div>
                        <div className="deletion-info-txt">
                            If you have any questions or need further assistance regarding your data privacy, please
                            feel
                            free to contact our support team at [admin@jipejobs.com]. We're here to help!
                        </div>

                        <div className="deletion-info-txt mt-4">
                            Thank you for being a part of the Jipe community. Your privacy and trust are paramount to
                            us.
                        </div>

                        <div className="deletion-note-txt">
                            Note: "You have also the option to delete your account from the Account settings > Delete
                            account
                            button."
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AccountDeletionPage;
