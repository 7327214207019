import types from "../types";

const defaultState = {
  postData: {
    loading: false,
    error: "",
    data: "",
  },
  locationData: {
    loading: false,
    error: "",
    locations: [],
  },
  users: {
    loading: false,
    error: "",
    data: [],
  },
  deleteUsers: {
    loading: false,
    error: "",
    success: false,
  },
};

const manageUsers = (state = defaultState, action) => {
  switch (action.type) {
    case types.manageUsersConstants.MANAGE_USERS_POST_LOADING:
      return {
        ...state,
        postData: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.manageUsersConstants.MANAGE_USERS_POST_FAILED:
      return {
        ...state,
        postData: {
          data: "",
          loading: false,
          error: action.error,
        },
      };
    case types.manageUsersConstants.MANAGE_USERS_POST_SUCCESS:
      return {
        ...state,
        postData: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.manageUsersConstants.REFRESH_STATE:
      return {
        ...state,
        postData: {
          error: "",
          data: "",
          loading: false,
        },
      };
    case types.manageUsersConstants.GET_LOCATIONS_LOADING:
      return {
        ...state,
        locationData: {
          locations: [],
          error: "",
          loading: true,
        },
      };
    case types.manageUsersConstants.GET_LOCATIONS_FAILED:
      return {
        ...state,
        locationData: {
          locations: [],
          error: action.error,
          loading: false,
        },
      };
    case types.manageUsersConstants.LOCATIONS:
      return {
        ...state,
        locationData: {
          locations: action.data,
          error: "",
          loading: false,
        },
      };
    case types.manageUsersConstants.GET_USERS_LOADING:
      return {
        ...state,
        users: {
          data: [],
          error: "",
          loading: true,
        },
      };
    case types.manageUsersConstants.GET_USERS_FAILED:
      return {
        ...state,
        users: {
          data: [],
          error: action.error,
          loading: false,
        },
      };
    case types.manageUsersConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          data: action.data,
          error: "",
          loading: false,
        },
      };
    case types.manageUsersConstants.DELETE_USERS_LOADING:
      return {
        ...state,
        deleteUsers: {
          success: false,
          error: "",
          loading: true,
        },
      };
    case types.manageUsersConstants.DELETE_USERS_FAILED:
      return {
        ...state,
        deleteUsers: {
          success: false,
          error: action.error,
          loading: false,
        },
      };
    case types.manageUsersConstants.DELETE_USERS_SUCCESS:
      return {
        ...state,
        deleteUsers: {
          success: true,
          error: "",
          loading: false,
        },
      };
    case types.manageUsersConstants.RESET_DELETE_USERS:
      return {
        ...state,
        deleteUsers: {
          success: false,
          error: "",
          loading: false,
        },
      };
      case types.manageUsersConstants.CLEAR_GET_LOCATION_LIST_ERROR : 
      return {
        ...state,
        locationData: {
          locations : "",
          error: "",
          loading: false
        }
      };
      case types.manageUsersConstants.CLEAR_GET_USER_ERROR : 
      return {
        ...state,
        users: {
          data : [],
          error: "",
          loading: false
        }
      }
    default:
      return state;
  }
};
export default manageUsers;
