import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {connect} from "react-redux";
import ButtonComponent from "../../../../components/ButtonComponent";
import "./style.css";
import CompanyIcon from "../../../../assets/website.png";
import FbIcon from "../../../../assets/facebookBtn.png";
import TwitterIcon from "../../../../assets/twitterBtn.png";
import InstagramIcon from "../../../../assets/instagramBtn.png";
import YoutubeIcon from "../../../../assets/googleBtn.png";
import validator from "validator";
import {useSnackbar} from "notistack";
import {clearSocialLinksState, setSocialLinks,} from "../../../../redux/actions/setSocialLinksAction";

const SocialLinks = (props) => {
  const {
    setSocialLinksAction,
    data,
    clearState,
    socialLinksState,
    meData,
  } = props;
  const [socialData, setSocialData] = useState({
    company_url: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
  });
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    clearState();
  }, []);

  useEffect(() => {
    if (data) {
      setSocialData({
        company_url: data.company_url || "",
        facebook_url: data.facebook_url || "",
        twitter_url: data.twitter_url || "",
        instagram_url: data.instagram_url || "",
        youtube_url: data.youtube_url || "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (!socialLinksState.loading) {
      if (socialLinksState.links && !socialLinksState.error) {
        setBtnLoading(false);
        enqueueSnackbar("Social Urls updated Successfully", {
          variant: "success",
        });
        setButtonEnabled(false);
        clearState();
      } else if (!socialLinksState.links && socialLinksState.error) {
        setBtnLoading(false);
        enqueueSnackbar("Social Url update failed", {
          variant: "error",
        });
        clearState();
      }
    } else {
      //console.log(btnLoading);
      setBtnLoading(true);
    }
  }, [socialLinksState]);

  useEffect(() => {
    if (
      data?.company_url === socialData.company_url &&
      data?.facebook_url === socialData.facebook_url &&
      data?.youtube_url === socialData.youtube_url &&
      data?.instagram_url === socialData.instagram_url &&
      data?.twitter_url === socialData.twitter_url
    ) {
      setButtonEnabled(false);
    } else {
      setButtonEnabled(true);
    }
  }, [socialData])

  const refresh = () => {
    setSocialData({
      company_url: data.company_url || "",
      facebook_url: data.facebook_url || "",
      twitter_url: data.twitter_url || "",
      instagram_url: data.instagram_url || "",
      youtube_url: data.youtube_url || "",
    });
    setButtonEnabled(false);
  };

  const validateSocialData = () => {
    const errors = {};
    const fbRegex = new RegExp(
      "(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?"
    );
    const youtubeRegex = new RegExp(
      "~(?:https?://)?(?:www.)?youtu(?:be.com/watch?(?:.*?&(?:amp;)?)?v=|.be/)([w-]+)(?:&(?:amp;)?[w?=]*)?~"
    );
    if (socialData.company_url && !validator.isURL(socialData.company_url)) {
      errors.company_url = "Invalid Company URL";
      // enqueueSnackbar(errors.company_url, {
      //   variant: "error",
      // });
    }
    // if (
    //   socialData.facebook_url &&
    //   (!validator.isURL(socialData.facebook_url) || !fbRegex.test(socialData.facebook_url))
    // ) {
    //   errors.facebook_url = "Invalid Facebook URL";
    //   // enqueueSnackbar(errors.facebook_url, {
    //   //   variant: "error",
    //   // });
    // }
    if (
      socialData.youtube_url &&
      !validator.isURL(socialData.youtube_url) &&
      !youtubeRegex.test(socialData.youtube_url)
    ) {
      errors.youtube_url = "Invalid Youtube URL";
      // enqueueSnackbar(errors.youtube_url, {
      //   variant: "error",
      // });
    }
    if (socialData.twitter_url && socialData.twitter_url[0] === "@") {
      errors.twitter_url = "Twitter handle does not require @. Please remove it";
      // enqueueSnackbar(errors.twitter_url, {
      //   variant: "error",
      // });
    }

    if (socialData.instagram_url && socialData.instagram_url[0] === "@") {
      errors.instagram_url = "Instagram handle does not require @. Please remove it";
      // enqueueSnackbar(errors.instagram_url, {
      //   variant: "error",
      // });
    }
    if (Object.keys(errors).length > 0) {
      return setErrors(errors);
    }
    setErrors({})
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target) {
      setSocialData({...socialData, [e.target.name]: e.target.value});
    }
  };

  const handleSubmit = () => {
    if (!validateSocialData()) {
      setBtnLoading(false);
      return;
    } else {
      setSocialLinksAction(
        {
          company_url: socialData.company_url,
          facebook_url: socialData.facebook_url,
          instagram_url: socialData.instagram_url,
          twitter_url: socialData.twitter_url,
          youtube_url: socialData.youtube_url,
          share_social_url: true,
        },
        meData
      );
    }
  };
  return (
    <div id="profileCard">
      <Row>
        <Col>
          <div className="profile-card-header">Link to social</div>
          <div className="d-flex flex-column  align-items-lg-start my-3">
            <div className="d-flex flex-row align-items-center mb-3">
              <div>
                <img
                  src={CompanyIcon}
                  alt="Company Website"
                  height="40px"
                  width="40px"
                />
              </div>

              <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
                <Form.Control
                  placeholder="Company Url"
                  required
                  value={socialData.company_url}
                  isInvalid={errors.company_url}
                  onChange={(e) => handleChange(e)}
                  className="ep-social-input"
                  name="company_url"
                />
                {errors.company_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.company_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="d-flex flex-row align-items-center mb-3">
              <div>
                <img src={FbIcon} alt="Facebook" height="40px" width="40px"/>
              </div>

              <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
                <Form.Control
                  placeholder="Facebook handle / URL"
                  required
                  className="ep-social-input"
                  value={socialData.facebook_url}
                  isInvalid={errors.facebook_url}
                  onChange={(e) => handleChange(e)}
                  name="facebook_url"
                />
                {errors.facebook_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.facebook_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="d-flex flex-row align-items-center mb-3">
              <div>
                <img
                  src={TwitterIcon}
                  alt="Twitter"
                  height="40px"
                  width="40px"
                />
              </div>

              <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
                <Form.Control
                  placeholder="Twitter handle / URL"
                  required
                  onChange={(e) => handleChange(e)}
                  isInvalid={errors.twitter_url}
                  value={socialData.twitter_url}
                  className="ep-social-input"
                  name="twitter_url"
                />
                {errors.twitter_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.twitter_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="d-flex flex-row align-items-center mb-3">
              <div>
                <img
                  src={InstagramIcon}
                  alt="Instagram"
                  height="40px"
                  width="40px"
                />
              </div>

              <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
                <Form.Control
                  placeholder="Instagram handle / URL"
                  required
                  value={socialData.instagram_url}
                  isInvalid={errors.instagram_url}
                  onChange={(e) => handleChange(e)}
                  className="ep-social-input"
                  name="instagram_url"
                />
                {errors.instagram_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.instagram_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="d-flex flex-row align-items-center mb-3">
              <div>
                <img
                  src={YoutubeIcon}
                  alt="Youtube"
                  height="40px"
                  width="40px"
                />
              </div>

              <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
                <Form.Control
                  placeholder="YouTube URL"
                  required
                  value={socialData.youtube_url}
                  isInvalid={errors.youtube_url}
                  onChange={(e) => handleChange(e)}
                  className="ep-social-input"
                  name="youtube_url"
                />
                {errors.youtube_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.youtube_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-end mt-3">
        <ButtonComponent
          idName="saveBtn"
          name="Save"
          disabled={socialLinksState.loading || !buttonEnabled}
          loading={btnLoading}
          onClickFunction={() => handleSubmit()}
        />
        {buttonEnabled && (
          <ButtonComponent
            className={"profile-cancel-btn"}
            name="Cancel"
            type={"outline"}
            disabled={!buttonEnabled || btnLoading}
            onClickFunction={() => refresh()}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  socialLinksState: state.setSocialLinks,
  meData: state.me.meResponse,
});

const mapDispatchToProps = (dispatch) => ({
  setSocialLinksAction: (socialData, meData) =>
    dispatch(setSocialLinks(socialData, meData)),
  clearState: () => dispatch(clearSocialLinksState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SocialLinks);
