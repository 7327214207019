import axiosInstance from "../../services/api/axios";
import types from "../types";
import {meSuccess} from "./me";

const setSuccess = (data) => ({
    type: types.SET_APPLICATION_TYPE,
    data,
});

const setFailed = (error) => ({
    type: types.SET_APPLICATION_TYPE_FAILED,
    error,
});

const setLoading = () => ({
    type: types.SET_APPLICATION_TYPE_LOADING,
});

export const setApplicationType = (data, meData, showNotification, profile_completed_up_to = null) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading())
            const response = await axiosInstance.post("/business/application-type/", {
                application_type: data[0],
            });
            if (response && response.data && response.status === 200) {
                dispatch(setSuccess(data[0]))
                if (meData && data[0] === 'jipe_application' || 'online_application') {
                    dispatch(meSuccess({...meData, data: {...meData.data, application_type: data[0]}}))
                    if (profile_completed_up_to) {
                        dispatch(meSuccess({
                            ...meData,
                            data: {
                                ...meData.data,
                                application_type: data[0],
                                profile_completed_up_to: profile_completed_up_to
                            }
                        }))
                    }
                }
                if (showNotification) {
                    showNotification("Application type updated successfully", "success")
                }
            }
        } catch (err) {
            dispatch(setFailed(err.message));
            if (showNotification) {
                showNotification("Application type update Failed", "error")
            }
        }
    };
};
