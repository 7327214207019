import React, {Fragment, useEffect, useState} from "react";
import {Toast} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {onMessageListener, requestFirebaseNotificationPermission} from '../../services/firebaseInit'
import NavBarComponent from "../NavBarComponent";
import FooterComponent from "../FooterComponent";
import './style.css';
import {connect} from "react-redux";
import useSocket from "../../utils/useSocket";


const FullLayoutComponent = (props) => {
    const {messaging, meData, recipientListState} = props
    const [show, setShow] = useState(false);
    const [pushNotification, setPushNotification] = useState({title: '', body: ''});
    const [hasNewMessages, setHasNewMessages] = useState(false)
    const {data, socket, emitEvent} = useSocket(process.env.REACT_APP_SOCKET_BASE_URL)

    useEffect(() => {
        if (messaging) {
            setHasNewMessages(false)
        }
    }, [messaging])

    useEffect(() => {
        if (!messaging) {
            if (recipientListState?.data) {
                const hasUnread = recipientListState?.data?.results?.filter(recipient => recipient.conversation?.has_unread_messages)
                if (hasUnread?.length) {
                    setHasNewMessages(true)
                } else {
                    setHasNewMessages(false)
                }
            }
        }
    }, [recipientListState])

    useEffect(() => {
        if (socket) {
            emitEvent('join', [meData?.user_id]);
        }
    }, [socket]);


    useEffect(() => {
        if (data) {
            if (!messaging) {
                setHasNewMessages(true)
            }
        }
    }, [data])


    requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
        })
        .catch((err) => {
            return err;
        });

    onMessageListener()
        .then((payload) => {
            const {title, body} = payload.notification;

            setShow(true)
            setPushNotification({
                title: title,
                body: body
            })
        })
        .catch((err) => {
            //console.log("on catch errorin messaging == ", err);
        });


    return (
        <Fragment>
            <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
                position: 'absolute',
                top: 20,
                right: 20,
                minWidth: 200
            }}>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded mr-2"
                        alt=""
                    />
                    <strong className="mr-auto">{pushNotification.title}</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body>{pushNotification.body}</Toast.Body>
            </Toast>
            <div className="navbar-container">
                <NavBarComponent {...props} hasNewMessages={hasNewMessages}/>
            </div>
            <div className="body-container" style={{height: `calc(100% - ${messaging ? '70px' : '180px'})`}}>
                {props.children}
            </div>
            {
                !messaging && (
                    <div className="footer-container">
                        <FooterComponent/>
                    </div>
                )
            }
        </Fragment>
    )
};

const mapStateToProps = (state) => ({
    meData: state.me?.meResponse?.data,
    recipientListState: state.messagingReducer?.recipient,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FullLayoutComponent)
