import './style.css';
import React from "react";
import {useHistory} from 'react-router-dom';
import ButtonComponent from "../../../components/ButtonComponent";
import {Image} from "react-bootstrap";
import jipeBlue from "../../../assets/jipe-logo-blue.svg";

const ErrorPage = () => {
  const history = useHistory()
  const goHome = () => history.push("/location-manager");
  return (
    <div className="main-wrapper">
      <div className="content-wrapper">
        <div style={{padding: "10px"}}>
          <Image src={jipeBlue} alt="user" className='jipe-logo'/>
        </div>
        <div className="error-text-container">
          <div className="error-one-day-txt">One day, You'll ask yourself..</div>
          <div className="error-info-txt">How did I get here? This page doesn't exist! Use the button below to go back home.</div>
          <ButtonComponent idName='home-btn' name='Home' onClickFunction={goHome}/>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage;
