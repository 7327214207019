import React from "react";
import "./style.css";
import {StepperComponent} from "./StepperComponent/index";
import {Container} from "react-bootstrap";

const BillingPage = (props) => {
  return (
    <Container fluid className="h-100 d-flex flex-row justify-content-center align-items-center"
               style={{paddingBottom: '150px'}}>
      <StepperComponent props={props}/>
    </Container>
  )
};

export default BillingPage;
