import {Accordion, Col, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import ButtonComponent from "../../../../components/ButtonComponent";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import './style.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight, faDollarSign, faTimes} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {getLocations} from "../../../../redux/actions";
import * as createJobActions from "../../../../redux/actions/createJob";
import * as updateJobActions from "../../../../redux/actions/updateJob";
import * as getJobDetailsActions from "../../../../redux/actions/getJobDetailsAction";
import * as meActions from "../../../../redux/actions/me";
import {useSnackbar} from "notistack";
import BillingConfirmationModal from "../../../../components/BillingConfirmationModal";
import {getCurrentPricingPlan} from "../../../../redux/actions/billingAction";
import {convertTime, days, hasConflict, timeOptions} from '../../../../utils/availabilityUtils'
import {checkTimeAvailability, checkTimeMayVary} from "../../../../utils/utils";
import {useHistory} from "react-router-dom";

const initialJobCreationValue = {
    title: null,
    number: null,
    min_age: null,
    hourly_rate: null,
    online_application_url: null,
    locations: {},
    availabilities: {
        types: [],
        time_sheets: [
            {
                day: 1,
                may_vary: false,
                time_slots: []
            },
            {
                day: 2,
                may_vary: false,
                time_slots: []
            },
            {
                day: 3,
                may_vary: false,
                time_slots: []
            },
            {
                day: 4,
                may_vary: false,
                time_slots: []
            },
            {
                day: 5,
                may_vary: false,
                time_slots: []
            },
            {
                day: 6,
                may_vary: false,
                time_slots: []
            },
            {
                day: 7,
                may_vary: false,
                time_slots: []
            }
        ]
    }
}

const AddPositionModal = (props) => {
    const {
        id,
        allLocationsData,
        getLocationDataAction,
        meDataState,
        create_job,
        update_job,
        refresh_add_job,
        refresh_update_job,
        getMeAction,
        createJob,
        updateJob,
        clearSelectedJobDetailsError,
        getSelectedJobDetails,
        jobDetailsData,
        getSelectedLocation,
        fetchCurrentPricingPlan,
        currentPricingPlanState,
        setShow
    } = props;
    let formValues = {};
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const [errors, setErrors] = useState({
        type: "Please select atleast one job type"
    });
    const [jobType, setJobType] = useState([]);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [timeToAdd, setTimeToAdd] = useState({day: null, start: null, end: null});
    const [btnLoading, setBtnLoading] = useState(false);
    const [jobCreationResponsibility, setJobCreationResponsibility] = useState({});
    const [jobCreationData, setJobCreationData] = useState(initialJobCreationValue);
    const [billingConfirmationModal, setBillingConfirmationModal] = useState({state: false, displayInfo: ""});

    useEffect(() => {
        if (!meDataState.meResponse) {
            getMeAction()
        }
        if (allLocationsData.length < 1) {
            getLocationDataAction();
        }
    }, []);

    useEffect(() => {
        if (props.show) {
            if (allLocationsData.length > 0) {
                allLocationsData.map((loc) => {
                    if (loc.location_id === id) {
                        locationSelected({value: loc?.location_id, label: loc?.location_title})
                        setJobCreationData({
                            ...jobCreationData,
                            locations: {value: loc?.location_id, label: loc?.location_title},
                            availabilities: props.openForEdit ? jobCreationData.availabilities : {
                                types: [],
                                time_sheets: [...jobCreationData?.availabilities?.time_sheets]
                            }
                        })
                    }
                })
            }
        }
    }, [allLocationsData])

    useEffect(() => {
        if (props.openForEdit && props.idOfEditableJob?.id) {
            getSelectedJobDetails(props.idOfEditableJob?.id)
            setErrors({})
        }
    }, [props.openForEdit])

    useEffect(() => {
        if (props.openForEdit) {
            if (jobDetailsData.error && !jobDetailsData.loading) {
                enqueueSnackbar("Could not fetch Job data!", {
                    variant: "error",
                });
                clearSelectedJobDetailsError();
            } else {
                if (jobDetailsData.data) {
                    if (jobDetailsData?.data?.responsibilities.length > 0) {
                        // setting responsibilities
                        let resps = {}
                        jobDetailsData.data.responsibilities.map((resp, index) => {
                            resps = {...resps, ['responsibility'.concat(index + 1)]: resp}
                        })
                        setJobCreationResponsibility(resps)
                    }

                    // setting jobType
                    if (jobDetailsData?.data?.availabilities?.types.length > 0) {
                        let typeOfJobs = []
                        jobDetailsData.data.availabilities.types.map((type) => {
                            typeOfJobs.push(type.type)
                        })
                        setJobType(typeOfJobs)
                    }

                    setJobCreationData({
                        ...jobCreationData,
                        title: jobDetailsData?.data?.title || "",
                        number: jobDetailsData?.data?.number || null,
                        min_age: jobDetailsData?.data?.min_age || null,
                        hourly_rate: jobDetailsData?.data?.hourly_rate || null,
                        locations: {
                            value: jobDetailsData?.data?.address[0].id,
                            label: jobDetailsData?.data?.address[0].name
                        },
                        online_application_url: jobDetailsData?.data?.application_type === "online_application" ? jobDetailsData?.data?.online_application_url : "",
                        availabilities: {...jobDetailsData?.data?.availabilities}
                    })
                }
            }
        }
    }, [jobDetailsData.data])

    useEffect(() => {
        if (updateJob.error && !updateJob.loading) {
            try {
                Object.values(updateJob?.error?.response?.data).map((err) => {
                    enqueueSnackbar(err, {
                        variant: 'error',
                    });
                })
            } catch (err) {
                enqueueSnackbar("Network Error. Please check your connection", {
                    variant: 'error',
                });
            }
            setBtnLoading(false);
        } else {
            //console.log(props.openForEdit);
            if (updateJob.updateJobData && props.openForEdit) {
                clearModal()
                setBtnLoading(false);
                enqueueSnackbar(updateJob.updateJobData.message ? updateJob.updateJobData.message : "Success", {variant: "success"});
                refresh_update_job();
                props.onHide();
            }
        }
    }, [updateJob])

    // remove all the availabilities that has null value and also deletes the type object as we will be handling the type of jobs using jobType state
    const removeEmptyOrNull = (obj) => {
        Object.keys(obj).forEach(k =>
            (obj[k] && typeof obj[k] === 'object') && removeEmptyOrNull(obj[k]) ||
            (!obj[k] && obj[k] !== undefined) && delete obj[k]
        );
        return obj;
    };

    useEffect(() => {
        if (!createJob.loading) {
            if (createJob.error) {
                try {
                    Object.values(createJob.error.response.data).map((err) => {
                        enqueueSnackbar(err, {
                            variant: 'error',
                        });
                    })
                } catch (err) {
                    enqueueSnackbar("Internal Error !!! Error creating job", {
                        variant: 'error',
                    });
                }
                setBillingConfirmationModal({state: false, displayInfo: ""});
                setBtnLoading(false);
            } else {
                if (createJob.createJobData && createJob.createJobData.data.id) {
                    clearModal();
                    setBtnLoading(false);
                    setBillingConfirmationModal({state: false, displayInfo: ""});
                    props.onHide();
                    refresh_add_job();
                    getLocationDataAction();
                    fetchCurrentPricingPlan();
                    history.go(0)
                    enqueueSnackbar(createJob.createJobData.message ? createJob.createJobData.message : "Success", {variant: "success"});
                }
            }
        }
    }, [createJob])

    //Set active button state for availability buttons
    const setActiveAvailability = (availability) => {
        setJobCreationData({...jobCreationData, availabilities: {...jobCreationData.availabilities, types: []}});
        if (jobType.includes(availability) && jobType.length === 1) {
            setJobType((prevData) => prevData.filter(item => item !== availability))
            setErrors({
                type: "Please select atleast one job type",
            })
        } else if (jobType.includes(availability)) {
            setJobType((prevData) => prevData.filter(item => item !== availability))
        } else {
            setJobType([
                ...jobType,
                availability
            ])
            setErrors((prevData) => {
                const newData = {...prevData}
                delete newData["type"]
                return newData
            })
        }
    }

    //Responsibility input lsitener
    const onResponsibilityChange = (event) => {
        if (event) {
            const {id, value} = event.target;
            if (!value) {
                setJobCreationResponsibility((prevData) => {
                    const newData = {...prevData}
                    delete newData[id]
                    return newData
                })
            } else {
                setJobCreationResponsibility({...jobCreationResponsibility, [id]: {name: value}})
            }
        }
    };

    const handleSubmit = () => {
        if (checkTimeAvailability(jobCreationData.availabilities.time_sheets) || checkTimeMayVary(jobCreationData.availabilities.time_sheets)) {
            if (Object.keys(errors).length === 0) {
                if (props.openForClone) {
                    runJobCreationValidations()
                } else {
                    if (props.openForEdit) {
                        setBtnLoading(true);
                        formValues.title = jobCreationData.title;
                        formValues.hourly_rate = jobCreationData.hourly_rate;
                        formValues.min_age = jobCreationData.min_age;
                        formValues.number = jobCreationData.number;
                        formValues.responsibilities = Object.values(jobCreationResponsibility);
                        formValues.availabilities = jobCreationData.availabilities;
                        jobType.map(data => {
                            formValues.availabilities.types.push({type: data});
                        });
                        if (jobCreationData.locations.value) {
                            formValues.locations = [{location_id: jobCreationData.locations.value}]
                        } else {
                            formValues.locations = [{location_id: props.id}]
                        }
                        if (jobCreationData.online_application_url) {
                            formValues.online_application_url = jobCreationData.online_application_url;
                        }
                        formValues.job_id = props.idOfEditableJob?.id
                        setBtnLoading(true);
                        update_job(formValues, id)
                    } else {
                        //console.log(currentPricingPlanState.data)
                        runJobCreationValidations()
                    }
                }
            } else {
                Object.values(errors).map((err) => {
                    enqueueSnackbar(err, {
                        variant: 'error',
                    });
                })
            }
        } else {
            enqueueSnackbar("Please enter at least one time availability!", {
                variant: 'error',
            });
        }
    }

    const runJobCreationValidations = () => {
        if (currentPricingPlanState.data?.id) {
            if (!currentPricingPlanState.data?.is_bundle_type) {
                setBillingConfirmationModal({
                    state: true,
                    displayInfo: `This job listing will charge your account $${currentPricingPlanState.data?.amount} and immediately post your job. Confirm to continue.`
                });
            } else if (currentPricingPlanState.data?.is_bundle_type && currentPricingPlanState.data?.remaining_credits > 0) {
                setBillingConfirmationModal({
                    state: true,
                    displayInfo: "Creating this job will use 1 credit from your remaining " + currentPricingPlanState.data?.remaining_credits + ". Do you wish to continue ?"
                });
            } else if (currentPricingPlanState.data?.is_bundle_type && currentPricingPlanState.data?.remaining_credits === 0) {
                setShow({status: true, modal: "package"});
                enqueueSnackbar("Out of credits, please select a package to continue", {
                    variant: "error",
                });
            }
        } else {
            setShow({status: true, modal: "package"});
            enqueueSnackbar("Please select a package to continue", {
                variant: "error",
            });
        }
    }

    const confirmationModalToggle = (data) => {
        formValues.title = jobCreationData.title;
        formValues.hourly_rate = jobCreationData.hourly_rate;
        formValues.min_age = jobCreationData.min_age;
        formValues.number = jobCreationData.number;
        formValues.responsibilities = Object.values(jobCreationResponsibility);
        formValues.availabilities = jobCreationData.availabilities;
        jobType.map(data => {
            formValues.availabilities.types.push({type: data});
        });
        if (jobCreationData.locations.value) {
            formValues.locations = [{location_id: jobCreationData.locations.value}]
        } else {
            formValues.locations = [{location_id: props.id}]
        }
        if (jobCreationData.online_application_url) {
            formValues.online_application_url = jobCreationData.online_application_url;
        }
        if (data) {
            setBtnLoading(true);
            create_job(formValues, id);
        } else {
            setBillingConfirmationModal({
                state: false,
                displayInfo: ""
            });
        }
    }

    const getLocations = () => {
        if (allLocationsData?.length > 0) {
            let value = [];
            allLocationsData.map((loc) => {
                value.push({value: loc?.location_id, label: loc?.location_title})
            })
            return value;
        }
    }

    const locationSelected = (event) => {
        setJobCreationData({...jobCreationData, locations: event});
    }

    const handleModalHide = () => {
        clearModal()
        props.onHide()
    }

    const clearModal = () => {
        setJobCreationData({...initialJobCreationValue})
        setJobCreationResponsibility({})
        setJobType([])
        setErrors({
            type: "Please select atleast one job type"
        })
    }

    //Onchange event trigger to filter and update the state of the input fields of the position modal
    const onInputChange = (event) => {
        if (event.currentTarget.id) {
            Object.keys(jobCreationData).map(key => {
                if (key === event.currentTarget.id) {
                    setJobCreationData({...jobCreationData, [key]: event.target.value});
                }
            });
        }
    };

    const removeTimeSlot = (day, index) => {
        let currentTimesheet = jobCreationData.availabilities.time_sheets.find(data => data.day === day)
        currentTimesheet.time_slots.splice(index, 1);
        setJobCreationData({...jobCreationData})
    }

    const saveSelectedTime = () => {
        if (timeToAdd.start && timeToAdd.end) {
            const availabilityDataChunks = hasConflict(timeToAdd.start.value, timeToAdd.end.value, jobCreationData.availabilities.time_sheets, timeToAdd.day)
            if (!availabilityDataChunks.error && !availabilityDataChunks.hasConflict) {
                setTimeToAdd(undefined);
                Object.keys(availabilityDataChunks.toAdd[0]).map(item => {
                    let currentTimesheet = jobCreationData.availabilities.time_sheets.find(data => data.day === +item)
                    if (currentTimesheet) {
                        currentTimesheet.time_slots.push(availabilityDataChunks.toAdd[0][item])
                        jobCreationData.availabilities.time_sheets.find(
                            (data, idx) => {
                                if (data.day === +item) {
                                    jobCreationData.availabilities.time_sheets[idx] = currentTimesheet;
                                }
                            });
                        //updating the state of job creation data
                        setJobCreationData({...jobCreationData})
                    }
                })
            } else {
                enqueueSnackbar(availabilityDataChunks.error, {variant: "error"});
            }
        } else {
            enqueueSnackbar("Please add in both from and to values before saving!", {variant: "error"});
        }
    }

    const timesMayVary = (index, event) => {
        const oldTimesheet = jobCreationData.availabilities.time_sheets[index]
        const updatedSheet = {...oldTimesheet, may_vary: event.currentTarget.checked}
        setJobCreationData(prevState => ({
            ...prevState,
            availabilities: {
                ...prevState.availabilities,
                time_sheets: prevState.availabilities.time_sheets.map((time, idx) => {
                    if (idx === index) {
                        return {...updatedSheet}
                    } else {
                        return time
                    }
                })
            }
        }))
    }

    return (
        <>
            <Modal show={props.show} dialogClassName="position-modal" keyboard={false}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Container className="modal-body-container">
                    <div className="information-div">
                        <div className="position-modal-title">
                            {props.openForClone ? "Clone job listing" : props.openForEdit ? "Edit job listing" : "Add a job listing"}
                            <FontAwesomeIcon icon={faTimes} id="position-modal-close"
                                             onClick={() => handleModalHide()}/>
                        </div>

                        <Row className="position-modal-body">
                            <Col className="pr-0">
                                <div className="position-section-header">
                                    Basic job info
                                </div>

                                <div>
                                    <Form.Group controlId="title" className="my-3">
                                        <Form.Control placeholder="Job title" required className="position-input-green"
                                                      value={jobCreationData.title}
                                                      onChange={onInputChange}/>
                                    </Form.Group>

                                    <Select
                                        value={jobCreationData.locations}
                                        onChange={locationSelected}
                                        options={getLocations()}
                                        placeholder={'Location'}
                                        isMulti={false}
                                        id="position-select"
                                    />
                                    <Row className="m-3">
                                        <Col lg="auto" className="p-0">
                                            <Form.Check aria-label="restaurant"/>
                                        </Col>
                                        <Col id="send-application-txt">Send applications to hiring manager</Col>
                                    </Row>

                                    <Form.Group controlId="number" className="my-3">
                                        <Form.Control placeholder="Requisition/Job number (optional)"
                                                      className="position-input-green"
                                                      value={jobCreationData.number} type="number"
                                                      onChange={onInputChange}/>
                                    </Form.Group>

                                    <Form.Group controlId="min_age" className="my-3">
                                        <Form.Control placeholder="Min age required" required
                                                      className="position-input-green"
                                                      value={jobCreationData.min_age} type="number"
                                                      onChange={onInputChange}/>
                                    </Form.Group>

                                    <Form.Group controlId="hourly_rate" className="my-3">
                                        <div className="price-input-wrapper">
                                            <FontAwesomeIcon icon={faDollarSign} style={{width: "10%"}}
                                                             className="dollar-prefix-icon"/>
                                            <Form.Control placeholder="Starting hourly rate" required
                                                          className="hourly-rate-input"
                                                          value={jobCreationData.hourly_rate} type="number"
                                                          onChange={onInputChange}/>
                                        </div>
                                    </Form.Group>

                                    {meDataState?.meResponse?.data?.application_type === "online_application" ?
                                        <>
                                            <div style={{width: '100%'}}>
                                                <Form.Group controlId="online_application_url">
                                                    <Form.Control placeholder="Enter job application URL" required
                                                                  value={jobCreationData.online_application_url}
                                                                  className="position-input-green"
                                                                  type="text" onChange={onInputChange}/>
                                                </Form.Group>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>

                                <div className="position-section-header">
                                    Job responsibilities
                                </div>

                                <div className="my-2">
                                    <Form.Group controlId="responsibility1" onChange={onResponsibilityChange}>
                                        <Form.Control placeholder="Responsibility 1"
                                                      value={jobCreationResponsibility?.responsibility1?.name || ""}
                                                      required
                                                      className="responsibility-input-green"
                                                      maxLength="150"/>
                                    </Form.Group>

                                    <Form.Group controlId="responsibility2" onChange={onResponsibilityChange}>
                                        <Form.Control placeholder="Responsibility 2"
                                                      value={jobCreationResponsibility?.responsibility2?.name || ""}
                                                      className="responsibility-input-green"
                                                      disabled={!jobCreationResponsibility.responsibility1}
                                                      maxLength="150"/>
                                    </Form.Group>

                                    <Form.Group controlId="responsibility3" onChange={onResponsibilityChange}>
                                        <Form.Control placeholder="Responsibility 3"
                                                      value={jobCreationResponsibility?.responsibility3?.name || ""}
                                                      className="responsibility-input-green"
                                                      disabled={!jobCreationResponsibility.responsibility2}
                                                      maxLength="150"/>
                                    </Form.Group>

                                    <Form.Group controlId="responsibility4" onChange={onResponsibilityChange}>
                                        <Form.Control placeholder="Responsibility 4"
                                                      value={jobCreationResponsibility?.responsibility4?.name || ""}
                                                      className="responsibility-input-green"
                                                      disabled={!jobCreationResponsibility.responsibility3}
                                                      maxLength="150"/>
                                    </Form.Group>

                                    <Form.Group controlId="responsibility5" onChange={onResponsibilityChange}>
                                        <Form.Control placeholder="Responsibility 5"
                                                      value={jobCreationResponsibility?.responsibility5?.name || ""}
                                                      className="responsibility-input-green"
                                                      disabled={!jobCreationResponsibility.responsibility4}
                                                      maxLength="150"/>
                                    </Form.Group>
                                </div>
                            </Col>

                            <Col className="pl-0">
                                <div style={{height: "20%"}}>
                                    <div className="position-section-header px-5">
                                        Availability needed
                                    </div>

                                    <div className="px-5 d-flex flex-row flex-wrap justify-content-between">
                                        <button
                                            className={"availability-custom-btn " + (jobType.includes('Part Time') ? 'active-modal-btn' : '')}
                                            onClick={() => setActiveAvailability('Part Time')}>PART TIME
                                        </button>
                                        <button
                                            className={"availability-custom-btn " + (jobType.includes('Full Time') ? 'active-modal-btn' : '')}
                                            onClick={() => setActiveAvailability('Full Time')}>FULL TIME
                                        </button>
                                        <button
                                            className={"availability-custom-btn " + (jobType.includes('Temp/Seasonal') ? 'active-modal-btn' : '')}
                                            onClick={() => setActiveAvailability('Temp/Seasonal')}>TEMP/SEASONAL
                                        </button>
                                        <button
                                            className={"availability-custom-btn " + (jobType.includes('Internship') ? 'active-modal-btn' : '')}
                                            onClick={() => setActiveAvailability('Internship')}>INTERNSHIP
                                        </button>
                                    </div>
                                </div>

                                <div className="px-5" style={{height: "80%", overflowY: 'none'}}>
                                    <div className="position-section-header">
                                        Availability time
                                    </div>

                                    <Accordion onSelect={(active) => setActiveAccordion(active)} className="mt-3">
                                        {
                                            jobCreationData.availabilities.time_sheets.map((data, index) =>
                                                <div key={index}>
                                                    <Accordion.Toggle as={"div"} eventKey={index + 1}
                                                                      className="accordion-header">
                                                        <span>{days[index + 1]}</span>

                                                        <FontAwesomeIcon
                                                            icon={activeAccordion === (index + 1) ? faChevronDown : faChevronRight}
                                                            style={{color: 'black'}}/>

                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={index + 1}>
                                                        <div>
                                                            {data.time_slots.map((time, idx) => {
                                                                    if (time.start && time.end) {
                                                                        return (
                                                                            <div
                                                                                className="d-flex flex-row align-items-center selected-time-wrapper"
                                                                                key={idx}>
                                                                                <div
                                                                                    className="availability-time-label">{convertTime(time.start)}</div>
                                                                                <div
                                                                                    className="availability-time-label">to
                                                                                </div>
                                                                                <div
                                                                                    className="availability-time-label">{convertTime(time.end)}</div>
                                                                                <FontAwesomeIcon icon={faTimes}
                                                                                                 className="availability-delete-btn"
                                                                                                 onClick={() => removeTimeSlot(data.day, idx)}/>
                                                                            </div>)
                                                                    }
                                                                }
                                                            )}

                                                            {
                                                                (timeToAdd?.day !== data?.day && !data?.time_slots?.length > 0) &&
                                                                (
                                                                    <div
                                                                        className="d-flex mt-2 flex-row align-items-center">
                                                                        <input type="checkbox"
                                                                               onChange={(event) => timesMayVary(index, event)}
                                                                               checked={data.may_vary}
                                                                               value={data.may_vary}
                                                                               name={"times_may_vary"}/>
                                                                        <span className="ml-2" id={"time-vary-lbl"}>Times may vary</span>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                timeToAdd?.day === data?.day && !data.may_vary && (
                                                                    <div
                                                                        className="d-flex mt-2 flex-row align-items-center">
                                                                        <Select
                                                                            className="react-select-wrapper"
                                                                            placeholder="FROM"
                                                                            isClearable={true}
                                                                            options={timeOptions}
                                                                            onChange={value => setTimeToAdd({
                                                                                ...timeToAdd,
                                                                                start: value
                                                                            })}
                                                                        />

                                                                        <Select
                                                                            className="react-select-wrapper"
                                                                            placeholder="TO"
                                                                            isClearable={true}
                                                                            options={timeOptions}
                                                                            onChange={value => setTimeToAdd({
                                                                                ...timeToAdd,
                                                                                end: value
                                                                            })}
                                                                        />

                                                                        <ButtonComponent idName="availability-save-btn"
                                                                                         name='ADD'
                                                                                         onClickFunction={() => saveSelectedTime()}/>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                !data?.may_vary &&
                                                                (
                                                                    <div className="w-100 text-center mt-2">
                                                                        <ButtonComponent type="link"
                                                                                         idName="availability-add-btn"
                                                                                         name='add time slot'
                                                                                         onClickFunction={() => setTimeToAdd({
                                                                                             ...timeToAdd,
                                                                                             day: data.day
                                                                                         })}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </Accordion.Collapse>
                                                </div>
                                            )
                                        }
                                    </Accordion>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="button-div">
                        <div className="d-flex flex-row justify-content-center align-items-center h-100 w-100">
                            <ButtonComponent
                                name={props.openForClone ? "Clone job listing" : props.openForEdit ? "Edit job listing" : "Add a job listing"}
                                idName="create-opening-btn" type={'submit'} loading={props.openForEdit && btnLoading}
                                onClickFunction={handleSubmit}/>
                        </div>
                    </div>
                </Container>
            </Modal>

            <BillingConfirmationModal show={billingConfirmationModal.state} btnState={btnLoading}
                                      displayInfo={billingConfirmationModal.displayInfo}
                                      confirmation={(data) => confirmationModalToggle(data)}/>
        </>
    )
}

const mapStateToProps = (state) => ({
    allLocationsData: state.manageUsers.locationData.locations,
    meDataState: state.me,
    createJob: state.createJob,
    updateJob: state.updateJob,
    jobDetailsData: state.jobDetails.getData,
    currentPricingPlanState: state.setBillingInfo.currentPricingPlan,
});

const mapDispatchToProps = (dispatch) => ({
    getLocationDataAction: () => dispatch(getLocations()),
    create_job: (jobCreationData, id) => dispatch(createJobActions.createJob(jobCreationData, id)),
    update_job: (updateJobData, id) => dispatch(updateJobActions.updateJob(updateJobData, id)),
    refresh_add_job: () => dispatch(createJobActions.refreshJob()),
    refresh_update_job: () => dispatch(updateJobActions.refreshJob()),
    getMeAction: () => dispatch(meActions.me()),
    getSelectedJobDetails: (jobId) => dispatch(getJobDetailsActions.getJobDetails({job_id: jobId})),
    clearSelectedJobDetailsError: () => dispatch(getJobDetailsActions.clearGetJobDetailsError()),
    fetchCurrentPricingPlan: () => dispatch(getCurrentPricingPlan()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPositionModal);
