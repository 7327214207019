import types from '../types';
import * as userService from '../../services/api/userService';
import LocalStorageService from '../../utils/LocalStorageService';

const localStorageService = LocalStorageService;

const setLoadingAction = () => ({
    type: types.LOGIN_SET_LOADING,
});

const login = loginData => ({
    type: types.LOGIN,
    loginData,
});

const updateMe = response => ({
    type: types.ME,
    response,
});

const logout = () => ({
    type: "RESET_APP"
})

const loginFail = error => ({
    type: types.LOGIN_FAILED,
    error,
});

export const doLogin = (loginForm) => dispatch => {
    dispatch(setLoadingAction());
    return userService.login(loginForm).then(response => {
            localStorageService.setToken(response.data.data.token, response.data.data.refresh_token)
            localStorageService.setAccountType(response?.data?.data?.account_type)
            dispatch(updateMe(response.data))
            dispatch(login(response.data.data));
            return true;
        },
        error => {
            dispatch(loginFail(error));
        },
    );
};

export const onLogout = () => dispatch => {
    dispatch(logout())
}

