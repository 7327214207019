import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import ButtonComponent from "../../../../../components/ButtonComponent";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { postImage } from "../../../../../redux/actions";
import { setProfilePictureLoading } from "../../../../../redux/actions/setProfilePictureAction";
import ImageCropperComponent from '../../../../../components/ImageCropperComponent'
import { useSnackbar } from "notistack";

const Logo = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    picture,
    loading,
    error,
    postImage,
    meData,
    setProfilePictureLoading,
  } = props;
  const imgInput5 = useRef(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const [showImageCropper, setShowImageCropper] = useState(false)
  const [src, setSrc] = useState(null)

  const inputClicked = (input) => {
    input.current.click();
  };

  const onFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
      setSrc(reader.result)
      );
      reader.readAsDataURL(event.target.files[0]);
    }
    setShowImageCropper(true)
    props.navigationState(false);
  };

  const handleSubmit = () => {
    if (buttonEnabled) {
      const formData = new FormData();
      formData.append("file", selectedLogo);
      setProfilePictureLoading();
      postImage(formData, showNotification, meData, "business_logo");
    }
  };

  useEffect(() => {
    if (picture && !loading && !error) {
      setButtonEnabled(false);
      props.navigationState(true);
    } else if (error && !loading) {
    }
  }, [picture, error, loading]);

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  return (
    <>
      <div className="h-25">
        <div className="stepper-title">Logo</div>
        <div className="stepper-info">
          Select your logo image for your company profile. (500x500)
        </div>
      </div>
      <div className="logo-content h-75">
        <div
          className="logo-container"
          onClick={() => inputClicked(imgInput5)}
          style={{
            backgroundImage: `url(${
              selectedLogo ? URL.createObjectURL(selectedLogo) : picture
            })`,
          }}
        >
          {!selectedLogo && !picture ? (
            <FontAwesomeIcon icon={faImages} />
          ) : (
            <></>
          )}
        </div>
        <input
          accept="image/*"
          type="file"
          onChange={(e) => onFileChange(e)}
          onClick={(event)=> { event.target.value = null }}
          hidden={true}
          ref={imgInput5}
        />
        <ButtonComponent
          idName="logoUploadBtn"
          name="UPLOAD"
          onClickFunction={handleSubmit}
          disabled={loading || !selectedLogo || !buttonEnabled}
        />
      </div>
      <ImageCropperComponent show={showImageCropper} hide={() => setShowImageCropper(false)} type={"profile"} src={src} setButtonEnabled={setButtonEnabled} setSelectedImage={setSelectedLogo}/>
    </>
  );
};

const mapStateToProps = (state) => ({
  picture: state.setProfilePicture.profilePicture,
  loading: state.setProfilePicture.loading,
  error: state.setProfilePicture.error,
});

const mapDispatchToProps = (dispatch) => ({
  postImage: (data, showNotification, meData, profile_completed_up_to) =>
    dispatch(postImage(data, "profilePicture", showNotification, meData, profile_completed_up_to)),
  setProfilePictureLoading: () => dispatch(setProfilePictureLoading()),
});
export const LogoComponent = connect(mapStateToProps, mapDispatchToProps)(Logo);
