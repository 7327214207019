import React, {useEffect, useRef, useState} from "react";
import {Col, Row, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonComponent from "../../../../components/ButtonComponent";
import ImageCropperComponent from '../../../../components/ImageCropperComponent'
import * as setPhotosAndVideosActions from "../../../../redux/actions/setPhotosAndVideosAction";
import {setPhotoVideoLoading} from "../../../../redux/actions/setPhotosAndVideosAction";
import * as deleteMediaActions from "../../../../redux/actions/deleteMedia";
import {postImage} from "../../../../redux/actions";

import "./style.css";
import {useSnackbar} from "notistack";

const initialPhotoVideosState = {
  media1: null,
  media2: null,
  media3: null,
  media4: null,
  media5: null,
  media6: null,
}

const PhotoVideo = (props) => {

  const {enqueueSnackbar} = useSnackbar();

  const {
    meData,
    setPreviousPhotosAndVideos,
    removeDeletedMedia,
    setPhotosVidoes,
    deleteMedia,
    photoVideo,
    photoVideoError,
    loading,
    deleteMediaResponse
  } = props;

  const photoInput1 = useRef(null);
  const photoInput2 = useRef(null);
  const photoInput3 = useRef(null);
  const photoInput4 = useRef(null);
  const photoInput5 = useRef(null);
  const photoInput6 = useRef(null);

  const [photosAndVideos, setPhotosAndVideos] = useState(initialPhotoVideosState);
  const [btnLoading, setBtnLoading] = useState(false);

  const [showImageCropper, setShowImageCropper] = useState(false)
  const [src, setSrc] = useState(null)
  const [currentId, setCurrentId] = useState(null)

  useEffect(() => {
    if (meData?.data) {
      // setting previous photos and videos to enable edit and view
      setPreviousPhotosAndVideos(meData?.data?.media || "")
    }
  }, [meData])

  const handlePhotoVideoSubmit = () => {
    setBtnLoading(true);
    if (Object.values(photosAndVideos).find((a) => a !== null)) {
      setPhotoVideoLoading();
      setPhotosVidoes(photosAndVideos, showNotification);
    }
  };

  useEffect(() => {
    if (!loading) {
      setBtnLoading(false);
      if (photoVideo && !photoVideoError) {
        setPhotosAndVideos(initialPhotoVideosState)
      }
    } else {
      setBtnLoading(true);
    }
  }, [photoVideo, photoVideoError, loading]);

  // to remove the specific media from redux state after delete media is successful
  useEffect(() => {
    if (!deleteMediaResponse.loading) {
      setBtnLoading(false);
      if (deleteMediaResponse.error) {
        // handle delete media error here
        Object.values(deleteMediaResponse?.error?.response?.data).map((err) => {
          enqueueSnackbar(err, {
            variant: "error",
          });
        })
      } else {
        if (deleteMediaResponse.response) {
          showNotification(deleteMediaResponse?.response?.message || "Media Deleted Successfully", "success")
          // remove the deleted media from redux state
          if (deleteMediaResponse?.response?.data?.media_id) {
            removeDeletedMedia(deleteMediaResponse?.response?.data?.media_id)
          }
        }
      }
    } else {
      setBtnLoading(true);
    }
  }, [deleteMediaResponse])

  const inputClicked = (input) => {
    input.current.click();
  };

  const onPhotoFileChange = (event, id) => {
    //console.log("aaa == ", photosAndVideos);
    const selectedFile = event?.target?.files[0];
    if (selectedFile?.type?.includes("video")) {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => {
          if (media.duration < 61) {
            setPhotosAndVideos({...photosAndVideos, [id]: selectedFile});
          } else {
            alert("Video length too big, must be under a minute! ");
          }
        };
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setCurrentId(id)
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSrc(reader.result)
      );
      reader.readAsDataURL(event.target.files[0]);
      setShowImageCropper(true)
    }
  };

  const setCroppedImage = (croppedImage) => {
    setPhotosAndVideos({...photosAndVideos, [currentId]: croppedImage});
  }

  const removeImage = (id) => {
    //console.log("to be deleted == ", id);
    if (photoVideo.length >= parseInt(id.slice(-1))) {
      // call delete api and remove the image at parseInt(id.slice(-1))-1 index of the redux state if delete api is successfull
      try {
        if (photoVideo[parseInt(id.slice(-1)) - 1]) {
          deleteMedia(photoVideo[parseInt(id.slice(-1)) - 1]?.id)
        }
      } catch (err) {
        //console.log("error removing media from redux state = ", err)
      }
    } else {
      setPhotosAndVideos({...photosAndVideos, [id]: null});
    }
  };

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }
  return (
    <>
    <div id="profileCard">
      <Row>
        <Col>
          <div className="profile-card-header">Photos/Videos</div>

          <div className="d-flex flex-row align-items-center flex-wrap">
            <div
              className="profile-photo-video-container"
              style={{
                backgroundImage: `url(${
                  photosAndVideos.media1
                    ? URL.createObjectURL(photosAndVideos.media1)
                    : photoVideo.length > 0 ?
                    photoVideo[0].url
                    : ""
                })`,
              }}
            >{photosAndVideos.media1 && loading && <Spinner animation="border" className="spinner"/>}
              {!(photosAndVideos.media1) && (photoVideo.length === 0) ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                  onClick={() => inputClicked(photoInput1)}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{color: "#FFF"}}
                  />
                  <input
                    accept="image/*, video/mp4"
                    type="file"
                    onChange={(e) => onPhotoFileChange(e, "media1")}
                    onClick={(event)=> { event.target.value = null }}
                    hidden={true}
                    ref={photoInput1}
                  />
                </div>
              ) : (photosAndVideos.media1 &&
                photosAndVideos.media1.type === "video/mp4") || (photoVideo[0]?.type === "video") ? (
                <>
                  <video muted auto loop controls width="100%" height="100%">
                    <source
                      src={photoVideo[0]?.url
                        ? photoVideo[0].url
                        : URL.createObjectURL(photosAndVideos.media1)}
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <></>
              )}
              {photosAndVideos.media1 || photoVideo.length > 0 ? (
                <div
                  className="photo-remove-fab"
                  onClick={() => removeImage("media1")}
                >
                  <FontAwesomeIcon icon={faTimes}/>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="profile-photo-video-container"
              style={{
                backgroundImage: `url(${
                  photosAndVideos.media2
                    ? URL.createObjectURL(photosAndVideos.media2)
                    : photoVideo.length > 1 ?
                    photoVideo[1].url
                    : ""
                })`,
              }}
            >{photosAndVideos.media2 && loading && <Spinner animation="border" className="spinner"/>}
              {photosAndVideos.media1 || photoVideo.length === 1 ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                  onClick={() => inputClicked(photoInput2)}
                  style={{
                    visibility: photosAndVideos?.media2 ? "hidden" : "unset",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{color: "#FFF"}}
                  />
                  <input
                    accept="image/*, video/mp4"
                    type="file"
                    onChange={(e) => onPhotoFileChange(e, "media2")}
                    onClick={(event)=> { event.target.value = null }}
                    hidden={true}
                    ref={photoInput2}
                  />
                </div>
              ) : (photosAndVideos.media2 &&
                photosAndVideos.media2.type === "video/mp4") || (photoVideo[1]?.type === "video") ? (
                <>
                  <video muted auto loop controls width="100%" height="100%">
                    <source
                      src={photoVideo[1]?.url
                        ? photoVideo[1].url
                        : URL.createObjectURL(photosAndVideos.media2)}
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <></>
              )}
              {photosAndVideos.media2 || photoVideo.length > 1 ? (
                <div
                  className="photo-remove-fab"
                  onClick={() => removeImage("media2")}
                >
                  <FontAwesomeIcon icon={faTimes}/>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="profile-photo-video-container"
              style={{
                backgroundImage: `url(${
                  photosAndVideos.media3
                    ? URL.createObjectURL(photosAndVideos.media3)
                    : photoVideo.length > 2 ?
                    photoVideo[2].url
                    : ""
                })`,
              }}
            >{photosAndVideos.media3 && loading && <Spinner animation="border" className="spinner"/>}
              {!(photosAndVideos.media3) && (photoVideo.length === 2) || photosAndVideos.media2 ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                  onClick={() => inputClicked(photoInput3)}
                  style={{
                    visibility: photosAndVideos?.media3 ? "hidden" : "unset",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{color: "#FFF"}}
                  />
                  <input
                    accept="image/*, video/mp4"
                    type="file"
                    onChange={(e) => onPhotoFileChange(e, "media3")}
                    onClick={(event)=> { event.target.value = null }}
                    hidden={true}
                    ref={photoInput3}
                  />
                </div>
              ) : (photosAndVideos.media3 &&
                photosAndVideos.media3.type === "video/mp4") || (photoVideo[2]?.type === "video") ? (
                <>
                  <video muted loop controls width="100%" height="100%">
                    <source
                      src={photoVideo[2]?.url
                        ? photoVideo[2].url
                        : URL.createObjectURL(photosAndVideos.media3)}
                      type="video/m4v"
                    />
                  </video>
                </>
              ) : (
                <></>
              )}
              {photosAndVideos.media3 || photoVideo.length > 2 ? (
                <div
                  className="photo-remove-fab"
                  onClick={() => removeImage("media3")}
                >
                  <FontAwesomeIcon icon={faTimes}/>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="profile-photo-video-container"
              style={{
                backgroundImage: `url(${
                  photosAndVideos.media4
                    ? URL.createObjectURL(photosAndVideos.media4)
                    : photoVideo.length > 3 ?
                    photoVideo[3].url
                    : ""
                })`,
              }}
            >{photosAndVideos.media4 && loading && <Spinner animation="border" className="spinner"/>}
              {!(photosAndVideos.media4) && (photoVideo.length === 3) || photosAndVideos.media3 ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                  onClick={() => inputClicked(photoInput4)}
                  style={{
                    visibility: photosAndVideos?.media4 ? "hidden" : "unset",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{color: "#FFF"}}
                  />
                  <input
                    accept="image/*, video/mp4"
                    type="file"
                    onChange={(e) => onPhotoFileChange(e, "media4")}
                    onClick={(event)=> { event.target.value = null }}
                    hidden={true}
                    ref={photoInput4}
                  />
                </div>
              ) : (photosAndVideos.media4 &&
                photosAndVideos.media4.type === "video/mp4") || (photoVideo[3]?.type === "video") ? (
                <>
                  <video muted loop controls width="100%" height="100%">
                    <source
                      src={photoVideo[3]?.url
                        ? photoVideo[3].url
                        : URL.createObjectURL(photosAndVideos.media4)}
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <></>
              )}
              {photosAndVideos.media4 || photoVideo.length > 3 ? (
                <div
                  className="photo-remove-fab"
                  onClick={() => removeImage("media4")}
                >
                  <FontAwesomeIcon icon={faTimes}/>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="profile-photo-video-container"
              style={{
                backgroundImage: `url(${
                  photosAndVideos.media5
                    ? URL.createObjectURL(photosAndVideos.media5)
                    : photoVideo.length > 4 ?
                    photoVideo[4].url
                    : ""
                })`,
              }}
            > {photosAndVideos.media5 && loading && <Spinner animation="border" className="spinner"/>}
              {!(photosAndVideos.media5) && (photoVideo.length === 4) || photosAndVideos.media4 ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                  onClick={() => inputClicked(photoInput5)}
                  style={{
                    visibility: photosAndVideos?.media5 ? "hidden" : "unset",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{color: "#FFF"}}
                  />
                  <input
                    accept="image/*, video/mp4"
                    type="file"
                    onChange={(e) => onPhotoFileChange(e, "media5")}
                    onClick={(event)=> { event.target.value = null }}
                    hidden={true}
                    ref={photoInput5}
                  />
                </div>
              ) : (photosAndVideos.media5 &&
                photosAndVideos.media5.type === "video/mp4") || (photoVideo[4]?.type === "video") ? (
                <>
                  <video muted loop controls width="100%" height="100%">
                    <source
                      src={photoVideo[4]?.url
                        ? photoVideo[4].url
                        : URL.createObjectURL(photosAndVideos.media5)}
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <></>
              )}
              {photosAndVideos.media5 || photoVideo.length > 4 ? (
                <div
                  className="photo-remove-fab"
                  onClick={() => removeImage("media5")}
                >
                  <FontAwesomeIcon icon={faTimes}/>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="profile-photo-video-container"
              style={{
                backgroundImage: `url(${
                  photosAndVideos.media6
                    ? URL.createObjectURL(photosAndVideos.media6)
                    : photoVideo.length > 5 ?
                    photoVideo[5].url
                    : ""
                })`,
              }}
            >{photosAndVideos.media6 && loading && <Spinner animation="border" className="spinner"/>}
              {!(photosAndVideos.media6) && (photoVideo.length === 5) || photosAndVideos.media5 ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center photo-add-btn"
                  onClick={() => inputClicked(photoInput6)}
                  style={{
                    visibility: photosAndVideos?.media6 ? "hidden" : "unset",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{color: "#FFF"}}
                  />
                  <input
                    accept="image/*, video/mp4"
                    type="file"
                    onChange={(e) => onPhotoFileChange(e, "media6")}
                    onClick={(event)=> { event.target.value = null }}
                    hidden={true}
                    ref={photoInput6}
                  />
                </div>
              ) : (photosAndVideos.media6 &&
                photosAndVideos.media6.type === "video/mp4") || (photoVideo[5]?.type === "video") ? (
                <>
                  <video muted loop controls width="100%" height="100%">
                    <source
                      src={photoVideo[5]?.url
                        ? photoVideo[5].url
                        : URL.createObjectURL(photosAndVideos.media6)}
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <></>
              )}
              {photosAndVideos.media6 || photoVideo.length > 5 ? (
                <div
                  className="photo-remove-fab"
                  onClick={() => removeImage("media6")}
                >
                  <FontAwesomeIcon icon={faTimes}/>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-end mt-3">
        <ButtonComponent idName="saveBtn" name="Save" loading={btnLoading}
                         disabled={!(Object.values(photosAndVideos).find((content) => content != null))}
                         onClickFunction={() => handlePhotoVideoSubmit()}/>
      </div>
    </div>
    <ImageCropperComponent show={showImageCropper} hide={() => setShowImageCropper(false)} type={"photoVideo"} src={src} setSelectedImage={setCroppedImage}/>
    </>
  );
};

const mapStateToProps = (state) => ({
  meData: state.me.meResponse,
  photoVideo: state.setPhotoVideo.media,
  photoVideoError: state.setPhotoVideo.error,
  loading: state.setPhotoVideo.loading,
  deleteMediaResponse: state.deleteMedia,
});

const mapDispatchToProps = (dispatch) => ({
  setPreviousPhotosAndVideos: (media) => dispatch(setPhotosAndVideosActions.setPreviousPhotosAndVideos(media)),
  deleteMedia: (mediaId) => dispatch(deleteMediaActions.deleteMedia(mediaId)),
  removeDeletedMedia: (mediaId) => dispatch(setPhotosAndVideosActions.removeDeletedMedia(mediaId)),
  setPhotosVidoes: (data, showNotification) => dispatch(postImage(data, "photosAndVideos", showNotification)),
  setPhotoVideoLoading: () => dispatch(setPhotoVideoLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhotoVideo);
