import types from '../types';

const defaultState = {
  signUpData: null,
  loading: false,
  error: null,
};

const createAccount = (state = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_ACCOUNT_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_ACCOUNT:
      return {
        ...state,
        signUpData: action.createAccountData,
        error: null,
        loading: false,
      };
    case types.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
export default createAccount;
