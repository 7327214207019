import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './styles.css';
import ButtonComponent from "../ButtonComponent";

const StatusConfirmationModal = (props) => {
    const [modalData, setModalData] = useState(null);
  const modalOptions = {
    location: {
      activate: {
        displayInfo: "Are you sure you want to deactivate this location? Doing this will also deactivate all job listings associated with this location.",
        btnTxt: "Confirm Deactivate Location"
      },
      deactivate: {
        displayInfo: "Are you sure you want to activate this location? Please activate the jobs under this location manually.",
        btnTxt: "Confirm Activate Location"
      }
    },
    job: {
      activate: {
        displayInfo: "I understand by pausing this job listing, it will be hidden from job seekers and the account will not be charged again until I choose to unpause it.",
        btnTxt: "Confirm Pause Listing"
      },
      deactivate: {
        displayInfo: "I understand by unpausing this job listing, it will be immediately active and visible to job seekers. The account will be charged according to my selected plan.",
        btnTxt: "Confirm Unpause Listing"
      }
    }
  };

  useEffect(() => {
    console.log(props.show);
    if (props.show.status) {
      setModalData(modalOptions[props.show?.type]?.activate);
    } else {
      setModalData(modalOptions[props.show?.type]?.deactivate);
    }
  }, [props.show])

  return (
    <>
      <Modal show={props.show.modalState} dialogClassName="deactivate-confirmation-modal" keyboard={false}
             backdrop={"static"}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <div className="modal-close-fab" onClick={() => props.loading ? null : props.hide(false)}>
          <FontAwesomeIcon icon={faTimes}/>
        </div>

        <div className="d-flex flex-column align-items-center w-100 h-100">
          <div className="deactivation-info-display">
            {modalData?.displayInfo}
          </div>

          <ButtonComponent
            idName="deactivate-location-btn"
            name={modalData?.btnTxt}
            loading={props.loading}
            disabled={props.loading}
            onClickFunction={() => props.hide(props.show)}
          />
        </div>
      </Modal>
    </>
  )
}

export default StatusConfirmationModal;
