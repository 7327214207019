import React from "react";
import {Nav, Navbar, OverlayTrigger, Popover} from "react-bootstrap";
import {connect} from 'react-redux';
import brand from "../../assets/jipe-green-logo.svg";
import {Link, NavLink, useHistory} from "react-router-dom";
import "./style.css";
import LocalStorageService from "../../utils/LocalStorageService";
import {onLogout} from '../../redux/actions/login'
import Dropdown from "react-bootstrap/Dropdown";
import {generateNameInitialImg} from "../../utils/nameInitial";

const NavBarComponent = (props) => {
    const history = useHistory();
    const localStorageService = LocalStorageService;
    const currentLocation = history.location.pathname;

    const {meData, hasNewMessages} = props;
    const routePage = (key) => {
        history.push(key);
    };

    const logOut = () => {
        props.onLogout()
        localStorageService.clearToken();
    };

    return (
        <Navbar
            expand="lg"
            id="mainNav"
            onSelect={(selectedKey) => routePage(selectedKey)}
        >
            <Navbar.Brand>
                <img
                    alt=""
                    src={brand}
                    height={"40px"}
                    width={"200px"}
                    className="d-inline-block align-top"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className="navbar-link-items">
                <Nav className="navbar-link-item">
                    <NavLink
                        to="/location-manager"
                        id="navLink"
                        active={(currentLocation === "/location-manager").toString()}
                    >
                        <b>MANAGE LOCATIONS</b>
                    </NavLink>
                    <NavLink
                        to="/manager"
                        id="navLink"
                        active={(currentLocation === "/manager").toString()}
                    >
                        <b>MANAGE USERS</b>
                    </NavLink>
                    <NavLink
                        to="/application"
                        id="navLink"
                        active={(currentLocation === "/application").toString()}
                    >
                        <b>ALL APPLICATIONS</b>
                    </NavLink>
                    <NavLink
                        to="/message"
                        id="navLink"
                        style={{position: 'relative'}}
                        active={(currentLocation === "/message").toString()}
                    >
                        {hasNewMessages && (<div className={"navbar-unread-badge"}/>)}
                        <b>MESSAGES</b>
                    </NavLink>
                </Nav>

                <div className="d-flex flex-column text-right mr-4">
                    <span id="navbar-businessname">{meData?.basic_info?.business_name || ""}</span>
                    <OverlayTrigger
                        placement={'bottom'}
                        overlay={
                            <Popover>
                                <Popover.Content className="custom-jp-popover">
                                    {meData?.basic_info?.first_name || ""} {meData?.basic_info?.last_name || ""}
                                </Popover.Content>
                            </Popover>
                        }
                    >
          <span
              id="navbar-username">{meData?.basic_info?.first_name || ""} {meData?.basic_info?.last_name || ""}</span>
                    </OverlayTrigger>
                </div>

                <Dropdown>
                    <Dropdown.Toggle id="dropdown-item-button">
                        <img
                            src={meData?.profile_picture_url || generateNameInitialImg(`${meData?.basic_info?.first_name || ""} ${meData?.basic_info?.last_name || ""}`)}
                            alt="User Image" className="navImage mx-2"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {(props.accountType && props.accountType === "business_owner") &&
                            <Link to="/edit-profile" id="navItem">
                                <b>EDIT ACCOUNT</b>
                            </Link>
                        }

                        {(props.accountType && props.accountType === "business_owner") && (
                            <>
                                <Link to="/billing-info" id="navItem">
                                    <b>BILLING INFO</b>
                                </Link>

                                <Link to="/settings" id="navItem">
                                    <b>SETTINGS</b>
                                </Link>
                            </>
                        )
                        }

                        {/*{(props.accountType && props.accountType === "business_owner") &&*/}
                        {/*<Link to="/account" id="navItem">*/}
                        {/*  <b>ACCOUNT SETTINGS</b>*/}
                        {/*</Link>*/}
                        {/*}*/}

                        <Link to="/login" onClick={logOut} id="navItem">
                            <b>LOG OUT</b>
                        </Link>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar.Collapse>
        </Navbar>
    );
};

const mapStateToProps = (state) => ({
    meData: state.me?.meResponse?.data
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch(onLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComponent);
