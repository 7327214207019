import types from '../types';
import * as userService from '../../services/api/userService';


const setLoadingAction = () => ({
  type: types.ME_SET_LOADING,
});

export const meSuccess = response => ({
  type: types.ME,
  response,
});

const meFailed = error => ({
  type: types.ME_FAILED,
  error,
});

export const me = () => dispatch => {
  dispatch(setLoadingAction())
  return userService.me()
    .then(
      response => {
        dispatch(meSuccess(response.data))
      },
      error => {
        dispatch(meFailed(error))
      }
    );
};

