import React, {useEffect, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import "./style.css";
import ApplicantTableComponent from "./ApplicantTableComponent";
import Select from "react-select";
import {connect, useDispatch} from "react-redux";
import {clearGetLocationWithJobError, getLocationsWithJobs,} from "../../../redux/actions/addLocation";
import {useSnackbar} from "notistack";
import {clearGetAllApplicantsError, getAllApplicants} from "../../../redux/actions/applicantsAction";
import ApplicantCardModal from "../PositionPage/components/ApplicantCardModal";
import {jobSwipeAction, jobSwipeReset} from "../../../redux/actions/messagingAction";
import {useHistory} from "react-router-dom";

const ApplicationPage = (props) => {
  const [filters, setFilters] = useState({location: "", job: ""});
  const [locationOptions, setLocationOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState({hired: false, interviewed: false, hidden: false});
  const [isJipeSolution, setIsJipeSolution] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    locationsList,
    getLocationsList,
    locationState,
    clearError,
    getAllApplicantsData,
    applicantsInfo,
    applicantsState,
    meState,
    clearGetApplicantsError,
    applicantStatusState,
    hideApplicantState,
    jobSwipeState,
    jobSwipeActionDispatch
  } = props;

  useEffect(() => {
    if (locationsList?.length < 1) {
      getLocationsList();
    } else {
      getLocationOptions();
    }
  }, []);

  useEffect(() => {
    getAllApplicantsData(null, null, filterStatus);
  }, [filterStatus]);

  useEffect(() => {
    if (!jobSwipeState.loading) {
      if (jobSwipeState.data) {
        history.push({pathname: `/message/${jobSwipeState.data?.conversation_id}`});
        dispatch(jobSwipeReset())
      } else if (jobSwipeState.error) {
        enqueueSnackbar(jobSwipeState.error, {variant: "error"})
      }
    }
  }, [jobSwipeState])

  useEffect(() => {
    if (!applicantStatusState.loading) {
      if (applicantStatusState.data) {
        getAllApplicantsData(null, null, filterStatus);
      } else if (applicantStatusState.error) {
        enqueueSnackbar(applicantStatusState.error, {variant: "error"})
      }
    }
  }, [applicantStatusState])

  useEffect(() => {
    if (!hideApplicantState.loading) {
      if (hideApplicantState.data) {
        getAllApplicantsData(null, null, filterStatus);
      } else if (hideApplicantState.error) {
        enqueueSnackbar(hideApplicantState.error, {variant: "error"})
      }
    }
  }, [hideApplicantState])

  useEffect(() => {
    setIsJipeSolution(meState?.meResponse?.data?.application_type === "jipe_application")
  }, [meState.meResponse])

  useEffect(() => {
    if (!locationState.loading && locationState.error) {
      enqueueSnackbar(locationState.error || "Could not fetch location data", {
        variant: "error",
      });
      clearError();
    }
    if (!locationState.loading && locationState.data) {
      getLocationOptions();
    }
  }, [locationState.error, locationState.data]);

  useEffect(() => {
    if (!applicantsState.loading && applicantsState.error) {
      enqueueSnackbar(
        applicantsState.error || "Could not fetch applicants data",
        {
          variant: "error",
        }
      );
      clearGetApplicantsError();
    }
  }, [applicantsState.error, applicantsState.data]);

  const getLocationOptions = () => {
    if (locationsList?.length > 0) {
      let options = [];
      locationsList.map((data) => {
        options.push({label: data.location_title, value: data.location_id});
      });
      setLocationOptions(options);
    }
  };

  const startConversationHandler = (e, seeker, id) => {
    e.preventDefault()
    e.stopPropagation()
    const data = {
      job_id: id,
      seeker_id: seeker,
      swipe_direction: 'right'
    }
    jobSwipeActionDispatch(data)
  }

  const closeModal = () => {
    setSelectedIndex(null);
    setShowModal(false);
  };

  const handleChange = (selectedOption, filterType) => {
    setFilters({...filters, [filterType]: selectedOption});
    if (filterType === "location") {
      if (selectedOption) {
        //api call for filter with location id
        getAllApplicantsData(selectedOption.value, null, filterStatus);
        let newArray =
          locationsList.filter((object) => {
            return object.location_id === selectedOption?.value;
          }) || [];
        if (newArray.length > 0 && newArray[0].jobs?.length > 0) {
          let options = [];
          newArray[0].jobs.map((data) => {
            options.push({label: data.title, value: data.id});
          });
          setJobOptions(options);
        } else {
          setJobOptions([]);
        }
      } else {
        setFilters({location: "", job: ""});
        getAllApplicantsData(null, null, filterStatus);
      }
    } else if (filterType === "job") {
      if (selectedOption) {
        //api call for filter with location id and job id
        getAllApplicantsData(filters.location.value, selectedOption.value, filterStatus);
      }
      if (!selectedOption) {
        setFilters({...filters, job: ""});
        getAllApplicantsData(filters.location.value, null, filterStatus);
      }
    }
  };

  const openModal = (status, index) => {
    setShowModal(status);
    setSelectedIndex(index);
  };

  const statusChanged = (event) => {
    const {id, checked} = event.currentTarget;
    setFilterStatus({...filterStatus, [id]: checked})
  }

  return (
    <div className="containerMain">
      <Container fluid className="main-body" style={{height: '100%'}}>
        <Row className="applicationRow">
          <Col className="d-flex flex-column justify-content-center">
            <Row className="d-flex flex-row">
              <Row style={{width: "60%"}}>
                <Col
                  className="d-flex flex-column justify-content-center"
                  lg={"auto"}
                >
                  <label className="location-header">Filters</label>
                </Col>

                <Col lg={"auto"}>
                  <div className="application-job">
                    <Select
                      value={filters.location}
                      onChange={(selectedOption) =>
                        handleChange(selectedOption, "location")
                      }
                      options={locationOptions}
                      placeholder={"Location"}
                      isMulti={false}
                      isClearable={true}
                    />
                  </div>
                </Col>

                <Col lg={"auto"}>
                  <div>
                    <div className="application-name">
                      <Select
                        isDisabled={!filters.location?.value}
                        value={filters.job}
                        onChange={(selectedOption) =>
                          handleChange(selectedOption, "job")
                        }
                        options={jobOptions}
                        placeholder={"Job Listing"}
                        isMulti={false}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row style={{width: "40%"}}>
                <Col lg={"12"} className="d-flex flex-row justify-content-end">
                  <div className="d-flex flex-row align-items-center">
                    <label className="location-header">Status</label>
                  </div>
                  <div className="d-flex flex-row align-items-center ml-5 status-filter">
                    <Form.Check checked={filterStatus.interviewed} className="mt-1" id="interviewed"
                                onChange={(event) => statusChanged(event)}/>
                    <span className="ml-2">Interviewed</span>
                  </div>
                  <div className="d-flex flex-row align-items-center ml-4 status-filter">
                    <Form.Check checked={filterStatus.hired} className="mt-1" id="hired"
                                onChange={(event) => statusChanged(event)}/>
                    <span className="ml-2">Hired</span>
                  </div>
                  <div className="d-flex flex-row align-items-center ml-4 status-filter">
                    <Form.Check checked={filterStatus.hidden} className="mt-1" id="hidden"
                                onChange={(event) => statusChanged(event)}/>
                    <span className="ml-2">Hidden</span>
                  </div>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>

        <Row id="application-table-row">
          <ApplicantTableComponent
            applicantsInfo={applicantsInfo}
            itemClicked={(status, index) => openModal(status, index)}
            loading={applicantsState.loading}
            swipeRight={startConversationHandler}
          />
        </Row>

        {showModal &&
          <ApplicantCardModal
            show={showModal}
            onHide={() => closeModal()}
            isJipeSolution={isJipeSolution}
            data={applicantsInfo?.length && applicantsInfo[selectedIndex]}/>
        }
        {/*<PaginationComponent currentPage={2} totalItems={12}/>*/}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locationsList: state.addLocation?.locationsWithJobs?.data,
  locationState: state.addLocation?.locationsWithJobs,
  applicantsInfo: state.applicants?.allApplicants?.data?.results,
  applicantsState: state.applicants?.allApplicants,
  applicantStatusState: state.applicants?.applicantStatus,
  hideApplicantState: state.applicants?.hideApplicant,
  meState: state.me,
  jobSwipeState: state.messagingReducer.jobSwipe
});

const mapDispatchToProps = (dispatch) => ({
  getLocationsList: () => dispatch(getLocationsWithJobs()),
  clearError: () => dispatch(clearGetLocationWithJobError()),
  getAllApplicantsData: (locationId, jobId, status) =>
    dispatch(getAllApplicants(locationId, jobId, status)),
  clearGetApplicantsError: () => dispatch(clearGetAllApplicantsError()),
  jobSwipeActionDispatch: (data) => dispatch(jobSwipeAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPage);
