import React, {useEffect, useState} from "react";
import "./style.css";
import {Badge, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faEllipsisV, faEye, faPen, faStop} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import {useSnackbar} from "notistack";
import DynamicLinkInfoModal from "../../../../components/DynamicLinkInfoModal";
import {faLink} from "@fortawesome/free-solid-svg-icons/faLink";

const JobListingTableComponent = (props) => {
  const {handleActivation, handleCardClick, handleModalShow, handlePreview, meData} = props;
  const {enqueueSnackbar} = useSnackbar();
  const [showModal, setShowModal] = useState({state: false, link: ""});

  useEffect(() => {
    console.log(props);
  }, [])

  const showSnackbar = () => {
    enqueueSnackbar("Job status is inactive", {
      variant: "error",
    });
  }

  const handleJobListingAction = (type, data) => {
    handleModalShow(type, data.id)
  }

  const copyDynamicLink = (data) => {
    setShowModal({state: true, link: data.dynamic_link_url})
  }

  return (
    <div id="job-listing-table">
      <div className="job-listing-table-head">
        <Row className="mx-0 w-100">
          <Col style={{width: '100px'}}>POSITION</Col>
          <Col style={{width: '100px'}}
               className="d-flex flex-row align-items-center justify-content-center">IMPRESSIONS</Col>
          <Col style={{width: '80px'}} className="d-flex flex-row align-items-center justify-content-center">
            FAVORITES
          </Col>
          <Col style={{width: '80px'}} className="d-flex flex-row align-items-center justify-content-center">
            {meData?.data?.application_type === 'online_application' ? "APPLY CLICKS" : "APPLICATIONS"}
          </Col>
          <Col lg={{span: 1}} className="d-flex flex-row align-items-center justify-content-center">
            STATUS
          </Col>
          <Col className="d-flex flex-row align-items-center justify-content-center">
            DAYS REMAINING
          </Col>
          <Col lg={{span: 1}}>
          </Col>
        </Row>
      </div>

      <div className="job-listing-table-body">
        {props.jobsData.map((data, index) => (
          <Row
            className="pt-td mx-0 w-100 job-listing-table-tr"
            key={index}
          >
            <Col style={{width: '100px'}} className="d-flex flex-row align-items-center">
              <span className="position-name">{data.title}</span>
              <span className="position-id">#{data.number}</span>
            </Col>
            <Col style={{width: '100px'}}
                 className="d-flex flex-row align-items-center justify-content-center jl-stats">
              <span>{data.swipe_count}</span>
            </Col>
            <Col style={{width: '80px'}}
                 className="d-flex flex-row align-items-center justify-content-center jl-stats ">
              <span className="jl-underlined" style={{fontSize: "14px"}}
                    onClick={data.is_active ? () => handleCardClick(props.locationId, data.id, false) : showSnackbar}>{data.favorited}</span>
            </Col>
            <Col style={{width: '80px'}}
                 className="d-flex flex-row align-items-center justify-content-center jl-stats">
              {meData?.data?.application_type === 'online_application' ?
                (
                  <span style={{fontSize: "14px"}} className="jl-underlined"
                        onClick={data.is_active ? () => handleCardClick(props.locationId, data.id, true) : showSnackbar}>
                  {data.application_clicked_count}
                </span>
                ) :
                (
                  <span style={{fontSize: "14px"}} className="jl-underlined"
                        onClick={data.is_active ? () => handleCardClick(props.locationId, data.id, true) : showSnackbar}>{data.job_applications_count}</span>
                )
              }
            </Col>
            <Col lg={{span: 1}} className="d-flex flex-row align-items-center justify-content-center jl-stats">
              <span style={{fontSize: "14px"}}>
                {data.is_active ? (
                  <Badge
                    variant="success"
                    style={{backgroundColor: "#A3CF62"}}
                  >
                    Active
                  </Badge>
                ) : (
                  <Badge
                    variant="danger"
                    style={{backgroundColor: "#EE6C4D"}}
                  >
                    Inactive
                  </Badge>
                )}
              </span>
            </Col>

            <Col className="d-flex flex-row align-items-center justify-content-center jl-stats">
              {data?.is_active ?
                <span style={{color: +data?.remaining_days < 5 ? '#EE6C4D' : ''}}>{data?.remaining_days}</span>
                :
                <>-</>
              }
            </Col>

            <Col lg={{span: 1}} className="d-flex flex-row justify-content-end align-items-center">
              {props.locationStatus && <Dropdown onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle className="jl-location-ellipsis">
                  <FontAwesomeIcon
                    className="jl-ellipsis-icon"
                    icon={faEllipsisV}
                    style={{fontSize: "18px"}}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="jl-dropdown-menu">
                  <Dropdown.Item className="jl-dropdown-item" onClick={() => handleActivation(data.is_active, data.id)}>
                    <FontAwesomeIcon
                      icon={faStop}
                      style={{color: "#2C2B7C"}}
                      className="mx-3"
                    />
                    {data.is_active ? (
                      <span>Pause Job Listing</span>
                    ) : (
                      <span>Unpause Job Listing</span>
                    )}
                  </Dropdown.Item>
                  {data.is_active &&
                    <Dropdown.Item className="jl-dropdown-item" onClick={() => handleJobListingAction("editJob", data)}>
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{color: "#2C2B7C"}}
                        className="mx-3"
                      />
                      <span>Edit Job Listing</span>
                    </Dropdown.Item>}
                  {data.is_active && <Dropdown.Item
                    className="jl-dropdown-item"
                    onClick={() => handlePreview(data.id)}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{color: "#2C2B7C"}}
                      className="mx-3"
                    />
                    <span>Preview Job</span>
                  </Dropdown.Item>}
                  <Dropdown.Item
                    className="jl-dropdown-item"
                    onClick={() => copyDynamicLink(data)}
                  >
                    <FontAwesomeIcon
                      icon={faLink}
                      style={{color: "#2C2B7C"}}
                      className="mx-3"
                    />
                    <span>Share job link</span>
                  </Dropdown.Item>
                  {data.is_active &&
                    <Dropdown.Item className="jl-dropdown-item"
                                   onClick={() => handleJobListingAction("cloneJob", data)}>
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{color: "#2C2B7C"}}
                        className="mx-3"
                      />
                      <span>Clone Job Listing</span>
                    </Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>}
            </Col>
          </Row>
        ))}
      </div>

      {
        showModal.state && <DynamicLinkInfoModal show={showModal} onHide={() => setShowModal({state: false, link: ""})}
        />
      }
    </div>
  );
};

export default JobListingTableComponent;
