import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import "./style.css";
import ButtonComponent from "../ButtonComponent";

const SubscriptionModal = (props) => {
  const {show, hide, loading} = props;
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (show.state && show.data) {
      setSubscriptionData(show.data);
    }
  }, []);

  const onSubscriptionConfirm = () => {
    hide(true);
  }

  return (
    <>
      <Modal
        show={show.state}
        backdrop={"static"}
        onHide={hide}
        dialogClassName="subscription-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="h-100 w-100 p-2">
          <div className="subscription-header">Subscription Details</div>
          {
            subscriptionData &&
            <div style={{height: '200px', wordBreak: "break-all", overflowY: "auto", paddingTop: "10px"}}
                 className="text-center d-flex flex-column justify-content-center">
              <div>
                <span className="confirmation-header">Job listing credits / {subscriptionData?.recurring_interval}:</span>
                <span className="confirmation-content">{subscriptionData?.no_of_items}</span>
              </div>
              <div>
                <span className="confirmation-header">Available trial days:</span>
                <span className="confirmation-content">{subscriptionData?.trial_days}</span>
              </div>
              <div className="plan-price mt-3">
                ${subscriptionData?.amount}
              </div>
              <div className="plan-per-list-lbl">per {subscriptionData?.recurring_interval}</div>
            </div>
          }

          <div className="d-flex flex-row justify-content-around">
            <ButtonComponent idName="confirm-subs-btn" name="Cancel" type="outline"
                             onClickFunction={() => hide(false)} disabled={loading}/>

            <ButtonComponent idName="cancel-subs-btn" name="Confirm" onClickFunction={onSubscriptionConfirm}
                             loading={loading} disabled={loading}/>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionModal;

