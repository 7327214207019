import './style.css';
import React from "react";

const DeleteConfirmationPage = () => {

    return (
        <div className="delete-confirmation-container">
            <div className="delete-conf-header">
                Data Retention:
            </div>

            <div className="delete-conf-info">
                The change or deletion of your personal information, and other use of the Services by you, may result in
                the
                deletion and/or de-identification of certain associated other information. We may retain data and other
                information pertaining to you for as long as necessary for the purposes described in this Privacy
                Policy. This
                may include keeping your personal and other information after you have deleted or cancelled your account
                for the
                period of time needed for Jipe to pursue its legitimate business interests, conduct audits, comply with
                (and
                demonstrate compliance with) legal obligations, resolve disputes, and enforce our agreements.
            </div>
        </div>
    )
}

export default DeleteConfirmationPage;
