import React, {useEffect, useState} from "react";
import {Accordion} from "react-bootstrap";
import Select from "react-select";
import {convertTime, days, hasConflict, timeOptions} from '../../../../utils/availabilityUtils'
import "./style.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../../../components/ButtonComponent";
import {useSnackbar} from "notistack";

const ShareAvailabilityComponent = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {jobCreationData, setJobCreationData, jobType, setErrors, setJobType} = props
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [timeToAdd, setTimeToAdd] = useState({day: null, start: null, end: null});

  const selectJobType = (job_type) => {
    if (Object.keys(jobType).includes(job_type)) {
      setJobType((prevData) => {
        const newData = {...prevData}
        delete newData[job_type]
        return newData
      })
      setErrors({
        type: "Please select atleast one job type"
      })
    } else {
      setJobType({
        ...jobType, [job_type]: {type: job_type}
      })
    }
  }

  const removeTimeSlot = (day, index) => {
    let currentTimesheet = jobCreationData.availabilities.time_sheets.find(data => data.day === day)
    currentTimesheet.time_slots.splice(index, 1);
    setJobCreationData({...jobCreationData})
  }

  const timesMayVary = (index, event) => {
    const oldTimesheet = jobCreationData.availabilities.time_sheets[index]
    const updatedSheet = {...oldTimesheet, may_vary: event.currentTarget.checked}
    setJobCreationData(prevState => ({
      ...prevState, availabilities: {
        ...prevState.availabilities, time_sheets: prevState.availabilities.time_sheets.map((time, idx) => {
          if (idx === index) {
            return {...updatedSheet}
          } else {
            return time
          }
        })
      }
    }))
  }

  const saveSelectedTime = () => {
    if (timeToAdd.start && timeToAdd.end) {
      const availabilityDataChunks = hasConflict(timeToAdd.start.value, timeToAdd.end.value, jobCreationData.availabilities.time_sheets, timeToAdd.day)
      if (!availabilityDataChunks.error && !availabilityDataChunks.hasConflict) {
        setTimeToAdd(undefined);
        Object.keys(availabilityDataChunks.toAdd[0]).map(item => {
          let currentTimesheet = jobCreationData.availabilities.time_sheets.find(data => data.day === +item)
          if (currentTimesheet) {
            currentTimesheet.time_slots.push(availabilityDataChunks.toAdd[0][item])
            jobCreationData.availabilities.time_sheets.find((data, idx) => {
              if (data.day === +item) {
                jobCreationData.availabilities.time_sheets[idx] = currentTimesheet;
              }
            });
            //updating the state of job creation data
            setJobCreationData({...jobCreationData})
          }
        })
      } else {
        enqueueSnackbar(availabilityDataChunks.error, {variant: "error"});
      }
    } else {
      enqueueSnackbar("Please add in both from and to values before saving!", {variant: "error"});
    }
  }

  useEffect(() => {
    if (Object.keys(jobType).length > 0) {
      setErrors((prevData) => {
        const newData = {...prevData}
        delete newData["type"]
        return newData
      })
      props.navigationState(true);
    } else {
      props.navigationState(false);
    }
  }, [jobType]);

  return (<>
    <div className="h-25">
      <div className="stepper-title">Share availability</div>
      <div className="stepper-info">
        Select the availability needed for this role. You can be as specific as you want, or keep it broad to maximize
        candidate matches.
      </div>
    </div>

    <div className="h-75" style={{overflowY: "auto", overflowX: "hidden"}}>
      <div className="d-flex flex-row justify-content-between w-100">
        <button
          className={"availability-custom-btn " + (Object.keys(jobType).includes('Part Time') ? 'active-modal-btn' : '')}
          onClick={() => selectJobType('Part Time')}>PART TIME
        </button>

        <button
          className={"availability-custom-btn " + (Object.keys(jobType).includes('Full Time') ? 'active-modal-btn' : '')}
          onClick={() => selectJobType('Full Time')}>FULL TIME
        </button>

        <button
          className={"availability-custom-btn " + (Object.keys(jobType).includes('Temp/Seasonal') ? 'active-modal-btn' : '')}
          onClick={() => selectJobType('Temp/Seasonal')}>TEMP/SEASONAL
        </button>

        <button
          className={"availability-custom-btn " + (Object.keys(jobType).includes('Internship') ? 'active-modal-btn' : '')}
          onClick={() => selectJobType('Internship')}>INTERNSHIP
        </button>
      </div>

      <div className="mt-3" style={{width: '70%'}}>
        <Accordion onSelect={(active) => setActiveAccordion(active)} className="mt-3">
          {jobCreationData.availabilities.time_sheets.map((data, index) => <div key={index}>
            <Accordion.Toggle as={"div"} eventKey={index + 1} className="accordion-header">
              <span>{days[index + 1]}</span>
              {activeAccordion === (index + 1) ? <FontAwesomeIcon icon={faChevronDown} style={{color: 'black'}}/> :
                <FontAwesomeIcon icon={faChevronRight} style={{color: 'black'}}/>}
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={index + 1}>
              <div>
                {data.time_slots.map((time, idx) => {
                  if (time.start && time.end) {
                    return (<div className="d-flex flex-row align-items-center selected-time-wrapper" key={idx}>
                      <div className="availability-time-label">{convertTime(time.start)}</div>
                      <div className="availability-time-label">to</div>
                      <div className="availability-time-label">{convertTime(time.end)}</div>
                      <FontAwesomeIcon icon={faTimes} className="availability-delete-btn"
                                       onClick={() => removeTimeSlot(data.day, idx)}/>
                    </div>)
                  }
                })}

                {(timeToAdd?.day !== data?.day && !data?.time_slots?.length > 0) && (
                  <div className="d-flex mt-2 flex-row align-items-center">
                    <input type="checkbox" onChange={(event) => timesMayVary(index, event)}
                           checked={data.may_vary} value={data.may_vary} name={"times_may_vary"}/>
                    <span className="ml-2" id={"time-vary-lbl"}>Times may vary</span>
                  </div>)}

                {
                  timeToAdd?.day === data?.day && !data.may_vary && (
                    <div className="d-flex mt-2 flex-row align-items-center">
                      <Select
                        className="react-select-wrapper"
                        placeholder="FROM"
                        isClearable={true}
                        options={timeOptions}
                        onChange={value => setTimeToAdd({...timeToAdd, start: value})}
                      />
                      <Select
                        className="react-select-wrapper"
                        placeholder="TO"
                        isClearable={true}
                        options={timeOptions}
                        onChange={value => setTimeToAdd({...timeToAdd, end: value})}
                      />
                      <ButtonComponent idName="availability-save-btn" name='ADD'
                                       onClickFunction={() => saveSelectedTime()}/>
                    </div>
                  )
                }

                {!data?.may_vary && (<div className="w-100 text-center mt-2">
                  <ButtonComponent type="link" idName="availability-add-btn" name='add time slot'
                                   onClickFunction={() => setTimeToAdd({...timeToAdd, day: data.day})}/>
                </div>)}
              </div>
            </Accordion.Collapse>
          </div>)}
        </Accordion>

      </div>

    </div>
  </>)
}

export default ShareAvailabilityComponent;
