import types from "../types";
import axiosInstance from "../../services/api/axios";

export const postPromoCodeSuccess = (data) => ({
  type: types.POST_PROMO_CODE,
  data
});

const postPromoCodeLoading = () => ({
  type: types.POST_PROMO_CODE_LOADING
});

const postPromoCodeFailed = (error) => ({
  type: types.POST_PROMO_CODE_ERROR,
  error,
});

export const resetPromoCode = () => ({
  type: types.RESET_PROMO_CODE
})

export const postPromoCode = (data) => {
  return async (dispatch) => {
    try {
      dispatch(postPromoCodeLoading());
      const response = await axiosInstance.get(`stripe/promocode-details/${data}`);
      if (response && response.status === 200 && response.data.data) {
        dispatch(postPromoCodeSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(postPromoCodeFailed(err.code || "Promo code post failed"));
    }
  };
}
