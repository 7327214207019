import {Container} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import React from "react";
import "./style.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../../../../components/ButtonComponent";
import profilePic from '../../../../../assets/profile-pic.png'
import {generateNameInitialImg} from "../../../../../utils/nameInitial";

const ApplicantCardModal = (props) => {
  const {onHide, data, isJipeSolution, showContact} = props;
  console.log(data)

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      static={"true"}
      dialogClassName="applicant-info-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="modal-body-container p-0">
        <div className="applicant-top-banner">
          <div className="applicant-pp-wrapper">
            <img
              src={data?.user?.seeker_profile_picture || generateNameInitialImg(data?.user?.seeker_full_name)}
              alt="Applicant img"
              className="applicant-pp"
            />
          </div>

          <div className="applicant-icon-wrapper">
            <FontAwesomeIcon color={"white"} icon={faCommentAlt}/>
          </div>
        </div>

        <div className="applicant-main-info-wrapper">
          <div id="applicant-name">{data?.user?.seeker_full_name}</div>

          <div id="applicant-desc">{data?.user?.about_you}</div>

          {data?.user?.work_history?.length > 0 && (
            <div>
              <div className="applicant-items-heading">Work history</div>
              {data.user.work_history.map((workHistory, idx) => (
                <div
                  className="applicant-wh-item d-flex flex-row align-items-center"
                  key={idx}
                >
                  <div className="w-50">
                    <div className="application-wh-title">
                      {workHistory?.position}
                    </div>
                    <div className="application-wh-info">
                      {workHistory?.name}
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="application-wh-info">
                      {(workHistory?.start_date
                          ? workHistory?.start_date
                          : "") +
                        (workHistory?.end_date ? ` - ` : "") +
                        (workHistory?.end_date ? workHistory?.end_date : "")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {!showContact &&
            <div>
              <div className="applicant-items-heading">Contact Info</div>

              <div className="d-flex flex-column" id="contact-info">
                <div><span className="font-weight-bold">Email: </span>{data?.user?.basic_info?.email}</div>
                <div><span className="font-weight-bold">Contact number: </span>{data?.user?.basic_info?.phone}</div>
              </div>
            </div>
          }

          {data?.user?.skills?.length > 0 && (
            <div className="mt-3">
              <div className="applicant-items-heading">Skills</div>

              <div id="applicant-skill-carousel">
                {data?.user?.skills.length > 0 &&
                  data.user?.skills.map((skill, idx) => {
                    return (
                      <ButtonComponent
                        className="application-skill-btn"
                        name={skill.name}
                        key={idx}
                      />
                    );
                  })}
              </div>
            </div>
          )}

          <div>
            <div className="applicant-items-heading">Availability</div>

            <div className="applicant-availability">
              <div className="d-flex flex-row mt-2">
                <div className="availability-day">Monday:</div>
                <div className="availability-day">
                  {data?.user?.availabilities?.mon_from ||
                  data?.user?.availabilities?.mon_from ? (
                    <>
                      {" "}
                      <b>{data?.user?.availabilities?.mon_from || "-"}</b>
                      <span>{` to `}</span>
                      <b>{data?.user?.availabilities?.mon_to || "-"}</b>{" "}
                    </>
                  ) : "Not Available"}
                </div>
              </div>

              <div className="d-flex flex-row mt-2">
                <div className="availability-day">Tuesday:</div>
                <div className="availability-day">
                  {data?.user?.availabilities?.tue_from ||
                  data?.user?.availabilities?.tue_from ? (
                    <>
                      {" "}
                      <b>{data?.user?.availabilities?.tue_from || "-"}</b>
                      <span>{` to `}</span>
                      <b>{data?.user?.availabilities?.tue_to || "-"}</b>{" "}
                    </>
                  ) : "Not Available"}
                </div>
              </div>

              <div className="d-flex flex-row mt-2">
                <div className="availability-day">Wednesday:</div>
                <div className="availability-day">
                  {data?.user?.availabilities?.wed_from ||
                  data?.user?.availabilities?.wed_from ? (
                    <>
                      {" "}
                      <b>{data?.user?.availabilities?.wed_from || "-"}</b>
                      <span>{` to `}</span>
                      <b>{data?.user?.availabilities?.wed_to || "-"}</b>{" "}
                    </>
                  ) : "Not Available"}
                </div>
              </div>

              <div className="d-flex flex-row mt-2">
                <div className="availability-day">Thursday:</div>
                <div className="availability-day">
                  {data?.user?.availabilities?.thur_from ||
                  data?.user?.availabilities?.thur_from ? (
                    <>
                      {" "}
                      <b>{data?.user?.availabilities?.thur_from || "-"}</b>
                      <span>{` to `}</span>
                      <b>{data?.user?.availabilities?.thur_to || "-"}</b>{" "}
                    </>
                  ) : "Not Available"}
                </div>
              </div>

              <div className="d-flex flex-row mt-2">
                <div className="availability-day">Friday:</div>
                <div className="availability-day">
                  {data?.user?.availabilities?.fri_from ||
                  data?.user?.availabilities?.fri_from ? (
                    <>
                      {" "}
                      <b>{data?.user?.availabilities?.fri_from || "-"}</b>
                      <span>{` to `}</span>
                      <b>{data?.user?.availabilities?.fri_to || "-"}</b>{" "}
                    </>
                  ) : "Not Available"}
                </div>
              </div>

              <div className="d-flex flex-row mt-2">
                <div className="availability-day">Saturday:</div>
                <div className="availability-day">
                  {data?.user?.availabilities?.sat_from ||
                  data?.user?.availabilities?.sat_from ? (
                    <>
                      {" "}
                      <b>{data?.user?.availabilities?.sat_from || "-"}</b>
                      <span>{` to `}</span>
                      <b>{data?.user?.availabilities?.sat_to || "-"}</b>{" "}
                    </>
                  ) : "Not Available"}
                </div>
              </div>

              <div className="d-flex flex-row mt-2">
                <div className="availability-day">Sunday:</div>
                <div className="availability-day">
                  {data?.user?.availabilities?.sun_from ||
                  data?.user?.availabilities?.sun_from ? (
                    <>
                      {" "}
                      <b>{data?.user?.availabilities?.sun_from || "-"}</b>
                      <span>{` to `}</span>
                      <b>{data?.user?.availabilities?.sun_to || "-"}</b>{" "}
                    </>
                  ) : "Not Available"}
                </div>
              </div>
            </div>
          </div>


          {
            isJipeSolution && data?.user?.responsibilities_answers?.length ?
              <div className="mt-2">
                <div className="applicant-items-heading">Application Questions</div>
                <div>
                  {
                    data?.user?.responsibilities_answers?.map((item, idx) =>
                      <div className="d-flex flex-row align-items-center mt-2" key={idx}>
                        <div className="applicant-card-responsibility-lbl">{item?.name}</div>
                        <div className="applicant-card-responsibility-radio">
                          <div className="d-flex flex-row align-items-center">
                            <input type="radio" checked={item?.value === 'yes'} disabled={item?.value === 'no'}
                                   readOnly={true}/>
                            <span className="ml-2">Yes</span>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <input type="radio" checked={item?.value === 'no'} disabled={item?.value === 'yes'}
                                   readOnly={true}/>
                            <span className="ml-2">No</span>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              :
              <></>
          }

        </div>
      </Container>
    </Modal>
  );
};

export default ApplicantCardModal;
