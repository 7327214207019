import Modal from "react-bootstrap/Modal";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'react-bootstrap'
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import ButtonComponent from "../ButtonComponent";
import './style.css';


const MapComponent = (props) => {

  const {openLocationModal, setNewLocationData, newLocationData} = props
  const mapRef = useRef(null)
  const [zoom, setZoom] = useState(13);
  const [hasLocation, setHasLocation] = useState(false);
  const [geolocationPermission, setGeolocationPermission] = useState(false)
  const [show, setShow] = useState(false);
  const [latlng, setLatlng] = useState({lat: 40.758701, lng: -111.876183});
  const [markerLatLng, setMarkerLatLng] = useState({
    lat: Number(newLocationData.lat),
    lng: Number(newLocationData.lng)
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setHasLocation(true)
      setShow(false)
      setGeolocationPermission(true)
      setLatlng({
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude
      })
      setMarkerLatLng({
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude
      })
    })
    askLocationPermission()
  }, [])

  const askLocationPermission = () => {
    if (!newLocationData.lat) {
      navigator.permissions.query({name: 'geolocation'}).then((result) => {
        if (result.state === 'granted') {
          setGeolocationPermission(true)
          setShow(false)
        } else if (result.state === 'prompt') {
          setGeolocationPermission(false)
          setShow(true)
        } else if (result.state === 'denied') {
          setShow(true)
          setGeolocationPermission(false)
        }
      })
    }
  }

  const ready = (e) => {
    askLocationPermission()
    setHasLocation(true)
    mapRef.current.leafletElement.invalidateSize()
    if (newLocationData.lat && newLocationData.lng) {
      setMarkerLatLng({
        lat: Number(newLocationData.lat),
        lng: Number(newLocationData.lng)
      })
      centerMapView({
        lat: Number(newLocationData.lat),
        lng: Number(newLocationData.lng)
      })
    } else {
      mapRef.current.leafletElement.locate()
      setMarkerLatLng({
        lat: Number(latlng.lat),
        lng: Number(latlng.lng)
      })
      centerMapView({
        lat: Number(latlng.lat),
        lng: Number(latlng.lng)
      })
    }
  }

  const handleClick = (e) => {
    const map = mapRef.current;

    if (map !== null) {
      setMarkerLatLng(e.latlng)
      setNewLocationData({
        ...newLocationData,
        lat: String(e.latlng.lat),
        lng: String(e.latlng.lng)
      })

      centerMapView(e.latlng)
    }
  };

  const centerMapView = (latlng) => {
    const {leafletElement} = mapRef.current

    // setting the clicked location to the center
    leafletElement.setView(latlng);
    const point = leafletElement.project(latlng);
    leafletElement.panTo(leafletElement.unproject(point), {animate: true});
  }

  const handleLocationFound = (e) => {
    setHasLocation(true);
    setLatlng(e.latlng);
    if (!newLocationData.lat || !newLocationData.lng) {
      setMarkerLatLng(e.latlng)
      centerMapView(e.latlng)
    }
  };

  const setLocation = () => {
    if (!newLocationData.lat || !newLocationData.lng) {
      setNewLocationData({
        ...newLocationData,
        lat: String(markerLatLng.lat),
        lng: String(markerLatLng.lng)
      })
    }
    props.hide()
    openLocationModal()
  }

  const clearLocationAndCloseModal = () => {
    props.hide()
    openLocationModal()
  }

  const marker = hasLocation || newLocationData.lat ? (
    <Marker position={markerLatLng}>
      <Popup>You are here</Popup>
    </Marker>
  ) : null;

  return (
    <>
      <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide animation style={{
        position: 'absolute',
        top: 20,
        right: 20,
        minWidth: 200
      }}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded mr-2"
            alt=""
          />
          <strong className="mr-auto">Location Permission</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>Please turn on your location from settings</Toast.Body>
      </Toast>

      <Modal show={props.show} onHide={props.hide} dialogClassName="map-preview-modal"
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <div>
          <div className="modal-close-fab" onClick={clearLocationAndCloseModal}>
            <FontAwesomeIcon icon={faTimes}/>
          </div>
          <ButtonComponent
            idName="selectLocationBtn"
            name="Verify Coordinates"
            onClickFunction={setLocation}
          />
          <div className="mt-1">
            <Map
              zoom={zoom}
              ref={mapRef}
              whenReady={ready.bind(this)}
              center={[latlng.lat, latlng.lng]}
              onClick={handleClick}
              style={{position: 'relative'}}
              onLocationfound={handleLocationFound}
              zoomControl={false}
              maxBoundsViscosity={0.95}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {marker}
            </Map>
          </div>
        </div>


      </Modal>
    </>
  )
}

export default MapComponent;
