import types from '../types';

const defaultState = {
  createJobData: null,
  loading: false,
  error: null,
};

const createJob = (state = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_JOB_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_JOB:
      return {
        ...state,
        createJobData: action.createJobData,
        error: null,
        loading: false,
      };
    case types.CREATE_JOB_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.CREATE_JOB_REFRESH:
      return {
        ...state,
        error: "",
        loading: false,
        createJobData: "",
      }
    default:
      return state;
  }
};
export default createJob;
