import {combineReducers} from 'redux';
import login from './login';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import changePassword from './changePassword';
import createAccount from './createAccount';
import createJob from './createJob';
import updateJob from './updateJob';
import setProfilePicture from "./setProfilePictureReducer";
import setBanner from "./setBannerReducer";
import setVerificationData from "./emailVerificationReducer";
import setDescription from "./setDescriptionReducer";
import setSocialLinks from "./setSocialLinksReducer";
import setBusinessInfo from "./businessInfoReducer";
import setPhotoVideo from "./photoVideoReducer";
import addLocation from './addLocation';
import jobCategory from "./jobCategoryReducer";
import applicationTypeData from "./applicationTypeReducer";
import applicationQuestions from "./applicationQuestionsReducer";
import manageUsers from "./manageUsersReducer";
import setBillingInfo from "./billingReducer";
import me from "./me";
import deleteMedia from "./deleteMedia";
import jobDetails from "./jobDetailsReducer";
import applicants from "./applicantsReducer"
import subscription from "./subscriptionReducer"
import setBillingInvoice from "./billingInvoiceReducer";
import setBillingCardInfo from "./billingCardReducer";
import deactivationReasons from "./deactivationReasonsReducer";
import favourites from "./favouritesReducer"
import cancelSubscriptionReducer from "./cancelSubscriptionReducer";
import messagingReducer from "./messagingReducer";

const appReducer = combineReducers({
    login,
    createAccount,
    forgotPassword,
    resetPassword,
    setProfilePicture,
    setBanner,
    setVerificationData,
    setDescription,
    setSocialLinks,
    setBusinessInfo,
    setPhotoVideo,
    createJob,
    updateJob,
    addLocation,
    jobCategory,
    applicationTypeData,
    applicationQuestions,
    manageUsers,
    me,
    setBillingInfo,
    deleteMedia,
    jobDetails,
    applicants,
    subscription,
    setBillingInvoice,
    setBillingCardInfo,
    deactivationReasons,
    changePassword,
    favourites,
    cancelSubscriptionReducer,
    messagingReducer
});

const rootReducer = (state, action) => {
    // clear all data in redux to initial
    if (action.type === "RESET_APP")
        state = undefined
    return appReducer(state, action)
}

export default rootReducer;
