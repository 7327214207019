import types from '../types';
import * as jobCreationService from '../../services/api/jobCreationService';
import {getLocationFromLocationId} from "./addLocation";

const setLoadingAction = () => ({
    type: types.UPDATE_JOB_SET_LOADING,
});

const updateJobSuccess = updateJobData => ({
    type: types.UPDATE_JOB,
    updateJobData,
});

const changeUpdatedJobData = updateJobData => ({
    type: types.CHANGE_UPDATED_JOB_DATA,
    updateJobData,
});

const updateJobFailed = error => ({
    type: types.UPDATE_JOB_FAILED,
    error,
});

const updateJobRefresh = () => ({
    type: types.UPDATE_JOB_REFRESH,
});


export const updateJob = (updateJobData, id = null) => dispatch => {
    console.log(updateJobData)
    dispatch(setLoadingAction());
    return jobCreationService.updateJob(updateJobData)
        .then(
            response => {
                dispatch(updateJobSuccess(response.data))
                dispatch(changeUpdatedJobData(updateJobData)) // this is in addLocation reducer
                if (id) {
                    dispatch(getLocationFromLocationId(id));
                }
            },
            error => {
                dispatch(updateJobFailed(error))
            }
        );
};

export const refreshJob = () => dispatch => {
    //console.log('update dispatcher');
    dispatch(updateJobRefresh());
}


