import types from '../types';

const defaultState = {
  bannerImage: "",
  loading: false,
  error: "",
};

const setBanner = (state = defaultState, action) => {
  switch (action.type) {
    case types.UPLOAD_BANNER_IMAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.UPLOAD_BANNER_IMAGE:
      return {
        ...state,
        bannerImage: action.imageData,
        error: "",
        loading: false,
      };
    case types.UPLOAD_BANNER_IMAGE_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
export default setBanner;
