import React, {useEffect, useState} from "react";
import "./style.css";
import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import { isPositiveInteger, isGreaterThanZero } from '../../../../utils/utils'
import validator from "validator";

const CreateJobComponent = (props) => {
  const {jobCreationData, setJobCreationData, meData} = props;
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (jobCreationData.title && jobCreationData.min_age && jobCreationData.hourly_rate && Object.keys(errors).length === 0) {
      if (meData?.data) {
        if (meData?.data?.application_type === "online_application") {
          if (jobCreationData.online_application_url) {
            props.navigationState(true);
          } else {
            props.navigationState(false);
          }
        } else {
          props.navigationState(true);
        }
      }
    } else {
      props.navigationState(false);
    }
  }, [jobCreationData]);

  const onDataChange = (event) => {
    const {id, value} = event.target;
    console.log(id, value)
    if (event) {
      console.log(!validator.isURL(value))
      setJobCreationData({...jobCreationData, [id]: value});
      if((id === "number" || id === "min_age" || id === "hourly_rate")) {
        if(isPositiveInteger(value) || ( id === 'hourly_rate' && isGreaterThanZero(value) ) ) {
          if(errors[id]) {
            setErrors((prevState) => {
              const newData = { ...prevState }
              delete newData[id]
              return newData
            })
          }
        } else {
          setErrors({
            ...errors,
            [id]: id === "hourly_rate" ? `${id} must be a greater than 0` : `${id} must be a positive integer`
          })
        }
      }
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <div className="h-25">
        <div className="stepper-title">Create job listing</div>
        <div className="stepper-info">
          We need to collect job-specific listing information. This helps us match the right candidates to you!
        </div>
      </div>

      <div className="h-75 d-flex flex-column justify-content-center">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="d-flex flex-row w-100">
            <div style={{width: '75%'}}>
              <Form.Group controlId="title" onChange={onDataChange} style={{height: '60px'}}>
                <Form.Control placeholder="Job title" value={jobCreationData?.title || ""} required
                              className="cj-input-green"/>
              </Form.Group>
            </div>

            <div style={{width: '25%'}} className="ml-2">
              <Form.Group controlId="number" onChange={onDataChange} style={{height: '60px'}}>
                <Form.Control placeholder="Requisition/Job number (optional)" value={jobCreationData?.number || null} type={"number"}
                              className="cj-input-green" isInvalid={errors.number}/>
                <Form.Control.Feedback type="invalid">
                  {errors.number}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <div className="d-flex flex-row w-100">
            <div style={{width: '50%'}}>
              <Form.Group controlId="min_age" onChange={onDataChange} style={{height: '60px'}}>
                <Form.Control placeholder="Min age required" value={jobCreationData?.min_age || null} required
                              className="cj-input-green" isInvalid={errors.min_age} type="number"/>
                <Form.Control.Feedback type="invalid">
                  {errors.min_age}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div style={{width: '50%'}} className="ml-2">
              <Form.Group controlId="hourly_rate" onChange={onDataChange} style={{height: '60px'}}>
                <div className="price-input-wrapper" style={{width: "100%"}}>
                  <FontAwesomeIcon icon={faDollarSign} style={{width: "10%"}} className="dollar-prefix-icon"/>
                  <Form.Control placeholder="Starting hourly rate" value={jobCreationData?.hourly_rate || null} required
                                className="hourly-rate-input" isInvalid={errors.hourly_rate} type="number"/>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.hourly_rate}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          {meData?.data?.application_type === "online_application" ?
            <>
              <div className="job-url-info">
                Provide your existing URL for this job application. All applicants will be redirected to this URL when
                they choose to apply from Jipe.
              </div>

              <div style={{width: '100%'}}>
                <Form.Group controlId="online_application_url" style={{height: '60px'}}>
                  <Form.Control placeholder="Enter job application URL" value={jobCreationData?.online_application_url}
                                required className="cj-input-green"
                                onChange={onDataChange} type="text"/>
                </Form.Group>
              </div>
            </>
            :
            <></>
          }
        </Form>
      </div>
    </>
  )
}

export default CreateJobComponent;
