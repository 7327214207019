const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }

  function _setToken(access, refresh) {
    _clearToken();
    localStorage.setItem("access_token", access);
    localStorage.setItem("refresh_token", refresh);
  }

  function _setAccountType(accountType) {
    localStorage.setItem("ACCOUNT_TYPE", accountType);
  }

  function _getAccountType() {
    return localStorage.getItem("ACCOUNT_TYPE")
  }

  function _getAccessToken() {
    return localStorage.getItem("access_token");
  }

  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("ACCOUNT_TYPE");
  }

  function _removeAccountType() {
    localStorage.removeItem("ACCOUNT_TYPE");
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setAccountType: _setAccountType,
    getAccountType: _getAccountType,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    removeAccountType: _removeAccountType,
  }
})();

export default LocalStorageService;
