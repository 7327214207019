import {Col, Container, Form, Row} from "react-bootstrap";
import ButtonComponent from "../../../../components/ButtonComponent";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {getApplicationQuestions, setApplicationQuestions} from "../../../../redux/actions/applicationQuestionsAction";
import {connect} from "react-redux";
import validator from "validator";


const Questions = (props) => {
  const {
    getApplicationQuestionsState,
    getApplicationQuestionsAction,
    setApplicationQuestionsAction,
    applicationQuestionData,
    loading,
    error
  } = props;

  const {enqueueSnackbar} = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [displayQuestions, setDisplayQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [newQuestion, setNewQuestion] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    getApplicationQuestionsAction();
  }, []);

  useEffect(() => {
    if (
      !getApplicationQuestionsState.error &&
      !getApplicationQuestionsState.loading &&
      getApplicationQuestionsState.data
    ) {
      setDisplayQuestions(getApplicationQuestionsState.data);
    }
  }, [getApplicationQuestionsState]);

  const selectQuestion = (name) => {
    //console.log(getApplicationQuestionsState.data)
    const questionsArray = displayQuestions;
    if (questionsArray.some((object) => object.name === name)) {
      const idx = questionsArray.findIndex((object) => object.name === name);
      if (questionsArray[idx].is_selected === true) {
        questionsArray[idx].is_selected = false;
        setDisplayQuestions([...questionsArray]);
      } else {
        if (checkMaxSelection() < 5) {
          questionsArray[idx].is_selected = true;
          setDisplayQuestions([...questionsArray]);
        } else {
          enqueueSnackbar("You can only select upto 5 items", {
            variant: "error",
          });
        }
      }
    } else {
      alert("Object not found.");
    }
  };

  const addQuestion = (event) => {
    event.preventDefault();
    validate();
    if (!validate()) {
      enqueueSnackbar("Question must be of minimum 5 characters long", {
        variant: "error",
      });
    } else {
      if (checkMaxSelection() < 5) {
        setDisplayQuestions([
          ...displayQuestions,
          {name: newQuestion, is_selected: true},
        ]);
        setNewQuestion("")
        setQuestionCount(0)
        setShowModal(false);
      } else {
        enqueueSnackbar("Maximum question selection reached", {
          variant: "error",
        });
      }
    }
  };

  const validate = () => {
    if (!validator.trim(newQuestion)) {
      return false;
    } else if (newQuestion.length < 5) {
      return false;
    } else {
      return true;
    }
  };

  const checkMaxSelection = () => {
    if (displayQuestions.length > 0) {
      const count = displayQuestions.filter((obj) => obj.is_selected === true)
        .length;
      return count;
    } else {
      return 0;
    }
  };

  const handleQuestionChange = (e) => {
    if (e) {
      setQuestionCount(e.target.value.length);
      setNewQuestion(e.target.value);
    }
  };

  const submitData = () => {
    let selectedData = [];
    displayQuestions.length > 0 &&
    displayQuestions.map((data) => {
      if (data.is_selected) {
        selectedData.push(data.name);
      }
    });
    setApplicationQuestionsAction(selectedData);
  };

  useEffect(() => {
    if (applicationQuestionData && !loading && !error) {
      enqueueSnackbar("Application Question updated successfully", {
        variant: "success",
      });
    } else if (!applicationQuestionData && !loading && error) {
      enqueueSnackbar("Application Question update Failed", {
        variant: "error",
      });
    }
  }, [applicationQuestionData, loading, error]);

  const refresh = () => {
    setDisplayQuestions(getApplicationQuestionsState.data);
    setButtonEnabled(false);
  }

  return (
    <div id="profileCard" className="mb-4">
      <Row>
        <Col>
          <div className="profile-card-header">Questions</div>

          <div className="d-flex flex-column align-items-center">
            {displayQuestions.length > 0 &&
            displayQuestions.map((item, index) => (
              <button
                className={
                  "add-question-custom-btn " +
                  (item.is_selected
                    ? "active-add-question-btn"
                    : "")
                }
                onClick={() => selectQuestion(item.name)}
                key={index}
              >
                {item.name}
              </button>
            ))}
            {displayQuestions.length === 0 && (
              <span id="no-qn-lbl">
                          No questions added yet, create your first question!
                        </span>
            )}
          </div>

          <div className="text-center mt-4">
            <ButtonComponent
              name="Create your own"
              idName="profile-create-qn-btn"
              onClickFunction={() => setShowModal(!showModal)}
            />
          </div>

          <Modal
            show={showModal}
            onHide={() => setShowModal(!showModal)}
            dialogClassName="add-question-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Container className="modal-body-container">
              <Form noValidate onSubmit={(event) => addQuestion(event)}>
                <Form.Group controlId="descriptionForm">
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Question"
                    required
                    className="qn-input"
                    onChange={(e) => handleQuestionChange(e)}
                    maxLength="150"
                  />
                </Form.Group>

                <div
                  className={
                    "qn-counter mt-3 " +
                    (questionCount === 150 ? "max-counter" : "")
                  }
                >
                  {questionCount}/150
                </div>

                <ButtonComponent
                  disabled={checkMaxSelection() > 4}
                  name={"Add Question"}
                  idName="create-question-btn"
                  type={"submit"}
                />

              </Form>
            </Container>
          </Modal>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-end mt-3">
        <ButtonComponent idName="saveBtn" name="Save" onClickFunction={() => submitData()}/>
        {buttonEnabled && <ButtonComponent className={"profile-cancel-btn"} name="Cancel"
                                           type={"outline"}
                                           disabled={!buttonEnabled || btnLoading} onClickFunction={() => refresh()}/>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  getApplicationQuestionsState: state.applicationQuestions.getData,
  applicationQuestionData: state.applicationQuestions.postData.data,
  loading: state.applicationQuestions.postData.loading,
  error: state.applicationQuestions.postData.error,
});

const mapDispatchToProps = (dispatch) => ({
  getApplicationQuestionsAction: () => dispatch(getApplicationQuestions()),
  setApplicationQuestionsAction: (data) =>
    dispatch(setApplicationQuestions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
