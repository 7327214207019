import types from "../types";

const defaultState = {
  data: "",
  loading: false,
  error: "",
};

const setBillingCardInfo = (state = defaultState, action) => {
  switch (action.type) {
    case types.billingCardInfoConstants.SET_BILLING_CARD_INFO_LOADING:
      return {
        ...state,
        data: "",
        loading: true,
        error: "",
      };
    case types.billingCardInfoConstants.SET_BILLING_CARD_INFO_FAILED:
      return {
        ...state,
        data: "",
        error: action.error,
        loading: false,
      };
    case types.billingCardInfoConstants.SET_BILLING_CARD_INFO_SUCCESS:
      return {
        ...state,
        error: "",
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default setBillingCardInfo;
