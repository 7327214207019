import axiosInstance from "../../services/api/axios";
import types from "../types";
import { setProfilePicture } from "./setProfilePictureAction";
import { setBannerImage } from "./setBannerImageAction";
import { setPhotosAndVideos } from "./setPhotosAndVideosAction";

const setImageFailed = (error, info) => {
  return (dispatch) => {
    switch (info) {
      case "profilePicture":
        return dispatch({
          type: types.uploadImageConstants.SET_PROFILE_IMAGE_FAILED,
          error,
        });
      case "banner":
        return dispatch({
          type: types.UPLOAD_BANNER_IMAGE_FAILED,
          error,
        });
      case "photosAndVideos":
        return dispatch({
          type: types.SET_PHOTOS_VIDEOS_FAILED,
          error,
        });
    }
  };
};

export const postImage = (imageFile, info, showNotification, meData, profile_completed_up_to = null) => {
  return async (dispatch) => {
    try {
      let response;
      if (info === "photosAndVideos") {
        let responseArray = [];
        for (const [key, value] of Object.entries(imageFile)) {
          if (value) {
            let formData = new FormData();
            formData.append("file", value);
            try {
              const responseData = await axiosInstance.post("upload/", formData);
              if (responseData && responseData.data) {
                responseArray.push({ media_id: responseData.data.data.id });
              }
            } catch(err) {
              // handle error here
            }
          }
        }
        response = responseArray
      } else {
        response = await axiosInstance.post("upload/", imageFile);
      }
      if (response) {
        switch (info) {
          case "profilePicture":
            return dispatch(setProfilePicture(response.data.data.id, showNotification, meData, profile_completed_up_to));
          case "banner":
            return dispatch(setBannerImage(response.data.data.id, showNotification, meData, profile_completed_up_to));
          case "photosAndVideos":
          return dispatch(setPhotosAndVideos(response, showNotification, meData, profile_completed_up_to));
        }
      }
    } catch (err) {
      dispatch(setImageFailed(err.message, info));
      showNotification("Failed", "error")
    }
  };
};
