import Modal from "react-bootstrap/Modal";
import React from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './styles.css';
import ButtonComponent from "../ButtonComponent";
import {useSnackbar} from "notistack";

const DynamicLinkInfoModal = (props) => {
  const {enqueueSnackbar} = useSnackbar();

  const copyDynamicLink = () => {
    if (props.show.link) {
      navigator.clipboard.writeText(props.show.link).then(() => enqueueSnackbar("Job link copied!", {
        variant: "success",
      }));
    } else {
      enqueueSnackbar("No dynamic url found!", {
        variant: "error",
      })
    }
    props.onHide(true)
  }

  return (
    <>
      <Modal show={props.show.state} dialogClassName="deactivate-confirmation-modal" keyboard={false}
             backdrop={"static"}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <div className="modal-close-fab" onClick={() => props.loading ? null : props.onHide(false)}>
          <FontAwesomeIcon icon={faTimes}/>
        </div>

        <div className="d-flex flex-column align-items-center w-100 h-100">
          <div className="deactivation-info-display">
            Job listings are only viewed in the Jipe mobile app. This link does not work on desktop web browsers. It
            only works on mobile devices — ideal for sharing in texts, emails, and QR codes! Share your mobile listing
            now!
          </div>

          <ButtonComponent
            idName="deactivate-location-btn"
            name={"Copy Link"}
            onClickFunction={copyDynamicLink}
          />
        </div>
      </Modal>
    </>
  )
}

export default DynamicLinkInfoModal;
