import React, {useEffect, useState} from "react";
import "./style.css";
import {Form} from "react-bootstrap";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Select from "react-select";
import {useSnackbar} from "notistack";
import {postBillingAndPlanInfo} from "../../../../redux/actions/billingAction";
import {connect} from "react-redux";
import {CARD_OPTIONS, checkPhone, checkZip, stateOptions} from "../../../../utils/utils";

const CreditCardComponent = (props) => {
  const {
    cardAndPlanDataState,
    billingAction,
    creditCardData,
    setCreditCardData,
    meData,
    selectedPlan
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const {enqueueSnackbar} = useSnackbar();
  const [selectedOption, setSelectedOption] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [stripeError, setStripeError] = useState(null);
  const [stripeToken, setStripeToken] = useState(null);

  useEffect(() => {
    const validationErr = {};
    if (props.nextClicked) {
      console.log(props.nextClicked)
      if (creditCardData.phone) {
        if (!checkPhone(creditCardData.phone)) {
          validationErr.phone = 'Please enter a valid phone number.';
        }
      }
      if (creditCardData.zip) {
        if (!checkZip(creditCardData.zip)) {
          validationErr.zip = 'Invalid zip code.'
        }
      } else {
        validationErr.zip = 'Please enter zip code.'
      }
      if (!creditCardData.street) {
        validationErr.street = 'Please enter a street.';
      }
      if (!stripeToken) {
        enqueueSnackbar("Please re-enter your card info!", {variant: "error"});
      }
      if (Object.keys(validationErr).length > 0) {
        setFormErrors(validationErr);
        props.navigationState(false);
        props.setNextClicked(false);
      } else {
        setFormErrors({});
        const ccData = {
          card_token: stripeToken,
          product_id: selectedPlan.planId,
          ...creditCardData
        }
        handleSubmit(ccData);
      }
    }
  }, [props.nextClicked]);


  useEffect(() => {
    console.log(cardAndPlanDataState)
    if (cardAndPlanDataState?.error) {
      props.setNextClicked(false);
      try {
        Object.values(cardAndPlanDataState?.error?.response?.data).map((err) => {
          enqueueSnackbar(err, {variant: "error"})
        });
      } catch (err) {
        enqueueSnackbar(err.message, {variant: "error"})
      }
    }
    if (!cardAndPlanDataState?.error && cardAndPlanDataState?.data && !cardAndPlanDataState?.loading) {
      enqueueSnackbar(cardAndPlanDataState?.data?.message, {variant: "success"});
      props.changePage();
    }
  }, [cardAndPlanDataState.data, cardAndPlanDataState.loading, cardAndPlanDataState.error]);


  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setCreditCardData({
      ...creditCardData,
      state: selectedOption.value.name
    })
  };

  const cardChange = async (event) => {
    if (event?.error) {
      setStripeError(event?.error?.message || "Invalid card number");
      enqueueSnackbar(event?.error?.message || "Invalid card number", {variant: "error"})
    } else {
      if (event.complete) {
        const cardElement = elements.getElement(CardElement);
        const {error, token} = await stripe.createToken(cardElement);
        if (token) {
          props.navigationState(true);
          setStripeToken(token.id);
        } else {
          enqueueSnackbar(error, {variant: "error"})
        }
      }
    }
  }

  const onDataChange = (event) => {
    const {id, value} = event.target;
    if (event) {
      setCreditCardData({...creditCardData, [id]: value});
    }
    if ((id === 'street' || 'phone' || 'zip') && stripeToken) {
      props.navigationState(true);
      setFormErrors({});
    }
  };

  const handleSubmit = (data) => {
    billingAction(data, meData);
  }

  return (
    <>
      <div className="h-25">
        <div className="stepper-title">Add credit card</div>
        <div className="stepper-info">
          Jipe requires using a credit card for your payment. All business locations listed under this account will be
          billed to this card. You will not be charged until you add your first listing.
        </div>
      </div>

      <div className="h-75 d-flex flex-column">
        <div className="card-info-header">Card Information</div>

        <Form.Group controlId="card_name" className="mt-1" style={{height: '60px'}}>
          <Form.Control placeholder="Name On Card" className="card-input-green"/>
        </Form.Group>

        <div className="custom-stripe-input-wrapper">
          <CardElement id="stripeElement" onChange={cardChange}
                       options={CARD_OPTIONS}
          />
        </div>

        <div className="card-info-header mt-4">Billing Information</div>

        <div className="d-flex flex-row mt-2">
          <div style={{width: "50%"}} className="mr-2">
            <Form.Group controlId="street" style={{height: '60px'}}>
              <Form.Control placeholder="Street" required className="card-input-green" isInvalid={formErrors.street}
                            onChange={onDataChange}/>
              <Form.Control.Feedback type="invalid">
                {formErrors.street}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div style={{width: "25%"}} className="mr-2">
            <Form.Group controlId="street_alt" style={{height: '60px'}}>
              <Form.Control placeholder="Street alt" className="card-input-green" onChange={onDataChange}/>
            </Form.Group>
          </div>

          <div style={{width: "25%"}}>
            <Form.Group controlId="city" style={{height: '60px'}}>
              <Form.Control placeholder="City" className="card-input-green" onChange={onDataChange}/>
            </Form.Group>
          </div>
        </div>

        <div className="d-flex flex-row">
          <div className="w-25 mr-2">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={stateOptions}
              placeholder={'State'}
              isMulti={false}
              id="card-location-select"
            />
          </div>

          <div className="w-25 mr-2">
            <Form.Group controlId="zip" style={{height: '60px'}}>
              <Form.Control placeholder="Zip" className="card-input-green" onChange={onDataChange} type={"number"}
                            max="5" isInvalid={formErrors.zip}/>
              <Form.Control.Feedback type="invalid">
                {formErrors.zip}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="w-50">
            <Form.Group controlId="phone" style={{height: '60px'}}>
              <Form.Control placeholder="Phone" type={"number"} className="card-input-green"
                            isInvalid={formErrors.phone} onChange={onDataChange}/>
              <Form.Control.Feedback type="invalid">
                {formErrors.phone}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  cardAndPlanDataState: state.setBillingInfo.cardAndPlanData,
});

const mapDispatchToProps = (dispatch) => ({
  billingAction: (data, meData) => dispatch(postBillingAndPlanInfo(data, meData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCardComponent);

