import types from "../types";

const defaultState = {
  getData: {
    loading: false,
    error: "",
    data: [],
  },
  postData: {
    loading: false,
    error: "",
    data: "",
  },
};

const applicationQuestions = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_APPLICATION_QUESTIONS_LOADING:
      return {
        ...state,
        getData: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.GET_APPLICATION_QUESTIONS_FAILED:
      return {
        ...state,
        getData: {
          data: "",
          error: action.error,
          loading: false,
        },
      };
    case types.GET_APPLICATION_QUESTIONS:
      return {
        ...state,
        getData: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.SET_APPLICATION_QUESTIONS_LOADING:
      return {
        ...state,
        postData: {
          data: "",
          loading: true,
          error: "",
        },
      };
    case types.SET_APPLICATION_QUESTIONS_FAILED:
      return {
        ...state,
        postData: {
          data: "",
          loading: false,
          error: action.error,
        },
      };
    case types.SET_APPLICATION_QUESTIONS:
      return {
        ...state,
        postData: {
        error: "",
        data: action.data,
        loading: false,
        }
      };
    default:
      return state;
  }
};
export default applicationQuestions;
