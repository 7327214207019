import axiosInstance from './axios';

export const verifyEmail = async (data) =>
  axiosInstance.post("users/verify-verification-link/", data)

export const resetPassword = async (resetPasswordData) =>
  axiosInstance.post("users/verify-verification-link/", resetPasswordData)







