import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {connect} from "react-redux";
import "./style.css";
import ButtonComponent from "../../../../components/ButtonComponent";
import {setDescription} from "../../../../redux/actions";
import {useSnackbar} from "notistack";

const Description = (props) => {
  const [description, setDescription] = useState("")
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const {meDataState, updateDescription, descriptionState} = props;
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (meDataState?.data && !meDataState?.loading) {
      setDescription(meDataState?.data?.about_you)
    }
  }, [meDataState])


  useEffect(() => {
    if (!descriptionState?.loading) {
      setBtnLoading(false);
      if (descriptionState?.description && !descriptionState.error) {
        setButtonEnabled(false)
      } else if (!descriptionState?.description && descriptionState.error) {
        setButtonEnabled(true)
      }
    } else {
      setBtnLoading(true);
    }
  }, [descriptionState])
  const handleDescChange = (e) => {
    if (e) {
      setDescription(e.target.value)
      if (meDataState.data.about_you !== e.target.value && !buttonEnabled) {
        setButtonEnabled(true)
      } else if (meDataState.data.about_you === e.target.value && buttonEnabled) {
        setButtonEnabled(false)
      }
    }
  };

  const handleSubmit = () => {
    if (buttonEnabled) {
      updateDescription(description, meDataState, showNotification)
    }

  }

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }

  const refresh = () => {
    setDescription(meDataState?.data?.about_you)
    setButtonEnabled(false)
  }


  return (
    <div id="profileCard">
      <Row>
        <Col>
          <div className="d-flex flex-column justify-content-center">
              <span id="desc-header">
                Business description for potential employees
              </span>

            <Form.Group
              controlId="descriptionForm"
              className="w-100 mt-4"
            >
              <Form.Control
                as="textarea"
                placeholder="Enter description"
                required
                value={description}
                className="profile-description-input"
                onChange={(e) => handleDescChange(e)}
                maxLength="300"
              />
            </Form.Group>

            <div
              className={
                "description-counter mt-3 " +
                (description?.length === 300 ? "max-counter" : "")
              }
            >
              {description?.length}/300
            </div>
          </div>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-end mt-3">
        <ButtonComponent idName="saveBtn" name="Save" disabled={!buttonEnabled}
                         loading={btnLoading} onClickFunction={() => handleSubmit()}/>
        {buttonEnabled &&
        <ButtonComponent className={"profile-cancel-btn"} name="Cancel" type={"outline"}
                         disabled={btnLoading || !buttonEnabled} onClickFunction={() => refresh()}/>}
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  meDataState: state.me?.meResponse,
  descriptionState: state.setDescription
});

const mapDispatchToProps = (dispatch) => ({
  updateDescription: (data, meData, snackBar) => dispatch(setDescription(data, meData, snackBar))
})
export default connect(mapStateToProps, mapDispatchToProps)(Description);
