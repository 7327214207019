import types from '../types';

const defaultState = {
  links: "",
  loading: false,
  error: "",
};

const setSocialLinks = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_SOCIAL_LINKS_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        links: ""
      };
    case types.SET_SOCIAL_LINKS:
      return {
        ...state,
        links: action.links,
        error: "",
        loading: false,
      };
    case types.SET_SOCIAL_LINKS_FAILED:
      return {
        ...state,
        error: action.error,
        links: "",
        loading: false,
      };
      case types.CLEAR_SOCIAL_LINKS_STATE:
      return {
        ...state,
        error: "",
        links: "",
        loading: false,
      };
    default:
      return state;
  }
};
export default setSocialLinks;
