import types from '../types';
import * as jobCreationService from '../../services/api/jobCreationService';

const setLoadingAction = () => ({
  type: types.GET_JOB_DETAILS_LOADING,
});

const getJobDetailsSuccess = data => ({
  type: types.GET_JOB_DETAILS,
  data
});

const getJobDetailsFailed = error => ({
  type: types.GET_JOB_DETAILS_FAILED,
  error
});

export const clearGetJobDetailsError = () => ({
  type: types.CLEAR_GET_JOB_DETAILS_ERROR
});

export const getJobDetails = (jobId) => dispatch => {
  dispatch(setLoadingAction());
  return jobCreationService.getJob(jobId)
    .then(res => {
      dispatch(getJobDetailsSuccess(res.data))
    }, err => {
      dispatch(getJobDetailsFailed(err))
    })
}


