import axiosInstance from './axios';

export const addLocation = async (locationData) =>
    axiosInstance.post("business/locations/", locationData)

// export const getLocations = async (signupForm) =>
//     axiosInstance.get("business/locations/", signupForm)

// Remove the limit below after implementing pagination.
export const getLocations = async() =>  await axiosInstance.get("business/my-locations/?limit=1000")

export const getHiringManagers = async() =>  await axiosInstance.get("business/hiring-manager/");

export const editLocationData = async(id, data) => await axiosInstance.put(`business/locations/${id}/`, data);

export const deleteLocationData = async(id) => await axiosInstance.delete(`business/locations/${id}`);

export const getLocationFromId = async(id) => await axiosInstance.get(`/business/my-locations/${id}`);

export const updateLocationStatus = async(data) => await axiosInstance.patch(`/business/update-location-status/`,data)

export const getLocationsWithJobs = async() => await axiosInstance.get(`/business/locations/`)
