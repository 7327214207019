import React, {useEffect, useState} from "react";
import "./style.css";
import {Form} from "react-bootstrap";
import {connect} from "react-redux";

const Description = (props) => {
    const {description} = props;
    const [descCount, setDescCount] = useState(props.value.length);

    const handleDescChange = (e) => {
        if (e) {
            setDescCount(e.target.value.length);
        }
    };

    useEffect(() => {
        if (descCount >= 5 && descCount <= 300) {
            props.navigationState(true);
        } else {
            props.navigationState(false);
        }
    }, [descCount]);

    useEffect(() => {
        if (description.length >= 5) {
            props.navigationState(true)
        }
    }, [description])


    return (
        <>
            <div className="h-25">
                <div className="stepper-title">Set description</div>
                <div className="stepper-info">
                    Add a short description of your business and why someone would want to
                    submit an application. This will be included on your business profile and all job listings.
                </div>
            </div>

            <div className="h-75 d-flex flex-column justify-content-center align-items-center">
                <Form.Group controlId="descriptionForm">
                    <Form.Control
                        as="textarea"
                        placeholder="Enter Description"
                        required
                        value={props.value}
                        className={
                            "description-input" + (descCount > 300 ? " invalid-input" : "")
                        }
                        onChange={(e) => {
                            handleDescChange(e);
                            props.onChange(e);
                        }}
                    />
                </Form.Group>

                <div
                    className={
                        "description-counter mt-3 " + (descCount > 300 ? "max-counter" : "")
                    }
                >
                    {descCount || props.value.length}/300
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    descriptionError: state.setDescription.error,
    descriptionLoading: state.setDescription.loading,
    description: state.setDescription.description,
});

export const DescriptionComponent = connect(mapStateToProps)(Description);
