import { meSuccess } from "./me";

export const updateStepperCompleteStatus = (meData, profile_completed_up_to = null) => {

  return async (dispatch) => {
    try {
      if(meData && profile_completed_up_to) {
        if(profile_completed_up_to === 'application_type') {
          dispatch(meSuccess({
            ...meData,
            data: {
              ...meData.data, 
              application_type: 'online_application',
              profile_completed_up_to : profile_completed_up_to,
            }
          }))
        } else {
          dispatch(meSuccess({
            ...meData,
            data: {
              ...meData.data, 
              profile_completed_up_to : profile_completed_up_to,
            }
          }))
        }
      }
    } catch (error) {
      // Catch error while updating profile completion status
    }
  };
};