import React, {useEffect} from "react";
import "./style.css";
import {Form} from "react-bootstrap";
import CompanyIcon from "../../../../../assets/website.png";
import FbIcon from "../../../../../assets/facebookBtn.png";
import TwitterIcon from "../../../../../assets/twitterBtn.png";
import InstagramIcon from "../../../../../assets/instagramBtn.png";
import YoutubeIcon from "../../../../../assets/googleBtn.png";

const SocialComponent = (props) => {

  useEffect(() => {
    props.navigationState(true);
  }, []);

  return (
    <>
      <div className="h-25">
        <div className="stepper-title">Social</div>
        <div className="stepper-info">
          Add your company website url and links to your social properties to be included on your profile.
        </div>
      </div>

      <div className="h-75 d-flex flex-column  align-items-lg-start">
        <div className="d-flex flex-row align-items-center mb-3">
          <div>
            <img src={CompanyIcon} alt="Company Website" height="40px" width="40px"/>
          </div>

          <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
            <Form.Control placeholder="Company Url" required className="social-input" name="company_url"
                          onChange={(e) => props.handleChange(e)} value={props.data.company_url}
            />
          </Form.Group>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div>
            <img src={FbIcon} alt="Facebook" height="40px" width="40px"/>
          </div>

          <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
            <Form.Control placeholder="Facebook handle / URL" required className="social-input" name="facebook_url"
                          onChange={(e) => props.handleChange(e)} value={props.data.facebook_url}/>
          </Form.Group>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div>
            <img src={TwitterIcon} alt="Twitter" height="40px" width="40px"/>
          </div>

          <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
            <Form.Control placeholder="Twitter handle / Url" required className="social-input" name="twitter_url"
                          onChange={(e) => props.handleChange(e)} value={props.data.twitter_url}/>
          </Form.Group>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div>
            <img src={InstagramIcon} alt="Instagram" height="40px" width="40px"/>
          </div>

          <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
            <Form.Control placeholder="Instagram handle / Url" required className="social-input" name="instagram_url"
                          onChange={(e) => props.handleChange(e)} value={props.data.instagram_url}/>
          </Form.Group>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div>
            <img src={YoutubeIcon} alt="Youtube" height="40px" width="40px"/>
          </div>

          <Form.Group controlId="descriptionForm" className="mb-0 ml-3">
            <Form.Control placeholder="YouTube" required className="social-input" name="youtube_url"
                          onChange={(e) => props.handleChange(e)} value={props.data.youtube_url}/>
          </Form.Group>
        </div>
      </div>
    </>
  )
}

export {SocialComponent};
