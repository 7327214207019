import types from "../types";

const defaultState = {
  applicantsFromJobId: {
    error: "",
    loading: false,
    data: "",
  },
  allApplicants: {
    error: "",
    loading: false,
    data: ""
  },
  applicantStatus: {
    error: "",
    loading: false,
    data: ""
  },
  hideApplicant: {
    error: "",
    loading: false,
    data: ""
  }
};

const applicants = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_APPLICANTS_LOADING:
      return {
        ...state,
        applicantsFromJobId: {
          error: null,
          data: "",
          loading: true,
        },
      };
    case types.GET_APPLICANTS_FAILED:
      return {
        ...state,
        applicantsFromJobId: {
          error: action.error,
          data: "",
          loading: false,
        },
      };
    case types.GET_APPLICANTS_SUCCESS:
      return {
        ...state,
        applicantsFromJobId: {
          error: "",
          data: action.data,
          loading: false,
        },
      };

    case types.CLEAR_APPLICANTS_GET_ERROR:
      return {
        ...state,
        applicantsFromJobId: {
          error: "",
          data: "",
          loading: false,
        },
      };
    case types.GET_ALL_APPLICANTS_LOADING:
      return {
        ...state,
        allApplicants: {
          error: "",
          data: "",
          loading: true,
        },
      };
    case types.GET_ALL_APPLICANTS_ERROR:
      return {
        ...state,
        allApplicants: {
          error: action.error,
          data: "",
          loading: false,
        },
      };
    case types.GET_ALL_APPLICANTS_SUCCESS:
      return {
        ...state,
        allApplicants: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    case types.CLEAR_GET_ALL_APPLICANTS_ERROR:
      return {
        ...state,
        allApplicants: {
          error: "",
          data: "",
          loading: false,
        },
      };

    case types.UPDATE_APPLICANT_STATUS_LOADING:
      return {
        ...state,
        applicantStatus: {
          error: null,
          data: "",
          loading: true,
        },
      };
    case types.UPDATE_APPLICANT_STATUS_ERROR:
      return {
        ...state,
        applicantStatus: {
          error: action.error,
          data: "",
          loading: false,
        },
      };
    case types.UPDATE_APPLICANT_STATUS_SUCCESS:
      return {
        ...state,
        applicantStatus: {
          error: "",
          data: action.data,
          loading: false,
        },
      };

    case types.HIDE_APPLICANT_LOADING:
      return {
        ...state,
        hideApplicant: {
          error: null,
          data: "",
          loading: true,
        },
      };
    case types.HIDE_APPLICANT_ERROR:
      return {
        ...state,
        hideApplicant: {
          error: action.error,
          data: "",
          loading: false,
        },
      };
    case types.HIDE_APPLICANT_SUCCESS:
      return {
        ...state,
        hideApplicant: {
          error: "",
          data: action.data,
          loading: false,
        },
      };
    default:
      return state;
  }
};
export default applicants;
