import types from "../types";
import * as mediaService from "../../services/api/mediaService";

const setLoadingAction = () => ({
  type: types.DELETE_MEDIA_SET_LOADING,
});

const deleteMediaSuccess = (response) => ({
  type: types.DELETE_MEDIA,
  response,
});

const deleteMediaFailed = (error) => ({
  type: types.DELETE_MEDIA_FAILED,
  error,
});

export const deleteMedia = (mediaId) => (dispatch) => {
  dispatch(setLoadingAction());
  return mediaService.deleteMedia(mediaId).then(
    (response) => {
      dispatch(deleteMediaSuccess(response.data));
    },
    (error) => {
      dispatch(deleteMediaFailed(error));
    }
  );
};
