import types from "../types";

const defaultState = {
  error: "",
  loading: false,
  data: "",
};

const subscription = (state = defaultState, action) => {
  switch (action.type) {
    case types.POST_PROMO_CODE_LOADING:
      return {
        ...state,
        loading: true
      };
    case types.POST_PROMO_CODE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case types.POST_PROMO_CODE:
      return {
        loading: false,
        data: action.data,
        error: ""
      };
    case types.RESET_PROMO_CODE:
      return {
        error: "",
        loading: false,
        data: "",
      };
    default:
      return state;
  }
};
export default subscription;
