import types from '../types';

const defaultState = {
  meResponse: null,
  loading: false,
  error: null,
};

const me = (state = defaultState, action) => {
  switch (action.type) {
    case types.ME_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.ME:
      return {
        ...state,
        meResponse: action.response,
        loading: false,
        error: null,
      };
    case types.ME_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        meResponse: null,
      };
    default:
      return state;
  }
};
export default me;
