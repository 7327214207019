import React, {useEffect, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import "./style.css";
import ButtonComponent from "../../../components/ButtonComponent";
import ManagerTableComponent from "./ManagerTableComponent";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import validator from "validator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {
  addUsers,
  clearGetLocationListError,
  clearGetUserError,
  getHiringManagers,
  getLocationData,
  getLocations,
  getUsers,
  refreshState,
  updateUserData
} from "../../../redux/actions";
import {useSnackbar} from "notistack";

const ManagerPage = (props) => {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const {enqueueSnackbar} = useSnackbar();
  const [selectedData, setSelectedData] = useState(null);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    location: [],
    isAdmin: false,
  });

  const {
    createUser,
    userAdditionState,
    refreshReduxState,
    getLocationList,
    locationData,
    getLocationFailed,
    getLocationLoading,
    getUsers,
    users,
    updateUser,
    getHiringManagers,
    getLocationData,
    clearLocationError,
    clearGetUsersError
  } = props;
  const [validationErrors, setValidationErrors] = useState({});

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
    });
  }

  useEffect(() => {
    //check if user is business_owner
    if (locationData?.length < 1) {
      getLocationList();
    } else {
      setLocationToState();
    }
    if (users.data.length === 0) {
      getUsers();
    }
  }, []);

  useEffect(() => {
    if (
      users.data.length === 0 &&
      users.error &&
      !users.loading
    ) {
      enqueueSnackbar("Could not get users", {
        variant: "error",
      });
      clearGetUsersError()
    }
  }, [users]);

  useEffect(() => {
    if (locationData?.length > 0 && !getLocationLoading && !getLocationFailed) {
      setLocationToState();
    } else if (
      !getLocationLoading &&
      getLocationFailed &&
      locationData?.length===0
    ) {
      enqueueSnackbar("Could not get locations data", {
        variant: "error",
      });
      clearLocationError()
    }
  }, [locationData, getLocationFailed, getLocationLoading]);

  useEffect(() => {
    if (
      userAdditionState.data &&
      !userAdditionState.loading &&
      !userAdditionState.error
    ) {
      enqueueSnackbar(userAdditionState.data, {
        variant: "success",
      });
      getUsers();
      getHiringManagers();
      getLocationData()
      handleClose();
      refreshReduxState();
    } else if (
      !userAdditionState.data &&
      !userAdditionState.loading &&
      userAdditionState.error
    ) {
      console.log("update user data = ", userAdditionState)
      enqueueSnackbar(userAdditionState?.error || "Failed !", {
        variant: "error",
      });
      refreshReduxState();
    }
  }, [userAdditionState.loading]);


  const setLocationToState = () => {
    let data = [];
    locationData.map((obj) => {
      data.push({value: obj.location_id, label: obj.location_title});
    });
    setOptions(data);
  };

  const handleSelectChange = (selectedOption) => {
    setData({...data, location: selectedOption});
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValidationErrors({...validationErrors, [name]: ""});
    setData({...data, [name]: value});
  };

  const handleClose = () => {
    setSelectedData(null);
    setShow(false);
    setData({
      firstName: "",
      lastName: "",
      email: "",
      location: [],
      isAdmin: false,
    });
  };
  const handleShow = () => setShow(true);

  const locationBtnClick = () => {
    handleShow();
  };

  const checkBoxToggled = () => {
    const newData = !data.isAdmin;
    setData({
      ...data,
      isAdmin: newData,
    });
  };

  const userEdit = (index, value) => {
    setSelectedData(value.id);
    handleShow();
    let locations = [];
    if (value.locations?.length > 0) {
      value.locations.map((loc) => {
        locations.push({value: loc.id, label: loc.name});
      });
    }
    setData({
      firstName: value.first_name,
      lastName: value.last_name,
      email: value.email,
      location: locations,
      isAdmin: value.account_type === "business_owner" ? true : false,
    });
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }
    let postLocation = [];
    if (data.location?.length > 0) {
      data.location.map((loc) => {
        postLocation.push({location_id: loc.value});
      });
    }
    let submitData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      account_type: data.isAdmin ? "business_owner" : "hiring_manager",
      locations: data.isAdmin ? null : data.location?.length > 0 ? postLocation : null,
    };
    if (selectedData) {
      updateUser(submitData, selectedData)
    } else {
      createUser(submitData);
    }
  };

  const getValue = () => {
    if (data.location?.length > 0) {
      let value = [];
      data.location.map((loc) => {
        value.push({value: loc.value, label: loc.label})
      })
      return value;
    }
  }

  const validate = () => {
    const errors = {};
    if (!validator.trim(data.firstName)) {
      errors.firstName = "First name can not be empty";
    }
    if (!validator.trim(data.lastName)) {
      errors.lastName = "Last name can not be empty";
    }
    if (!validator.trim(data.email)) {
      errors.email = "Email can not be empty";
    } else if (!validator.isEmail(data.email)) {
      errors.email = "Provided email is not valid";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    }
    return Object.keys(errors).length === 0;
  };

  return (
    <div className="containerMain">
      <Container fluid className="main-body" style={{height: '100%'}}>
        {(props.accountType && props.accountType === "business_owner") &&
          <Row className="add-btn-row">
            <Col className="p-0 d-flex flex-column justify-content-center">
              <ButtonComponent
                name="ADD NEW"
                idName="add-manager-btn"
                onClickFunction={locationBtnClick}
              />
            </Col>
          </Row>
        }
        {users.loading ? (
          <div className="spinner-wrapper">
            <Spinner animation="border" className="spinner"/>
          </div>
        ) : users.data.length > 0 ? (
          <>
            {" "}
            <Row className="manager-table-row">
              <Col className="p-0">
                <ManagerTableComponent
                  userData={users.data}
                  onUserEdit={(index, data) => userEdit(index, data)}
                  {...props}
                />
              </Col>
            </Row>
            {/*<Row>*/}
            {/*  <Col className="p-0">*/}
            {/*    <PaginationComponent totalItems={8} currentPage={2}/>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </>
        ) : (
          <>
            <div className="no-data"> No data to display</div>
            {" "}
          </>
        )}
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="user-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Container className="modal-body-container p-0">
          <div className="location-modal-title">
            {selectedData ? "Edit User" : "Add User"}
            <FontAwesomeIcon
              icon={faTimes}
              className="user-modal-close"
              onClick={handleClose}
            />
          </div>

          <div className="user-modal-setting-wrapper d-flex flex-row">
            <div className="mr-3">
              <Form.Check
                id="make-admin-checkbox"
                value={data.isAdmin}
                checked={data.isAdmin}
                onChange={checkBoxToggled}
              />
            </div>
            <div className="d-flex flex-column">
              <div className="make-admin-lbl">Make this user an admin</div>
              <div className="make-admin-info">
                Admins have access to all locations in this business account and
                can add new users.
              </div>
            </div>
          </div>

          <Form.Group controlId="formBasicLast" className="my-3">
            <Form.Control
              placeholder="First name"
              required
              value={data.firstName}
              isInvalid={validationErrors.firstName}
              className="modal-input-green"
              name="firstName"
              maxLength={"50"}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid" className="error">
              {validationErrors.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicLast" className="my-3">
            <Form.Control
              placeholder="Last name"
              required
              className="modal-input-green"
              value={data.lastName}
              isInvalid={validationErrors.lastName}
              name="lastName"
              onChange={handleChange}
              maxLength={"50"}
            />
            <Form.Control.Feedback type="invalid" className="error">
              {validationErrors.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicLast" className="my-3">
            <Form.Control
              placeholder="Email"
              required
              className="modal-input-green"
              name="email"
              value={data.email}
              isInvalid={validationErrors.email}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid" className="error">
              {validationErrors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <label id="invite-info">
            An invite link will be sent to this email address
          </label>

          {data.isAdmin || locationData?.length === 0 ? (
            <></>
          ) : (
            <Select
              value={getValue()}
              onChange={handleSelectChange}
              options={options}
              placeholder={"Location"}
              isMulti={true}
              id="location-select"
            />
          )}

          <Row className="my-2">
            <Col className="p-0 d-flex flex-row justify-content-center">
              <ButtonComponent
                name={selectedData ? "Edit User" : "Add User"}
                idName="hiring-manager-btn"
                onClickFunction={handleSubmit}
              />
            </Col>
          </Row>
        </Container>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userAdditionState: state.manageUsers.postData,
  locationData: state.manageUsers.locationData.locations,
  getLocationLoading: state.manageUsers.locationData.loading,
  getLocationFailed: state.manageUsers.locationData.error,
  users: state.manageUsers.users,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(addUsers(data)),
  refreshReduxState: () => dispatch(refreshState()),
  getLocationList: () => dispatch(getLocations()),
  getUsers: () => dispatch(getUsers()),
  getHiringManagers: () => dispatch(getHiringManagers()),
  getLocationData: () => dispatch(getLocationData()),
  updateUser: (data, id) => dispatch(updateUserData(data, id)),
  clearLocationError: () => dispatch(clearGetLocationListError()),
  clearGetUsersError: () => dispatch(clearGetUserError())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerPage);
